import React, { useState } from "react";
import { Link } from "wouter";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { BsExclamationSquare } from "react-icons/bs";
import { FaLongArrowAltRight, FaClock, FaRedo } from "react-icons/fa";
import { groupBy } from "ramda";

import Layout from "layout/layout";
import { useTitle } from "helpers/useTitle";

import { NewProject, NewReference, Employee } from "generated/graphql";
import { EditProjectModal } from "components/editVersioned/editProject";
import { ActionLink, IconButton, TabButton } from "components/button";
import { inTenderingStatus } from "pages/project/utils";
import { TabBar, TabContent, Pane, useTab } from "components/tab";
import { Team } from "./team";
import ProjectHeader from "./header";
import { ProjectReferences } from "./references";
import { formatProjectId } from "pages/newProject/header";
import { NarrowTwoColumnLayout } from "layout/twoColumnLayout";
import { useMultiLang } from "helpers/multiLang";
import { Avatar } from "pages/newEmployee/avatar";
import { Search } from "components/search";
import { Banner, DraftBanner, DraftProcessBanner } from "components/banner";
import {
  EDIT_NEW_PROJECT_MUTATION,
  RESTORE_PROJECT_MUTATION,
} from "queries/newProject";
import { siaOrHoai } from "components/newReferences/utils";
import { useMutation } from "@apollo/client";

const Item = ({ label }) => {
  return (
    <div className="inline-block px-2 py-1 mb-2 mr-2 text-xs text-blue-500 bg-blue-500 bg-opacity-5">
      {label}
    </div>
  );
};

const ItemList = ({ title, items }: any) => {
  const { t } = useTranslation(["abacusProjects", "common"]);

  return (
    <div className="mt-1 md:mt-6">
      {title ? (
        <h5 className="hidden mb-2 text-lg text-blue-500 capitalize md:flex">
          {title}
        </h5>
      ) : null}
      {items?.length > 0 ? (
        items?.map((item: any) => <Item key={item} label={t(item)} />)
      ) : (
        <div>{t("common:none")}</div>
      )}
    </div>
  );
};

const KeyPeople = ({
  adminProjectManager,
  adminDeputyProjectManager,
  portfolioManager,
}: Partial<NewProject>) => {
  const { t } = useTranslation(["common", "project"]);
  const persons = [
    [adminProjectManager, "adminProjectManager"],
    [adminDeputyProjectManager, "adminDeputyProjectManager"],
    [portfolioManager, "portfolioManager"],
  ].filter(([p, _r]: any) => !!p?.id);

  const byPerson = groupBy(([p, _r]: any) => {
    return p?.id;
  });

  const grouped = Object.values(byPerson(persons));
  const sortedGroup = grouped.sort((a, b) => {
    const rolePersonA = a?.[0][1];
    const rolePersonB = b?.[0][1];
    if (
      rolePersonA == "adminDeputyProjectManager" &&
      rolePersonB == "adminProjectManager"
    )
      return 1;
    else {
      return -1;
    }
  });

  return (
    <div className="border-b border-gray-200">
      {sortedGroup.length > 0 ? (
        sortedGroup.map((keyPersons) => {
          const person = keyPersons?.[0][0];
          const roles = keyPersons?.map(([_p, role]: any) =>
            t(`project:keyRoles:${role}` as any),
          );
          return (
            <EmployeeShortProfile
              person={person}
              roles={roles}
              key={person.id}
            />
          );
        })
      ) : (
        <div>No key people defined</div>
      )}
    </div>
  );
};

const EmployeeShortProfile = ({ person, roles }) => {
  const { t } = useTranslation(["project", "abacusProjects"]);

  return (
    <div className="flex items-start mb-6">
      <div>
        <Avatar size="small" url={person.profilePicture} />
      </div>
      <div className="pl-4">
        <span className="cursor-pointer text-lg font-medium leading-3 text-blue-500 whitespace-nowrap hover:text-gray-500">
          <Link href={`/employee/${person.id}`}>
            {person.firstName} {person.lastName}
          </Link>
        </span>
        <div className="text-sm leading-4 text-gray-350">
          {roles.map((role, index) => (
            <div key={role}>
              {role ? role : t("abacusProjects:role_0")}
              {index < roles.length - 1 ? ",\u0020" : ""}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const RelatedProjectsList = ({
  projects,
}: {
  projects: Array<Partial<NewProject>>;
}) => {
  return (
    <>
      {projects.map((project) => (
        <div key={project.id} className="py-3 border-t border-gray-300">
          <RelatedProject project={project} />
        </div>
      ))}
    </>
  );
};

const RelatedProject = ({ project }: { project: Partial<NewProject> }) => {
  const m = useMultiLang();

  return (
    <div className="flex-1 truncate">
      <Link
        className="text-sm leading-tight text-black group hover:text-blue-500"
        href={`/project/${project.id}`}
      >
        <div>{m(project.name)}</div>

        <div className="flex items-center space-x-2 text-xs leading-relaxed text-gray-500 group-hover:text-blue-500">
          <div className="leading-none">
            {formatProjectId(project.abacusProjectId ?? "")}
          </div>
          <div>&mdash;</div>
          <div
            style={{ maxWidth: "8rem" }}
            className="overflow-hidden leading-none overflow-ellipsis whitespace-nowrap"
          >
            {project.client?.name || ""}
          </div>
        </div>
      </Link>
    </div>
  );
};

const ProjectDuration = ({ project }) => {
  const topLevelReferences = project.projectReferences?.filter(
    (r) => r.parentReferenceId === null,
  );

  const projectStartYears = topLevelReferences.map((reference) =>
    parseInt(reference?.from?.slice(0, 4), 10),
  );

  const projectEndYears = topLevelReferences.map((reference) =>
    parseInt(reference?.to?.slice(0, 4), 10),
  );

  const projectYears = projectStartYears
    .concat(projectEndYears)
    .sort((y1, y2) => {
      return y1 - y2;
    })
    .filter((value) => !Number.isNaN(value));

  const duration =
    projectYears[0] !== projectYears[projectYears.length - 1]
      ? `${projectYears[0]}–${projectYears[projectYears.length - 1]}`
      : `${projectYears[0]}`;

  return projectYears.length > 0 ? (
    <div>
      <div className="flex py-3 text-lg font-medium text-blue-500 border-b border-gray-200">
        <FaClock className="mt-1 mr-3" />
        {duration}
      </div>
    </div>
  ) : null;
};

const ProjectDetails = ({
  project,
  projectId,
}: {
  project: Partial<NewProject>;
  projectId: string;
}) => {
  const { t, i18n } = useTranslation(["project", "abacusProjects"]);
  const m = useMultiLang();
  const showPassiveAggressiveInfo =
    m(project.description).split(" ").length < 20;

  const { applicationAreas, services, siaPhases, hoaiPhases } = project;
  const useSia = i18n.language !== "de-DE";
  const phases = siaOrHoai(useSia, siaPhases ?? [], hoaiPhases ?? []);

  const [filterText, setFilterText] = useState("");
  return (
    <NarrowTwoColumnLayout
      leftContent={
        <>
          <div>
            <KeyPeople {...project} />
            {(project?.projectReferences ?? []).length > 0 ? (
              <ProjectDuration project={project} />
            ) : null}
            {(applicationAreas && applicationAreas.length > 0) ||
            (services && services.length > 0) ||
            phases.length > 0 ? (
              <div>
                <ItemList title={t("project:phases")} items={phases} />
                <ItemList
                  title={t("abacusProjects:application_areas")}
                  items={applicationAreas}
                />
                <ItemList
                  title={t("abacusProjects:services")}
                  items={services}
                />
              </div>
            ) : null}
          </div>

          <div>
            {(project.relatedProjects ?? []).length > 0 ? (
              <>
                <div className="mt-16 mb-2 text-xl font-normal text-blue-500">
                  {t("project:relatedProjects")}
                </div>
                <RelatedProjectsList projects={project.relatedProjects ?? []} />
              </>
            ) : null}
          </div>
        </>
      }
      rightContent={
        <div>
          <div className="mb-8 text-sm">
            <div className="markdown">
              <ReactMarkdown>{m(project.description)}</ReactMarkdown>
            </div>
            {showPassiveAggressiveInfo ? (
              <div className="pt-4 text-gray-500">
                <BsExclamationSquare className="float-left mt-2 mb-4 mr-4 text-4xl" />
                {t("project:shortText.title")}
                <br />
                {t("project:shortText.description")}
              </div>
            ) : null}
          </div>
          <div className="flex justify-between w-full">
            <div className="flex mt-2 font-medium leading-loose text-red-500">
              {t("project:projectTeam")}
            </div>
            <div className="flex items-center space-x-2"></div>

            <div className="flex justify-end mt-2">
              <Search text={filterText} setText={setFilterText} />
            </div>
          </div>
          <Team
            projectId={projectId}
            team={project?.aggregatedTeam ?? []}
            filterText={filterText}
          />
        </div>
      }
    />
  );
};

const MergedInfoBanner = ({
  parentProject,
}: {
  parentProject: Partial<NewProject>;
}) => {
  const { t } = useTranslation("project");
  const m = useMultiLang();

  return (
    <Banner
      title={t("mergedProject")}
      text={t("mergedProjectInfo", {
        projectName: m(parentProject.name),
      })}
      secondText={""}
      thirdText={""}
    >
      <ActionLink
        link={`/project/${parentProject.id}`}
        Icon={FaLongArrowAltRight}
        label={t("navigateToProject")}
        iconRight
      />
    </Banner>
  );
};

const ArchiveItem = ({ item }) => {
  const { t } = useTranslation("project");
  const formattedDate = item.dateCreated
    ? new Date(item.dateCreated).toLocaleDateString("de-CH")
    : null;
  return (
    <div className="flex flex-col p-2 my-8 border border-gray-100 rounded shadow">
      <div className="flex flex-row space-x-4 text-sm text-gray-800">
        <div>{item.location}</div>

        {formattedDate ? <div>{formattedDate}</div> : null}
        <div>
          {item.keywords
            .filter((keyword: any) => keyword !== "")
            .map((keyword: any) => {
              return (
                <span
                  className="inline-block px-2 mb-1 mr-2 text-xs leading-5 text-blue-500 bg-blue-500 bg-opacity-5"
                  key={`${keyword}-${item.id}`}
                >
                  {keyword}
                </span>
              );
            })}
        </div>
      </div>
      <h4 className="my-2 text-lg">{item.description}</h4>
      <div className="flex flex-row space-x-4 text-sm text-gray-800">
        <div>
          {t("room")}: {item.room}
        </div>
        <div>
          {t("shelf")}: {item.shelf}
        </div>
        <div>Nr: {item.internalNumber}</div>
      </div>
    </div>
  );
};

const ArchiveItems = ({ archiveItems }) => {
  const { t } = useTranslation("project");
  if (archiveItems.length === 0) {
    return <p>{t("noArchiveItems")}</p>;
  }
  return (
    <div>
      {archiveItems.map((item) => (
        <ArchiveItem key={item.id} item={item} />
      ))}
    </div>
  );
};

const Project = ({
  id,
  loading,
  project,
  currentEmployee,
  tab,
}: {
  id: string;
  loading: boolean;
  project: Partial<NewProject>;
  currentEmployee: Partial<Employee>;
  tab?: string;
}): JSX.Element => {
  const { t } = useTranslation(["project", "common"]);
  const m = useMultiLang();

  useTitle(`${m(project?.name)} - TBF TeamHub`);

  const [restoreProjectMutation] = useMutation(RESTORE_PROJECT_MUTATION);

  const [showsEditProject, setShowsEditProject] = useState(false);

  const displayedProject = project?.draft ? project.draft : project;

  const restoreProject = () => {
    restoreProjectMutation({
      variables: { id: project?.id },
      update(cache) {
        // remove from apollo cache
        const id = cache.identify({
          __typename: "NewProject",
          id: project?.id,
        });
        cache.evict({ id });
        cache.gc();
      },
    });
  };
  const areReferenceDraftsPresent =
    (displayedProject?.projectReferences ?? []).filter((r) => r?.draft).length >
    0;
  const referenceDrafts = (displayedProject?.projectReferences ?? [])
    .map((ref) => ref.draft as Partial<NewReference>)
    .filter((r) => r);

  const teamRefDrafts = (displayedProject?.aggregatedTeam ?? [])
    .map((member) => member.references)
    .reduce((acc, val) => acc.concat(val), [])
    .map((ref) => ref.draft as Partial<NewReference>)
    .filter((draft) => draft);

  const panes = (
    displayedProject
      ? [
          inTenderingStatus(displayedProject.status)
            ? null
            : {
                name: t("project:details"),
                key: "team",
                Content: () => (
                  <ProjectDetails
                    project={displayedProject}
                    projectId={project.id!}
                  />
                ),
              },
          inTenderingStatus(displayedProject.status)
            ? null
            : {
                name: t("project:references"),
                key: "references",
                Content: () => (
                  <ProjectReferences
                    projectId={project.id!}
                    team={project?.aggregatedTeam ?? []}
                    references={project?.projectReferences ?? []}
                    currentEmployee={currentEmployee}
                  />
                ),
              },
          (displayedProject.archiveItems ?? []).length > 0
            ? {
                name: t("project:archiveItems"),
                key: "archiveItems",
                Content: () => (
                  <ArchiveItems archiveItems={displayedProject.archiveItems} />
                ),
              }
            : null,
        ].filter((pane) => pane !== null)
      : []
  ) as Pane[];

  const tabProps = { panes, path: `/project/${id}`, currentTab: tab };
  useTab(tabProps);

  if (loading && !displayedProject) {
    return <Layout loading={loading}></Layout>;
  } else if (!displayedProject) {
    return (
      <Layout
        title={t("project:projectNotFound", { accountId: id }) ?? ""}
      ></Layout>
    );
  }

  return (
    <>
      <Layout loading={loading} noPadding />
      <DraftBanner url="/project" entity={project} />
      {displayedProject.isUnlisted && displayedProject?.parentProjects?.[0] ? (
        <MergedInfoBanner parentProject={displayedProject.parentProjects[0]} />
      ) : null}
      {project?.draft ||
      teamRefDrafts.length > 0 ||
      areReferenceDraftsPresent ? (
        <DraftProcessBanner
          draft={project?.draft ?? null}
          editMutation={EDIT_NEW_PROJECT_MUTATION}
          refDrafts={referenceDrafts}
          teamRefDrafts={teamRefDrafts}
        />
      ) : null}
      {displayedProject?.deletedAt && !project?.draft?.deletedAt && (
        <>
          <Banner
            title={t("project:deletedProject")}
            text={t("project:restoreProject")}
            secondText={""}
            thirdText={""}
          >
            <IconButton Icon={FaRedo} onClick={restoreProject}>
              {t("common:restore")}
            </IconButton>
          </Banner>
        </>
      )}
      <>
        <div className="sticky top-12 z-20 bg-white shadow">
          <div className="px-3 mx-auto max-w-screen-tbf">
            <ProjectHeader project={displayedProject} />

            <div className="flex items-center justify-between py-3 mx-auto mt-2 max-w-screen-tbf">
              <div>
                <TabBar {...tabProps} />
              </div>
              <div className="hidden md:block">
                {project?.draft?.isReadyForReview ? null : (
                  <TabButton
                    onClick={() => {
                      setShowsEditProject(true);
                    }}
                  >
                    {t("project:editProject")}
                  </TabButton>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="relative px-3 mx-auto max-w-screen-tbf">
          <TabContent {...tabProps} />
        </div>
        <EditProjectModal
          id={project.id}
          open={showsEditProject}
          close={() => {
            setShowsEditProject(false);
          }}
        />
      </>
    </>
  );
};

export default Project;
