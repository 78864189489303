import Blockquote from "@tiptap/extension-blockquote";
import BulletList from "@tiptap/extension-bullet-list";
import Document from "@tiptap/extension-document";
import Heading from "@tiptap/extension-heading";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Dropcursor from "@tiptap/extension-dropcursor";
import Gapcursor from "@tiptap/extension-gapcursor";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import History from "@tiptap/extension-history";
import { EditorContent, useEditor } from "@tiptap/react";
import React, { useState } from "react";
import { ReducedMenuBar } from "../../wiki/tiptapEditor/components/menuBar";

import "../../wiki/tiptapEditor/styles.scss";
import { useTranslation } from "react-i18next";
import { GenericFieldProps } from "components/form/fieldProps";
import Link from "@tiptap/extension-link";
import { LinkTextField } from "components/form/linkTextField";
import { ensureAbsoluteUrl } from "../../wiki/tiptapEditor/tiptapUtils";

export const ReducedEditor = ({
  value,
  onChange,
  supportedLanguage,
}: GenericFieldProps<string>): JSX.Element => {
  const { t } = useTranslation(["common", "wiki", "tiptapMenubar"]);
  const initialContent = sampleContent(
    t("wiki:placeholderTitle", { lng: supportedLanguage }),
    t("wiki:placeholderBodyTiptap", { lng: supportedLanguage }),
  );

  const editor = useEditor({
    extensions: [
      Document,
      Dropcursor,
      Gapcursor,
      Text,
      Paragraph,
      //
      Bold,
      Link.configure({ openOnClick: false }),
      Italic,
      ListItem,
      OrderedList,
      BulletList,
      Heading.configure({
        levels: [1, 2, 3],
      }),
      Blockquote,
      // Undo / Redo functionality
      History,
    ],
    content: value && value !== "" ? value : initialContent,
    onUpdate: (props) => {
      onChange(props.editor.getHTML());
    },
  });

  const [currentLinkUrl, setCurrentLinkUrl] = useState("");
  const [currentAdditionalInput, setCurrentAdditionalInput] = useState<
    string | null
  >(null);

  const handleSetLink = (editor, href) => {
    if (currentLinkUrl) {
      // update link
      editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({
          href: ensureAbsoluteUrl(href),
        })
        .run();
    } else {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();
    }
    setCurrentAdditionalInput(null);
  };

  return (
    <div className="w-full">
      {editor ? (
        <>
          <div
            className={`px-5 border mx-5 ${
              currentAdditionalInput == "link"
                ? "border-b rounded-t mt-2"
                : "mb-7 rounded"
            }`}
          >
            <ReducedMenuBar
              editor={editor}
              currentAdditionalInput={currentAdditionalInput}
              onClickLink={() => {
                if (currentAdditionalInput == null) {
                  setCurrentAdditionalInput("link");
                } else {
                  setCurrentAdditionalInput(null);
                }
                const previousUrl = editor.getAttributes("link").href;
                setCurrentLinkUrl(previousUrl);
              }}
            />
          </div>
          {currentAdditionalInput == "link" ? (
            <div className="p-1 mx-5 bg-white border border-t-0 rounded-b">
              <LinkTextField
                placeholder={t("tiptapMenubar:reducedLinkPlaceholder")}
                value={currentLinkUrl}
                onChange={setCurrentLinkUrl}
                onEnter={() => handleSetLink(editor, currentLinkUrl)}
                onClose={() => setCurrentAdditionalInput(null)}
              />
            </div>
          ) : null}
          <div
            className={`px-5 ${currentAdditionalInput == "link" ? "pt-3" : ""}`}
          >
            <EditorContent editor={editor} />
          </div>
        </>
      ) : null}
    </div>
  );
};

const sampleContent = (title, body) => {
  return `
    <h1>${title}</h1>
    <p>${body}</p>
  `;
};
