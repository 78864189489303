export type MultiLang = {
  de?: string;
  it?: string;
  fr?: string;
  en?: string;
  [lang: string]: string | undefined;
};

export const languages = ["de", "it", "fr", "en"];

export const newEmptyMultiLang = () => ({
  id: "new_lang",
  de: "",
  en: "",
  fr: "",
  it: "",
});

export const mergeMulti = (m1: MultiLang, m2: MultiLang): MultiLang => {
  return {
    de: m2?.de ?? m1?.de,
    fr: m2?.fr ?? m1?.fr,
    it: m2?.it ?? m1?.it,
    en: m2?.en ?? m1?.en,
  };
};

export const extractMulti = (field?: any): MultiLang => {
  if (field) {
    return {
      de: field.de || "",
      fr: field.fr || "",
      it: field.it || "",
      en: field.en || "",
    };
  } else {
    return { de: "", fr: "", it: "", en: "" };
  }
};

export const stringifyMulti = (field?: any): string => {
  if (field) {
    return `${field.de || ""}-${field.fr || ""}-${field.it || ""}-${
      field.en || ""
    }-`;
  } else {
    return "-";
  }
};
