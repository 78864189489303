import React from "react";
import { languages } from "../helpers/languages";
import { GroupedButton } from "./button";

export type LanguageSelectorProps = {
  currentLanguage: string;
  setCurrentLanguage: (lang: string) => void;
  disabled?: boolean;
};

export const LanguageSelector = ({
  currentLanguage,
  setCurrentLanguage,
  disabled,
}: LanguageSelectorProps) => {
  return (
    <div className="flex border rounded border-grey-300 bg-white">
      {languages.map((language) => {
        return (
          <GroupedButton
            key={language}
            selected={currentLanguage === language}
            onClick={() => setCurrentLanguage(language)}
            disabled={disabled}
          >
            <span className="px-3 uppercase">{language}</span>
          </GroupedButton>
        );
      })}
    </div>
  );
};
