import { NewProject } from "generated/graphql";
import { EditProjectForm } from "components/editVersioned/editProject";
import React from "react";
import { ManageGeneric } from "./common";
import { useMultiLang } from "helpers/multiLang";
import { formatProjectId } from "pages/newProject/header";
import { useTranslation } from "react-i18next";
import { FaHashtag, FaCoins } from "react-icons/fa";

export const ManageProjects = () => {
  const m = useMultiLang();
  const { t } = useTranslation(["project", "common"]);

  return (
    <ManageGeneric<NewProject>
      title={"Projekte"}
      searchType="new_project"
      dataAccessor="newProject"
      canCreateEntity
      editForm={EditProjectForm}
      listItemContent={(project, isActive) => {
        return (
          <>
            {m(project.name)}
            <div
              className={`flex flex-row text-sm space-x-4 ${
                isActive ? "text-gray-100" : "text-gray-500"
              }`}
            >
              {project.abacusProjectId ? (
                <div className="flex items-center">
                  <FaHashtag size="0.85em" className="mr-1 text-gray-400" />
                  {formatProjectId(project.abacusProjectId)}
                </div>
              ) : null}
              {project?.invoicingResponsible ? (
                <div className="flex items-center">
                  <FaCoins size="0.85em" className="mr-1 text-gray-400" />
                  {project.invoicingResponsible.fullName}
                </div>
              ) : null}
              {project?.invoicingResponsible?.isFormerEmployee ? (
                <span className="px-1 mx-3 text-sm text-gray-400 border border-gray-400 rounded-sm">
                  {t("common:former")}
                </span>
              ) : null}
              {project.isUnlisted ? (
                <span className="px-1 text-gray-400 border border-gray-400 rounded-sm">
                  {t("project:unlisted")}
                </span>
              ) : null}
            </div>
          </>
        );
      }}
    />
  );
};
