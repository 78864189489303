import { NewReference } from "generated/graphql";

export const getSortedProjectReferences = (
  references: Partial<NewReference>[],
): Partial<NewReference>[] => {
  const sortedParentReferences = references
    .filter((reference) => !reference.parentReference)
    .sort((a, b) => {
      const aSortDate = a?.from?.slice(0, 4) || a?.to?.slice(0, 4) || 0;
      const bSortDate = b?.from?.slice(0, 4) || b?.to?.slice(0, 4) || 0;
      if (aSortDate > bSortDate) return -1;
      else {
        return 1;
      }
    });

  const sortedChildReferences = references
    .filter((reference) => reference?.parentReference)
    .sort((a, b) => {
      const aSortDate = a?.from?.slice(0, 4) || a?.to?.slice(0, 4) || 0;
      const bSortDate = b?.from?.slice(0, 4) || b?.to?.slice(0, 4) || 0;
      if (aSortDate > bSortDate) return -1;
      else {
        return 1;
      }
    });

  const allSortedReferences = sortedParentReferences.concat(
    sortedChildReferences,
  );
  return allSortedReferences;
};
