import { gql } from "@apollo/client";

// QUERIES

export const PROFILE_QUERY = gql`
  query GetProfile {
    me {
      id
      lastName
      firstName
      username: activeDirectoryId
      profilePicture(size: 96)
      phone
      email
      activeDirectoryId
      isContentAdmin
      isWebsiteEditor
      isWikiEditor
    }
  }
`;
