import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NarrowFullHeightTwoColumnLayout } from "layout/twoColumnLayout";
import classNames from "classnames";
import { IconButton } from "components/button";
import { FaPlus } from "react-icons/fa";
import { newEmptyMultiLang } from "helpers/ui/multiLang";

import { Table, Item } from "./table";
import { EditItemModal } from "./editModal";
import { MergeItemModal } from "./mergeModal";

// HELPER COMPONENTS

const SideBar = ({ selectedCategory, setSelectedCategory }) => {
  const { t } = useTranslation(["employee", "reference-data"]);

  const categoryOptions = [
    {
      type: "header",
      key: "header-1",
      text: t("reference-data:profileSlashCv"),
    },
    {
      key: "skills",
      text: t("employee:skills"),
    },
    {
      key: "competences",
      text: t("employee:competences"),
    },
    {
      key: "diplomas",
      text: t("employee:diplomas"),
    },
    {
      key: "languages",
      text: t("employee:languages"),
    },
    {
      key: "roles",
      text: t("employee:roles"),
    },
    {
      key: "referenceRoles",
      text: t("employee:referenceRoles"),
    },
    {
      type: "header",
      key: "header-2",
      text: t("reference-data:projectsSlashReferences"),
    },
    {
      key: "factKeys",
      text: t("employee:factKeys"),
    },
  ];

  return (
    <div className="my-10">
      {categoryOptions.map((category) => {
        if (category.type === "header") {
          return (
            <h2
              key={category.key}
              className="mt-8 mb-2 text-xl font-medium text-blue-500"
            >
              {category.text}
            </h2>
          );
        }
        return (
          <div
            key={category.key}
            className={classNames("my-2 hover:text-black cursor-pointer", {
              "text-black": selectedCategory === category.key,
              "text-gray-600": selectedCategory !== category.key,
            })}
            role="button"
            onClick={() => setSelectedCategory(category.key)}
          >
            {category.text}
          </div>
        );
      })}
    </div>
  );
};

const ListCategory = ({ data, selectedCategory, actions }: any) => {
  const { t } = useTranslation(["employee", "reference-data"]);
  const [itemToEdit, setItemToEdit] = useState<Item | null>(null);
  const [itemToMerge, setItemToMerge] = useState<Item | null>(null);

  const listEntries = [...(data ?? [])]?.sort((a, b) => {
    const nameA = a.name["de"]?.toUpperCase() ?? "";
    const nameB = b.name["de"]?.toUpperCase() ?? "";
    return nameA < nameB ? -1 : 1;
  });

  return (
    <div className="flex flex-col h-full py-10 pl-12">
      <div className="flex">
        <h2 className="flex-grow mb-6 text-xl font-medium text-red-500">
          {t(selectedCategory)}
        </h2>
        <div>
          <IconButton
            Icon={() => <FaPlus size="1.2em" />}
            onClick={() =>
              setItemToEdit({
                id: "new",
                name: newEmptyMultiLang(),
              })
            }
          >
            {t("reference-data:new")}
          </IconButton>
        </div>
      </div>
      <div className="flex-grow overflow-y-auto border-b border-l border-r border-gray-200 shrink">
        <Table
          hideCount={selectedCategory === "factKeys"}
          items={listEntries}
          setItemToEdit={setItemToEdit}
          setItemToMerge={setItemToMerge}
        />
        {itemToEdit ? (
          <EditItemModal
            actions={actions}
            item={itemToEdit}
            close={() => setItemToEdit(null)}
          />
        ) : null}
        {itemToMerge ? (
          <MergeItemModal
            allItems={data}
            actions={actions}
            item={itemToMerge}
            close={() => setItemToMerge(null)}
          />
        ) : null}
      </div>
    </div>
  );
};

// MAIN COMPONENT

const Categories = ({ data, actions }: any) => {
  const [selectedCategory, setSelectedCategory] = useState("skills");

  return (
    <div className="flex-grow w-full px-3 mx-auto overflow-y-auto max-w-screen-2xl">
      <NarrowFullHeightTwoColumnLayout
        leftContent={
          <SideBar
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
        }
        rightContent={
          <ListCategory
            selectedCategory={selectedCategory}
            data={data?.[selectedCategory]}
            actions={selectedCategory ? actions[selectedCategory] : null}
          />
        }
      />
    </div>
  );
};

export default Categories;
