import React from "react";
import { createRoot } from "react-dom/client";
import { ErrorBoundary } from "@appsignal/react";
import { Toaster } from "react-hot-toast";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";

// TODO: do we need to import all locales that we support?
import dayjs from "dayjs";
import "dayjs/locale/de";
dayjs.extend(utc);
dayjs.extend(tz);
dayjs.tz.guess();

import translations_de from "./assets/i18n/de.json";
import translations_de_DE from "./assets/i18n/de-DE.json";
import translations_en from "./assets/i18n/en.json";
import translations_fr from "./assets/i18n/fr.json";
import translations_fr_FR from "./assets/i18n/fr-FR.json";
import translations_it from "./assets/i18n/it.json";
const translations = {
  de: translations_de,
  "de-DE": translations_de_DE,
  en: translations_en,
  fr: translations_fr,
  "fr-FR": translations_fr_FR,
  it: translations_it,
};
import ContextProvider from "./context/provider";
import AppShell from "./layout/appShell";
import ErrorPage from "./layout/errorPage";
import LoginForm from "./layout/loginForm";

// IMPORT BASE STYLES

import "easymde/dist/easymde.min.css";
import "./index.css";

// SETUP BUG TRACKING

import { stages, getReleaseStage } from "./helpers/getReleaseStage";
import { appsignal } from "initializers/appsignal";

// Lets keep this line to know what version is running
// @ts-ignore
console.log("APP_REVISION", import.meta.env.VITE_APP_REVISION);

// SETUP TRANSLATIONS

const language =
  sessionStorage.getItem("language") ||
  localStorage.getItem("language") ||
  window.navigator.language;

i18next.use(initReactI18next).init({
  lng: language,
  fallbackLng: "de",
  supportedLngs: ["de", "de-DE", "fr", "fr-FR", "it", "it-CH", "en"],
  nonExplicitSupportedLngs: true, // allows also e.g. en-GB, which then uses en
  debug: !(getReleaseStage() === stages.production_swarm),
  interpolation: { escapeValue: false },
  defaultNS: "common",
  resources: translations,
  saveMissing: true,
  // When using language 'cimode', this shows the full path for the keys
  appendNamespaceToCIMode: true,
});

// https://www.i18next.com/overview/typescript
declare module "i18next" {
  interface CustomTypeOptions {
    defaultNS: "common";
    resources: typeof translations_de;
  }
}

// TODO: this value should come from i18next
dayjs.locale("de");

// START APP

const App = () => {
  return (
    <ErrorBoundary
      instance={appsignal}
      fallback={(error) => <ErrorPage error={error} />}
    >
      <ContextProvider LoginForm={LoginForm}>
        <AppShell />
        <Toaster />
      </ContextProvider>
    </ErrorBoundary>
  );
};

const root = createRoot(document.getElementById("root")!);

root.render(<App />);
