import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa";
import Masonry from "react-masonry-css";
import { sortBy, uniq, flatten } from "ramda";
import { IconButton } from "components/button";

import { ReferenceItemView } from "components/newReferences";
import { EditReferenceModal } from "components/editVersioned/editReference";
import { NarrowTwoColumnLayout } from "layout/twoColumnLayout";
import { ProjectList } from "../projectList";
import { NewReference, NewProject, Employee } from "generated/graphql";
import { ItemListPlain } from "pages/newEmployee/tags";
import { useFuse } from "helpers/useFuse";
import { Search } from "components/search";
import dayjs from "dayjs";
import { SIA_CODES, CURRENCIES_TO_CHF, hoaiToSia } from "pages/project/utils";

// HELPER
const deduplicate = (array, fieldSelection) => {
  const duplicationArray = array.reduce(
    (acc, item) => [...acc, ...(fieldSelection(item) || [])],
    [],
  );
  return [...Array.from(new Set(duplicationArray))];
};

const siaToCategory = (sia: string): string | null => {
  const siaCode = SIA_CODES[sia];
  if (siaCode.startsWith("1") || siaCode.startsWith("2")) {
    return "sia_category_early";
  } else if (siaCode.startsWith("3") || siaCode.startsWith("4")) {
    return "sia_category_middle";
  } else if (siaCode.startsWith("5") || siaCode.startsWith("6")) {
    return "sia_category_late";
  } else {
    return null;
  }
};

const durationToDurationCategory = (
  from: string | null,
  to: string | null,
): string | null => {
  const fromD = dayjs(from);
  const toD = dayjs(to);

  if (!(fromD.isValid() && toD.isValid())) {
    return null;
  }

  const duration = toD.diff(fromD, "month");

  if (duration <= 12) {
    return "project_duration_short";
  } else if (duration <= 2 * 12) {
    return "project_duration_medium";
  } else if (duration <= 5 * 12) {
    return "project_duration_long";
  } else {
    return "project_duration_very_long";
  }
};

const investmentToProjectSizeCategory = (
  investmentCurrency?: string | null,
  investment?: number | null,
): string | null => {
  if (!investment) {
    return null;
  }
  const factor =
    CURRENCIES_TO_CHF[investmentCurrency?.toLowerCase() ?? ""] ?? 1;
  if (investment * factor < 100 * 1000) {
    return "project_size_small";
  } else if (investment * factor < 250 * 1000) {
    return "project_size_middle";
  } else {
    return "project_size_big";
  }
};

const getAggregatedCategories = (
  references: NewReference[],
  sortOrder: string[],
  toCategory: (reference: NewReference) => Array<string | null>,
) => {
  return sortBy(
    (category) => sortOrder.indexOf(`${category}`),
    uniq(
      flatten(
        references
          .map((r) => toCategory(r).filter((category) => !!category))
          .filter((category) => !!category),
      ),
    ),
  );
};

const EmployeeFact = ({ title, items }) => {
  return items.length > 0 ? (
    <>
      <div className="hidden mt-4 mb-1 text-base font-normal text-blue-500 md:block">
        {title}
      </div>
      <div>
        <ItemListPlain items={items} />
      </div>
    </>
  ) : null;
};

// MAIN COMPONENT

export const EmployeeReferences: React.FC<{
  referenceItems: NewReference[];
  projects: Array<Partial<NewProject>> | undefined;
  employeeId: string;
  employee: Partial<Employee>;
  currentEmployee: Partial<Employee>;
}> = ({ referenceItems, projects, employeeId, employee, currentEmployee }) => {
  const { t } = useTranslation(["employee", "abacusProjects", "project"]);
  const [showEditReferenceModal, setShowEditReferenceModal] = useState(false);

  const orderedReferences = referenceItems.sort((a, b) => {
    const aSortDate = a?.from?.slice(0, 4) || a?.to?.slice(0, 4) || 0;
    const bSortDate = b?.from?.slice(0, 4) || b?.to?.slice(0, 4) || 0;
    if (aSortDate > bSortDate) return -1;
    else {
      return 1;
    }
  });

  const [filter, setFilter] = useState("");
  const filteredReferences = useFuse(orderedReferences || [], filter, {
    keys: [
      "project.name.de",
      "project.name.en",
      "project.name.fr",
      "project.name.it",
      "project.abacusProjectId",
    ],
  });

  const filteredProjects = useFuse(projects || [], filter, {
    keys: ["name.de", "name.en", "name.fr", "name.it", "abacusProjectId"],
  });

  const uniqueApplicationAreas = deduplicate(
    orderedReferences,
    (r) => r.applicationAreas,
  );

  const uniqueSiaPhases = getAggregatedCategories(
    orderedReferences,
    ["sia_category_early", "sia_category_middle", "sia_category_late"],
    (r) => {
      const siaPhases =
        (r?.siaPhases ?? []).length > 0
          ? r.siaPhases
          : hoaiToSia(r.hoaiPhases ?? []);
      return (siaPhases ?? []).map(siaToCategory);
    },
  );

  const projectSizes = getAggregatedCategories(
    orderedReferences,
    ["project_size_small", "project_size_middle", "project_size_big"],
    (r) => {
      return [
        investmentToProjectSizeCategory(
          r?.privateReferenceProjectInvestmentCurrency ??
            r?.project?.investmentCurrency,
          r?.privateReferenceProjectInvestment ?? r?.project?.investment,
        ),
      ];
    },
  );

  const projectDurations = getAggregatedCategories(
    orderedReferences,
    [
      "project_duration_short",
      "project_duration_medium",
      "project_duration_long",
      "project_duration_very_long",
    ],
    (r) => {
      return [durationToDurationCategory(r.from, r.to)];
    },
  );
  const uniqueServices = deduplicate(orderedReferences, (r) => r.services);
  const uniqueRoles = deduplicate(orderedReferences, (r) =>
    r.role ? [r.role] : [],
  );
  const referenceSummaryAvailable =
    uniqueApplicationAreas.length + uniqueServices.length + uniqueRoles.length;
  const breakpointColumns = {
    default: 2,
    767: 1,
  };

  return (
    <NarrowTwoColumnLayout
      leftContent={
        <>
          <div className="hidden mb-1 text-xl font-medium text-blue-500 md:block">
            {t(`employee:referenceProfile`)}
          </div>
          <div>
            {referenceSummaryAvailable > 0 ? (
              <div>
                <h2 className="mb-2 text-xl font-medium text-red-500 md:hidden md:text-blue-500">
                  {`${t("employee:services")} & ${t(
                    "employee:applicationAreas",
                  )}`}
                </h2>
                <EmployeeFact
                  title={t(`abacusProjects:application_areas`)}
                  items={uniqueApplicationAreas.map((k) =>
                    t(`abacusProjects:${k}` as any),
                  )}
                />
                <EmployeeFact
                  title={t(`abacusProjects:services`)}
                  items={uniqueServices.map((k) =>
                    t(`abacusProjects:${k}` as any),
                  )}
                />
                <EmployeeFact
                  title={t(`project:projectDuration`)}
                  items={projectDurations.map((k) =>
                    t(`employee:aggregations.${k}` as any),
                  )}
                />
                <EmployeeFact
                  title={t(`employee:projectPhases`)}
                  items={uniqueSiaPhases.map((k) =>
                    t(`employee:aggregations.${k}` as any),
                  )}
                />
                <EmployeeFact
                  title={t(`employee:roles`)}
                  items={uniqueRoles.map((k) =>
                    t(`abacusProjects:${k}` as any),
                  )}
                />
                <EmployeeFact
                  title={t(`employee:projectSizes`)}
                  items={projectSizes.map((k) =>
                    t(`employee:aggregations.${k}` as any),
                  )}
                />
              </div>
            ) : (
              <p className="text-gray-500">
                {t(`employee:referenceSummaryAbsent`)}
              </p>
            )}
          </div>
        </>
      }
      rightContent={
        <>
          {filteredReferences.length ? (
            <div>
              <div className="flex flex-col sm:flex-row justify-between w-full">
                <div className="text-xl pb-2 sm:pb-none font-medium text-red-500">
                  {t(`employee:referenceProjects`)}
                </div>
                <div className="flex items-center space-x-2">
                  <IconButton
                    Icon={FaPlus}
                    onClick={() => {
                      setShowEditReferenceModal(true);
                    }}
                  >
                    {t(`employee:addPrivateReference`)}
                  </IconButton>
                  <Search text={filter} setText={setFilter} />
                </div>
              </div>
              <Masonry
                breakpointCols={breakpointColumns}
                className="masonry-grid"
                columnClassName="masonry-grid_column"
              >
                {filteredReferences?.map((referenceItem: any) => (
                  <ReferenceItemView
                    key={referenceItem?.id}
                    reference={referenceItem}
                    currentEmployee={currentEmployee}
                    isEmployeePartOfTeam={true}
                  />
                ))}
              </Masonry>
            </div>
          ) : (
            <>
              <div className="flex items-center justify-between w-full">
                <div className="text-xl font-medium text-red-500">
                  {t(`employee:referenceProjects`)}
                </div>
                <IconButton
                  Icon={FaPlus}
                  onClick={() => {
                    setShowEditReferenceModal(true);
                  }}
                >
                  {t(`employee:addPrivateReference`)}
                </IconButton>
              </div>
              <p className="mt-2 text-gray-500">
                {t(`employee:referencesAbsent`)}
              </p>
            </>
          )}

          {filteredProjects.length ? (
            <div>
              <div className="mt-10 mb-2 text-xl font-medium text-red-500 md:text-xl md:font-normal">
                {t(`project:additionalProjects`)}
              </div>
              <ProjectList
                projects={filteredProjects}
                employeeId={employeeId}
                references={referenceItems}
              />
            </div>
          ) : null}
          <EditReferenceModal
            newEntity={{
              projectId: null,
              employeeId,
              employee: employee as Employee,
            }}
            close={() => setShowEditReferenceModal(false)}
            open={showEditReferenceModal}
          />
        </>
      }
    />
  );
};
