import React, { useState } from "react";
import EmployeeHeader from "./profile/header";
import Layout from "layout/layout";
import { TabButton } from "components/button";
import { EditEmployeeModal } from "components/editVersioned/editEmployee";
import EmployeeBanner from "./banner";
import { EmployeeReferences } from "./references";
import { EmployeeOpenDrafts } from "./openDrafts";
import { TabBar, TabContent, Pane, useTab } from "components/tab";
import { useTranslation } from "react-i18next";
import { Employee, NewReference } from "generated/graphql";
import {
  DraftBanner,
  DraftProcessBanner,
  FormerEmployeeBanner,
} from "components/banner";
import { useTitle } from "helpers/useTitle";
import { EDIT_EMPLOYEE_MUTATION } from "queries/newEmployee";

const EmployeeDisplay: React.FC<{
  id?: string;
  activeDirectoryId?: string;
  loading: boolean;
  employee: Partial<Employee>;
  currentEmployee: Partial<Employee>;
  tab?: string;
}> = ({ id, activeDirectoryId, loading, employee, currentEmployee, tab }) => {
  const { t } = useTranslation("employee");
  useTitle(`${employee?.firstName} ${employee?.lastName} - TBF TeamHub`);
  const [showEditEmployee, setShowEditEmployee] = useState(false);

  const shouldDisplayOpenDraftsPane =
    employee?.countOpenDraftsCreatedByMe ?? 0 > 0;

  const panes = (
    employee
      ? [
          {
            name: t("profile"),
            key: "info",
            Content: () => <EmployeeBanner employee={displayedEmployee} />,
          },
          {
            name: t("projects_references"),
            key: "references",
            Content: () => (
              <EmployeeReferences
                referenceItems={
                  employee.references?.filter((r) => r !== null) || []
                }
                projects={employee.projects?.filter((p) => p !== null) || []}
                employeeId={employee.id!}
                employee={employee}
                currentEmployee={currentEmployee}
              />
            ),
          },
          shouldDisplayOpenDraftsPane
            ? {
                name: t("open_drafts"),
                annotation: shouldDisplayOpenDraftsPane
                  ? employee?.countOpenDraftsCreatedByMe
                  : null,
                key: "open-drafts",
                Content: () => <EmployeeOpenDrafts employeeId={employee.id!} />,
              }
            : null,
        ].filter((p) => !!p)
      : []
  ) as Pane[];

  const tabProps = {
    panes,
    path: activeDirectoryId ? `/user/${activeDirectoryId}` : `/employee/${id}`,
    currentTab: tab,
  };

  useTab(tabProps);
  if (loading && !employee) {
    return <Layout loading={loading}></Layout>;
  } else if (!employee) {
    return <Layout>{`employee ${id ?? activeDirectoryId} not found`}</Layout>;
  }

  const displayedEmployee = employee.draft ? employee.draft : employee;
  const areEmployeeReferenceDraftsPresent =
    (employee?.references ?? []).filter((r) => r?.draft).length > 0;
  const employeeReferenceDrafts = (employee?.references ?? [])
    .map((ref) => ref.draft as Partial<NewReference>)
    .filter((draft) => draft);

  return (
    <>
      <Layout noPadding loading={loading} />
      <DraftBanner url="/employee" entity={employee} />
      <FormerEmployeeBanner employee={employee} />
      {employee.draft || areEmployeeReferenceDraftsPresent ? (
        <DraftProcessBanner
          draft={employee?.draft ?? null}
          editMutation={EDIT_EMPLOYEE_MUTATION}
          refDrafts={employeeReferenceDrafts}
        />
      ) : null}

      <>
        <div className="sticky top-12 bg-white z-20 shadow pt-5">
          <div className="px-3 mx-auto max-w-screen-tbf">
            <EmployeeHeader
              employee={displayedEmployee}
              approvedEmployee={employee}
            />

            <div className="flex items-center justify-between py-3 mx-auto mt-2 max-w-screen-tbf">
              <div>
                <TabBar {...tabProps} />
              </div>
              <div className="hidden md:block">
                {employee?.draft?.isReadyForReview ? null : (
                  <TabButton
                    onClick={() => {
                      setShowEditEmployee(true);
                    }}
                  >
                    {t("editProfile")}
                  </TabButton>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="relative px-3 mx-auto max-w-screen-tbf">
          <TabContent {...tabProps} />
        </div>

        {showEditEmployee ? (
          <EditEmployeeModal
            id={employee.id}
            open={setShowEditEmployee}
            close={() => {
              setShowEditEmployee(false);
            }}
          />
        ) : null}
      </>
    </>
  );
};

export default EmployeeDisplay;
