import React from "react";
import { useTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";
import { Modal } from "components/modal";
import { IconButton } from "components/button";

import FocusedImage from "./focusedImage";
import FocusPicker from "./focusPicker";

const FocusGrid = ({ imageSrc, focus }) => {
  return (
    <div className="flex flex-col items-center">
      <div className="flex-1 block w-full h-full">
        <div
          className="grid"
          style={{
            height: 600,
            gridTemplateColumns: "6fr 3fr 2fr",
            gridTemplateRows: "6fr 3fr 2fr",
            gridGap: 5,
          }}
        >
          <div style={{ gridColumn: 1, gridRow: 1 }}>
            <FocusedImage imageSrc={imageSrc} x={focus.x} y={focus.y} />
          </div>
          <div style={{ gridColumn: 2, gridRow: 1 }}>
            <FocusedImage imageSrc={imageSrc} x={focus.x} y={focus.y} />
          </div>
          <div style={{ gridColumn: 3, gridRow: 1 }}>
            <FocusedImage imageSrc={imageSrc} x={focus.x} y={focus.y} />
          </div>
          <div style={{ gridColumn: 1, gridRow: 2 }}>
            <FocusedImage imageSrc={imageSrc} x={focus.x} y={focus.y} />
          </div>
          <div style={{ gridColumn: 2, gridRow: 2 }}>
            <FocusedImage imageSrc={imageSrc} x={focus.x} y={focus.y} />
          </div>
          <div style={{ gridColumn: 3, gridRow: 2 }}>
            <FocusedImage imageSrc={imageSrc} x={focus.x} y={focus.y} />
          </div>
          <div style={{ gridColumn: 1, gridRow: 3 }}>
            <FocusedImage imageSrc={imageSrc} x={focus.x} y={focus.y} />
          </div>
          <div style={{ gridColumn: 2, gridRow: 3 }}>
            <FocusedImage imageSrc={imageSrc} x={focus.x} y={focus.y} />
          </div>
          <div style={{ gridColumn: 3, gridRow: 3 }}>
            <FocusedImage imageSrc={imageSrc} x={focus.x} y={focus.y} />
          </div>
        </div>
      </div>
    </div>
  );
};

type Focus = {
  x: number;
  y: number;
};

type FocusModalProps = {
  imageSrc: string;
  focus: Focus;
  setFocus: (focus: Focus) => void;
  close: () => void;
};

export const FocusModal = ({
  imageSrc,
  focus,
  setFocus,
  close,
}: FocusModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      actions={
        <div className="flex items-center px-6 space-x-2">
          <IconButton type="secondary" Icon={IoMdClose} onClick={close}>
            {t("close")}
          </IconButton>
        </div>
      }
      open={true}
      close={close}
      title={
        <div className="px-6 py-8 text-2xl font-medium text-red-500">
          {t("focusPoint")}
        </div>
      }
    >
      <div className="flex my-8 mx-10 space-x-8">
        <div className="flex-1">
          <h2 className="mb-2 text-2xl">{t("picker")}</h2>
          <FocusPicker
            imageSrc={imageSrc}
            focus={focus}
            onFocusChanged={setFocus}
          />
        </div>
        <div className="flex-1">
          <h2 className="mb-2 text-2xl">{t("previewGrid")}</h2>
          <FocusGrid imageSrc={imageSrc} focus={focus} />
        </div>
      </div>
    </Modal>
  );
};
