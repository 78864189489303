import React, { FC, useContext } from "react";
import { IconButton, SecondaryButton } from "components/button";
import {
  FaArrowDown,
  FaCheck,
  FaChevronDown,
  FaChevronUp,
  FaEdit,
  FaExternalLinkAlt,
  FaLanguage,
  FaSave,
  FaSpinner,
  FaTimes,
} from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { Link } from "wouter";
import { useMutation, useQuery } from "@apollo/client";
import {
  EDIT_AND_APPROVE_EMPLOYEE_MUTATION,
  OUTDATED_TRANSLATIONS,
} from "queries/translations";
import { useState } from "react";
import {
  Employee,
  InputMultilangText,
  MultilangText,
  UpdatedMultilangField,
} from "generated/graphql";
import { languages } from "helpers/languages";
import { MarkDownField } from "components/form/markDownField";
import { Popup } from "components/popup";
import { extractMulti, mergeMulti } from "helpers/ui/multiLang";
import { Modal } from "components/modal";
import { IoMdClose } from "react-icons/io";
import axios from "axios";
import { getApiUrl } from "helpers/apiUrl";
import AuthContext from "context/user";
import { useEffect } from "react";

export const OutdatedTranslations = () => {
  const { t } = useTranslation("reference-data");
  const { data } = useQuery(OUTDATED_TRANSLATIONS);
  const employees = data?.employeesWithOutdatedTranslations ?? [];
  return (
    <div className="flex flex-col h-full mt-4">
      {employees.map((e) => {
        return <TranslationGroup key={e.id} employee={e} />;
      })}
      {employees.length == 0 ? (
        <div className="mx-auto max-w-screen-xl w-full px-4">
          {t("noOutdatedTranslations")}
        </div>
      ) : null}
    </div>
  );
};

const TranslationGroup: FC<{ employee: Partial<Employee> }> = ({
  employee,
}) => {
  const { t } = useTranslation();
  const [values, setValues] = useState<Partial<InputMultilangText>>({});
  const [showResetButton, setShowResetButton] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [editEmployee] = useMutation(EDIT_AND_APPROVE_EMPLOYEE_MUTATION, {
    refetchQueries: ["CountDraftsAndTranslationsQuery"],
  });

  const [performingApplyAction, setPerformingApplyAction] = useState(false);

  const link = `/employee/${employee.id}`;
  const updatedFields = employee.updatedMultilangFields ?? [];
  return (
    <div className="flex w-full max-w-screen-xl px-3 mx-auto">
      <div className="relative w-full px-4 pt-4 mb-4 border border-gray-200 rounded-md shadow-md">
        <div className="flex items-start justify-between">
          <Link href={link} target="_blank" className="text-lg font-medium">
            {employee.fullName}
          </Link>
          <div className="flex items-center space-x-6">
            <a
              className="flex items-center px-2 py-1 text-sm text-blue-500 whitespace-pre border rounded border-grey-300 hover:bg-opacity-5 hover:border-opacity-40 focus:outline-none hover:bg-blue-500 hover:border-blue-500"
              target="_blank"
              href={link}
              rel="noreferrer"
            >
              <FaExternalLinkAlt aria-hidden="true" />
              <span className="ml-2">Zum Eintrag</span>
            </a>
            <IconButton
              Icon={expanded ? FaChevronUp : FaChevronDown}
              onClick={() => setExpanded(!expanded)}
              className="flex items-center justify-center h-7 w-7"
            />
          </div>
        </div>
        {expanded
          ? updatedFields.map((fieldStatus) => {
              return (
                <TranslationStatus
                  key={fieldStatus.field}
                  fieldStatus={fieldStatus}
                  value={
                    values[fieldStatus.field] ??
                    extractMulti(employee[fieldStatus.field])
                  }
                  setValue={(text) => {
                    setValues({ ...values, [fieldStatus.field]: text });
                  }}
                  onInput={() => {
                    setShowResetButton(true);
                  }}
                />
              );
            })
          : null}
        <div
          className={`p-4 -mx-4 text-right ${
            expanded ? "bg-gray-100 border-t" : ""
          } border-gray-200`}
        >
          <div className="flex items-center justify-end">
            <div className="flex items-center space-x-2">
              <IconButton
                disabled={!showResetButton}
                type="secondary"
                className="bg-white"
                Icon={FaTimes}
                onClick={() => {
                  setValues({});
                  setShowResetButton(false);
                }}
              >
                {t("reset")}
              </IconButton>
              <IconButton
                className="bg-white"
                Icon={
                  performingApplyAction
                    ? () => <FaSpinner className="animate-spin" />
                    : FaSave
                }
                disabled={performingApplyAction}
                onClick={() => {
                  setPerformingApplyAction(true);
                  editEmployee({
                    variables: {
                      id: employee.id,
                      fields: { ...values, updatedMultilangFields: null },
                    },
                  }).finally(() => {
                    setPerformingApplyAction(false);
                  });
                }}
              >
                {t("save")}
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TranslationStatus: FC<{
  fieldStatus: UpdatedMultilangField;
  value: MultilangText;
  setValue: (text: Partial<InputMultilangText>) => void;
  onInput: () => void;
}> = ({ value, setValue, fieldStatus, onInput }) => {
  const { t } = useTranslation(["common", "form", "mainLanguages"]);
  const [sourceLanguage, setSourceLanguage] = useState<string | null>(null);
  return (
    <div className="mb-4">
      <div className="flex items-end justify-between mt-8">
        <div className="flex-1 pb-2 font-bold">
          {t(`form:fieldName.employee.${fieldStatus.field}` as any)}
        </div>
      </div>

      <div className="flex flex-row space-x-8">
        {languages.map((l) => {
          const isUpdated = fieldStatus.updatedLanguages?.includes(l);
          return (
            <div key={l} className="flex flex-col flex-1">
              <div className="flex flex-row">
                <div className="text-sm">{t(`mainLanguages:${l}` as any)}</div>
                <div className="flex-grow" />
                <Popup
                  content={
                    isUpdated
                      ? `Feld durch Benutzer verändert`
                      : `Feld zu übersetzen`
                  }
                >
                  <FaLanguage
                    className={`text-3xl ${
                      isUpdated ? "text-green-500" : "text-red-500"
                    }`}
                  />
                </Popup>
              </div>
              <div className="flex-grow">
                <MarkDownField
                  value={value[l]}
                  onChange={(txt) => {
                    setValue({ ...value, [l]: txt });
                  }}
                  onInput={onInput}
                />
              </div>
              <div className="mt-4">
                <IconButton Icon={FaEdit} onClick={() => setSourceLanguage(l)}>
                  In andere Sprachen Übersetzen
                </IconButton>
              </div>
            </div>
          );
        })}
      </div>
      <DeeplModal
        value={value}
        sourceLanguage={sourceLanguage}
        close={() => setSourceLanguage(null)}
        onUseTranslations={(m) => {
          onInput();
          setValue(mergeMulti(extractMulti(value), m));
        }}
      />
    </div>
  );
};

const DeeplModal = ({ value, sourceLanguage, close, onUseTranslations }) => {
  const { t } = useTranslation(["common", "mainLanguages"]);
  const { token } = useContext(AuthContext);

  const [languagesToDownload, setLanguagesToDownload] = useState<{
    [lang: string]: boolean;
  }>({});
  const [fetchStatus, setFetchStatus] = useState<{
    [lang: string]: "downloading" | "downloaded";
  }>({});
  const [translatedText, setTranslatedText] =
    useState<Partial<InputMultilangText> | null>({});
  const finishedDownloading =
    Object.values(fetchStatus).length > 0 &&
    Object.values(fetchStatus).every((s) => s === "downloaded");
  const didStartDownloading = Object.values(fetchStatus).length > 0;

  // Initially we want to have all languages selected for download
  useEffect(() => {
    if (sourceLanguage) {
      setLanguagesToDownload(
        languages
          .filter((l) => l !== sourceLanguage)
          .reduce((acc, l) => ({ ...acc, [l]: true }), {}),
      );
    }
  }, [sourceLanguage]);

  const reset = () => {
    setTranslatedText(null);
    setLanguagesToDownload({});
    setFetchStatus({});
  };

  const downloadTranslations = () => {
    const initFetchStatus = Object.entries(languagesToDownload).reduce(
      (acc, [l, shouldTranslate]) =>
        shouldTranslate ? { ...acc, [l]: "downloading" } : acc,
      {},
    );
    setFetchStatus(initFetchStatus);

    const fetchAll = Object.entries(languagesToDownload)
      .filter(([_, shouldTranslate]) => shouldTranslate)
      .map(([targetLang, _]) => {
        return fetchFromDeepl(token, {
          sourceText: value[sourceLanguage],
          sourceLang: sourceLanguage,
          targetLang,
        }).then((txt) => {
          setFetchStatus((status) => ({
            ...status,
            [targetLang]: "downloaded",
          }));
          return { txt, targetLang };
        });
      });

    Promise.all(fetchAll).then((txts) => {
      const translations = txts.reduce(
        (acc, { txt, targetLang }) => ({
          ...acc,
          [targetLang]: txt,
        }),
        {},
      );
      setTranslatedText(translations);
    });
  };

  return (
    <Modal
      title={
        <div className="px-6 py-8 text-2xl font-medium text-red-500">
          Mit Deepl übersetzen
        </div>
      }
      open={sourceLanguage !== null}
      close={close}
      actions={
        <div className="flex flex-row space-x-2">
          <IconButton
            type="secondary"
            Icon={IoMdClose}
            onClick={() => {
              reset();
              close();
            }}
          >
            {t("common:cancel")}
          </IconButton>
          <IconButton
            Icon={FaArrowDown}
            disabled={!finishedDownloading}
            onClick={() => {
              onUseTranslations(translatedText);
              reset();
              close();
            }}
          >
            Übernehmen
          </IconButton>
        </div>
      }
    >
      <div className="flex flex-col px-10 my-8 space-y-4">
        <div>
          {Object.entries(languagesToDownload).map(
            ([targetLang, shouldTranslate]) => {
              return (
                <div key={targetLang} className="flex flex-row space-x-2">
                  <input
                    type="checkbox"
                    className="block w-5 h-5 p-2 border rounded-md shadow-sm outline-none border-grey-300 focus:ring-grey-900 focus:ring-2 focus:border-grey-900 sm:text-sm"
                    checked={shouldTranslate}
                    disabled={didStartDownloading}
                    onChange={(e) =>
                      setLanguagesToDownload({
                        ...languagesToDownload,
                        [targetLang]: e.currentTarget.checked,
                      })
                    }
                  />
                  <span>{t(`mainLanguages:${targetLang}` as any)}</span>
                  {fetchStatus[targetLang] === "downloading" ? (
                    <FaSpinner />
                  ) : null}
                  {fetchStatus[targetLang] === "downloaded" ? (
                    <FaCheck />
                  ) : null}
                </div>
              );
            },
          )}
        </div>
        <div>
          <SecondaryButton
            disabled={didStartDownloading}
            onClick={() => {
              downloadTranslations();
            }}
          >
            Übersetzen
          </SecondaryButton>
        </div>
      </div>
      <div className="px-10 my-8">
        {Object.entries(translatedText ?? {}).map(([lang, txt]) => {
          return (
            <div key={lang} className="mb-4">
              <div className="font-medium">
                {t(`mainLanguages:${lang}` as any)}
              </div>
              <div>{txt}</div>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

const fetchFromDeepl = async (
  token: string,
  params: {
    sourceText: string;
    sourceLang: string;
    targetLang: string;
  },
) => {
  return axios
    .post(`${getApiUrl()}/api/translation/translate`, params, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data.text);
};
