import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
// @ts-ignore This module is injected by vite-plugin-pwa, so typescript doesn't know it exists
import { registerSW } from "virtual:pwa-register";

// @ts-ignore
import useOnlineStatus from "helpers/useOnlineStatus";
import Pages from "pages";

import "./styles.less";

// HELPER COMPONENTS

// MAIN COMPONENT

const AppShell = () => {
  const { t } = useTranslation();

  // This code is actually used to unregister the service worker..
  useEffect(() => {
    const updateSW = registerSW({
      onNeedRefresh() {
        // activate service worker, without refreshing the page
        updateSW(false);
      },
    });
  }, []);

  useOnlineStatus(
    () => {
      toast.success(t("toast.backOnline"));
    },
    () => {
      toast.error(t("toast.appOffline"));
    },
  );

  return <Pages />;
};

export default AppShell;
