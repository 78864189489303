import React, { Dispatch, useRef, useState } from "react";
import { IconButton, SecondaryButton } from "components/button";
import { useEffect } from "react";
import { FaImage } from "react-icons/fa";

const readFileBase64 = (el: any) => {
  return new Promise<string>((resolve) => {
    const file = el.files[0];
    const reader: any = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        resolve(reader.result);
      },
      false,
    );

    if (file) {
      return reader.readAsDataURL(file);
    }
  });
};

type InputFileProps = {
  title: string;
  filename?: string;
  accept?: string;
  showFilename?: boolean;
  displayIcon?: boolean;
  disabled?: boolean;
  setIsLoading: Dispatch<React.SetStateAction<boolean>>;
  onChange: (image: string, filename?: string) => void;
};

export const InputFileBase64: React.FC<InputFileProps> = ({
  title,
  onChange,
  filename,
  accept,
  showFilename = true,
  disabled = false,
  displayIcon = false,
  setIsLoading,
}) => {
  const inputRef: any = useRef(null);
  const [newFilename, setNewFilename] = useState(filename ?? null);
  useEffect(() => {
    setNewFilename(filename ?? null);
  }, [filename]);

  return (
    <>
      {displayIcon ? (
        <IconButton
          disabled={disabled}
          Icon={FaImage}
          onClick={(e) => {
            inputRef!.current.click();
            e.preventDefault();
          }}
        >
          {title || "Upload"}
        </IconButton>
      ) : (
        <SecondaryButton
          disabled={disabled}
          onClick={(e) => {
            inputRef!.current.click();
            e.preventDefault();
          }}
        >
          {title || "Upload"}
        </SecondaryButton>
      )}
      <input
        type="file"
        accept={accept}
        hidden
        ref={inputRef}
        onChange={(e) => {
          setIsLoading(true);
          const filename = (e as any).target.files[0].name;
          setNewFilename(filename);
          readFileBase64(e.target)
            .then((blob) => onChange(blob, filename))
            .finally(() => {
              setIsLoading(false);
            });
        }}
      />
      {showFilename && newFilename ? (
        <span className="ml-4">{newFilename}</span>
      ) : null}
    </>
  );
};
