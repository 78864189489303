import { gql } from "@apollo/client";
import { multiLangString } from "./common";

const fileUploadFragment = gql`
  fragment fileUploadFragment on FileUpload {
    id
    filename
    url
  }
`;

const fileAttachmentFragment = gql`
  ${fileUploadFragment}

  fragment fileAttachmentFragment on FileAttachment {
    id
    name ${multiLangString}
    fileDe {
      ...fileUploadFragment
    }
    fileEn {
      ...fileUploadFragment
    }
    fileFr {
      ...fileUploadFragment
    }
    fileIt {
      ...fileUploadFragment
    }
  }
`;

// QUERIES
export const EDIT_FILE_ATTACHMENT_QUERY = gql`
  ${fileAttachmentFragment}

  query GetFileAttachment($id: ID!) {
    fileAttachment(id: $id) {
      ...fileAttachmentFragment
    }
  }
`;

export const FILE_ATTACHMENT_QUERY = gql`
  ${fileAttachmentFragment}

  query GetFileAttachment($id: ID!) {
    fileAttachment(id: $id) {
      ...fileAttachmentFragment
    }
  }
`;

// MUTATIONS

export const EDIT_FILE_ATTACHMENT_MUTATION = gql`
  ${fileAttachmentFragment}

  mutation EditFileAttachmentMutation($id: ID!, $fields: InputFileAttachment!) {
    editAndApproveFileAttachment(id: $id, fields: $fields) {
      ...fileAttachmentFragment
    }
  }
`;

export const CREATE_FILE_ATTACHMENT_MUTATION = gql`
  ${fileAttachmentFragment}

  mutation CreateFileAttachmentMutation($fields: InputFileAttachment!) {
    createApprovedFileAttachment(fields: $fields) {
      ...fileAttachmentFragment
    }
  }
`;
