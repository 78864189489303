export const pathShortener = (str: string): string => {
  const splitter = str?.indexOf("/") > -1 ? "/" : "\\";
  const tokens = str?.split(splitter);
  const firstToken = tokens?.find((token) => token !== "");
  const start = tokens?.indexOf(firstToken!);
  const filteredTokens = tokens?.slice(start);

  const shortenedPath: string[] = [];
  if (filteredTokens?.length > 4) {
    for (let i = 0; i < filteredTokens.length; i++) {
      if (i < 2 || i >= filteredTokens.length - 2) {
        shortenedPath.push(filteredTokens[i]);
      }
    }
    shortenedPath.splice(2, 0, "...");
    return shortenedPath.join(splitter);
  } else {
    return str;
  }
};
