import React from "react";

import { textField } from "components/form/textField";
import { boolField } from "components/form/boolField";
import { FormDefinition } from "components/form";
import { EDIT_CLIENT_MUTATION, CLIENT_QUERY } from "queries/newClient";
import {
  EditModal,
  EditForm,
  ISpecializedEditFormProps,
} from "components/editVersioned/genericForm";
import { Client } from "generated/graphql";
import {
  APPROVE_CLIENT_DRAFT_MUTATION,
  REJECT_CLIENT_DRAFT_MUTATION,
} from "queries/newDraft";

// MAIN COMPONENT

export const clientFormDefinition: FormDefinition<Client> = {
  title: ({ entity, t }) => <>{entity?.name ?? t("common:loading")}</>,
  titleString: (entity) => entity?.name,
  link: (entity) => `/client/${entity.id}`,
  fields: {
    name: {
      name: "client.name",
      component: textField,
      multiLang: false,
    },
    isUnlisted: {
      name: "client.isUnlisted",
      component: boolField,
      onlyVisibleToAdmins: true,
    },
  },
};

export const EditClientModal = ({ open, id, close }) => {
  const editVersioned = {
    id,
    editMutation: EDIT_CLIENT_MUTATION,
    approveDraftMutation: APPROVE_CLIENT_DRAFT_MUTATION,
    rejectDraftMutation: REJECT_CLIENT_DRAFT_MUTATION,
    query: CLIENT_QUERY,
    queryDataAccessor: "client",
    editDataAccessor: "editClient",
    formDefinition: clientFormDefinition,
    refetchQueries: ["GetClient"],
  };

  return <EditModal open={open} editVersioned={editVersioned} close={close} />;
};

export const EditClientForm = React.memo(
  ({ id, onSaved, isOnAdminPage }: ISpecializedEditFormProps) => {
    const editVersioned = {
      id,
      editMutation: EDIT_CLIENT_MUTATION,
      approveDraftMutation: APPROVE_CLIENT_DRAFT_MUTATION,
      rejectDraftMutation: REJECT_CLIENT_DRAFT_MUTATION,
      query: CLIENT_QUERY,
      queryDataAccessor: "client",
      editDataAccessor: "editClient",
      formDefinition: clientFormDefinition,
      refetchQueries: [],
    };

    return (
      <EditForm
        onSaved={onSaved}
        editVersioned={editVersioned}
        isOnAdminPage={isOnAdminPage}
      />
    );
  },
);
