import React, { useEffect } from "react";
import { useTitle } from "helpers/useTitle";
import { useTranslation } from "react-i18next";

import Layout from "layout/layout";
import { TabBar, TabContent, Pane, useTab } from "components/tab";

import { List } from "./list";

export const Onepagers = ({ tab }: { tab?: string }): JSX.Element => {
  const { t } = useTranslation("onepager");
  useTitle(`TBF Onepager-Tool`);

  const panes: Pane[] = [
    {
      name: t("myOnepagers"),
      key: "my",
      Content: () => <List onepagerType="my" />,
    },
    {
      name: t("allOnepagers"),
      key: "all",
      Content: () => <List onepagerType="all" />,
    },
    {
      name: t("archivedOnepagers"),
      key: "archived",
      Content: () => <List archived onepagerType="deleted" />,
    },
  ];

  const tabProps = { panes, path: `/onepagers`, currentTab: tab };
  useTab(tabProps);

  useEffect(() => {
    if (tab) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [tab]);

  return (
    <>
      <Layout noPadding />
      <div>
        <div className="sticky top-12 z-20 bg-white shadow">
          <div className="px-3 mx-auto max-w-screen-tbf">
            <div className="pt-4 text-3xl font-medium text-red-500">
              Onepager-Tool
            </div>
            <div className="flex items-center justify-between py-3 mx-auto mt-2 max-w-screen-tbf">
              <TabBar {...tabProps} />
            </div>
          </div>
        </div>
        <div className="relative px-3 mx-auto max-w-screen-tbf">
          <TabContent {...tabProps} />
        </div>
      </div>
    </>
  );
};
