import React from "react";
import { useTranslation } from "react-i18next";

import { FormDefinition } from "components/form";
import {
  CREATE_NEW_PROJECT_MUTATION,
  EDIT_NEW_PROJECT_MUTATION,
  EDIT_PROJECT_QUERY,
  DELETE_NEW_PROJECT_MUTATION,
} from "queries/newProject";
import {
  EditModal,
  EditForm,
  ISpecializedEditFormProps,
  EditVersionedProps,
} from "components/editVersioned/genericForm";
import {
  SELECTABLE_SERVICES,
  SELECTABLE_APPLICATION_AREAS,
  COUNTRIES,
} from "pages/project/utils";
import { NewProject } from "generated/graphql";
import { useMultiLang } from "helpers/multiLang";
import { getFirstNonEmpty } from "helpers/languages";

import { FieldName } from "components/form";
import { markDownField } from "components/form/markDownField";
import { factsField } from "components/form/factsField";
import { clientsField } from "components/form/clientsField";
import { projectsField } from "components/form/projectsField";
import { referencesField } from "components/form/referencesField";
import { enrichedFromField } from "components/form/enrichedFromField";
import { siaPhasesField } from "components/form/siaPhasesField";
import { TextField, textField } from "components/form/textField";
import { investmentField } from "components/form/investmentField";
import { multiSelectField } from "components/form/multiSelectField";
import {
  singleSelectField,
  SingleSelectField,
} from "components/form/singleSelectField";
import { boolField } from "components/form/boolField";
import { employeesField } from "components/form/employeesField";
import {
  APPROVE_PROJECT_DRAFT_MUTATION,
  REJECT_PROJECT_DRAFT_MUTATION,
} from "queries/newDraft";
import isFeatureEnabled from "helpers/isFeatureEnabled";

// HELPER FUNCTIONS

const projectStatuses = [
  "tender",
  "opened",
  "active",
  "suspended",
  "toBeFinalised",
  "finalised",
  "archived",
];

const statusOptions = projectStatuses.map((status: string) => ({
  value: status,
  labelKey: `project:status:${status}`,
}));

const countryOptions = COUNTRIES.map((code) => ({
  value: code,
  labelKey: `abacusProjects:${code}`,
}));

// MAIN COMPONENT

export const projectFormDefinition: FormDefinition<NewProject> = {
  title: ({ entity, t }) => {
    const m = useMultiLang();
    return <>{m(entity?.name, t("common:loading"))}</>;
  },
  titleString: (entity, _t, m) => m(entity?.name),
  link: (entity) => (entity?.id ? `/project/${entity.id}` : ""),
  fields: {
    name: {
      name: "project.name",
      component: textField,
      multiLang: true,
      props: {
        placeholderFn: (v) => getFirstNonEmpty(v),
      },
    },
    description: {
      name: "project.description",
      component: markDownField,
      multiLang: true,
      props: {
        maxWordCount: 150,
        placeholderFn: (v) => getFirstNonEmpty(v),
      },
    },
    status: {
      name: "project.status",
      component: singleSelectField,
      props: { options: statusOptions },
      readOnly: true,
    },
    relatedProjectIds: {
      name: "project.relatedProjects",
      component: projectsField,
      onlyVisibleToAdmins: true,
    },
    enrichedFrom: {
      name: "project.enrichedFrom",
      component: enrichedFromField,
      onlyVisibleToAdmins: true,
    },
    services: {
      name: "project.services",
      component: multiSelectField,
      props: { options: SELECTABLE_SERVICES },
    },
    applicationAreas: {
      name: "project.applicationAreas",
      component: multiSelectField,
      props: { options: SELECTABLE_APPLICATION_AREAS },
    },
    siaPhases: {
      name: "project.siaPhases",
      component: siaPhasesField(),
      controlledFields: [
        "siaPhases",
        "hoaiPhases",
        "frPhases",
        "itPhases",
        "primaryPhase",
      ],
    },
    facts: {
      name: "project.facts",
      component: factsField,
    },
    investment: {
      name: "project.investment",
      component: investmentField(),
      controlledFields: ["investment", "investmentCurrency"],
    },
    country: {
      name: "project.country",
      component: singleSelectField,
      props: { options: countryOptions },
    },
    clientId: {
      name: "project.client",
      component: clientsField,
      readOnly: true,
    },
    clientHidden: {
      name: "project.clientHidden",
      component: boolField,
    },
    isUnlisted: {
      name: "project.isUnlisted",
      component: boolField,
      onlyVisibleToAdmins: true,
    },
    projectReferences: {
      name: "project.projectReferences",
      component: referencesField,
      onlyVisibleToAdmins: true,
    },
    adminProjectManagerId: {
      name: "project.adminProjectManager",
      component: employeesField,
    },
    adminDeputyProjectManagerId: {
      name: "project.adminDeputyProjectManager",
      component: employeesField,
    },
    invoicingResponsibleId: {
      name: "project.invoicingResponsible",
      component: employeesField,
    },
    portfolioManagerId: {
      name: "project.portfolioManager",
      component: employeesField,
    },
  },
};

const AdditionalInfo = ({
  entity: project,
}: {
  entity?: Partial<NewProject>;
}) => {
  const { t } = useTranslation(["form"]);

  if (!project?.abacusProject) {
    return null;
  }

  const abacusProject = project.abacusProject;

  return (
    <div>
      <h2 className="mb-2 text-2xl font-medium text-gray-500 sm:mb-none ">
        {t("abacusProjectFields")}
      </h2>
      <div className="grid grid-cols-1 gap-4 my-8 sm:grid-cols-3">
        <div className="col-span-1">
          <FieldName
            field={{ name: "abacusProject.name" }}
            fieldKey={"abacusProject.name"}
            values={[]}
          />
        </div>
        <div className="col-span-2">
          <TextField value={abacusProject.name} disabled={true} />
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 my-8 sm:grid-cols-3">
        <div className="col-span-1">
          <FieldName
            field={{ name: "abacusProject.status" }}
            fieldKey={"abacusProject.status"}
            values={[]}
          />
        </div>
        <div className="col-span-2">
          <SingleSelectField
            value={abacusProject.status}
            disabled={true}
            options={statusOptions}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 my-8 sm:grid-cols-3">
        <div className="col-span-1">
          <FieldName
            field={{ name: "abacusProject.client" }}
            fieldKey={"abacusProject.client"}
            values={[]}
          />
        </div>
        <div className="col-span-2">
          <TextField value={abacusProject.clientId} disabled={true} />
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 my-8 sm:grid-cols-3">
        <div className="col-span-1">
          <FieldName
            field={{ name: "abacusProject.projectManager" }}
            fieldKey={"abacusProject.projectManager"}
            values={[]}
          />
        </div>
        <div className="col-span-2">
          <TextField value={abacusProject.projectManagerId} disabled={true} />
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 my-8 sm:grid-cols-3">
        <div className="col-span-1">
          <FieldName
            field={{ name: "abacusProject.deputyProjectManager" }}
            fieldKey={"abacusProject.deputyProjectManager"}
            values={[]}
          />
        </div>
        <div className="col-span-2">
          <TextField
            value={abacusProject.deputyProjectManagerId}
            disabled={true}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 my-8 sm:grid-cols-3">
        <div className="col-span-1">
          <FieldName
            field={{ name: "abacusProject.invoicingResponsible" }}
            fieldKey={"abacusProject.invoicingResponsible"}
            values={[]}
          />
        </div>
        <div className="col-span-2">
          <TextField
            value={abacusProject.invoicingResponsibleId}
            disabled={true}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 my-8 sm:grid-cols-3">
        <div className="col-span-1">
          <FieldName
            field={{ name: "abacusProject.portfolioManager" }}
            fieldKey={"abacusProject.portfolioManager"}
            values={[]}
          />
        </div>
        <div className="col-span-2">
          <TextField value={abacusProject.portfolioManagerId} disabled={true} />
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 my-8 sm:grid-cols-3">
        <div className="col-span-1">
          <FieldName
            field={{ name: "abacusProject.parentProject" }}
            fieldKey={"abacusProject.parentProject"}
            values={[]}
          />
        </div>
        <div className="col-span-2">
          <TextField value={abacusProject.parentProjectId} disabled={true} />
        </div>
      </div>
    </div>
  );
};

export const EditProjectModal = ({ open, id, close }) => {
  const editVersioned = {
    id,
    editMutation: EDIT_NEW_PROJECT_MUTATION,
    createMutation: CREATE_NEW_PROJECT_MUTATION,
    approveDraftMutation: APPROVE_PROJECT_DRAFT_MUTATION,
    rejectDraftMutation: REJECT_PROJECT_DRAFT_MUTATION,
    createDataAccessor: "createNewProject",
    editDataAccessor: "editNewProject",
    query: EDIT_PROJECT_QUERY,
    queryDataAccessor: "newProject",
    formDefinition: projectFormDefinition,
    refetchQueries: ["GetNewProject"],
  };

  const showAdditionalInfos = isFeatureEnabled("abacus-project-details");

  return (
    <EditModal
      open={open}
      editVersioned={editVersioned}
      close={close}
      AdditionalInfo={
        showAdditionalInfos ? (entity) => AdditionalInfo(entity) : undefined
      }
    />
  );
};

export const EditProjectForm = React.memo(
  ({ id, onSaved, onCreated, isOnAdminPage }: ISpecializedEditFormProps) => {
    const editVersioned: EditVersionedProps<NewProject> = {
      id,
      editMutation: EDIT_NEW_PROJECT_MUTATION,
      createMutation: CREATE_NEW_PROJECT_MUTATION,
      deleteMutation: DELETE_NEW_PROJECT_MUTATION,
      approveDraftMutation: APPROVE_PROJECT_DRAFT_MUTATION,
      rejectDraftMutation: REJECT_PROJECT_DRAFT_MUTATION,
      createDataAccessor: "createNewProject",
      editDataAccessor: "editNewProject",
      query: EDIT_PROJECT_QUERY,
      queryDataAccessor: "newProject",
      formDefinition: projectFormDefinition,
      refetchQueries: [],
    };

    return (
      <EditForm
        isOnAdminPage={isOnAdminPage}
        onSaved={onSaved}
        onCreated={onCreated}
        editVersioned={editVersioned}
      />
    );
  },
);
