import React from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import CV from "./../cv";
import TwoColumnLayout from "layout/twoColumnLayout";
import { ItemList, Item } from "../tags";
import { LanguagesWithLevel } from "./languages";
import { DiplomaItem } from "./diplomaItem";
import { useMultiLang } from "helpers/multiLang";
import { Employee } from "generated/graphql";
import { sortItemsByYear } from "helpers/getSortedItems";
import { hyphenateText } from "helpers/hyphenate";

const Section = ({ title, children }) => {
  return (
    <div className="mb-8">
      <h2 className="mb-1 text-xl font-medium text-red-500">{title}</h2>
      <div className="text-sm">{children}</div>
    </div>
  );
};

const SummarySection = ({ title, children }) => {
  return (
    <div className="space-y-1">
      <h3 className="text-base font-normal text-blue-500">{title}</h3>
      <div>{children}</div>
    </div>
  );
};

const WorkAreas = ({ items }: { items: string[] | null | undefined }) => {
  const { t } = useTranslation();

  return (
    <>
      {items?.map((item: any) => (
        <Item key={item} label={t(`abacusProjects:${item}` as any)} />
      ))}
    </>
  );
};

const Banner = ({ employee }: { employee: Partial<Employee> }) => {
  const { t, i18n } = useTranslation(["employee", "common", "drafts", "form"]);
  const m = useMultiLang();
  const sortedCvItems = sortItemsByYear(employee.cv?.slice() ?? []);
  const locale = i18n.language;

  return (
    <TwoColumnLayout
      leftContent={
        <>
          <div className="hidden md:block">
            <h2 className="text-xl font-medium text-blue-500">
              {t("employee:yearOfBirth")}
            </h2>
            {employee?.birthday ? (
              <h3 className="mt-4 text-base font-normal text-blue-500">
                {employee.birthday.substring(0, 4)}
              </h3>
            ) : null}
          </div>

          <div className="md:hidden">
            <h2 className="text-xl font-medium text-red-500">
              {`${t("employee:services")} & ${t("employee:applicationAreas")}`}
            </h2>
            <div className="mt-3">
              {!employee?.services && !employee?.domains ? (
                <div className="mt-3 text-sm text-gray-500">
                  {t("common:noneYet")}
                </div>
              ) : (
                <>
                  <WorkAreas items={employee?.services} />
                  <WorkAreas items={employee?.domains} />
                </>
              )}
            </div>
          </div>

          <div className="hidden pt-6 mt-6 border-t md:block">
            <h2 className="text-xl font-medium text-blue-500">
              {t("employee:workAreas")}
            </h2>
            {!employee?.services && !employee?.domains ? (
              <div className="mt-3 text-sm text-gray-500">
                {t("common:noneYet")}
              </div>
            ) : (
              <div className="mt-4 space-y-6">
                {employee?.services ? (
                  <SummarySection title={t("employee:services")}>
                    <WorkAreas items={employee.services} />
                  </SummarySection>
                ) : null}
                {employee?.domains ? (
                  <SummarySection title={t("employee:applicationAreas")}>
                    <WorkAreas items={employee?.domains} />
                  </SummarySection>
                ) : null}
              </div>
            )}
          </div>

          <div className="hidden pt-6 mt-6 border-t md:block">
            <h2 className="text-xl font-medium text-blue-500">
              {t("employee:skillsTitle")}
            </h2>
            {!employee?.skills?.length &&
            !employee?.competences?.length &&
            !employee?.languages?.length ? (
              <div className="mt-3 text-sm text-gray-500">
                {t("common:noneYet")}
              </div>
            ) : (
              <div className="mt-4 space-y-6">
                {employee?.skills?.length ? (
                  <SummarySection title={t("employee:skillsShortTitle")}>
                    <ItemList keyString="skill" items={employee?.skills} />
                  </SummarySection>
                ) : null}
                {employee?.competences?.length ? (
                  <SummarySection title={t("employee:competencesShortTitle")}>
                    <ItemList
                      keyString="competence"
                      items={employee?.competences}
                    />
                  </SummarySection>
                ) : null}
                {employee?.languages?.length ? (
                  <SummarySection title={t("employee:languages")}>
                    <LanguagesWithLevel languages={employee?.languages ?? []} />
                  </SummarySection>
                ) : null}
              </div>
            )}
          </div>
        </>
      }
      rightContent={
        <>
          <div className="flex w-full mb-2 md:mr-1">
            {employee?.aboutMe ? (
              <Section title={t("employee:aboutMe")}>
                <div className="markdown">
                  <ReactMarkdown>
                    {hyphenateText(
                      m(employee?.aboutMe, t("employee:noFacts")),
                      locale,
                    )}
                  </ReactMarkdown>
                </div>
              </Section>
            ) : (
              <Section title={t("employee:aboutMe")}>
                <div className="mt-3 text-sm text-gray-500">
                  {t("common:noneYet")}
                </div>
              </Section>
            )}
          </div>
          {employee?.diplomas?.length ? (
            <Section title={t("employee:diplomas")}>
              {employee.diplomas.map((diploma) => (
                <DiplomaItem key={diploma.id} diploma={diploma} />
              ))}
            </Section>
          ) : null}
          {m(employee?.trainings) !== "" ? (
            <Section title={t("drafts:training")}>
              <div className="markdown">
                <ReactMarkdown>{m(employee?.trainings)}</ReactMarkdown>
              </div>
            </Section>
          ) : null}
          {m(employee?.additionalField?.content) !== "" ? (
            <Section
              title={
                m(employee?.additionalField?.title)
                  ? m(employee?.additionalField?.title)
                  : t("form:fieldName.employee.additionalField")
              }
            >
              <div className="markdown">
                <ReactMarkdown>
                  {m(employee?.additionalField?.content)}
                </ReactMarkdown>
              </div>
            </Section>
          ) : null}
          {employee?.cv?.length ? (
            <Section title={t("employee:cvItemsTitle")}>
              <CV cvItems={sortedCvItems ?? []} />
            </Section>
          ) : (
            <Section title={t("employee:cvItemsTitle")}>
              <div className="mt-3 text-sm text-gray-500">
                {t("common:noneYet")}
              </div>
            </Section>
          )}
        </>
      }
    />
  );
};

export default Banner;
