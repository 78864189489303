import React from "react";

import { FormDefinition } from "components/form";
import { Onepager } from "generated/graphql";
import {
  CREATE_APPROVED_ONEPAGER_MUTATION,
  EDIT_ONEPAGER_MUTATION,
  EDIT_ONEPAGER_QUERY,
} from "queries/onepager";
import { EditModal } from "components/editVersioned/genericForm";

import { textField } from "components/form/textField";
import {
  singleSelectField,
  singleSelectFromArrayField,
} from "components/form/singleSelectField";
import { tagsField } from "components/form/tagsField";

const languagesOptions = ["de", "fr", "it", "en"].map((lang: string) => ({
  value: lang,
  labelKey: `mainLanguages:${lang}`,
}));

const countryOptions = ["CH", "DE", "FR", "IT"].map((code) => ({
  value: code,
  labelKey: `abacusProjects:${code}`,
}));

export const onepagerFormDefinition: FormDefinition<Onepager> = {
  title: ({ entity }) => {
    return <>{entity?.title}</>;
  },
  titleString: (entity, _t, _m) => entity?.title,
  link: (entity) => (entity?.id ? `/onepager/${entity.id}` : ""),
  fields: {
    title: {
      name: "onepager.title",
      component: textField,
      required: true,
    },
    tags: {
      name: "onepager.tags",
      component: tagsField,
      props: {
        options: languagesOptions,
        defaultValue: "de",
      },
    },
    supportedLanguages: {
      name: "onepager.supportedLanguages",
      component: singleSelectFromArrayField,
      props: {
        options: languagesOptions,
        defaultValue: "de",
      },
      multiLangIcon: "admin",
    },
    targetCountry: {
      name: "onepager.targetCountry",
      component: singleSelectField,
      props: {
        options: countryOptions,
        defaultValue: "CH",
      },
    },
    browserTitle: {
      name: "onepager.browserTitle",
      component: textField,
    },
    person: {
      name: "onepager.person",
      component: textField,
    },
    institution: {
      name: "onepager.institution",
      component: textField,
    },
  },
};

export const EditOnepagerModal = ({
  open,
  id = "new",
  close,
  newEntity,
  onSave,
}: {
  open: boolean;
  id?: string;
  close: () => void;
  newEntity?: Partial<Onepager>;
  onSave?: (id: string | null) => void;
}) => {
  const editVersioned = {
    id,
    editMutation: EDIT_ONEPAGER_MUTATION,
    createMutation: CREATE_APPROVED_ONEPAGER_MUTATION,
    createDataAccessor: "createApprovedOnepager",
    editDataAccessor: "editAndApproveOnepager",
    query: EDIT_ONEPAGER_QUERY,
    queryDataAccessor: "onepager",
    formDefinition: onepagerFormDefinition,
    refetchQueries: ["GetOnepager"],
  };

  return (
    <EditModal
      open={open}
      // Since the onepager settings do not contain any user translatable fields,
      // we should not show the language switcher and hardcode "de" for any multilang fields.
      hardCodeLanguage="de"
      editVersioned={editVersioned}
      hideReviewWorkflowButtons={true}
      newEntity={newEntity}
      onSave={onSave}
      close={close}
    />
  );
};
