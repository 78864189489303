import React from "react";
import { useTranslation } from "react-i18next";
import * as Menubar from "@radix-ui/react-menubar";

const HamburgerMenuIcon = () => {
  return (
    <svg
      focusable="false"
      preserveAspectRatio="xMidYMid meet"
      fill="currentColor"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      role="img"
      className="h-7 w-7"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 12H14V13H2zM2 9H14V10H2zM2 6H14V7H2zM2 3H14V4H2z"></path>
    </svg>
  );
};

type NavigationItemType = {
  label: string;
  href: string;
};

type NavigationsType = { navigations: NavigationItemType[] };

export const MobileNav: React.FC<NavigationsType> = ({ navigations }) => {
  const { t } = useTranslation("navigation");
  return (
    <Menubar.Root className="inline-block h-full md:hidden">
      <Menubar.Menu>
        <Menubar.Trigger className="-ml-4 button focus bg-white hover:bg-gray-10">
          <HamburgerMenuIcon />
        </Menubar.Trigger>
        <Menubar.Portal>
          <Menubar.Content
            align="start"
            sideOffset={5}
            alignOffset={-3}
            className="z-40 -mt-1 min-w-[8rem] border bg-white px-4 py-4 md:px-0 w-screen py-3 md:w-64"
          >
            {navigations.map((nav) => (
              <Menubar.Item
                key={nav.href}
                className="focus:text-accent-foreground focus:bg-gray-10 relative flex cursor-pointer select-none items-center rounded-sm outline-none transition-colors data-[disabled]:pointer-events-none data-[disabled]:opacity-50 py-4 text-lg md:py-1.5 md:text-sm flex gap-2 !px-4"
              >
                <a target="_self" className="text-gray-800" href={nav.href}>
                  {t(nav.label as "people" | "contributions")}
                </a>
              </Menubar.Item>
            ))}
          </Menubar.Content>
        </Menubar.Portal>
      </Menubar.Menu>
    </Menubar.Root>
  );
};

export const DesktopNav: React.FC<NavigationsType> = ({ navigations }) => {
  const { t } = useTranslation("navigation");

  return (
    <nav className="hidden md:flex" aria-labelledby="primary-navigation">
      <p className="sr-only" id="primary-navigation">
        {t("home")}
      </p>
      {navigations.map((nav) => (
        <a
          key={nav.href}
          className="text-secondary hover:bg-gray-10 flex items-center bg-white !px-4 !py-[0.9375rem] focus-visible:ring-inset button focus bg-white hover:bg-gray-10"
          target="_self"
          href={nav.href}
        >
          {t(nav.label as "people" | "contributions")}
        </a>
      ))}
    </nav>
  );
};
