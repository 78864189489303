import React from "react";
import { ActionLink } from "components/button";
import { FaPhoneSquareAlt } from "react-icons/fa";
import { GrMail } from "react-icons/gr";

import { Client } from "generated/graphql";

// HELPER COMPONENTS

const ClientName = ({ client }: { client: Partial<Client> }) => {
  return (
    <div className="mt-0 text-3xl font-medium text-red-500" data-cy="name">
      {client.name}
    </div>
  );
};

const ClientActions = ({ client }: { client: Partial<Client> }) => {
  return (
    <div className="flex-wrap hidden mt-1 space-x-4 md:flex md:mt-1 md:flex-row">
      {client.email ? (
        <div>
          <ActionLink
            Icon={GrMail}
            label={client.email}
            link={`mailto:${client.email}`}
            external
          />
        </div>
      ) : null}
      {client.phone ? (
        <div>
          <ActionLink
            Icon={FaPhoneSquareAlt}
            label={client.phone}
            link={`tel:${client.phone}`}
            external
          />
        </div>
      ) : null}
    </div>
  );
};

// MAIN COMPONENT

export const ClientHeader: React.FC<{ client: Partial<Client> }> = ({
  client,
}) => {
  return (
    <div className="relative flex-1 pt-0 sm:pt-5">
      <ClientName client={client} />
      <ClientActions client={client} />
    </div>
  );
};
