import React from "react";
import { useMutation, useQuery } from "@apollo/client";

import Layout from "layout/layout";
import {
  WIKI_VERSION_PAGE_QUERY,
  RESTORE_WIKI_PAGE_MUTATION,
} from "queries/wiki";

import { PreviousVersionBanner } from "components/banner";

import { Content } from "./Content";
import { NavBar } from "./NavBar";
import { useTitle } from "helpers/useTitle";
import { useMultiLang } from "helpers/multiLang";

export const PreviousVersion = ({ slugs, version }) => {
  const parentSlugs = slugs.slice(0, -1);

  const m = useMultiLang();

  const { data, loading } = useQuery(WIKI_VERSION_PAGE_QUERY, {
    variables: {
      slugs: slugs,
      parentSlugs: parentSlugs,
      version: parseInt(version),
    },
    // Return data even if some fields failed to resolve
    errorPolicy: "all",
  });

  const [restoreMutation] = useMutation(RESTORE_WIKI_PAGE_MUTATION);
  const restore = (id: string, version: number) => {
    return restoreMutation({
      variables: {
        id: id,
        version: version,
      },
    });
  };
  const page = data?.["wikiPage"];
  const parentPage = data?.["parentPage"];

  useTitle(
    page?.title ? `TBF Wiki - ${m(page.title)} (v${version})` : "TBF Wiki",
  );

  if (loading && !page) {
    return <Layout loading={loading} noPadding></Layout>;
  } else if (!page) {
    return null;
  } else {
    return (
      <>
        <Layout loading={loading} noPadding />
        <div className="bg-white">
          <NavBar page={page} parentPage={parentPage} readOnly />

          <div className="relative">
            <PreviousVersionBanner
              entity={page}
              url={`/wiki/${slugs.join("/")}`}
              restore={restore}
              version={parseInt(version)}
            />
            <div className="max-w-screen-xl px-3 mx-auto">
              <Content page={page} slugs={slugs} readOnly />
            </div>
          </div>
        </div>
      </>
    );
  }
};
