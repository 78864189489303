import React, { useContext } from "react";
import { useTitle } from "helpers/useTitle";

import { frontendUrl } from "./publications";
import AuthContext from "context/user";
import { useQuery } from "@apollo/client";
import { ONEPAGER_QUERY } from "queries/onepager";
import { Onepager } from "generated/graphql";
import { Link } from "wouter";
import { Trans } from "react-i18next";

export const OnepagerPreview = ({ id }) => {
  useTitle(`TBF Onepager-Tool`);
  const { token } = useContext(AuthContext);

  const url = frontendUrl({
    previewId: id,
    authToken: token,
  });
  const { data } = useQuery(ONEPAGER_QUERY, {
    variables: { id },
  });
  const onepager: Onepager | undefined = data?.["onepager"];

  const liveUrl = frontendUrl({
    token:
      onepager?.publication?.status === "PUBLISHED"
        ? onepager?.publication?.token
        : onepager?.previousPublication?.token,
  });

  const publicationExists =
    onepager?.previousPublication ||
    onepager?.publication?.status === "PUBLISHED";

  return (
    <div style={{ height: "100vh" }}>
      <div
        style={{
          backgroundColor: "#fff166",
          height: "62px",
          width: "calc(100% - 20rem)",
          marginLeft: "10rem",
          marginRight: "10rem",
        }}
        className="fixed flex justify-center items-center top-0 left-0 right-0 z-50 flex-col py-2 space-y-1"
      >
        <div className="flex items-center" style={{ fontSize: "18px" }}>
          <Trans
            i18nKey="previewInformation"
            defaults="This is a preview of <bold>{{title}}</bold>."
            values={{ title: onepager?.title }}
            components={{
              bold: <div className="font-medium pl-1" />,
            }}
          />
        </div>

        <div>
          {publicationExists ? (
            <Trans
              i18nKey="editingAndPublication"
              defaults="Go back to the <Link>editing</Link> or to the <a>publication</a> page."
              components={{
                Link: (
                  <Link
                    className="text-red-500 hover:text-gray-950 cursor-pointer transition"
                    href={`/onepagers/edit/${onepager?.id}`}
                  />
                ),
                a: (
                  <a
                    className="text-red-500 hover:text-gray-950 cursor-pointer transition"
                    href={liveUrl}
                    target="_blank"
                    rel="noreferrer"
                  />
                ),
              }}
            />
          ) : (
            <Trans
              i18nKey="onlyEditing"
              defaults="Go back to the <Link>editing</Link> page."
              components={{
                Link: (
                  <Link
                    className="text-red-500 hover:text-gray-950 cursor-pointer transition"
                    href={`/onepagers/edit/${onepager?.id}`}
                  />
                ),
              }}
            />
          )}
        </div>
      </div>
      <iframe src={url} width="100%" height="100%" />
    </div>
  );
};
