import { uniq, flatten } from "ramda";

export const inTenderingStatus = (status: any) => status === "tender";

// hoai_phases_0_xx don't exist.
// We invented them to convert SIA phases that are outside a valid HOAI phase to HOAI.
const HOAI_TO_SIA: { [key: string]: string[] } = {
  hoai_phase_0_11: ["sia_phase_79"],
  hoai_phase_0_21: ["sia_phase_80"],
  hoai_phase_0_22: ["sia_phase_81"],
  hoai_phase_1: ["sia_phase_82"],
  hoai_phase_2: ["sia_phase_82"],
  hoai_phase_3: ["sia_phase_83"],
  hoai_phase_4: ["sia_phase_84"],
  hoai_phase_5: ["sia_phase_86"],
  hoai_phase_6: ["sia_phase_85"],
  hoai_phase_7: ["sia_phase_85"],
  hoai_phase_8: ["sia_phase_87", "sia_phase_88"],
  hoai_phase_9: ["sia_phase_89", "sia_phase_90"],
};

const SIA_TO_HAOI: { [key: string]: string[] } = {
  sia_phase_79: ["hoai_phase_0_11"],
  sia_phase_80: ["hoai_phase_0_21"],
  sia_phase_81: ["hoai_phase_0_22"],
  sia_phase_82: ["hoai_phase_1", "hoai_phase_2"],
  sia_phase_83: ["hoai_phase_3"],
  sia_phase_84: ["hoai_phase_4"],
  sia_phase_85: ["hoai_phase_6", "hoai_phase_7"],
  sia_phase_86: ["hoai_phase_5"],
  sia_phase_87: ["hoai_phase_8"],
  sia_phase_88: ["hoai_phase_8"],
  sia_phase_89: ["hoai_phase_9"],
  sia_phase_90: ["hoai_phase_9"],
};

export const SIA_CODES = {
  sia_phase_79: "11",
  sia_phase_80: "21",
  sia_phase_81: "22",
  sia_phase_82: "31",
  sia_phase_83: "32",
  sia_phase_84: "33",
  sia_phase_85: "41",
  sia_phase_86: "51",
  sia_phase_87: "52",
  sia_phase_88: "53",
  sia_phase_89: "61",
  sia_phase_90: "62",
};

export const HOAI_CODES: { [key: string]: string } = {
  hoai_phase_1: "1",
  hoai_phase_2: "2",
  hoai_phase_3: "3",
  hoai_phase_4: "4",
  hoai_phase_5: "5",
  hoai_phase_6: "6",
  hoai_phase_7: "7",
  hoai_phase_8: "8",
  hoai_phase_9: "9",
};

export const FR_PHASES = [
  "fr_phase_1",
  "fr_phase_2",
  "fr_phase_3",
  "fr_phase_4",
  "fr_phase_5",
  "fr_phase_6",
  "fr_phase_7",
  "fr_phase_8",
  "fr_phase_9",
  "fr_phase_10",
  "fr_phase_11",
];

export const IT_PHASES = [
  "it_phase_1",
  "it_phase_2",
  "it_phase_3",
  "it_phase_4",
  "it_phase_5",
  "it_phase_6",
  "it_phase_7",
  "it_phase_8",
];

export const SIA_PHASES = Object.keys(SIA_CODES);

export const HOAI_PHASES = Object.keys(HOAI_CODES);

export const hoaiToSia = (hoaiPhases: string[]): string[] => {
  return uniq(flatten(hoaiPhases.map((key) => HOAI_TO_SIA[key])));
};

export const siaToHoai = (siaPhases: string[]): string[] => {
  return uniq(flatten(siaPhases.map((phase) => SIA_TO_HAOI[phase])));
};

// Selectable application areas are only the ones on the official rosette on tbf.ch
export const SELECTABLE_APPLICATION_AREAS = [
  "application_area_130",
  "application_area_64",
  "application_area_65",
  "application_area_110",
  "application_area_120",
  "application_area_70",
  "application_area_71",
  "application_area_72",
  "application_area_140",
  "application_area_73",
  "application_area_150",
  "application_area_74",
  "application_area_79",
  "application_area_75",
  "application_area_76",
  "application_area_100",
];

// Selectable services are only the ones on the official rosette on tbf.ch:
// Engineering, Projektmanagement, Projektentwicklung, Unternehmensberatung, Bildung
export const SELECTABLE_SERVICES = [
  "service_94",
  "service_96",
  "service_97",
  "service_98",
  "service_1004",
];

export const ROLES = [
  "role_10",
  "role_11",
  "role_20",
  "role_21",
  "role_30",
  "role_31",
  "role_40",
  "role_41",
  "role_50",
  "role_51",
  "role_60",
  "role_61",
  "role_70",
  "role_71",
  "role_80",
  "role_81",
  "role_90",
  "role_91",
  "role_100",
  "role_110",
  "role_111",
  "role_120",
  "role_121",
  "role_130",
  "role_131",
];

export const COUNTRIES = [
  "CH",
  "FR",
  "IT",
  "DE",
  "PL",
  "AL",
  "DZ",
  "AM",
  "AT",
  "AZ",
  "ARG",
  "BY",
  "BE",
  "BA",
  "BG",
  "HR",
  "CN",
  "CY",
  "CZ",
  "DK",
  "EG",
  "EE",
  "FI",
  "GE",
  "EL",
  "HU",
  "IS",
  "IE",
  "IL",
  "JO",
  "LV",
  "LB",
  "LY",
  "LI",
  "LT",
  "LU",
  "MT",
  "MD",
  "ME",
  "MA",
  "NL",
  "MK",
  "NO",
  "PT",
  "RO",
  "RU",
  "RS",
  "SK",
  "SI",
  "ES",
  "SAU",
  "SE",
  "SY",
  "TN",
  "TR",
  "UA",
  "UK",
  "US",
  "CA",
  "SG",
  "ET",
  "DO",
  "AU",
  "BR",
  "KR",
  "MZ",
];

export const CURRENCIES = ["CHF", "EUR", "GBP", "USD", "JPY", "PLN"];

// This is just a rough conversation, it should only be used for estimates
export const CURRENCIES_TO_CHF = {
  chf: 1,
  eur: 1.1,
  gbp: 1.27,
  usd: 0.9,
  jpy: 0.0082,
  pln: 0.24,
};
