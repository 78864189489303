import { Employee } from "generated/graphql";
import { EditEmployeeForm } from "components/editVersioned/editEmployee";
import React from "react";
import { ManageGeneric } from "./common";
import { useTranslation } from "react-i18next";

export const ManageEmployees = () => {
  const { t } = useTranslation();
  return (
    <ManageGeneric<Employee>
      title={"Mitarbeiter"}
      searchType="new_employee"
      dataAccessor="newEmployee"
      editForm={EditEmployeeForm}
      listItemContent={(employee, isActive) => {
        return (
          <>
            {employee.fullName}
            <div className="flex flex-row">
              <div
                className={
                  "text-sm " + (isActive ? "text-gray-100" : "text-gray-600")
                }
              >
                {employee.activeDirectoryId}
              </div>
              {employee.isFormerEmployee ? (
                <span className="px-1 mx-3 text-sm text-gray-400 border border-gray-400 rounded-sm">
                  {t("former")}
                </span>
              ) : null}
            </div>
          </>
        );
      }}
    />
  );
};
