import { FormDefinition } from "components/form";
import { NetworkBlock } from "generated/graphql";
import { textField } from "components/form/textField";
import { markDownField } from "components/form/markDownField";
import { singleSelectField } from "components/form/singleSelectField";
import { teamField } from "components/form/teamField";
import { editBlockModal } from "../editBlock";
import { previewText } from "../helpers/previewText";
import { blockPictureField } from "components/form/blockPictureField";
import { linksField } from "components/form/linksField";

const centerTypes = ["text", "logo"];

const centerTypeOptions = centerTypes.map((centerType: string) => ({
  value: centerType,
  labelKey: `onepager:networkBlock.centerType.${centerType}`,
}));

const formDefinition: FormDefinition<NetworkBlock> = {
  title: () => "",
  titleString: () => "",
  previewText: (block) => {
    return previewText(block.navigationTitle?.de ?? "");
  },
  fields: {
    navigationTitle: {
      name: "onepager.networkBlock.navigationTitle",
      component: textField,
      multiLang: true,
      required: true,
    },
    title: {
      name: "onepager.networkBlock.title",
      component: textField,
      multiLang: true,
    },
    intro: {
      name: "onepager.networkBlock.intro",
      component: markDownField,
      multiLang: true,
    },
    centerType: {
      name: "onepager.networkBlock.centerType",
      component: singleSelectField,
      props: { options: centerTypeOptions },
    },
    centerText: {
      name: "onepager.networkBlock.centerText",
      component: textField,
      multiLang: true,
      props: {
        lines: 5,
      },
      displayIf: (values) => {
        return values?.centerType == "text";
      },
    },
    centerLogo: {
      name: "onepager.networkBlock.centerLogo",
      component: blockPictureField,
      props: { hideFocusPoint: true },
      displayIf: (values) => {
        return values?.centerType == "logo";
      },
    },
    teamLevel1: {
      name: "onepager.networkBlock.level1",
      component: teamField,
      props: { hideNonWebsitePeople: true, allowExternalPeople: true },
    },
    teamLevel2: {
      name: "onepager.networkBlock.level2",
      component: teamField,
      props: { hideNonWebsitePeople: true, allowExternalPeople: true },
    },
    teamLevel3: {
      name: "onepager.networkBlock.level3",
      component: teamField,
      props: { hideNonWebsitePeople: true, allowExternalPeople: true },
    },
    externalLinks: {
      name: "onepager.networkBlock.externalLinks",
      component: linksField,
    },
  },
  link: () => "",
};

export const editNetwork = {
  formDefinition,
  modal: editBlockModal(formDefinition),
};
