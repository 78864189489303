import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { GenericFieldProps } from "./fieldProps";
import Select from "react-select";
import { customStyles, i18nOptions } from "./multiSelectField";

import { useMultiLang } from "helpers/multiLang";
import {
  ALL_PROJECT_REFERENCE_QUERY,
  ONEPAGER_STARRED_REFERENCE_QUERY,
} from "queries/references";
import { useFuse } from "helpers/useFuse";
import { getShortProjectId } from "pages/newProject/header";
import { MultilangText } from "generated/graphql";

export type ProjectReferenceFieldProps = GenericFieldProps<string>;

type OptionType = {
  id: string;
  title: MultilangText;
  project: {
    id: string;
    abacusProjectId: string;
    name: MultilangText;
  };
};

export const ProjectReferenceField = ({
  value,
  onChange,
  disabled,
}: ProjectReferenceFieldProps) => {
  const { t } = useTranslation(["common", "project"]);
  const m = useMultiLang();
  const allProjectsResponse = useQuery(ALL_PROJECT_REFERENCE_QUERY);
  const references = allProjectsResponse?.data?.allProjectReferences ?? [];

  const starredReferencesResponse = useQuery(ONEPAGER_STARRED_REFERENCE_QUERY);
  const starredReferenceIds = new Set<string>(
    starredReferencesResponse?.data?.me?.starredReferences?.map(
      (r) => r.referenceId,
    ) ?? [],
  );

  const maybeStarIcon = (referenceId) => {
    return starredReferenceIds.has(`${referenceId}`) ? "★ " : "";
  };

  const optionMap = (e: OptionType) => ({
    value: e.id,
    label: `${maybeStarIcon(e?.id)}${
      m(e.title) || t("project:noTitle")
    } (#${getShortProjectId(e.project?.abacusProjectId ?? "")} ${m(
      e.project?.name,
    )})`,
  });

  const toOption = (reference) => ({
    value: reference?.id,
    label: `${maybeStarIcon(reference?.id)}${m(
      reference?.title,
      t("project:noTitle"),
    )} (#${getShortProjectId(reference?.project?.abacusProjectId ?? "")} ${m(
      reference?.project?.name,
    )})`,
  });

  const selectedOption = references?.find((c) => c.id === value);

  const preparedReferences = references?.map(optionMap);
  const [inputValue, setInputValue] = useState("");
  const filterReferences = useFuse(preparedReferences, inputValue, {
    keys: ["label"],
  })
    ?.sort((a: any, b: any) => {
      const aIsStarred = starredReferenceIds.has(`${a.value}`);
      const bIsStarred = starredReferenceIds.has(`${b.value}`);
      return aIsStarred == bIsStarred ? 0 : aIsStarred ? -1 : 1;
    })
    ?.slice(0, 200);

  return (
    <div className="text-sm">
      <Select
        onInputChange={(value) => setInputValue(value)}
        styles={customStyles}
        options={filterReferences as any[]}
        isDisabled={disabled}
        {...i18nOptions(t)}
        value={toOption(selectedOption)}
        onChange={
          onChange &&
          ((newValue: any) => {
            onChange(newValue?.value);
          })
        }
      />
    </div>
  );
};

export const projectReferenceField = {
  renderer: ProjectReferenceField,
};
