import React from "react";
import { FormDefinition, virtualField } from "components/form";
import { ReferenceBlock, MultilangText } from "generated/graphql";
import { textField } from "components/form/textField";
import { MarkDownField } from "components/form/markDownField";
import { projectReferenceField } from "components/form/projectReferenceField";
import { editBlockModal } from "../editBlock";
import { fromToField } from "components/form/fromToField";
import { siaPhasesField } from "components/form/siaPhasesField";
import { multiSelectField } from "components/form/multiSelectField";
import { factsField } from "components/form/factsField";
import { linksField } from "components/form/linksField";
import { teamField } from "components/form/teamField";
import { blockPictureField } from "components/form/blockPictureField";
import { galleryField } from "components/form/galleryField";

import {
  SELECTABLE_SERVICES,
  SELECTABLE_APPLICATION_AREAS,
} from "pages/project/utils";
import { ONEPAGER_REFERENCE_QUERY } from "queries/references";
import { uniqBy } from "ramda";
import { previewText } from "../helpers/previewText";
import { useTranslation } from "react-i18next";

const fetchReferenceDetail = async (apolloClient, referenceId) => {
  const reference = await apolloClient.query({
    query: ONEPAGER_REFERENCE_QUERY,
    variables: {
      id: referenceId,
    },
    fetchPolicy: "network-only",
  });

  const childReferences =
    reference?.data?.reference?.childReferences?.filter(
      (childReference) => childReference.employeeId !== null,
    ) ?? [];

  const enrichedReference = {
    ...(reference?.data?.reference ?? {}),
    team: uniqBy((r: any) => r.employeeId, childReferences),
  };

  return enrichedReference;
};

const formDefinition: FormDefinition<
  ReferenceBlock & {
    description: MultilangText;
    separator: void;
  }
> = {
  title: () => "",
  previewText: (block) => {
    return previewText(block.navigationTitle?.de ?? "");
  },
  titleString: () => "",
  fields: {
    navigationTitle: {
      name: "onepager.referenceBlock.navigationTitle",
      component: textField,
      multiLang: true,
      required: true,
    },
    referenceId: {
      name: "onepager.referenceBlock.reference",
      component: projectReferenceField,
    },
    separator: {
      name: "onepager.referenceBlock.separator",
      component: {
        renderer: () => (
          <div>
            <hr />
          </div>
        ),
      },
      skipFieldOnSave: true,
      useFullWidth: true,
    },
    title: {
      name: "onepager.referenceBlock.title",
      component: textField,
      multiLang: true,
      dependentData: "referenceId",
    },
    from: {
      name: "onepager.referenceBlock.fromTo",
      component: fromToField(),
      dependentData: "referenceId",
      hidableFromExport: true,
      controlledFields: ["from", "to"],
    },
    picture: {
      name: "onepager.referenceBlock.picture",
      component: blockPictureField,
      dependentData: "referenceId",
      hidableFromExport: true,
    },
    headline: {
      name: "onepager.referenceBlock.headline",
      component: textField,
      props: { lines: 3 },
      hidableFromExport: true,
      multiLang: true,
    },
    description: {
      name: "onepager.referenceBlock.description",
      component: {
        renderer: ({ value }) => {
          const { t } = useTranslation(["common", "form"]);
          return (
            <>
              <div className="px-10 pb-2 font-medium">
                {t(
                  "form:fieldName.reference.privateReferenceProjectDescription",
                )}
              </div>
              <MarkDownField readOnly value={value} />
            </>
          );
        },
      },
      dependentData: "referenceId",
      readOnly: true,
      multiLang: true,
      useFullWidth: true,
      skipFieldOnSave: true,
    },
    applicationAreas: {
      name: "onepager.referenceBlock.applicationAreas",
      component: multiSelectField,
      dependentData: "referenceId",
      hidableFromExport: true,
      props: { options: SELECTABLE_APPLICATION_AREAS },
    },
    services: {
      name: "onepager.referenceBlock.services",
      component: multiSelectField,
      dependentData: "referenceId",
      hidableFromExport: true,
      props: { options: SELECTABLE_SERVICES },
    },
    siaPhases: {
      name: "onepager.referenceBlock.phases",
      component: siaPhasesField(),
      dependentData: "referenceId",
      hidableFromExport: true,
      controlledFields: [
        "siaPhases",
        "hoaiPhases",
        "frPhases",
        "itPhases",
        "primaryPhase",
      ],
    },
    team: {
      name: "onepager.referenceBlock.team",
      component: teamField,
      dependentData: "referenceId",
      hidableFromExport: true,
    },
    facts: {
      name: "onepager.referenceBlock.facts",
      component: factsField,
      dependentData: "referenceId",
      hidableFromExport: true,
    },
    gallery: {
      name: "onepager.referenceBlock.gallery",
      component: galleryField,
      hidableFromExport: true,
    },
    externalLinks: {
      name: "onepager.referenceBlock.externalLinks",
      component: linksField,
      hidableFromExport: true,
    },
    linkedFields: virtualField,
    exportedFields: virtualField,
  },
  dataDependencies: [
    {
      fetchFunction: fetchReferenceDetail,
      key: "referenceId",
      resetFieldKeysOnFetch: ["headline", "gallery"],
    },
  ],
  link: () => "",
};

export const editReference = {
  formDefinition,
  modal: editBlockModal(formDefinition),
};
