import { gql } from "@apollo/client";
import { multiLangString } from "./common";

const teamOnepagerFragment = gql`
 fragment teamOnepagerFragment on EmployeeRoleNetwork {
  id
  employeeId
  employee {
    id
    fullName
    roles {
      id
      role {
        id
        name ${multiLangString}
      }
    }
  }
  externalName
  role ${multiLangString}
 }
`;

const blockPictureFragment = gql`
  fragment blockPictureFragment on BlockPicture {
    id
    url(width: 0)
    filename
    focusX
    focusY
  }
`;

export const onepagerShortFields = gql`
  fragment OnepagerShortFields on Onepager {
    id
    title
    browserTitle
    person
    institution
    supportedLanguages
    targetCountry
    tags {
      tagId
      id
      newText
      tag {
        id
        name ${multiLangString}
      }
    }
  }
`;

export const onepagerFragment = gql`
  ${onepagerShortFields}
  ${teamOnepagerFragment}
  ${blockPictureFragment}

  fragment OnepagerFields on Onepager {
    ...OnepagerShortFields
    blocks {
      __typename
      ... on IntroBlock {
        id
        type
        welcome ${multiLangString}
        intro ${multiLangString}
        navigationTitle ${multiLangString}
        logo {
          ...blockPictureFragment
        }
        hideTbfLogo
        externalLinks {
          id
          name ${multiLangString}
          url
        }
      }
      ... on JokerBlock {
        id
        type
        navigationTitle ${multiLangString}
        externalLinks {
          id
          name ${multiLangString}
          url
        }
        elements {
          __typename
          ... on RichtextElement {
            id
            type
            data ${multiLangString}
          }
          ... on PictureElement {
            id
            type
            caption ${multiLangString}
            orientation
            picture {
              ...blockPictureFragment
            }
          }
          ... on EmptyElement {
            id
            type
          }
        }
        layout {
          id
          type
          index
          indexes
        }
      }
      ... on NetworkBlock {
        id
        type
        title ${multiLangString}
        intro ${multiLangString}
        navigationTitle ${multiLangString}
        centerType
        centerText ${multiLangString}
        centerLogo {
          ...blockPictureFragment
        }
        teamLevel1 {
          ...teamOnepagerFragment
        }
        teamLevel2 {
          ...teamOnepagerFragment
        }
        teamLevel3 {
          ...teamOnepagerFragment
        }
        externalLinks {
          id
          name ${multiLangString}
          url
        }
      }
      ... on IntermissionBlock {
        id
        type
        title ${multiLangString}
        text ${multiLangString}
        picture
        externalLinks {
          id
          name ${multiLangString}
          url
        }
      }
      ... on ReferenceBlock {
        id
        type
        referenceId
        navigationTitle ${multiLangString}
        title ${multiLangString}
        headline ${multiLangString}
        from
        to
        gallery {
          id
          caption ${multiLangString}
          captionPosition
          orientation
          pictureId
          picture {
            ...blockPictureFragment
          }
        }
        picture {
          ...blockPictureFragment
        }
        siaPhases
        hoaiPhases
        frPhases
        itPhases
        primaryPhase
        applicationAreas
        services
        team {
          ...teamOnepagerFragment
        }
        facts {
          id
          value ${multiLangString}
          newText
          keyId
          key {
            id
            name ${multiLangString}
          }
        }
        externalLinks {
          id
          name ${multiLangString}
          url
        }
        linkedFields
        exportedFields
      }
      ... on OutroBlock {
        id
        type
        trustedAdvisorId
        trustedAdvisor {
          id
          fullName
        }
        navigationTitle ${multiLangString}
        linkedFields
        externalLinks {
          id
          name ${multiLangString}
          url
        }
        exportedFields
        message ${multiLangString}
      }
      ... on AboutTbfBlock {
        id
        type
        navigationTitle ${multiLangString}
        intro ${multiLangString}
        showRosette
        facts {
          id
          type
          itemKey ${multiLangString}
          value ${multiLangString}
        }
        externalLinks {
          id
          name ${multiLangString}
          url
        }
        linkedFields
        exportedFields
      }
    }
  }
`;

const metadataFragment = gql`
  fragment metadataFields on Onepager {
    modifiedBy {
      __typename
      ... on Employee {
        id
        activeDirectoryId
        fullName
      }
    }
    createdBy {
      __typename
      ... on Employee {
        id
        activeDirectoryId
        fullName
      }
    }
    updatedAt
    insertedAt
    deletedAt
  }
`;

const previousPublicationField = gql`
  ${metadataFragment}

  fragment previousPublicationField on Onepager {
    previousPublication {
      id
      token
      updatedAt
      status
      onepager {
        updatedAt
        ...metadataFields
      }
    }
  }
`;

export const publicationMetadataFragment = gql`
  fragment publicationMetadataFields on Onepager {
    id
    title
    version
    publication {
      id
      token
      updatedAt
      status
    }
  }
`;

export const ONEPAGER_QUERY = gql`
  ${onepagerFragment}
  ${publicationMetadataFragment}
  ${previousPublicationField}

  query GetOnepager($id: ID!) {
    onepager(id: $id) {
      id
      ...OnepagerFields
      numSnapshots
      ...publicationMetadataFields
      ...previousPublicationField
      ...metadataFields
    }
  }
`;

export const ONEPAGER_SNAPSHOTS_QUERY = gql`
  ${publicationMetadataFragment}
  ${metadataFragment}

  query GetOnepagerSnapshots($id: ID!) {
    onepager(id: $id) {
      ...publicationMetadataFields
      ...metadataFields
      snapshots {
        ...publicationMetadataFields
        ...metadataFields
      }
    }
  }
`;

export const ALL_ONEPAGERS_QUERY = gql`
  ${onepagerShortFields}
  ${publicationMetadataFragment}
  ${metadataFragment}
  ${previousPublicationField}

  query AllOnepagers($offset: Int, $limit: Int, $onepagerType: String!) {
    allOnepagers(offset: $offset, limit: $limit, onepagerType: $onepagerType) {
      approvedAt
      ...OnepagerShortFields
      ...metadataFields
      ...publicationMetadataFields
      ...previousPublicationField
    }
  }
`;

export const PUBLISH_ONEPAGER_MUTATION = gql`
  mutation PublishOnepager($id: ID!) {
    publishOnepager(id: $id) {
      id
      token
      onepagerId
    }
  }
`;

export const UN_PUBLISH_ONEPAGER_MUTATION = gql`
  mutation UnPublishOnepager($id: ID!) {
    unPublishOnepager(id: $id) {
      id
    }
  }
`;

export const EDIT_ONEPAGER_QUERY = gql`
  ${onepagerFragment}

  query GetEditOnepager($id: ID!) {
    onepager(id: $id) {
      id
      ...OnepagerFields
    }
  }
`;

export const CREATE_APPROVED_ONEPAGER_MUTATION = gql`
  ${onepagerFragment}

  mutation CreateApprovedOnepagerMutation($fields: InputOnepager!) {
    createApprovedOnepager(fields: $fields) {
      ...OnepagerFields
    }
  }
`;

export const DELETE_AND_APPROVE_ONEPAGER_MUTATION = gql`
  ${onepagerFragment}

  mutation DeleteAndApproveOnepagerMutation($id: ID!) {
    deleteAndApproveOnepager(id: $id) {
      ...OnepagerFields
    }
  }
`;

export const RESTORE_AND_APPROVE_ONEPAGER_MUTATION = gql`
  ${onepagerFragment}

  mutation RestoreAndApproveOnepagerMutation($id: ID!) {
    restoreDeletedOnepager(id: $id) {
      ...OnepagerFields
    }
  }
`;

export const COPY_ONEPAGER_MUTATION = gql`
  ${onepagerFragment}

  mutation CopyOnepagerMutation($id: ID!, $fields: InputOnepager) {
    copyOnepager(id: $id, fields: $fields) {
      ...OnepagerFields
    }
  }
`;

export const EDIT_ONEPAGER_MUTATION = gql`
  ${onepagerFragment}

  mutation EditOnepagerMutation($id: ID!, $fields: InputOnepager!) {
    editAndApproveOnepager(id: $id, fields: $fields) {
      ...OnepagerFields
      approvedBy {
        __typename
      }
    }
  }
`;
