import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import * as R from "ramda";

import { ElasticParams } from "../helpers";
import { FilterButton } from "components/button";

import Select from "react-select";
import { useQuery } from "@apollo/client";
import { ALL_PROJECTS_SEARCH_QUERY } from "queries/newProject";

import { useFuse } from "helpers/useFuse";
import { customStyles } from "components/form/multiSelectField";
import { useMultiLang } from "helpers/multiLang";
import { getShortProjectId } from "pages/newProject/header";
import { NewProject } from "generated/graphql";

// Filter config

type FilterDefinition = {
  key: string;
  subFilters: Array<{
    key: string;
    filter: Filter;
  }>;
};

type Filter = {
  types?: string[];
  filters?: Array<{ type: string; args?: any }>;
};

const emptySubFilter: Filter = { types: [], filters: [] };

const imageFilter: Filter = {
  filters: [{ type: "file_type", args: "Image" }],
  types: ["file"],
};

const allFileTypes = [
  "PDF",
  "Image",
  "Vector",
  "Document",
  "Presentation",
  "Spreadsheet",
  "Visio",
  "MSProject",
  "CAD",
];

const allFilters: FilterDefinition[] = [
  {
    key: "project",
    subFilters: [
      {
        key: "current_project",
        filter: {
          types: ["new_project"],
          filters: [{ type: "current_project" }],
        },
      },
      {
        key: "archived_project",
        filter: {
          types: ["new_project"],
          filters: [{ type: "archived_project" }],
        },
      },
      {
        key: "paper_archived_project",
        filter: {
          types: ["new_project"],
          filters: [{ type: "paper_archived_project" }],
        },
      },
    ],
  },
  {
    key: "people",
    subFilters: [
      { key: "employee", filter: { types: ["new_employee"] } },
      {
        key: "client",
        filter: { types: ["new_client"], filters: [{ type: "client" }] },
      },
      {
        key: "contact",
        filter: { types: ["new_client"], filters: [{ type: "contact" }] },
      },
    ],
  },
  {
    key: "references",
    subFilters: [
      {
        key: "employee_reference",
        filter: {
          filters: [{ type: "employee_references" }],
          types: ["new_reference"],
        },
      },
      {
        key: "project_reference",
        filter: {
          filters: [{ type: "project_references" }],
          types: ["new_reference"],
        },
      },
    ],
  },
  {
    key: "wikiPage",
    subFilters: [{ key: "wiki_page", filter: { types: ["wiki_page"] } }],
  },
  {
    key: "onepager",
    subFilters: [{ key: "onepager", filter: { types: ["onepager"] } }],
  },
  {
    key: "file",
    subFilters: allFileTypes.map((fileType: string) => ({
      key: fileType,
      filter: {
        filters: [{ type: "file_type", args: fileType }],
        types: ["file"],
      },
    })),
  },
  {
    key: "image",
    subFilters: [
      {
        key: "image",
        filter: imageFilter,
      },
    ],
  },
  {
    key: "note",
    subFilters: [{ key: "note", filter: { types: ["note"] } }],
  },
  {
    key: "notion",
    subFilters: [{ key: "notion", filter: { types: ["notion"] } }],
  },
].filter((it) => it) as any[];

// ---
// Helpers

const getAllEnabledTypes = () => {
  const { types } = allFilters.reduce((acc, elem) => {
    return mergeSubFilters(
      acc,
      elem.subFilters.map((f) => f.filter),
    );
  }, emptySubFilter);
  return types || [];
};

const mergeSubFilters = (baseFilter: Filter, filters: Filter[]): Filter => {
  return filters.reduce((acc: Filter, elem: Filter): Filter => {
    return {
      types: R.uniq([...(acc.types || []), ...(elem.types || [])]),
      filters: [...(acc.filters || []), ...(elem.filters || [])],
    };
  }, baseFilter);
};

const getAllSubFilters = (topFilter?: string) => {
  return (
    (R.find(({ key }) => key === topFilter, allFilters) || {}).subFilters || []
  );
};

export const allEnabledTypes = getAllEnabledTypes();

// ---
// UI state -> elastic parameters

export const toElasticParams = (
  searchText: string,
  accuracy: "slow" | "fast",
  filters: SelectedFilterButtons,
): ElasticParams => {
  const merged = mergeFilters(filters);
  let scopeFilter = [] as any;
  if (filters.scope === "myProjects") {
    scopeFilter = [{ type: "my_projects" }];
  } else if (filters.scope) {
    scopeFilter = [{ type: "file_bucket", args: filters.scope }];
  }
  if (filters.selectedProjects?.length ?? 0 > 0) {
    scopeFilter = [
      { type: "selected_projects", args: filters.selectedProjects },
      ...scopeFilter,
    ];
  }

  const fileFilterSelected = [...(merged.types ?? [])].every(
    (value) => "file" === value,
  );

  return {
    searchText,
    types: merged.types,
    filters: [...(merged.filters || []), ...scopeFilter],
    useFuzzy: accuracy === "slow",
    searchFileContents: fileFilterSelected || accuracy === "slow",
  };
};

const mergeFilters = (selectedFilters: SelectedFilterButtons): Filter => {
  const { selectedTopFilter, selectedSubFilters } = selectedFilters;
  if (selectedTopFilter) {
    const { subFilters } =
      allFilters.find((v) => v.key === selectedTopFilter) ?? {};

    const chosenSubFilters = selectedSubFilters[selectedTopFilter];

    if (selectedTopFilter === "image") {
      return imageFilter;
    }

    if (!chosenSubFilters || chosenSubFilters.length === 0) {
      // If only top level filter selected, search in all sub-filters types, but don't activate the filters
      const { types } = mergeSubFilters(
        emptySubFilter,
        subFilters?.map((f) => f.filter) || [],
      );
      return { types };
    }

    // If sub-filters are selected, only search with them
    const chosenFilters = subFilters
      ?.filter((f) => chosenSubFilters.includes(f.key))
      ?.map((f) => f.filter);

    return mergeSubFilters(emptySubFilter, chosenFilters || []);
  } else {
    // If nothing is selected, search everywhere
    return { types: allEnabledTypes };
  }
};

// ---
// UI state

export type SelectedFilterButtons = {
  selectedTopFilter?: string;
  selectedSubFilters: { [key: string]: string[] };
  scope?: string;
  selectedProjects?: string[];
};

export const initialState: SelectedFilterButtons = { selectedSubFilters: {} };

// ---
// Component

type SelectProjectsFilterProps = {
  selectedProjects: string[];
  setSelectedProjects: (projectIds: string[]) => void;
};

const SelectProjectsFilter = ({
  selectedProjects,
  setSelectedProjects,
}: SelectProjectsFilterProps) => {
  const { t } = useTranslation("search");
  const m = useMultiLang();

  const { data } = useQuery<{ newProjects: NewProject[] }>(
    ALL_PROJECTS_SEARCH_QUERY,
  );

  const optionMap = (v) => ({
    value: v.abacusProjectId,
    label: `${getShortProjectId(v?.abacusProjectId)} - ${m(v.name)}`,
  });
  const projects =
    data &&
    data.newProjects
      .filter((p) => !!p.abacusProjectId)
      .sort(
        (a, b) =>
          parseInt(getShortProjectId(a.abacusProjectId ?? "")) -
          parseInt(getShortProjectId(b.abacusProjectId ?? "")),
      )
      .map(optionMap);

  const selectedOptions = projects?.filter((project) =>
    selectedProjects?.includes(project.value),
  );

  const [inputValue, setInputValue] = useState("");

  const handleChange = (newValues) => {
    setSelectedProjects(
      Array.isArray(newValues) ? newValues.map((x) => x.value) : [],
    );
  };

  const filterProjects = useFuse(projects ?? [], inputValue, {
    keys: ["label", "value"],
  })?.slice(0, 200);

  return (
    <div>
      <Select
        className="min-w-96"
        styles={customStyles}
        placeholder={t("chooseProjects")}
        isMulti
        value={selectedOptions}
        options={filterProjects}
        onChange={handleChange}
        onInputChange={(value) => setInputValue(value)}
        isLoading={!filterProjects}
      />
    </div>
  );
};

export const SearchFilter = ({
  filters,
  onChangeFilters,
}: {
  filters: SelectedFilterButtons;
  onChangeFilters: (filters: SelectedFilterButtons) => void;
}) => {
  const { t } = useTranslation(["search"]);
  const { selectedSubFilters, selectedTopFilter, scope, selectedProjects } =
    filters;
  const allSubFilters = getAllSubFilters(selectedTopFilter);
  const subFilters = selectedTopFilter
    ? selectedSubFilters[selectedTopFilter] || []
    : [];

  const ScopeButton = ({ scopeType }: { scopeType: string }) => (
    <FilterButton
      onClick={() => {
        onChangeFilters({
          ...filters,
          scope: scope === scopeType ? undefined : scopeType,
        });
      }}
      active={scope === scopeType}
    >
      {t(scopeType as any)}
    </FilterButton>
  );

  const objectSelected =
    selectedTopFilter === "project" ||
    selectedTopFilter === "references" ||
    selectedTopFilter === "file";

  return (
    <div className="relative px-3 pb-6 mx-auto max-w-screen-tbf">
      <div className="flex flex-row mt-6">
        <p className="text-gray-500 w-32 mt-0.5">{t("search:for")}</p>

        <div className="flex flex-row flex-wrap flex-1 gap-x-8">
          {allFilters.map(({ key }) => {
            const isActive = selectedTopFilter === key;
            return (
              <FilterButton
                key={key}
                onClick={() => {
                  if (isActive) {
                    onChangeFilters({
                      selectedSubFilters: {},
                      selectedTopFilter: undefined,
                      selectedProjects: filters.selectedProjects,
                    });
                  } else {
                    onChangeFilters({
                      selectedSubFilters: {},
                      selectedTopFilter: key,
                      selectedProjects: filters.selectedProjects,
                      scope: scope,
                    });
                  }
                }}
                active={isActive}
              >
                {t(key as any)}
              </FilterButton>
            );
          })}
        </div>
      </div>

      {selectedTopFilter && allSubFilters.length > 1 ? (
        <div className="flex flex-row mt-6">
          <p className="text-gray-500 w-32 mt-0.5">{t("search:filterBy")}</p>

          <div className="flex flex-row flex-wrap flex-1 gap-x-8">
            {allSubFilters.map(({ key }) => {
              const isActive = subFilters.includes(key);
              return (
                <label
                  key={key}
                  className={`text-lg w-full justify-center md:text-lg sm:justify-start sm:w-auto sm:text-base focus:outline-none transition cursor-pointer ${
                    isActive
                      ? "text-red-500 hover:text-red-500"
                      : "text-gray-900 hover:text-red-500"
                  }
            
            `}
                >
                  <input
                    type="checkbox"
                    className="mr-2 transition cursor-pointer accent-red-500"
                    checked={isActive}
                    onChange={() => {
                      if (isActive) {
                        onChangeFilters({
                          ...filters,
                          selectedSubFilters: {
                            ...selectedSubFilters,
                            [selectedTopFilter]: subFilters.filter(
                              (f) => f !== key,
                            ),
                          },
                        });
                      } else {
                        onChangeFilters({
                          ...filters,
                          selectedSubFilters: {
                            ...selectedSubFilters,
                            [selectedTopFilter]: [...subFilters, key],
                          },
                        });
                      }
                    }}
                  />
                  {t(key as any)}
                </label>
              );
            })}
          </div>
        </div>
      ) : null}

      <div className="flex flex-row items-center mt-6 gap-x-8">
        {objectSelected ? (
          <p className="text-gray-500 basis-24">{t("search:includeOnly")}</p>
        ) : null}
        {selectedTopFilter === "file" ? (
          <div>
            <ScopeButton scopeType={"External"} />
          </div>
        ) : null}
        {objectSelected && (
          <>
            <div>
              <ScopeButton scopeType={"myProjects"} />
            </div>
            <div>
              <ScopeButton scopeType={"chooseProjects"} />
            </div>
          </>
        )}

        {selectedTopFilter === "file" ? (
          <>
            <div>
              <ScopeButton scopeType={"Internal"} />
            </div>
            <div>
              <ScopeButton scopeType={"Archive"} />
            </div>
            <div>
              <ScopeButton scopeType={"Knowhow"} />
            </div>
          </>
        ) : null}
      </div>
      {scope === "chooseProjects" && objectSelected ? (
        <div className="py-2 pl-32">
          <SelectProjectsFilter
            selectedProjects={selectedProjects ?? []}
            setSelectedProjects={(projectIds) => {
              onChangeFilters({
                ...filters,
                selectedProjects: projectIds,
              });
            }}
          />
          <div className="pt-2 text-xs text-gray-500">
            {t("search:selectedObject", {
              object: t(selectedTopFilter as any),
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
};
