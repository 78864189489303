import { FieldDefinition } from "components/form";
import { GenericFieldProps } from "components/form/fieldProps";

export const validateMultilangFields = (
  value: Record<string, any>,
): boolean => {
  const languages = ["de", "en", "fr", "it"];
  return languages.some((lang) => value?.[lang]?.length > 0);
};

const validateField = (values, key) => {
  if (validateMultilangFields(values[key]) === true) {
    return true;
  } else {
    return false;
  }
};

export const getFirstInvalidField = (
  formDefinitionFields: Partial<
    Record<keyof GenericFieldProps<any>, FieldDefinition<any>>
  >,
  values: Record<string, any>,
): string | null => {
  const firstInvalidField = Object.keys(formDefinitionFields).reduce<
    string | null
  >((accumulator, key) => {
    if (
      formDefinitionFields[key]?.required &&
      !validateField(values, key) &&
      accumulator === null
    ) {
      return key;
    } else return accumulator;
  }, null);
  return firstInvalidField;
};
