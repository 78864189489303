import { editBlockModal } from "../editBlock";
import { previewText } from "../helpers/previewText";
import { singleSelectField } from "components/form/singleSelectField";
import { FormDefinition } from "components/form";
import { IntermissionBlock } from "generated/graphql";
import { textField } from "components/form/textField";
import { linksField } from "components/form/linksField";

const pictureOptions = ["seaMonster", "sailingBoat", "boulder", "guide"].map(
  (option: string) => ({
    value: option,
    labelKey: `pictures:${option}`,
  }),
);

const formDefinition: FormDefinition<IntermissionBlock> = {
  title: () => "",
  titleString: () => "",
  previewText: (block) => {
    return previewText(block.title?.de ?? "");
  },
  fields: {
    title: {
      name: "onepager.intermissionBlock.title",
      component: textField,
      multiLang: true,
      props: {
        placeholderKey: "onepager:intermissionBlock.placeholder.title",
      },
    },
    text: {
      name: "onepager.intermissionBlock.text",
      component: textField,
      multiLang: true,
      props: {
        placeholderKey: "onepager:intermissionBlock.placeholder.text",
        lines: 5,
      },
    },
    picture: {
      name: "onepager.intermissionBlock.picture",
      component: singleSelectField,
      props: {
        options: pictureOptions,
        defaultValue: null,
      },
    },
    externalLinks: {
      name: "onepager.intermissionBlock.externalLinks",
      component: linksField,
    },
  },
  link: () => "",
};

export const editIntermission = {
  formDefinition,
  modal: editBlockModal(formDefinition),
};
