import React, { useState } from "react";
import { Link, useLocation } from "wouter";
import { useTranslation } from "react-i18next";
import { FaBook, FaHashtag, FaBuilding } from "react-icons/fa";
import { sortBy, flatten, uniqBy } from "ramda";

import { IconButton } from "components/button";
import { getShortProjectId } from "pages/newProject/header";
import { EditReferenceModal } from "components/editVersioned/editReference";
import { MetadataReference } from "components/newReferences";

import { useMultiLang } from "helpers/multiLang";
import { NewProject } from "generated/graphql";

const isInternalProject = (abacusProjectId) => abacusProjectId?.length <= 4;

const MainInfo = ({ project }: { project: Partial<NewProject> }) => {
  const { t } = useTranslation(["common", "project"]);
  const m = useMultiLang();

  return (
    <div className="flex-1 mb-2 truncate">
      <Link
        href={`/project/${project.id}`}
        className="text-sm font-medium leading-8 text-gray-900 hover:text-blue-500"
      >
        {m(project.name)}
      </Link>

      <div className="flex items-center space-x-4 text-xs leading-relaxed text-gray-500">
        <div className="flex items-center space-x-1">
          <FaHashtag />
          <div className="leading-none">
            {getShortProjectId(project.abacusProjectId ?? "")}
          </div>
        </div>
        {project.client ? (
          <Link
            className="flex items-center space-x-2 text-gray-500 cursor-pointer hover:text-blue-500"
            href={`/client/${project.client?.id}`}
          >
            <FaBuilding />
            <div
              style={{ maxWidth: "8rem" }}
              className="overflow-hidden leading-none overflow-ellipsis whitespace-nowrap"
            >
              {project.client?.name || ""}
            </div>
          </Link>
        ) : (
          <div className="flex items-center space-x-1">
            <FaBuilding />
            <div className="leading-none">{t("common:noClient")}</div>
          </div>
        )}
        <div className="flex items-center space-x-1">
          <FaBook />
          <div className="leading-none">
            {t(`project:mainReferencesCount`, {
              count: project.projectMainReferencesCount ?? 0,
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export const mergeIntoMainProjects = (projects: NewProject[]): NewProject[] => {
  return uniqBy(
    (p) => p.id,
    flatten(
      (projects ?? []).map((p) => {
        // Substitute unlisted, merged projects with their parent project(s)
        if (p.isUnlisted) {
          return p.parentProjects ?? [];
        } else {
          return [p];
        }
      }),
    ),
  );
};

export const ProjectList: React.FC<any> = ({
  projects,
  employeeId,
  references,
}) => {
  const { t } = useTranslation(["common", "project"]);
  const [projectIdForEdit, setProjectIdForEdit] = useState<string | null>(null);
  const [_location, navigate] = useLocation();

  const referencesProjectIds = references
    .map((reference) => reference?.projectId)
    .filter((it) => !!it);

  const filteredProjects = mergeIntoMainProjects(projects).filter((project) => {
    return (
      !isInternalProject(project.abacusProjectId) &&
      !referencesProjectIds.includes(project.id)
    );
  });

  const sortedProjects = sortBy(
    (project) => -(project.projectMainReferencesCount ?? 0),
    filteredProjects,
  );

  return (
    <>
      {sortedProjects?.map((project) => (
        <div
          key={project.id}
          className="flex justify-between py-2 border-t border-gray-300 item-center"
        >
          <MainInfo project={project} />
          {project.from ||
          project.to ||
          project.siaPhases ||
          project.hoaiPhases ? (
            <div className="flex items-center ml-2">
              <MetadataReference
                from={project.from}
                to={project.to}
                siaPhases={project.siaPhases ?? []}
                hoaiPhases={project.hoaiPhases ?? []}
              />
            </div>
          ) : null}
          <div className="items-center hidden ml-4 md:flex">
            <IconButton
              Icon={FaBook}
              onClick={() => {
                navigate(`/project/${project.id}/references`);
              }}
            >
              {t(`project:references`)}
            </IconButton>
          </div>
        </div>
      ))}
      <EditReferenceModal
        newEntity={{ projectId: projectIdForEdit, employeeId }}
        close={() => setProjectIdForEdit(null)}
        open={projectIdForEdit !== null}
      />
    </>
  );
};

export const SimpleProjectsList = ({
  projects,
}: {
  projects: NewProject[];
}) => {
  return (
    <>
      {projects.map((project) => (
        <div
          key={project.id}
          className="flex justify-between py-3 border-t border-gray-300 item-center"
        >
          <MainInfo project={project} />
          <MetadataReference
            from={project.from}
            to={project.to}
            siaPhases={project.siaPhases ?? []}
            hoaiPhases={project.hoaiPhases ?? []}
          />
        </div>
      ))}
    </>
  );
};
