import { useTranslation } from "react-i18next";

export const getTextFromMultiLang =
  (language: string) =>
  (multi: any, fallback = ""): string => {
    if (!multi) {
      return fallback;
    }
    const val = multi[language];
    if (!val || multi === "") {
      return multi.de || fallback;
    }
    return val;
  };

export const useMultiLang = () => {
  const { i18n } = useTranslation();
  // this sets language fallback for language variants like it-CH to it, fr-FR to fr,...
  const language = i18n.language.length > 2 ? i18n.languages[1] : i18n.language;
  const m = getTextFromMultiLang(language);
  return m;
};
