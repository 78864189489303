import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { IconButton } from "./button";
import { HiX } from "react-icons/hi";

export const WikiSearchField = ({
  text,
  setText,
  onClose,
}: {
  text: string;
  setText: (value: string) => void;
  onClose?: () => void;
}): JSX.Element => {
  const { t } = useTranslation("wiki");

  return (
    <div className="flex flex-row">
      <input
        type="text"
        autoFocus
        className={classNames(
          "block px-2  w-full py-1.5 text-sm border border-gray-300 rounded-l shadow-inner outline-none md:mt-0 h-8",
        )}
        placeholder={t(`wikiSearch`) + "..."}
        value={text}
        onChange={(e) => setText(e.currentTarget.value)}
      />
      <IconButton
        straightLeftBorder
        Icon={HiX}
        className="w-92"
        onClick={onClose}
      />
    </div>
  );
};
