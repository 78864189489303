import React, { Dispatch, SetStateAction, useState } from "react";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { FaEdit, FaCopy, FaExternalLinkAlt } from "react-icons/fa";
import { ImCopy } from "react-icons/im";

import { Onepager, Employee } from "generated/graphql";
import { DropdownButton } from "components/dropdownButton";
import { copyToClipboard } from "helpers/clipboard";

import { useMutation } from "@apollo/client";
import {
  DELETE_AND_APPROVE_ONEPAGER_MUTATION,
  RESTORE_AND_APPROVE_ONEPAGER_MUTATION,
  COPY_ONEPAGER_MUTATION,
} from "queries/onepager";

import { Modal } from "components/modal";
import { IconButton, ActionLink } from "components/button";
import { Popup } from "components/popup";
import { IoMdClose } from "react-icons/io";
import { MdArchive, MdUnarchive } from "react-icons/md";
import { frontendUrl, Publications } from "./publications";

const PublishedStatus = ({ onepager, token }) => {
  const { t } = useTranslation("onepager");
  const liveUrl = frontendUrl({ token });

  return (
    <StatusLine
      onepager={onepager}
      clipboardUrl={liveUrl}
      newTabUrl={liveUrl}
      externalUrl={true}
      statusText={t("publishedStatus")}
      copyLinkText={t("copyLiveLink")}
      openLinkText={t("openLink")}
    />
  );
};

const DraftStatus = ({ onepager }) => {
  const { t } = useTranslation(["onepager", "common"]);
  const previewId = onepager.id;
  const previewUrl = `${window.location.origin}/onepagers/preview/${previewId}`;

  return (
    <StatusLine
      onepager={onepager}
      clipboardUrl={previewUrl}
      newTabUrl={`/onepagers/preview/${previewId}`}
      externalUrl={false}
      statusText={t("onepager:draftStatus")}
      copyLinkText={t("onepager:copyPreviewLink")}
      openLinkText={t("common:preview")}
    />
  );
};

const StatusLine = ({
  onepager,
  clipboardUrl,
  newTabUrl,
  statusText,
  copyLinkText,
  openLinkText,
  externalUrl,
}) => {
  const { t } = useTranslation("onepager");

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-col">
        <div className="font-medium">{statusText}</div>
        <div className="text-sm text-gray-500">
          <span>{t("lastModifiedBy")}</span>
          <span className="font-medium">
            {(onepager?.modifiedBy as Employee)?.fullName}{" "}
          </span>
          <span>
            ({dayjs(`${onepager?.updatedAt}Z`).format(`DD.MM.YYYY — H:mm`)}{" "}
            {`${t("hours")})`}
          </span>
        </div>
      </div>
      <div className="flex justify-end w-92">
        <input
          type="text"
          readOnly
          className="block w-64 px-2 py-0 text-gray-500 border rounded-l-md shadow-sm outline-none text-ellipsis border-grey-300 focus:ring-2 sm:text-sm focus:border-grey-900 focus:ring-grey-900"
          value={clipboardUrl}
        />
        <Popup content={copyLinkText}>
          <IconButton
            straightLeftBorder
            straightRightBorder
            Icon={FaCopy}
            onClick={() => {
              copyToClipboard(clipboardUrl);
              toast.success(t("publication.linkCopied"));
            }}
          />
        </Popup>
        <Popup content={openLinkText}>
          <ActionLink
            straightLeftBorder
            link={newTabUrl}
            Icon={FaExternalLinkAlt}
            external={externalUrl}
            newTab
          />
        </Popup>
      </div>
    </div>
  );
};

const StatusIndicator = ({ onepager }: { onepager: Partial<Onepager> }) => {
  return onepager?.publication?.status === "PUBLISHED" ? (
    <div className="px-6 py-3 border border-gray-300 rounded-b-md">
      <PublishedStatus
        onepager={onepager}
        token={onepager?.publication?.token}
      />
    </div>
  ) : (
    <>
      {onepager?.previousPublication ? (
        <div className="px-6 pt-3 pb-3 border border-gray-300 border-b-md">
          <PublishedStatus
            onepager={onepager?.previousPublication?.onepager}
            token={onepager?.previousPublication?.token}
          />
        </div>
      ) : null}
      <div
        className={`px-6 pt-3 pb-2 border-x border-b border-gray-300 rounded-b-md ${
          onepager.previousPublication ? "" : "border-t"
        }`}
      >
        <DraftStatus onepager={onepager} />
      </div>
    </>
  );
};

const ArchiveModal = ({
  onepager,
  showArchiveConfirmation,
  setShowArchiveConfirmation,
  setIsArchiving,
  setSelectedId,
}) => {
  const { t } = useTranslation();
  const [deleteOnepager] = useMutation(DELETE_AND_APPROVE_ONEPAGER_MUTATION);

  const archiveOnepager = () => {
    setIsArchiving(true);
    setSelectedId(onepager.id);

    deleteOnepager({
      variables: { id: onepager.id },
      update(cache) {
        // remove from apollo cache
        const id = cache.identify({
          __typename: "Onepager",
          id: onepager.id,
        });
        cache.evict({ id });
        cache.gc();
      },
      onCompleted: () => setIsArchiving(false),
    });
  };
  return (
    <Modal
      size="small"
      title={
        <div className="px-6 py-8 text-2xl font-medium text-red-500">
          Möchten Sie Onepager archivieren?
        </div>
      }
      open={showArchiveConfirmation}
      close={() => setShowArchiveConfirmation(false)}
      actions={
        <div className="flex flex-row space-x-2">
          <IconButton
            type="secondary"
            Icon={IoMdClose}
            onClick={() => setShowArchiveConfirmation(false)}
          >
            {t("cancel")}
          </IconButton>
          <IconButton
            type="soft-warning"
            Icon={MdArchive}
            onClick={() => {
              archiveOnepager();
              setShowArchiveConfirmation(false);
            }}
          >
            {t("archive")}
          </IconButton>
        </div>
      }
    >
      <div className="px-6 my-8">
        Wenn Sie den Onepager archivieren, finden Sie ihn unter dem Archiv tab.
      </div>
    </Modal>
  );
};

const RestoreModal = ({
  onepager,
  showRestoreConfirmation,
  setShowRestoreConfirmation,
}) => {
  const { t } = useTranslation(["common", "onepager"]);
  const [restoreOnepagerMutation] = useMutation(
    RESTORE_AND_APPROVE_ONEPAGER_MUTATION,
  );

  const restoreOnepager = () => {
    restoreOnepagerMutation({
      variables: { id: onepager.id },
      update(cache) {
        // remove from apollo cache
        const id = cache.identify({
          __typename: "Onepager",
          id: onepager.id,
        });
        cache.evict({ id });
        cache.gc();
      },
    });
  };

  return (
    <Modal
      size="small"
      title={
        <div className="px-6 py-8 text-2xl font-medium text-red-500">
          {t("onepager:restoreModalTitle")}
        </div>
      }
      open={showRestoreConfirmation}
      close={() => setShowRestoreConfirmation(false)}
      actions={
        <div className="flex flex-row space-x-2">
          <IconButton
            type="secondary"
            Icon={IoMdClose}
            onClick={() => setShowRestoreConfirmation(false)}
          >
            {t("common:cancel")}
          </IconButton>
          <IconButton
            type="soft-warning"
            Icon={MdUnarchive}
            onClick={() => {
              restoreOnepager();
              setShowRestoreConfirmation(false);
            }}
          >
            {t("common:restore")}
          </IconButton>
        </div>
      }
    >
      <div className="px-6 my-8">{t("onepager:restoreModalBody")}</div>
    </Modal>
  );
};

export const ListItem = ({
  onepager,
  archived,
  setIsArchiving,
  onDuplicate,
  setSelectedId,
}: {
  onepager: Onepager;
  archived: boolean;
  setIsArchiving?: Dispatch<SetStateAction<boolean>>;
  setSelectedId?: Dispatch<SetStateAction<string | undefined>>;
  onDuplicate: (id: string) => void;
}): JSX.Element => {
  const { t } = useTranslation(["onepager", "common"]);
  const [showArchiveConfirmation, setShowArchiveConfirmation] = useState(false);
  const [showRestoreConfirmation, setShowRestoreConfirmation] = useState(false);
  const [showPublications, setShowPublications] = useState(false);

  const [copyOnepager] = useMutation(COPY_ONEPAGER_MUTATION);

  const duplicateOnepager = () => {
    return copyOnepager({
      variables: {
        id: onepager.id,
        fields: { title: `KOPIE - ${onepager.title}` },
      },
    });
  };

  const displayedOnepager = onepager;

  return (
    <div className="rounded-md shadow-md">
      <div className="px-6 py-3 border border-b-0 border-gray-300 mt-7 rounded-t-md">
        <div className="flex items-center justify-between">
          <h2 className="text-xl font-medium">{displayedOnepager.title}</h2>
          {!archived ? (
            <div className="flex flex-row">
              <ActionLink
                straightRightBorder
                link={`/onepagers/edit/${onepager.id}`}
                Icon={FaEdit}
                label={t("common:edit")}
              />
              <DropdownButton
                straightLeftBorder
                options={[
                  {
                    text: t("common:archive"),
                    key: "archive",
                    icon: <MdArchive />,
                    onClick: () => {
                      setShowArchiveConfirmation(true);
                    },
                  },
                  {
                    text: t("common:duplicate"),
                    key: "duplicate",
                    icon: <FaCopy />,
                    onClick: () => {
                      duplicateOnepager().then((res) => {
                        onDuplicate(res?.data?.copyOnepager?.id);
                      });
                    },
                  },
                  {
                    text: t("onepager:versions"),
                    key: "versions",
                    icon: <ImCopy />,
                    onClick: () => {
                      setShowPublications(true);
                    },
                  },
                ]}
              ></DropdownButton>
            </div>
          ) : (
            <IconButton
              Icon={MdUnarchive}
              onClick={() => setShowRestoreConfirmation(true)}
            >
              {t("common:restore")}
            </IconButton>
          )}
          <ArchiveModal
            onepager={onepager}
            showArchiveConfirmation={showArchiveConfirmation}
            setShowArchiveConfirmation={setShowArchiveConfirmation}
            setIsArchiving={setIsArchiving}
            setSelectedId={setSelectedId}
          />
          <RestoreModal
            onepager={onepager}
            showRestoreConfirmation={showRestoreConfirmation}
            setShowRestoreConfirmation={setShowRestoreConfirmation}
          />

          {/* Avoid perform query defined inside Publications if not visible */}
          {showPublications ? (
            <Publications
              isOpen={showPublications}
              id={onepager.id}
              setIsOpen={setShowPublications}
            />
          ) : null}
        </div>
      </div>
      <StatusIndicator onepager={onepager} />
    </div>
  );
};
