import React from "react";
import { useTranslation } from "react-i18next";
import { ActionMeta } from "react-select";

import Creatable from "react-select/creatable";
import { customStyles, i18nOptions } from "./multiSelectField";

export const CustomCreatable = ({
  onChange,
  value,
  options,
  disabled,
  idValue,
}) => {
  const { t } = useTranslation();

  const onKeyDown = (e) => {
    // 8 is the keyCode of backspace key
    if (e.keyCode === 8 && e.target?.value === "") {
      const v = value ?? [];
      const newValue = [...v.slice(0, v.length - 1)];
      onChange(newValue, { action: "remove-value" });
    }
  };

  const optionToValue = (opt) =>
    opt.__isNew__
      ? {
          [idValue]: null,
          newText: opt.value,
        }
      : {
          [idValue]: opt.value,
        };

  const valueToOption = (v: any) =>
    v.newText
      ? { label: v.newText, value: v.newText, __isNew__: true }
      : options?.find((r) => r.value === v[idValue]);

  const handleChange = (newValues: any, { action }: ActionMeta<any>) => {
    if (
      action === "create-option" ||
      action === "select-option" ||
      action === "remove-value"
    ) {
      if (onChange) {
        onChange(newValues.map(optionToValue));
      }
    } else {
      // On clearing
      if (onChange) {
        onChange([]);
      }
    }
  };

  const displayedValue = value?.map(valueToOption);

  return (
    <Creatable
      styles={customStyles}
      isDisabled={disabled}
      {...i18nOptions(t)}
      isClearable
      backspaceRemovesValue={false}
      onKeyDown={onKeyDown}
      isMulti
      onChange={handleChange}
      options={options}
      value={displayedValue}
    />
  );
};
