import React, { useContext } from "react";
import AppContext from "context/app";

import { GenericFieldProps, GenericField } from "./fieldProps";
import { CustomCreatable } from "./creatable";
import { getTextFromMultiLang } from "helpers/multiLang";
import { EmployeeRole } from "generated/graphql";

type RolesType = EmployeeRole[];
type RoleFieldProps = GenericFieldProps<RolesType>;

const RolesField = ({
  value,
  onChange,
  disabled,
  language,
}: RoleFieldProps): JSX.Element => {
  const { catalogues } = useContext(AppContext);
  const m = getTextFromMultiLang(language ?? "de");

  const allRoles = catalogues?.roles
    ?.map((role: any) => ({
      label: m(role?.name).trim(),
      value: `${role.id}`,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <div className="text-sm">
      <CustomCreatable
        disabled={disabled}
        onChange={onChange}
        options={allRoles}
        value={value}
        idValue="roleId"
      />
    </div>
  );
};

export const rolesField: GenericField<RolesType> = {
  renderer: RolesField,
  onBeforeSave: (value: RolesType) =>
    value
      ?.map((v) => ({
        roleId: v.roleId,
        newText: v.newText,
      }))
      .filter(
        (v) => (v.roleId ? v.roleId : 0 > 0) || (v.newText?.length ?? 0) > 0,
      ),
  equals: (a: RolesType, b: RolesType) => {
    return (
      (a?.map((v) => `${v.roleId}-${v.newText ?? ""}`).join(",") ?? "") ===
      (b?.map((v) => `${v.roleId}-${v.newText ?? ""}`).join(",") ?? "")
    );
  },
};
