import { gql } from "@apollo/client";
import { multiLangString } from "./common";

const updatedMultilangFields = gql`
  fragment updatedMultilangFields on Employee {
    updatedMultilangFields {
      field
      updatedLanguages
    }
  }
`;

export const OUTDATED_TRANSLATIONS = gql`
  ${updatedMultilangFields}

  query OutdatedTranslationsQuery {
    employeesWithOutdatedTranslations {
      id
      fullName
      aboutMe ${multiLangString}
      ...updatedMultilangFields
    }
  }
`;

export const EDIT_AND_APPROVE_EMPLOYEE_MUTATION = gql`
  ${updatedMultilangFields}

  mutation EditAndApproveEmployee($id: ID!, $fields: InputEmployee) {
    editAndApproveEmployee(id: $id, fields: $fields) {
      id
      aboutMe ${multiLangString}
      ...updatedMultilangFields
    }
  }
`;
