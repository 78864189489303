import React, { useRef, useState, ReactNode } from "react";
import { createPortal } from "react-dom";

export type PopupProps = {
  content: ReactNode;
  disabled?: boolean;
  children: React.ReactNode;
};

export const Popup: React.FC<PopupProps> = ({
  disabled,
  content,
  children,
}) => {
  const [visible, setVisible] = useState(false);
  const targetRef = useRef<HTMLDivElement>(null);
  const targetRefRect = targetRef?.current?.getBoundingClientRect();
  return (
    <div
      className="relative inline-block"
      onMouseEnter={() => {
        if (!disabled) {
          setVisible(true);
        }
      }}
      onMouseLeave={() => {
        if (!disabled) {
          setVisible(false);
        }
      }}
      ref={targetRef}
    >
      {children}
      {visible && content
        ? createPortal(
            <div
              style={{
                top: (targetRefRect?.top || 0) + window.scrollY,
                left:
                  (targetRefRect?.left || 0) + (targetRefRect?.width || 0) / 2,
              }}
              className="absolute z-50 -translate-x-1/2 -translate-y-full "
            >
              <div className="max-w-lg px-2 py-1 mb-2 text-sm text-center text-gray-500 bg-white border rounded shadow-lg border-1">
                <div
                  className="absolute w-0 h-0 -translate-x-1/2 -translate-y-2 border-8 border-transparent top-full left-1/2"
                  style={{ borderTopColor: "white", marginTop: "-1px" }}
                />
                {content}
              </div>
            </div>,
            document.body,
          )
        : null}
    </div>
  );
};
