import React, { useContext } from "react";
import AppContext from "context/app";

import { GenericFieldProps, GenericField } from "./fieldProps";
import { CustomCreatable } from "./creatable";
import { getTextFromMultiLang } from "helpers/multiLang";
import { ReferenceRole } from "generated/graphql";

type ReferenceRolesType = ReferenceRole[];
type ReferenceRoleFieldProps = GenericFieldProps<ReferenceRolesType>;

const ReferenceRolesField = ({
  value,
  onChange,
  disabled,
  language,
}: ReferenceRoleFieldProps): JSX.Element => {
  const { catalogues } = useContext(AppContext);
  const m = getTextFromMultiLang(language ?? "de");

  const allRoles = catalogues?.referenceRoles
    ?.map((role: any) => ({
      label: m(role?.name, "").trim(),
      value: `${role.id}`,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <div className="text-sm">
      <CustomCreatable
        disabled={disabled}
        onChange={onChange}
        options={allRoles}
        value={value}
        idValue="referenceRoleId"
      />
    </div>
  );
};

export const referenceRolesField: GenericField<ReferenceRolesType> = {
  renderer: ReferenceRolesField,
  onBeforeSave: (value: ReferenceRolesType) =>
    value
      ?.map((v) => ({
        referenceRoleId: v.referenceRoleId,
        newText: v.newText,
      }))
      .filter(
        (v) =>
          (v.referenceRoleId ? +v.referenceRoleId : 0) > 0 ||
          (v.newText?.length ?? 0) > 0,
      ),
  equals: (a: ReferenceRolesType, b: ReferenceRolesType) => {
    return (
      (a?.map((v) => `${v.referenceRoleId}-${v.newText ?? ""}`).join(",") ??
        "") ===
      (b?.map((v) => `${v.referenceRoleId}-${v.newText ?? ""}`).join(",") ?? "")
    );
  },
};
