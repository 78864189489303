import React from "react";

import { FormDefinition } from "components/form";
import {
  EDIT_FILE_ATTACHMENT_MUTATION,
  EDIT_FILE_ATTACHMENT_QUERY,
  CREATE_FILE_ATTACHMENT_MUTATION,
} from "queries/fileAttachment";
import {
  EditModal,
  EditVersionedProps,
} from "components/editVersioned/genericForm";
import { FileAttachment } from "generated/graphql";
import { getFirstNonEmpty } from "helpers/languages";

import { textField } from "components/form/textField";
import { fileField } from "components/form/fileField";
import { useMultiLang } from "helpers/multiLang";

// MAIN COMPONENT

export const attachmentFormDefinition: FormDefinition<FileAttachment> = {
  title: ({ entity, t }) => {
    const m = useMultiLang();
    return (
      <>
        {entity?.id
          ? t("wiki:attachments.edit", { name: m(entity.name) })
          : t("wiki:attachments.upload")}
      </>
    );
  },
  titleString: (entity) => `${entity?.id}`,
  link: (_entity) => `#`,
  fields: {
    name: {
      name: "fileAttachment.name",
      component: textField,
      multiLang: true,
      props: {
        placeholderFn: (v) => getFirstNonEmpty(v),
      },
    },
    fileDe: {
      name: "fileAttachment.fileDe",
      component: fileField,
    },
    fileFr: {
      name: "fileAttachment.fileFr",
      component: fileField,
    },
    fileIt: {
      name: "fileAttachment.fileIt",
      component: fileField,
    },
    fileEn: {
      name: "fileAttachment.fileEn",
      component: fileField,
    },
  },
};

const versionedAttributes = (
  id,
  refetchQueries,
): EditVersionedProps<FileAttachment> => ({
  id,
  editMutation: EDIT_FILE_ATTACHMENT_MUTATION,
  query: EDIT_FILE_ATTACHMENT_QUERY,
  queryDataAccessor: "fileAttachment",
  editDataAccessor: "editFileAttachment",
  formDefinition: attachmentFormDefinition,
  refetchQueries,
  createDataAccessor: "createApprovedFileAttachment",
  createMutation: CREATE_FILE_ATTACHMENT_MUTATION,
});

export const EditAttachmentModal = ({
  open,
  id,
  close,
  newEntity,
  onSave,
}: {
  open: boolean;
  id?: string;
  close: () => void;
  newEntity?: Partial<FileAttachment>;
  onSave?: (id: string | null) => void;
}) => {
  return (
    <EditModal
      open={open}
      editVersioned={versionedAttributes(id ?? "new", ["GetFileAttachment"])}
      newEntity={newEntity}
      close={close}
      onSave={onSave}
      hideReviewWorkflowButtons={true}
    />
  );
};
