import React, { useEffect, useRef } from "react";

export const TriStateCheckbox = (
  props: React.InputHTMLAttributes<HTMLInputElement> & {
    indeterminate: boolean;
  },
): JSX.Element => {
  const { indeterminate, ...otherProps } = props;
  const checkboxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!checkboxRef?.current) {
      return;
    } else if (indeterminate) {
      checkboxRef.current.indeterminate = true;
    }
  }, [indeterminate]);

  return (
    <input
      className="mr-1 mb-0.5 relative inline-block transition accent-blue-500 cursor-pointer"
      type="checkbox"
      ref={checkboxRef}
      {...otherProps}
    />
  );
};
