import React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../../components/modal";
import { IconButton } from "../../components/button";
import { IoMdClose } from "react-icons/io";
import { FaRedo } from "react-icons/fa";

export const RestoreModal = ({
  showRecoverModal,
  setShowRecoverModal,
  restore,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      size="small"
      title={
        <div className="px-6 py-8 text-2xl font-medium text-red-500">
          {t("restore-title")}
        </div>
      }
      open={showRecoverModal}
      close={() => setShowRecoverModal(false)}
      actions={
        <div className="flex flex-row space-x-2">
          <IconButton
            type="secondary"
            Icon={IoMdClose}
            onClick={() => setShowRecoverModal(false)}
          >
            {t("cancel")}
          </IconButton>
          <IconButton
            type="soft-warning"
            Icon={FaRedo}
            onClick={() => {
              restore();
            }}
          >
            {t("restore")}
          </IconButton>
        </div>
      }
    >
      <div className="px-6 my-8">{t("restore-body")}</div>
    </Modal>
  );
};
