import React from "react";
import { useTranslation } from "react-i18next";
import { ActionLink } from "components/button";
import { Avatar } from "../avatar";
import { FaPhoneSquareAlt, FaGlobe } from "react-icons/fa";
import { GrMail, GrLinkedin } from "react-icons/gr";
import { Employee } from "generated/graphql";
import { useMultiLang } from "helpers/multiLang";
import CvExportWizard from "../../newEmployee/cv/cvExportWizard";

// HELPER COMPONENTS

const EmployeeName = ({ employee }) => {
  return (
    <div className="mt-0 text-3xl font-medium text-red-500" data-cy="name">
      {employee.firstName} {employee.lastName}
    </div>
  );
};

const EmployeeRoles = ({ employee }) => {
  const m = useMultiLang();

  return (
    <h3 className="mb-5 text-2xl text-gray-500 font-base">
      {employee.roles?.map((r: any) => m(r.role?.name, r.newText)).join(", ")}
    </h3>
  );
};

const EmployeeActions = ({
  employee,
  approvedEmployee,
}: {
  employee: Partial<Employee>;
  approvedEmployee: Partial<Employee>;
}) => {
  const { t } = useTranslation("employee");

  return (
    <>
      <div className="flex-wrap hidden mt-1 space-x-4 md:flex md:mt-1 md:flex-row">
        {employee.email ? (
          <div>
            <ActionLink
              Icon={GrMail}
              label={employee.email}
              link={`mailto:${employee.email}`}
              external
            />
          </div>
        ) : null}
        {employee.phone ? (
          <div>
            <ActionLink
              Icon={FaPhoneSquareAlt}
              label={employee.phone}
              link={`tel:${employee.phone}`}
              external
            />
          </div>
        ) : null}
        {employee.linkedin ? (
          <div>
            <ActionLink
              Icon={GrLinkedin}
              label={t("linkedin")}
              link={employee.linkedin}
              external
            />
          </div>
        ) : null}
        {employee.isTrustedAdvisor ? (
          <div>
            <ActionLink
              Icon={FaGlobe}
              label={t("external_profile")}
              link={`https://tbf.ch/contact-partners/${employee.activeDirectoryId}`}
              external
            />
          </div>
        ) : null}
        <div>
          <CvExportWizard approvedEmployee={approvedEmployee} />
        </div>
      </div>
    </>
  );
};

const EmployeeHeader: React.FC<{
  employee: Partial<Employee>;
  approvedEmployee: Partial<Employee>;
}> = ({ employee, approvedEmployee }) => {
  return (
    <>
      <div className="flex md:flex-row">
        <div className="mr-7 lg:mr-10">
          <Avatar
            url={
              employee.colorProfilePicture ?? employee.profilePicture ?? null
            }
          />
        </div>
        <div className="relative flex-1 pt-0 sm:pt-5">
          <EmployeeName employee={employee} />
          <EmployeeRoles employee={employee} />
          <EmployeeActions
            employee={employee}
            approvedEmployee={approvedEmployee}
          />
        </div>
      </div>
    </>
  );
};

export default EmployeeHeader;
