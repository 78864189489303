import React, { useEffect } from "react";
import { IconButton } from "components/button";

import { useSearch } from "pages/search/helpers";
import { useState } from "react";
import {
  niceReadableNumber,
  WikiSearchResultItem,
} from "pages/search/components/resultItem";
import { WikiSearchField } from "components/wikiSearchField";
import { LoadingSpinner } from "components/loadingSpinner";
import { useTranslation } from "react-i18next";
import { mergeAttributes } from "@tiptap/core";
import { Node, NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";
import { IoDocumentTextOutline } from "react-icons/io5";
import { FaExternalLinkAlt } from "react-icons/fa";
import { CREATE_WIKI_PAGE_MUTATION } from "queries/wiki";
import { useMutation } from "@apollo/client";
import { WikiPage } from "generated/graphql";
import { slugify } from "components/form/slugField";
import { Modal } from "components/modal";
import { HiCheck, HiX } from "react-icons/hi";

export const WikiLinkTag = Node.create({
  name: "wikiLinkTag",

  group: "inline",
  inline: true,
  selectable: false,
  atom: true,

  addAttributes() {
    return {
      title: {
        default: "",
      },
      url: { default: "" },
    };
  },

  parseHTML() {
    return [
      {
        tag: "wiki-link-tag",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["wiki-link-tag", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(TiptapWrapper);
  },

  addCommands(): any {
    return {
      insertWikiLinkTag:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },
    };
  },
});

const TiptapWrapper = (props) => {
  const title = props.node.attrs.title;
  const url = props.node.attrs.url;
  const readOnly = !props.editor.isEditable;

  const tagStyle = {
    textDecoration: "none",
    padding: "2px 5px",
    top: "-1.5px",
    background: "#fdfdfd",
    borderColor: "rgb(38 103 130 / 32%)",
  };

  return (
    <NodeViewWrapper className="inline-block align-sub">
      <a
        href={url}
        style={tagStyle}
        onClick={
          readOnly ? (event) => event : (event) => event.preventDefault()
        }
        className="focus:outline-none relative flex items-center border text-gray-500 rounded leading-3 hover:bg-blue-500 hover:bg-opacity-5 hover:border-blue-500 hover:border-opacity-40"
      >
        <IoDocumentTextOutline size="1em" className="mr-1" />
        {title}
      </a>
    </NodeViewWrapper>
  );
};

export const SearchWiki = ({
  initialSearchText,
  onChange,
  onClose,
  slugs,
  page,
  onSubPageCreated,
}: {
  initialSearchText: string;
  value: string;
  onChange: (wikiPage: { href?: string; title?: string }) => void;
  onClose?: () => void;
  slugs: string[];
  page: Partial<WikiPage>;
  onSubPageCreated: (subPageUrl: string) => void;
}): JSX.Element => {
  const [searchText, setSearchText] = useState(initialSearchText);
  const [searchResults, search] = useSearch({
    searchText,
    types: ["wiki_page"],
    filters: [],
    useFuzzy: true,
  });
  const { t } = useTranslation(["search", "wiki", "common"]);
  const { results, resultsCount, isLoading } = searchResults;
  const [error, setError] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [createSubPage] = useMutation(CREATE_WIKI_PAGE_MUTATION);

  const onCreateSubPage = () => {
    const newSlugs = [...slugs, slugify(searchText)];
    const title = {
      de: searchText,
      fr: searchText,
      it: searchText,
      en: searchText,
    };

    createSubPage({
      variables: {
        fields: {
          slugs: newSlugs,
          parentPageId: page.id,
          title: title,
        },
      },
    })
      .then((result: any) => {
        const subPageId = result.data?.createApprovedWikiPage?.id;
        if (subPageId) {
          const subPageUrl = `/wiki/byId/${subPageId}`;

          onChange({ href: subPageUrl, title: searchText });
          onSubPageCreated(subPageUrl);
        }
      })
      .catch(() => {
        setError(true);
      });
  };

  useEffect(() => {
    const cancelRequest = search(1);
    setError(false);
    return () => {
      cancelRequest();
    };
  }, [searchText]);

  return (
    <div className="flex flex-col w-full mt-1">
      <WikiSearchField
        text={searchText}
        setText={setSearchText}
        onClose={onClose}
      />
      {isLoading ? (
        <div className="w-8 h-8">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="px-2 py-2 text-sm">
          {t("search:results", { count: niceReadableNumber(resultsCount) })}
        </div>
      )}
      <div className="overflow-x-auto max-h-72 scroll-shadow">
        {results.map((result) => {
          return (
            <WikiSearchResultItem
              key={result.id}
              result={result}
              onClick={({ id, title }) => {
                onChange({ href: `/wiki/byId/${id}`, title: title });
              }}
            />
          );
        })}
      </div>
      {searchText.length > 2 ? (
        error ? (
          <div className="text-sm border mt-1 p-2 border-red-400 bg-red-50 rounded">
            <div className="font-bold">{t("wiki:errorMessage")}</div>
            <div>{t("wiki:errorDescription")}</div>
          </div>
        ) : (
          <div className="flex justify-end border-t pt-2 pb-1">
            <IconButton
              Icon={FaExternalLinkAlt}
              onClick={() => setShowConfirmation(true)}
            >
              {t("wiki:createSubPage", { searchText: searchText })}
            </IconButton>
            <Modal
              size="small"
              title={
                <div className="px-6 py-8 text-2xl font-medium text-red-500">
                  {t("wiki:createSubPage", { searchText: searchText })}
                </div>
              }
              open={showConfirmation}
              close={() => setShowConfirmation(false)}
              actions={
                <div className="flex flex-row space-x-2">
                  <IconButton
                    type="secondary"
                    Icon={HiX}
                    onClick={() => setShowConfirmation(false)}
                  >
                    {t("common:cancel")}
                  </IconButton>
                  <IconButton
                    type="soft-warning"
                    Icon={HiCheck}
                    onClick={() => {
                      onCreateSubPage();
                    }}
                  >
                    {t("common:confirm")}
                  </IconButton>
                </div>
              }
            >
              <div className="px-6 my-8">
                {t("wiki:confirmationDialog", { searchText: searchText })}
              </div>
            </Modal>
          </div>
        )
      ) : null}
    </div>
  );
};
