import React from "react";
import classnames from "classnames";
import { GenericField, GenericFieldProps } from "./fieldProps";

export type NumberFieldProps = GenericFieldProps<
  number | string | undefined
> & { isValid?: boolean };

export const NumberField = ({
  value,
  onChange,
  disabled = false,
  placeholder,
  onBlur,
  isValid = true,
}: NumberFieldProps) => {
  return (
    <input
      type="number"
      disabled={disabled}
      placeholder={placeholder ?? ""}
      className={classnames(
        "block w-full p-2 border rounded-md shadow-sm outline-none  sm:text-sm",
        {
          "border-red-500 focus:border-red-500 focus:ring-red-500 focus:ring-1":
            !isValid,
          "border-grey-300 focus:border-grey-900 focus:ring-grey-900 focus:ring-2":
            isValid,
        },
      )}
      value={value ?? ""}
      onChange={(e) => onChange && onChange(e.currentTarget.value)}
      onBlur={(e) => onBlur && onBlur(e)}
    ></input>
  );
};

export const numberField: GenericField<NumberFieldProps> = {
  renderer: NumberField,
  onBeforeSave: (value) => (value ? +value : null),
};
