import { Client } from "generated/graphql";
import { EditClientForm } from "components/editVersioned/editClient";
import {} from "queries/newProject";
import React from "react";
import { ManageGeneric } from "./common";

export const ManageClients = () => {
  return (
    <ManageGeneric<Client>
      title="Kunden"
      searchType="new_client"
      dataAccessor="newClient"
      editForm={EditClientForm}
      listItemContent={(client) => <>{client.name}</>}
    />
  );
};
