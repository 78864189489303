import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "context/app";
import Select from "react-select";
import { customStyles, i18nOptions } from "./multiSelectField";

import { GenericField, GenericFieldProps } from "./fieldProps";
import { ListField } from "./ListField";

type LanguageType = {
  id?: string;
  level: string;
  languageId: number;
};
type LanguagesType = LanguageType[];

export type LanguagesFieldProps = GenericFieldProps<LanguagesType>;

export const levelCodes = ["A1", "A2", "B1", "B2", "C1", "C2", "Native"];

export const levelOptions = levelCodes.map((l) => ({
  value: l,
  labelKey: `common:languageLevel.${l}`,
}));

const Language = ({ value, disabled, onChange, catalogue }) => {
  const { t } = useTranslation();

  const handleLevelChange = (selectedOption) => {
    const newLang = { ...value, level: selectedOption.value };
    onChange(newLang);
  };

  const handleLanguageChange = (selectedOption) => {
    const newLang = { ...value, languageId: selectedOption.value };
    onChange(newLang);
  };

  const selectedLanguage = catalogue?.find((d) => d.id === value.languageId);
  const levelOptionsDisplay = levelOptions.map((o) => ({
    value: o.value,
    label: t(o.labelKey as any),
  }));

  return (
    <div className="flex items-center space-x-2">
      <div className="w-64 text-sm">
        <Select
          styles={customStyles}
          isDisabled={disabled}
          {...i18nOptions(t)}
          options={catalogue}
          onChange={handleLanguageChange}
          value={selectedLanguage}
        />
      </div>
      <div className="w-48 text-sm">
        <Select
          styles={customStyles}
          defaultValue={levelOptionsDisplay[0]}
          isDisabled={disabled}
          options={levelOptionsDisplay}
          {...i18nOptions(t)}
          onChange={handleLevelChange}
          value={{
            value: value.level,
            label: value.level
              ? t(`common:languageLevel.${value.level}` as any)
              : "",
          }}
        />
      </div>
    </div>
  );
};

const LanguagesField = ({
  value,
  onChange,
  language,
  disabled,
}: LanguagesFieldProps) => {
  const { catalogues } = useContext(AppContext);

  const allLanguages = catalogues?.languages?.map((lang: any) => ({
    id: lang.id,
    key: lang.id,
    label: lang.name[language ?? "de"],
    value: lang.id,
  }));

  const newLanguage = { id: undefined, level: "", languageId: -1, key: "new" };

  return (
    <ListField
      value={(value ?? []).map((item) => ({
        ...item,
        key: `${item.id}-${item.level}-${item.languageId}`,
      }))}
      language={language}
      onChange={onChange}
      disabled={disabled}
      render={Language}
      newItem={newLanguage}
      catalogue={allLanguages}
    />
  );
};

export const languagesField: GenericField<LanguagesFieldProps> = {
  renderer: LanguagesField,
  onBeforeSave: (value) =>
    value
      ?.map((v) => ({ level: v.level, languageId: v.languageId }))
      .filter((v) => v.languageId > 0),
  equals: (a, b) =>
    (a?.map((v) => `${v.level}-${v.languageId}`).join(",") ?? "") ===
    (b?.map((v) => `${v.level}-${v.languageId}`).join(",") ?? ""),
};
