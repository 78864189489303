import React from "react";

import { GenericFieldProps, GenericField } from "./fieldProps";
import { extractMulti, stringifyMulti } from "helpers/ui/multiLang";
import { BlockPictureWithCaption, BlockPicture } from "generated/graphql";
import { PictureField } from "./blockPictureField";
import { PictureMetadata } from "./galleryField";

type BlockPictureWithCaptionExtended = BlockPictureWithCaption & {
  picture?: BlockPicture & {
    action: string;
  };
};

export type PictureFieldProps =
  GenericFieldProps<BlockPictureWithCaptionExtended | null>;

// HELPER COMPONENT

export const PictureFieldWithCaption: PictureFieldProps = ({
  value,
  onChange,
  language,
  disabled = false,
}: PictureFieldProps): JSX.Element => {
  const handlePictureChange = (newValue) => {
    const newPicture = { ...value, picture: newValue };
    onChange(newPicture);
  };

  const handleCaptionChange = (newValue) => {
    onChange({
      ...value,
      caption: { ...value?.caption, [language]: newValue },
    });
  };

  const handleCaptionPositionChange = (newValue) => {
    onChange({
      ...value,
      captionPosition: newValue.value,
    });
  };

  const handleOrientationChange = (newValue) => {
    onChange({
      ...value,
      orientation: newValue.value,
    });
  };

  return (
    <div className="max-w-md">
      <PictureField
        disabled={disabled}
        onChange={handlePictureChange}
        value={value.picture}
        language={language}
      />
      {value?.picture?.url ? (
        <div className="px-2 mt-2">
          <PictureMetadata
            value={value}
            handleCaptionChange={handleCaptionChange}
            handleCaptionPositionChange={handleCaptionPositionChange}
            handleOrientationChange={handleOrientationChange}
            language={language}
            disabled={disabled}
          />
        </div>
      ) : null}
    </div>
  );
};

export const pictureFieldWithCaption: GenericField<BlockPictureWithCaptionExtended> =
  {
    renderer: PictureFieldWithCaption,
    onBeforeSave: (data) => {
      const newPicture = data?.picture?.action
        ? {
            url: data.picture.url,
            action: data.picture.action,
            filename: data.picture.filename,
            focusX: data.picture.focusX,
            focusY: data.picture.focusY,
            id: data.picture.id,
          }
        : {
            action: "noaction",
            id: data?.picture?.id,
          };

      return {
        picture: newPicture,
        caption: extractMulti(data?.caption),
        captionPosition: data?.captionPosition,
        orientation: data?.orientation,
      };
    },

    equals: (a, b) =>
      (a?.picture?.url ?? "") === (b?.picture?.url ?? "") &&
      (a?.captionPosition || "") === (b?.captionPosition || "") &&
      (a?.orientation || "") === (b?.orientation || "") &&
      stringifyMulti(a?.caption) === stringifyMulti(b?.caption),
  };
