import React from "react";
import { useTranslation } from "react-i18next";

import { GenericField, GenericFieldProps } from "./fieldProps";
import { MultiLang } from "helpers/ui/multiLang";
import { ListField } from "./ListField";
import { TextField } from "./textField";
import { YearField } from "./yearField";
import { extractMulti, stringifyMulti } from "helpers/ui/multiLang";
import { languages } from "helpers/languages";
import { CvEntry } from "generated/graphql";

type CvItemType = {
  id?: string;
  from?: Date;
  to?: Date;
  description?: MultiLang;
  institution?: MultiLang;
};
type CvType = CvItemType[];

export type CvFieldProps = GenericFieldProps<CvType>;

const CvItem = ({ value, disabled, onChange, language }) => {
  const { t } = useTranslation(["common", "employee"]);

  const handleFromChange = (newValue) => {
    const newCvItem = { ...value, from: newValue };
    onChange(newCvItem);
  };

  const handleToChange = (newValue) => {
    const newCvItem = { ...value, to: newValue };
    onChange(newCvItem);
  };

  const handleInstitutionChange = (newValue) => {
    const newCvItem = {
      ...value,
      institution: { ...value.institution, [language]: newValue },
    };
    onChange(newCvItem);
  };

  const handleDescriptionChange = (newValue) => {
    const newCvItem = {
      ...value,
      description: { ...value.description, [language]: newValue },
    };
    onChange(newCvItem);
  };

  return (
    <div>
      <div>
        <div className="flex items-center w-full space-x-2">
          <div className="w-24">
            <YearField
              disabled={disabled}
              onChange={handleFromChange}
              placeholder={t("employee:cvItem.from")}
              value={value.from?.slice(0, 4) ?? ""}
              language={language}
            />
          </div>
          <div>—</div>
          <div className="w-24">
            <YearField
              disabled={disabled}
              onChange={handleToChange}
              placeholder={t("employee:cvItem.to")}
              value={value.to?.slice(0, 4) ?? ""}
              language={language}
            />
          </div>
          <div className="flex-1">
            <TextField
              disabled={disabled}
              onChange={handleInstitutionChange}
              placeholder={t("employee:cvItem.institution")}
              value={value.institution?.[language] ?? ""}
              language={language}
            />
          </div>
        </div>
      </div>
      <div className="mt-2">
        <TextField
          disabled={disabled}
          onChange={handleDescriptionChange}
          placeholder={t("employee:cvItem.description")}
          value={value.description?.[language] ?? ""}
          language={language}
          lines={5}
        />
      </div>
    </div>
  );
};

const CvField: React.FC<CvFieldProps> = ({
  value,
  onChange,
  language,
  disabled,
}) => {
  const { t } = useTranslation("form");

  const newCvItem = {
    id: undefined,
    institution: { de: "", fr: "", it: "", en: "" },
    description: { de: "", fr: "", it: "", en: "" },
    key: "new",
  };

  return (
    <ListField
      value={(value ?? []).map((item) => ({
        ...item,
        key: `${item.id}`,
      }))}
      vertical
      language={language}
      onChange={onChange}
      disabled={disabled}
      render={CvItem}
      newItem={newCvItem}
      addText={t("fieldAddPopup.employee.cv")}
    />
  );
};

const hasMissingTranslation = (l, value) => {
  const missingField = value?.filter((v) => !v.description?.[l]);
  return missingField?.length > 0;
};

export const cvField: GenericField<CvFieldProps> = {
  renderer: CvField,
  onBeforeSave: (value) =>
    value?.map((v) => ({
      from: v.from,
      to: v.to,
      institution: extractMulti(v.institution),
      description: extractMulti(v.description),
    })),
  equals: (a: Partial<CvEntry>[], b: Partial<CvEntry>[]) => {
    return (
      (a
        ?.map(
          (v) =>
            `${v.from}-${v.to}-${stringifyMulti(
              v.institution,
            )}-${stringifyMulti(v.description)}`,
        )
        .join(",") ?? "") ===
      (b
        ?.map(
          (v) =>
            `${v.from}-${v.to}-${stringifyMulti(
              v.institution,
            )}-${stringifyMulti(v.description)}`,
        )
        .join(",") ?? "")
    );
  },
  missingTranslationLanguages: (value) => {
    return languages.filter((l) => hasMissingTranslation(l, value));
  },
};
