import React from "react";

import { hiddenField } from "components/form/hiddenField";
import { singleSelectField } from "components/form/singleSelectField";
import { FormDefinition } from "components/form";

import { ProjectTeamMember } from "generated/graphql";
import { ROLES } from "pages/project/utils";
import { useMultiLang } from "helpers/multiLang";

const rolesOptions = ROLES.map((role: string) => ({
  value: role,
  labelKey: `abacusProjects:${role}`,
}));

// MAIN COMPONENT

const teamMemberTitle = (entity, t, m) =>
  `${entity?.employee?.firstName ?? t("common:loading")} ${
    entity?.employee?.lastName ?? ""
  } - ${m(entity?.project?.name, t("common:loading"))}`;

export const teamMemberFormDefinition: FormDefinition<ProjectTeamMember> = {
  title: ({ entity, t }) => {
    const m = useMultiLang();
    return <>{teamMemberTitle(entity, t, m)}</>;
  },
  titleString: teamMemberTitle,
  link: (entity) => `/project/${entity.projectId}#${entity.id}`,
  fields: {
    employeeId: {
      name: "teamMember.employeeId",
      displayIf: () => false,
      component: hiddenField,
    },
    projectId: {
      name: "",
      displayIf: () => false,
      component: hiddenField,
    },
    hours: {
      name: "teamMember.hours",
      displayIf: () => false,
      component: hiddenField,
    },
    role: {
      name: "teamMember.role",
      component: singleSelectField,
      props: { options: rolesOptions },
    },
  },
};
