import React from "react";
import { IconButton } from "components/button";
import { GenericFieldProps } from "./fieldProps";
import { useTranslation } from "react-i18next";
import { LoadingSpinner } from "components/loadingSpinner";
import { HiCheck, HiX } from "react-icons/hi";

export type LinkTextFieldProps = GenericFieldProps<string> & {
  onEnter?: () => void;
};

export const LinkTextField = ({
  value,
  onChange,
  placeholder,
  onEnter,
  onClose,
  errorMessage,
  isLoading,
}: LinkTextFieldProps): JSX.Element => {
  const { t } = useTranslation("tiptapMenubar");

  return (
    <>
      <div className="flex flex-row">
        <input
          type="text"
          autoFocus
          placeholder={placeholder}
          className="block w-full h-8 p-2 border shadow-inner rounded-l outline-none border-grey-300 sm:text-sm"
          value={value ?? ""}
          onChange={(e) => onChange && onChange(e.currentTarget.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onEnter();
            }
          }}
        />
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className="flex justify-end w-92">
              <IconButton
                straightLeftBorder
                straightRightBorder
                Icon={HiX}
                onClick={() => onClose()}
              />
              <IconButton
                straightLeftBorder
                Icon={HiCheck}
                onClick={() => onEnter()}
              />
            </div>
          </>
        )}
      </div>
      {errorMessage && (
        <div className="text-sm border mt-1 p-2 border-red-400 bg-red-50 rounded">
          <div className="font-bold">{t("error")}</div>
          <div>{errorMessage}</div>
        </div>
      )}
    </>
  );
};
