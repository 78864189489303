import { useState, useContext, useMemo } from "react";
import axios from "axios";

import AuthContext from "context/user";
import { getApiUrl } from "helpers/apiUrl";
import { appsignal } from "initializers/appsignal";

export type ElasticParams = {
  searchText: string; // args["search"]
  types?: string[]; // args["filter"]
  filters?: Array<{ type: string; args: any }>;
  scope?: string; // args["scope"]
  pageSize?: number;
  useFuzzy?: boolean;
  enrichResultsMethod?: string;
  searchFileContents?: boolean;
};

const getCustomWeights = () => {
  let customWeights = {};
  try {
    const storedWeights = localStorage.getItem("customWeights");
    customWeights = JSON.parse(storedWeights ?? "{}");
  } catch (_e) {
    console.log("Failed to parse custom weights. Use default search weights");
  }
  return customWeights;
};

// This is performance optimization that "caches" the loading and parsing of custom weights
const getMemoizedCustomWeights = () => useMemo(() => getCustomWeights(), []);

export type SearchResults = {
  results: any[];
  resultsCount: number;
  isLoading: boolean;
  error: any;
};

export const useSearch = (
  {
    searchText,
    types,
    filters,
    scope,
    pageSize = 10,
    useFuzzy,
    enrichResultsMethod,
    searchFileContents,
  }: ElasticParams,
  endpoint = "/api/search",
): [SearchResults, (page: number) => () => void] => {
  const { token } = useContext(AuthContext);

  // Retrieve custom weights from local storage
  const customWeights = getMemoizedCustomWeights();

  const [results, setResults] = useState([] as any[]);
  const [resultsCount, setResultsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const search = (page: number) => {
    setIsLoading(true);

    if (page === undefined || page === 1) {
      // Reset existing results when a search is triggered
      setResults([]);
      setResultsCount(0);
    }

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    appsignal.addBreadcrumb({
      category: "Search",
      action: "search",
      metadata: {
        searchText,
        types: (types ?? []).join(", "),
        useFuzzy: useFuzzy ?? "",
        searchFileContents: searchFileContents ?? "",
      },
    });
    axios
      .post(
        `${getApiUrl()}${endpoint}`,
        {
          search: searchText,
          types,
          filters,
          pageSize,
          from: (page - 1) * pageSize,
          scope: scope === "" ? null : scope,
          useFuzzy,
          searchFileContents,
          customWeights,
          enrichResultsMethod,
        },
        {
          headers: {
            authorization: token ? `Bearer ${token}` : "",
          },
          cancelToken: source.token,
        },
      )
      .then(({ data }) => data)
      .then((response) => {
        if (response) {
          setIsLoading(false);
          if (response.results) {
            setError(null);
            setResultsCount(response.resultCount);
            setResults(
              page === 1 ? response.results : [...results, ...response.results],
            );
          } else if (response.error) {
            setError(response.error);
          }
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          // ignore
        } else if (err?.code === "ECONNABORTED") {
          // ignore, this is a timeout
          // https://stackoverflow.com/a/50620317
        } else if (err?.response?.data) {
          setError(err.response.data);
        } else {
          throw err;
        }
      });

    const cancelRequest = () => source.cancel("CanceledRequest");
    return cancelRequest;
  };

  return [{ results, resultsCount, isLoading, error }, search];
};
