import React, { useEffect, useRef } from "react";
import { FocusPicker } from "image-focus/dist/index";

const FocusPickerComponent = ({ imageSrc, focus, onFocusChanged }) => {
  const imageRef = useRef(null);

  useEffect(() => {
    if (imageRef.current !== null) {
      new FocusPicker(imageRef.current, {
        onChange: onFocusChanged,
        focus: focus,
      });
    }
  }, []);

  return (
    <div>
      <img alt="Focus picker" ref={imageRef} src={imageSrc} />
    </div>
  );
};

export default React.memo(FocusPickerComponent);
