import React, { useState, useContext } from "react";
import { useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useLocation } from "wouter";
import { FaSpinner } from "react-icons/fa";
import * as Menubar from "@radix-ui/react-menubar";

import AuthContext from "context/user";
import { EMPLOYEE_QUERY } from "queries/newEmployee";
import { useExportCv } from "pages/newProject/wordExport";
import { CvExportModal } from "pages/newEmployee/cv/cvExportWizard";
import { FeatureFlagModal } from "pages/manageData/featureFlagModal";

const UserIcon = () => (
  <svg
    focusable="false"
    preserveAspectRatio="xMidYMid meet"
    fill="currentColor"
    width="16"
    height="16"
    viewBox="0 0 32 32"
    aria-hidden="true"
    className="h-7 w-7 md:h-5 md:w-5"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="none"
      d="M8.0071,24.93A4.9958,4.9958,0,0,1,13,20h6a4.9959,4.9959,0,0,1,4.9929,4.93,11.94,11.94,0,0,1-15.9858,0ZM20.5,12.5A4.5,4.5,0,1,1,16,8,4.5,4.5,0,0,1,20.5,12.5Z"
    ></path>
    <path d="M26.7489,24.93A13.9893,13.9893,0,1,0,2,16a13.899,13.899,0,0,0,3.2511,8.93l-.02.0166c.07.0845.15.1567.2222.2392.09.1036.1864.2.28.3008.28.3033.5674.5952.87.87.0915.0831.1864.1612.28.2417.32.2759.6484.5372.99.7813.0441.0312.0832.0693.1276.1006v-.0127a13.9011,13.9011,0,0,0,16,0V27.48c.0444-.0313.0835-.0694.1276-.1006.3412-.2441.67-.5054.99-.7813.0936-.08.1885-.1586.28-.2417.3025-.2749.59-.5668.87-.87.0933-.1006.1894-.1972.28-.3008.0719-.0825.1522-.1547.2222-.2392ZM16,8a4.5,4.5,0,1,1-4.5,4.5A4.5,4.5,0,0,1,16,8ZM8.0071,24.93A4.9957,4.9957,0,0,1,13,20h6a4.9958,4.9958,0,0,1,4.9929,4.93,11.94,11.94,0,0,1-15.9858,0Z"></path>
  </svg>
);

export const UserMenu: React.FC<{
  isContentAdmin: boolean;
  activeDirectoryId: string;
}> = ({ isContentAdmin, activeDirectoryId }) => {
  const { user, logOut } = useContext(AuthContext);
  const { t } = useTranslation(["common", "navigation"]);
  const [_location, navigate] = useLocation();
  const [showFeatureFlagModal, setShowFeatureFlagModal] = useState(false);
  const [showExportWizard, setShowExportWizard] = useState(false);

  const { exportCv, loading } = useExportCv();
  const [getApprovedEmployee, { data }] = useLazyQuery(EMPLOYEE_QUERY);

  const options = [
    {
      key: "profile",
      text: t("navigation:myProfile"),
      onClick: () => {
        navigate(`/user/${user.username}`);
      },
    },
    {
      key: "cv-export",
      text: t("navigation:createCV"),
      icon: loading ? <FaSpinner className="animate-spin" /> : null,
      onClick: () => {
        getApprovedEmployee({
          variables: { activeDirectoryId },
        });
        setShowExportWizard(true);
      },
    },
    isContentAdmin
      ? {
          key: "drafts",
          text: t("navigation:drafts"),
          onClick: () => {
            navigate("/manage-data/drafts");
          },
        }
      : null,
    {
      key: "log-out",
      text: t("common:logout"),
      onClick: logOut,
    },
    {
      key: "featureFlags",
      text: t("navigation:experimental"),
      onClick: () => {
        setShowFeatureFlagModal(true);
      },
    },
  ].filter((x) => x);

  return (
    <>
      <Menubar.Root>
        <Menubar.Menu>
          <Menubar.Trigger className="focus-visible:ring-inset button focus bg-white hover:bg-gray-10">
            <UserIcon />
          </Menubar.Trigger>
          <Menubar.Portal>
            <Menubar.Content
              align="start"
              sideOffset={5}
              alignOffset={-3}
              className="z-40 -mt-1 min-w-[8rem] border bg-white px-4 py-4 md:px-0 w-screen py-3 md:w-64"
            >
              {options.map((option: any) => {
                return (
                  <Menubar.Item
                    key={option.key || option.value}
                    className="flex items-center justify-between px-4 py-2 space-x-3 text-sm whitespace-nowrap text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                    role="menuitem"
                    onClick={() => {
                      if (option.onClick) {
                        option.onClick(option.value);
                      }
                    }}
                  >
                    <div>{option.text}</div>
                    {option.icon && (
                      <div className="text-blue-500">{option.icon}</div>
                    )}
                  </Menubar.Item>
                );
              })}
            </Menubar.Content>
          </Menubar.Portal>
        </Menubar.Menu>
      </Menubar.Root>
      {showExportWizard && data ? (
        <CvExportModal
          approvedEmployee={data?.employee}
          loading={loading}
          exportCv={exportCv}
          showExportWizard={showExportWizard}
          setShowExportWizard={setShowExportWizard}
        />
      ) : null}
      <FeatureFlagModal
        isOpen={showFeatureFlagModal}
        setIsOpen={setShowFeatureFlagModal}
      />
    </>
  );
};
