import React from "react";
import { createPortal } from "react-dom";

import { AiOutlineClose } from "react-icons/ai";

export const ImageModal = ({ picture, pictureCredit, open, setFullImage }) => {
  return open
    ? createPortal(
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div
                className="absolute inset-0 bg-gray-500 opacity-75"
                onClick={() => {
                  setFullImage(false);
                }}
              ></div>
            </div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div
              className="absolute top-0 left-0 w-full h-full"
              onClick={() => {
                setFullImage(false);
              }}
            >
              <div
                className="inline-block px-4 pt-5 pb-4 text-left align-bottom transition-all bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                <div
                  className="absolute top-0 right-0 p-1 cursor-pointer"
                  onClick={() => {
                    setFullImage(false);
                  }}
                >
                  <AiOutlineClose className="text-xl" />
                </div>
                <figure>
                  <img
                    src={picture?.urlFull ?? picture?.url}
                    className="object-cover w-full h-full"
                  />
                  <figcaption className="mt-2">
                    {pictureCredit ?? ""}
                  </figcaption>
                </figure>
              </div>
            </div>
          </div>
        </div>,
        document.body,
      )
    : null;
};
