import React from "react";
import { GenericFieldProps } from "./fieldProps";

export type BoolFieldProps = GenericFieldProps<boolean>;

export const BoolField: React.FC<BoolFieldProps> = ({
  value,
  onChange,
  disabled = false,
}) => {
  return (
    <input
      type="checkbox"
      disabled={disabled}
      className="block w-5 h-5 p-2 border rounded-md shadow-sm outline-none border-grey-300 focus:ring-grey-900 focus:ring-2 focus:border-grey-900 sm:text-sm"
      checked={value ?? false}
      onChange={(e) => onChange && onChange(e.currentTarget.checked)}
    ></input>
  );
};

export const boolField = {
  renderer: BoolField,
};
