export type LayoutType = "single" | "twoColumn";

export type LayoutStructure = {
  layout: {
    id: string;
    type: LayoutType;
    index?: number;
    indexes?: number[];
  }[];
  elements: any[];
};

export const insertTemplate = (
  [e1, e2]: [any] | [any, any],
  index: number,
  { layout, elements }: LayoutStructure,
): LayoutStructure => {
  const fieldId = `${Math.random()}`;
  let newLayout;
  if (e2) {
    newLayout = {
      id: fieldId,
      type: "twoColumn",
      indexes: [elements.length, elements.length + 1],
    };
  } else {
    newLayout = {
      id: fieldId,
      type: "single",
      index: elements.length,
    };
  }
  const layoutArray = [...layout];
  layoutArray.splice(index, 0, newLayout);

  return {
    layout: layoutArray,
    elements: [...elements, e1, ...(e2 ? [e2] : [])],
  };
};

export const swapTwoColumnElements = (
  layoutId: string,
  { layout, elements }: LayoutStructure,
): LayoutStructure => {
  return {
    layout: layout.map((it) => {
      if (it.id === layoutId && it.indexes) {
        return { ...it, indexes: [it.indexes[1], it.indexes[0]] };
      } else {
        return it;
      }
    }),
    elements,
  };
};

export const setElement = (
  index: number,
  value: any,
  { layout, elements }: LayoutStructure,
): LayoutStructure => {
  return {
    elements: elements.map((element, i) => {
      if (index === i) {
        return { ...element, ...value };
      }
      return element;
    }),
    layout,
  };
};

export const deleteLayoutBlock = (
  layoutId: string,
  layoutStructure: LayoutStructure,
): LayoutStructure => {
  const layoutToDelete = layoutStructure.layout.find(
    ({ id }) => layoutId == id,
  );
  const elementIndexesToDelete: number[] =
    layoutToDelete?.type === "single"
      ? [layoutToDelete.index!]
      : (layoutToDelete?.indexes ?? []);

  // calculate new indexes of elements, after some will be removed:
  //
  //  elements = ["foo", "bar", "baz"]
  //    -> delete index 1
  //  newIndexMapping = { 0: 0, 1: null, 2: 1 }
  const oldIndexToNewIndexMap = Object.fromEntries(
    layoutStructure.elements.map((_, index) => {
      // counts how many of the elements to be removed have a lower index than the current index.
      const numLowerIndexes: number = sum(
        elementIndexesToDelete.map((i) => (i < index ? 1 : 0) as number),
      );

      const oldIndex = index;
      const newIndex = elementIndexesToDelete.includes(index)
        ? null
        : index - numLowerIndexes;
      return [oldIndex, newIndex];
    }),
  );

  const newLayout = layoutStructure.layout
    // remove layout to be deleted
    .filter(({ id }) => layoutId !== id)
    // remap indexes of elements
    .map((layout) => {
      return {
        ...layout,
        indexes: layout.indexes?.map((i) => oldIndexToNewIndexMap[i]!),
        index:
          layout.index !== undefined
            ? oldIndexToNewIndexMap[layout.index]!
            : undefined,
      };
    });

  const newElements = layoutStructure.elements.filter(
    (_, index) => !elementIndexesToDelete.includes(index),
  );

  return { layout: newLayout, elements: newElements };
};

const sum = (numbers) => numbers.reduce((a, b) => a + b, 0);

export const moveElementUp = (
  layoutId: number,
  layoutStructure: LayoutStructure,
): LayoutStructure => {
  const layout = layoutStructure.layout;
  const newLayout = [
    ...layout.slice(0, layoutId - 1),
    layout[layoutId],
    layout[layoutId - 1],
    ...layout.slice(layoutId + 1, layout.length),
  ];

  return { elements: layoutStructure.elements, layout: newLayout };
};

export const moveElementDown = (
  layoutId: number,
  layoutStructure: LayoutStructure,
): LayoutStructure => {
  const layout = layoutStructure.layout;
  const newLayout = [
    ...layout.slice(0, layoutId),
    layout[layoutId + 1],
    layout[layoutId],
    ...layout.slice(layoutId + 2, layout.length),
  ];

  return { elements: layoutStructure.elements, layout: newLayout };
};
