import React from "react";
import { useMultiLang } from "helpers/multiLang";

export const Item = ({ label }) => {
  return (
    <div className="inline-block px-2 mb-1 mr-2 leading-5 text-xs text-blue-500 bg-blue-500 bg-opacity-5">
      {label}
    </div>
  );
};

export const ItemList = ({ keyString, items }: any) => {
  const m = useMultiLang();

  return items?.map((item: any) => (
    <Item key={item?.id} label={m(item[keyString]?.name) ?? item?.newText} />
  ));
};

export const ItemListPlain = ({ items, className }: any) => {
  return (
    <div className={className}>
      {items?.map((item: any) => <Item key={item} label={item} />)}
    </div>
  );
};
