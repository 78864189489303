import React, { useState } from "react";
import { GenericField, GenericFieldProps } from "./fieldProps";
import { MdFilterCenterFocus } from "react-icons/md";

import { InputPicture } from "generated/graphql";
import { InputFileBase64 } from "components/inputFileBase64";
import { IconButton } from "components/button";
import { SecondaryButton } from "components/button";
import { useTranslation } from "react-i18next";

import { FocusModal } from "./picture/focusModal";
import { LoadingSpinner } from "components/loadingSpinner";

export type PictureFieldProps = GenericFieldProps<InputPicture | null>;

export const PictureField: React.FC<PictureFieldProps> = ({
  value,
  onChange,
  disabled = false,
}) => {
  const { t } = useTranslation(["common", "project"]);

  const [showEditFocus, setShowEditFocus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          {disabled ? null : (
            <div className="flex">
              <InputFileBase64
                title={t("project:addImage")}
                // If you make changes to this list, you also need to change the server validation in `reference_picture.ex`
                accept="image/jpeg, image/png, image/gif, image/png"
                onChange={(base64Url, filename) => {
                  onChange?.({
                    ...value,
                    url: base64Url,
                    filename,
                    action: "create",
                  });
                }}
                setIsLoading={setIsLoading}
              />
              {value?.url ? (
                <>
                  <div className="flex-grow" />
                  <SecondaryButton
                    onClick={() => {
                      onChange?.({ action: "unlink" });
                    }}
                  >
                    {t("common:delete")}
                  </SecondaryButton>
                </>
              ) : null}
            </div>
          )}
          {value?.url ? (
            <div className="mt-3">
              {value.url === "" ? null : (
                <div className="flex items-end justify-between w-full space-x-2">
                  <div>
                    <img src={value.url} className="max-h-64" />
                  </div>
                  <div>
                    <IconButton
                      Icon={MdFilterCenterFocus}
                      disabled={disabled}
                      onClick={() => {
                        setShowEditFocus(true);
                      }}
                    >
                      {t("common:setFocusPoint")}
                    </IconButton>
                  </div>
                  {showEditFocus ? (
                    <FocusModal
                      imageSrc={value.urlFull}
                      focus={{ x: value?.focusX ?? 0, y: value?.focusY ?? 0 }}
                      setFocus={(newFocus) => {
                        onChange?.({
                          ...value,
                          action: value?.action ? value.action : "update",
                          focusX: newFocus.x,
                          focusY: newFocus.y,
                        });
                      }}
                      close={() => setShowEditFocus(false)}
                    />
                  ) : null}
                </div>
              )}
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export const pictureField: GenericField<PictureFieldProps> = {
  renderer: PictureField,
  onBeforeSave: (picture) =>
    picture?.action
      ? {
          url: picture.url,
          filename: picture.filename,
          id: picture?.id,
          action: picture.action,
          focusX: picture.focusX,
          focusY: picture.focusY,
        }
      : {
          action: "noaction",
          id: picture?.id,
          focusX: picture?.focusX,
          focusY: picture?.focusY,
        },
  equals: (a, b) => (a?.url ?? "") === (b?.url ?? ""),
};
