import * as docx from "docx";
import { Document } from "docx";

export const downloadWord = async (title: string, doc: Document) => {
  const blob = await convertToBlob(doc);
  saveData(blob, `${title}.docx`);
};

const convertToBlob = (doc: Document) => {
  return docx.Packer.toBlob(doc);
};

// https://stackoverflow.com/questions/19327749/javascript-blob-filename-without-link
const saveData = (blob: Blob, fileName: string) => {
  // IE and Edge workaround
  if ((navigator as any).msSaveBlob) {
    (navigator as any).msSaveBlob(blob, fileName);
  } else {
    const a = document.createElement("a");
    document.body.appendChild(a);

    const url = URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();

    // Delay revoking the object, this prevents some crazy edge conditions on some browsers
    setTimeout(() => {
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
  }
};
