import React, { ReactNode } from "react";
import { usePreventBodyScroll } from "../helpers/usePreventBodyScroll";
import { createPortal } from "react-dom";
import classNames from "classnames";

export type ModalProps = {
  open: boolean;
  close: () => void;
  actions: ReactNode;
  title: ReactNode;
  size?: "big" | "normal" | "small" | "mini";
  fullScreen?: boolean;
  closeOnClick?: boolean;
  children: React.ReactNode;
};

export const Modal: React.FC<ModalProps> = ({
  open,
  actions,
  children,
  title,
  close,
  size = "normal",
  fullScreen = false,
  closeOnClick = true,
}) => {
  usePreventBodyScroll({ active: open });

  return open
    ? createPortal(
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div
              className="absolute top-0 left-0 w-full h-full"
              onClick={() => {
                if (closeOnClick) {
                  close();
                }
              }}
            >
              <div
                className={classNames(
                  "flex flex-col mx-auto text-left transition-all bg-white border-white rounded shadow-xl border-xs  sm:align-middle sm:w-full",
                  {
                    "sm:max-w-5xl lg:max-w-6xl xl:max-w-screen-xl 2xl:max-w-screen-2xl":
                      size === "big",
                    "sm:max-w-5xl": size === "normal",
                    "sm:max-w-2xl": size === "small",
                    "sm:max-w-sm": size === "mini",
                    "absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 mb-0 -mt-8":
                      fullScreen,
                    "sm:my-8": !fullScreen,
                  },
                )}
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
                onClick={(e) => e.stopPropagation()}
                style={{ maxHeight: "calc(100% - 4em)" }}
              >
                {title && (
                  <div className="z-10 w-full text-2xl bg-white border-white rounded-t shadow border-xs">
                    <h2 className="px-4 border-b border-b-gray-300">{title}</h2>
                  </div>
                )}
                <div
                  className={classNames("overflow-y-auto", {
                    "min-h-32": size === "normal",
                  })}
                >
                  {children}
                </div>
                <div className="w-full bg-white border-white rounded-b shadow-2xl border-xs">
                  <div className="p-4 border-t border-t-gray-300 sm:flex sm:flex-row-reverse">
                    {actions}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>,
        document.body,
      )
    : null;
};
