import { gql } from "@apollo/client";
import { projectListFields } from "./newProject";

export const clientFields = gql`
  fragment Client_fields on Client {
    id
    name
    email
    phone
    title
    postalCode
    street
    city
    country
    language
    isUnlisted
    isReadyForReview
  }
`;

export const CLIENT_QUERY = gql`
  ${clientFields}
  ${projectListFields}

  query GetClient($id: ID, $abacusId: ID) {
    client(id: $id, abacusId: $abacusId) {
      ...Client_fields
      projects {
        ...ProjectList_fields
        parentProjects {
          ...ProjectList_fields
        }
      }
      childClients {
        ...Client_fields
      }
      approvedBy {
        __typename
      }
      approvedEntityId
      connectedFields {
        field
        isConnected
      }
      draft {
        ...Client_fields
        projects {
          ...ProjectList_fields
          parentProjects {
            ...ProjectList_fields
          }
        }
        childClients {
          ...Client_fields
        }
        modifiedBy {
          __typename
        }
        connectedFields {
          field
          isConnected
        }
      }
    }
  }
`;

export const EDIT_CLIENT_MUTATION = gql`
  ${clientFields}
  mutation EditClientMutation($id: ID!, $fields: InputClient!) {
    editClient(id: $id, fields: $fields) {
      ...Client_fields
      approvedBy {
        __typename
      }
      draft {
        ...Client_fields
        modifiedBy {
          __typename
        }
      }
    }
  }
`;
