import React, { useState } from "react";
import { Link } from "wouter";
import { useTranslation } from "react-i18next";
import { useMultiLang } from "helpers/multiLang";
import * as R from "ramda";

import { EditReferenceModal } from "components/editVersioned/editReference";

import { ProjectTeamMember, NewReference } from "generated/graphql";
import { FaEdit, FaPlus, FaUserAlt } from "react-icons/fa";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { SmallIconButton } from "components/button";
import {
  Overlay,
  needToMigrate,
  ReferencesContent,
} from "components/newReferences";

import { Avatar } from "pages/newEmployee/avatar";
import { DotDotMenu } from "components/dropdownMenu";
import { selectTeamMemberReferenceData } from "helpers/getReferenceData";

// HELPER COMPONENTS

const TeamMemberReferenceItemView = ({
  reference,
  expanded,
  setExpanded,
}: {
  reference: Partial<NewReference>;
  expanded: boolean;
  setExpanded: (id: string | null) => void;
}) => {
  const { t } = useTranslation(["project", "common"]);
  const m = useMultiLang();

  const isUnapprovedNew = !reference.draft && !reference.approvedAt;
  const isDraft = reference.draft ? true : false;
  const isReadyForReview = isUnapprovedNew
    ? (reference.isReadyForReview ?? false)
    : (reference.draft?.isReadyForReview ?? false);
  const displayReference = reference.draft ?? reference;
  const [showEdit, setShowEdit] = useState(false);
  const parentRefName = selectTeamMemberReferenceData(
    displayReference,
    "title",
  );

  const role = displayReference.roles
    ? displayReference.roles
        .map((r) =>
          r.referenceRoleId ? m(r.referenceRole?.name, "") : (r.newText ?? ""),
        )
        .join(", ")
    : "";

  return (
    <div className="relative">
      {needToMigrate(displayReference) ? (
        <Overlay
          reference={displayReference}
          parentProject={displayReference?.project?.parentProjects[0]}
        />
      ) : null}
      <div
        className={`rounded ${
          needToMigrate(displayReference) ? "pointer-events-none" : ""
        }`}
        id={displayReference.id}
      >
        <div
          className={`p-4 ${needToMigrate(displayReference) ? "min-h-12" : ""}`}
        >
          <div className="flex items-end justify-between gap-x-5">
            <div
              className="group hover:cursor-pointer"
              onClick={() => {
                setExpanded(expanded ? null : (reference.id ?? null));
              }}
            >
              <div className="text-blue-500 group-hover:text-gray-900">
                {displayReference?.parentReference?.title
                  ? m(displayReference?.parentReference?.title)
                  : parentRefName
                    ? m(parentRefName)
                    : m(displayReference?.project?.name)}
              </div>
              <div className="text-xs text-gray-500 group-hover:text-gray-900">
                {displayReference.deletedAt ? (
                  <span className="text-gray-300">{t("common:deleted")}</span>
                ) : (
                  role
                )}
              </div>
            </div>
            <div className="flex items-center gap-x-1">
              {!isReadyForReview && expanded && (
                <SmallIconButton
                  popupContent={t("project:editReference")}
                  className="text-blue-500 cursor-pointer"
                  Icon={FaEdit}
                  onClick={() => {
                    setShowEdit(true);
                  }}
                />
              )}
              {expanded ? (
                <div>
                  <SmallIconButton
                    Icon={IoIosArrowUp}
                    onClick={() => {
                      setExpanded(null);
                    }}
                  />
                </div>
              ) : (
                <div>
                  <SmallIconButton
                    Icon={IoIosArrowDown}
                    onClick={() => {
                      setExpanded(reference.id ?? null);
                    }}
                  />
                </div>
              )}
            </div>
          </div>

          {expanded && (
            <ReferencesContent
              reference={displayReference}
              isDraft={isDraft || isUnapprovedNew}
              isReadyForReview={isReadyForReview}
            />
          )}
        </div>

        <EditReferenceModal
          id={displayReference.id}
          close={() => setShowEdit(false)}
          open={showEdit}
        />
      </div>
    </div>
  );
};

export const TeamMember = ({
  member,
  projectId,
}: {
  projectId: string;
  member: Partial<ProjectTeamMember>;
}) => {
  const [showCreate, setShowCreate] = useState(false);

  const displayedMember = member.draft ?? member;
  const { references, ...memberProps } = displayedMember;

  const { t } = useTranslation(["common", "abacusProjects", "reference"]);
  const m = useMultiLang();

  // If there is no roles in any reference, assume team member has "Projektarbeit" default role in the project
  const allRoles =
    member?.references
      ?.map(
        (r) =>
          r.roles?.map((r) => m(r.referenceRole?.name, "")) ??
          t("abacusProjects:role_0"),
      )
      .flat() ?? [];

  const uniqueRoles = R.uniq(allRoles);
  const [expandedReference, setExpandedReference] = useState<string | null>(
    null,
  );
  return (
    <>
      <div
        className="border border-gray-100 rounded shadow"
        id={member.employeeId}
      >
        <div className="flex justify-between p-4">
          <div className="flex items-center">
            <div>
              <Avatar
                url={memberProps?.employee?.profilePicture ?? ""}
                size="small"
              />
            </div>
            <div className="pl-4">
              <Link
                className="font-medium text-black hover:text-blue-500"
                href={`/employee/${memberProps?.employee?.id}`}
              >
                {memberProps?.employee?.firstName}{" "}
                {memberProps?.employee?.lastName}
              </Link>
              <ul className="overflow-hidden text-sm roles text-gray-350 sm:w-28 md:w-40 xl:w-60 whitespace-nowrap text-ellipsis">
                {uniqueRoles?.map((role, i) => {
                  return (
                    <li
                      key={i}
                      className="inline pr-1 -mt-1 text-sm text-gray-350"
                    >
                      {role}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <DotDotMenu
            items={[
              {
                key: "create",
                render: () => (
                  <span
                    className="flex items-center cursor-pointer whitespace-nowrap"
                    onClick={() => setShowCreate(true)}
                  >
                    <FaPlus className="mr-3 text-blue-500" />
                    {t("reference:create")}
                  </span>
                ),
              },
            ]}
          />
        </div>
        <EditReferenceModal
          newEntity={{ projectId, employeeId: member.employeeId }}
          open={showCreate}
          close={() => {
            setShowCreate(false);
          }}
        />
        {(references ?? []).map((reference) => {
          return (
            <TeamMemberReferenceItemView
              key={reference.id}
              reference={reference}
              expanded={expandedReference === reference.id}
              setExpanded={setExpandedReference}
            />
          );
        })}
      </div>
    </>
  );
};

export const PreviousTeamMember = ({ member }) => {
  return (
    <div className="p-4 border-2 border-gray-200 rounded">
      <div className="flex justify-between ">
        <div className="flex items-center">
          <div className="text-2xl text-white">
            <div className="flex items-center justify-center w-12 h-12 bg-gray-200 rounded-full">
              <FaUserAlt />
            </div>
          </div>
          <div className="pl-4">
            <span className="font-medium ">
              {member?.employee?.firstName} {member?.employee?.lastName}
            </span>
            <div className="-mt-1 text-sm">Projektarbeit</div>
          </div>
        </div>
      </div>
    </div>
  );
};
