import { useEffect } from "react";

export default () => {
  useEffect(() => {
    const { hash } = window.location;
    if (hash !== "") {
      const id = hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.classList.add("highlight-blink");
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  });
};
