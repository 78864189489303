import { useEffect, useRef } from "react";

export const useInfiniteScrollAlternative = (
  callback: any,
  element: any = window,
  fixedOffset = 100,
  deps?: any[],
): [() => void, () => void] => {
  const active = useRef(true);

  const handleScroll = () => {
    const root = document.getElementById("root");
    if (
      root &&
      active.current &&
      element.innerHeight + window.scrollY + fixedOffset >= root.offsetHeight
    ) {
      callback();
    }
  };

  useEffect(() => {
    element.addEventListener("scroll", handleScroll);
    return () => element.removeEventListener("scroll", handleScroll);
  }, deps);

  const unlockInfiniteScroll = () => {
    active.current = true;
  };

  const lockInfiniteScroll = () => {
    active.current = false;
  };

  return [lockInfiniteScroll, unlockInfiniteScroll];
};
