import { MultiLang } from "./ui/multiLang";

export const languages: string[] = ["de", "fr", "it", "en"];

export const wordExportLanguageOptions = [
  { key: "de", value: "de", text: "DE-CH" },
  { key: "de-DE", value: "de-DE", text: "DE-DE" },
  { key: "it", value: "it", text: "IT" },
  { key: "fr", value: "fr", text: "FR" },
  { key: "en", value: "en", text: "EN" },
];

export const getFirstNonEmpty = (field: MultiLang): string | undefined => {
  const firstNonEmptyLang =
    languages.find(
      (lang) =>
        field?.[lang] !== "" &&
        field?.[lang] !== null &&
        field?.[lang] !== undefined,
    ) || "de";
  return field?.[firstNonEmptyLang];
};

export const getCurrentSupportedLanguage = (
  currentLang: string | undefined,
): string => {
  const shortLang = currentLang?.slice(0, 2) ?? "de";
  return languages.includes(shortLang) ? shortLang : "de";
};
