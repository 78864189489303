import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import { GenericFieldProps, GenericField } from "./fieldProps";
import { ListField } from "./ListField";
import { TextField } from "./textField";
import { PictureField, blockPictureField } from "./blockPictureField";
import { extractMulti, stringifyMulti } from "helpers/ui/multiLang";
import { BlockPictureWithCaption } from "generated/graphql";
import { customStyles, i18nOptions } from "./multiSelectField";
import { CaptionPositions, Orientations } from "generated/graphql";

type GalleryType = BlockPictureWithCaption[];

export type GalleryFieldProps = GenericFieldProps<GalleryType>;

// HELPER COMPONENT

export const PictureMetadata: React.FC<
  GenericFieldProps<BlockPictureWithCaption>
> = ({
  value,
  handleCaptionPositionChange,
  handleOrientationChange,
  handleCaptionChange,
  language,
  disabled,
  needsCaptionPosition = false,
}) => {
  const { t } = useTranslation(["common", "onepager", "form"]);

  const optionMap = (key: string) => (o: string) => ({
    value: o,
    label: o ? t(`onepager:select.${key}.${o}` as any) : "",
  });

  return (
    <div>
      <label className="text-sm">{t("form:caption")}:</label>
      <div className="flex items-center space-x-2 text-sm">
        <TextField
          disabled={disabled}
          onChange={handleCaptionChange}
          value={value?.caption?.[language] ?? ""}
          language={language}
        />
      </div>
      <div className="flex mt-2 space-x-2">
        {needsCaptionPosition ? (
          <div className="flex-1 text-sm">
            <label className="inline-block my-2 text-sm">
              {t("form:captionPosition")}:
            </label>
            <Select
              styles={customStyles}
              options={Object.values(CaptionPositions)?.map(
                optionMap("captionPosition"),
              )}
              isDisabled={disabled}
              {...i18nOptions(t)}
              value={{
                value: value?.captionPosition as string,
                label: value?.captionPosition
                  ? t(
                      `onepager:select.captionPosition.${value.captionPosition}` as any,
                    )
                  : "",
              }}
              onChange={handleCaptionPositionChange}
            />
          </div>
        ) : null}
        <div className="flex-1 text-sm">
          <label className="inline-block my-2 text-sm">
            {t("form:pictureOrientation")}:
          </label>
          <Select
            styles={customStyles}
            options={Object.values(Orientations)?.map(optionMap("orientation"))}
            isDisabled={disabled}
            {...i18nOptions(t)}
            value={{
              value: value?.orientation as string,
              label: value?.orientation
                ? t(`onepager:select.orientation.${value.orientation}` as any)
                : "",
            }}
            onChange={handleOrientationChange}
          />
        </div>
      </div>
    </div>
  );
};

const Picture = ({
  value,
  disabled,
  onChange,
  language,
}: GenericFieldProps<BlockPictureWithCaption>) => {
  const handleCaptionChange = (newValue) => {
    const newPicture = {
      ...value,
      caption: { ...value.caption, [language]: newValue },
    };
    onChange(newPicture);
  };

  const handlePictureChange = (newValue) => {
    const newPicture = { ...value, picture: newValue };
    onChange(newPicture);
  };

  const handleCaptionPositionChange = (newValue) => {
    onChange({
      ...value,
      captionPosition: newValue.value as string,
    });
  };

  const handleOrientationChange = (newValue) => {
    onChange({
      ...value,
      orientation: newValue.value as string,
    });
  };

  return (
    <div className="p-2 border rounded border-grey-200">
      <PictureField
        disabled={disabled}
        onChange={handlePictureChange}
        value={value.picture}
        language={language}
      />
      <div className="px-2 mt-2">
        <PictureMetadata
          value={value}
          handleCaptionChange={handleCaptionChange}
          handleCaptionPositionChange={handleCaptionPositionChange}
          handleOrientationChange={handleOrientationChange}
          language={language}
          disabled={disabled}
          needsCaptionPosition
        />
      </div>
    </div>
  );
};

export const GalleryField: React.FC<GalleryFieldProps> = ({
  value,
  onChange,
  language,
  disabled,
}) => {
  const newPicture = {
    caption: { de: "", fr: "", it: "", en: "" },
    captionPosition: "BEFORE",
    orientation: "LANDSCAPE",
    key: "new",
  };

  return (
    <ListField
      value={(value ?? []).map((item) => ({
        ...item,
        key: `${item.id}`,
      }))}
      vertical
      language={language}
      onChange={onChange}
      disabled={disabled}
      render={Picture}
      newItem={newPicture}
      catalogue={[]}
    />
  );
};

export const galleryField: GenericField<GalleryType> = {
  renderer: GalleryField,
  onBeforeSave: (value) => {
    return value?.map((v) => ({
      caption: extractMulti(v.caption),
      captionPosition: v.captionPosition,
      orientation: v.orientation,
      picture: blockPictureField?.onBeforeSave?.(v.picture),
    }));
  },
  equals: (a, b) => {
    return (
      (a
        ?.map(
          (v) =>
            `${stringifyMulti(v.caption)}-${v.pictureId ?? ""}-${
              v.captionPosition
            }-${v.orientation}`,
        )
        .join(",") ?? "") ===
      (b
        ?.map(
          (v) =>
            `${stringifyMulti(v.caption)}-${v.pictureId ?? ""}-${
              v.captionPosition
            }-${v.orientation}`,
        )
        .join(",") ?? "")
    );
  },
};
