import * as docx from "docx";
import {
  Paragraph,
  TextRun,
  Run,
  PageBreak,
  IParagraphOptions,
  IRunOptions,
  ITableCellOptions,
  IShadingAttributesProperties,
  Table,
  ImageRun,
  AlignmentType,
} from "docx";
import { defaultBlockParse, SingleASTNode } from "simple-markdown";

// This seems to be about the width of a document minus the margins.
// No idea what unit that is... (points?, centi-inches?, something dependent on dpi?, ..)
export const MAX_IMAGE_WIDTH = 600;
export const MAX_IMAGE_HEIGHT = 160;

export const TBF_BLUE = "266782";
export const TBF_LIGHT_GREY = "f2f2f2";

export const pageBreak = (): Paragraph => {
  return new Paragraph({ children: [new PageBreak()] });
};

export const textRun = (
  text: string,
  options: IRunOptions = {},
): docx.TextRun => {
  const optionsWithDefaults = {
    bold: false,
    italics: false,
    ...options,
  };
  return new TextRun({ children: [text], ...optionsWithDefaults } as any);
};

export const textParagraph = (
  text: string,
  runOptions: IRunOptions = {},
  paragraphOptions: IParagraphOptions = {},
): Paragraph => {
  return new Paragraph({
    children: [textRun(text, runOptions)],
    ...paragraphOptions,
  });
};

export const labeledText = (labelText: string, text: string): Paragraph[] => {
  return [
    labelText ? h3Alt(labelText) : h3Alt(""),
    text ? multilineText(`${text}`) : multilineText(""),
  ];
};

export const labeledH3Paragraphs = (
  labelText: string,
  paragraphs: Paragraph[],
): Paragraph[] => {
  return [h3(labelText), ...paragraphs];
};

export const h1 = (text: string, style?: string): Paragraph => {
  return new docx.Paragraph({
    children: [textRun(text)],
    heading: docx.HeadingLevel.HEADING_1,
    style,
  });
};

export const h1Subtitle = (text: string, style?: string): Paragraph => {
  return new docx.Paragraph({
    children: [textRun(text)],
    heading: docx.HeadingLevel.HEADING_1,
    style: style || "Subtitle",
  });
};

export const h2 = (text: string): Paragraph => {
  return new docx.Paragraph({
    children: [textRun(text)],
    heading: docx.HeadingLevel.HEADING_2,
    style: "MainHeading",
  });
};

export const ReferencesSectionTitle = (text: string): Paragraph => {
  return new docx.Paragraph({
    children: [textRun(text)],
    style: "ReferencesSectionTitle",
  });
};

export const ReferenceTitle = (text: string): Paragraph => {
  return new docx.Paragraph({
    children: [textRun(text)],
    style: "ReferenceTitle",
    spacing: { before: 360 },
  });
};

export const h2Line = (): Paragraph => {
  return new docx.Paragraph({
    children: [textRun("___")],
    heading: docx.HeadingLevel.HEADING_2,
    style: "BeforeMainHeading",
  });
};

export const h2Alt = (text: string): Paragraph => {
  return new docx.Paragraph({
    children: [textRun(text, { color: TBF_BLUE })],
    heading: docx.HeadingLevel.HEADING_2,
    style: "SecondaryMainHeading",
  });
};

export const h2AltLine = (): Paragraph => {
  return new docx.Paragraph({
    children: [textRun("___", { color: TBF_BLUE })],
    heading: docx.HeadingLevel.HEADING_2,
    style: "BeforeSecondaryMainHeading",
  });
};

export const h3 = (text: string): Paragraph => {
  return new docx.Paragraph({
    children: [textRun(text)],
    heading: docx.HeadingLevel.HEADING_3,
    style: "SmallHeading",
  });
};

export const h3Alt = (text: string): Paragraph => {
  return new docx.Paragraph({
    children: [textRun(text)],
    heading: docx.HeadingLevel.HEADING_3,
    style: "AlternateSmallHeading",
  });
};

export const hiddenAnnotation = (text: string): Paragraph => {
  return new docx.Paragraph({
    children: [textRun(text, { color: TBF_LIGHT_GREY, size: 15 })],
    alignment: AlignmentType.RIGHT,
  });
};

export const AlternateSmallHeadingNoSpacing = (text: string): Paragraph => {
  return new docx.Paragraph({
    children: [textRun(text)],
    style: "AlternateSmallHeadingnospacing",
  });
};

export const pictureSource = (text: string): Paragraph => {
  return new docx.Paragraph({
    children: [textRun(text)],
    style: "PictureSource",
    spacing: { after: 60 },
  });
};

export const multilineText = (text: string): Paragraph => {
  if (text) {
    const runs = text.split("\n").map((segment, i) => {
      if (i === 0) {
        return textRun(segment);
      } else {
        return textRun(segment, { break: 1 });
      }
    });
    return new docx.Paragraph({ children: runs });
  } else {
    return new docx.Paragraph({ children: [] });
  }
};

const flattenOnce = <T>(arr: T[][]): T[] =>
  arr.reduce((acc, current) => [...acc, ...current], []);

const markDownInParagraphToTextRun = (
  contents: SingleASTNode[] | string,
  options: IRunOptions = {},
): TextRun[] => {
  if (typeof contents === "string") {
    return [textRun(contents, options)];
  }

  return flattenOnce(
    contents.map((node) => {
      switch (node.type) {
        case "text":
          return markDownInParagraphToTextRun(node.content, options);
        case "br":
          return [new docx.Run({ break: 1 })];
        case "newline":
          return [new docx.Run({ break: 1 })];
        case "strong":
          return markDownInParagraphToTextRun(node.content, {
            ...options,
            bold: true,
          });
        case "em":
          return markDownInParagraphToTextRun(node.content, {
            ...options,
            italics: true,
          });
        case "list":
          // since we do not support nested lists, return the text contents
          return flattenOnce(
            (node.items as SingleASTNode[][]).map((item: SingleASTNode[]) => {
              return markDownInParagraphToTextRun(item);
            }),
          );
        default:
          return markDownInParagraphToTextRun(node.content, options);
      }
    }),
  );
};

const markDownAstToParagraph = (
  astNodes: SingleASTNode[],
  paragraphOptions: IParagraphOptions = {},
): Paragraph => {
  const paragraphTextRuns = markDownInParagraphToTextRun(astNodes);
  return new Paragraph({ children: paragraphTextRuns, ...paragraphOptions });
};

const markDownAstToParagraphs = (astNodes: SingleASTNode[]): Paragraph[] => {
  return flattenOnce(
    astNodes.map((node) => {
      switch (node.type) {
        case "list":
          return (node.items as SingleASTNode[][]).map(
            (item: SingleASTNode[], i) => {
              const isLast = i === node.items.length - 1;
              const paragraph = markDownAstToParagraph(item, {
                numbering: { reference: "tbf-numbering", level: 0 },
                spacing: isLast ? { after: 8 * 20 } : {},
              });

              return paragraph;
            },
          );
        case "hr":
          return [];
        case "newline":
          return [new Paragraph({ children: [new Run({ break: 1 })] })];
        case "table": {
          const cellContents = flattenOnce(
            flattenOnce(node.cells as SingleASTNode[][][]),
          );
          return [markDownAstToParagraph(cellContents)];
        }
        case "paragraph":
        case "heading":
        default:
          return [markDownAstToParagraph(node.content)];
      }
    }),
  );
};

export const markdownText = (markdownSource: string): Paragraph[] => {
  // Replacing \n with \n\n makes sure that new lines actually result in a break.
  // With this little tweak, it behaves much closer to github flavoured markdown
  const transformedSource = markdownSource.replace(/\n/g, "\n\n");
  const syntaxTree = defaultBlockParse(transformedSource);
  return markDownAstToParagraphs(syntaxTree);
};

// ---

export type Cell = {
  content?: Array<Paragraph | Table>;
  shading?: IShadingAttributesProperties;
  mergeWithUpper?: boolean;
  margins?: ITableCellOptions["margins"];
  width?: number;
};

const groupCellsByTwo = <T>(cells: T[]): T[][] => {
  const numGroups = Math.ceil(cells.length / 2);
  return new Array(numGroups)
    .fill([])
    .map((_, i) => cells.slice(i * 2, (i + 1) * 2));
};

export const get5050TableNoSpacing = (cells: Cell[]) => {
  if (cells.length === 0) {
    return new Paragraph({});
  }
  const cellsWithDefaults = cells.map((c) => {
    return {
      margins: {
        left: 0,
      },
      width: 50,
      ...c,
    };
  });

  return getTable(groupCellsByTwo(cellsWithDefaults), 0);
};

export const getTable = (rows: Cell[][], _leftIndent = 120): Table => {
  const tableRows = rows.map((row) => {
    return new docx.TableRow({
      children: row.map((cellContent) => {
        return new docx.TableCell({
          children: cellContent?.content || [],
          width: {
            type: cellContent.width
              ? docx.WidthType.PERCENTAGE
              : docx.WidthType.AUTO,
            size: cellContent?.width ?? 0,
          },
          shading: cellContent?.shading || {},
          borders: {
            top: { style: docx.BorderStyle.NIL, size: 0 },
            bottom: { style: docx.BorderStyle.NIL, size: 0 },
            left: { style: docx.BorderStyle.NIL, size: 0 },
            right: { style: docx.BorderStyle.NIL, size: 0 },
          },
          margins: {
            top: 120,
            left: 120,
            right: 120,
            ...cellContent?.margins,
          },
          verticalMerge: !cellContent?.mergeWithUpper
            ? docx.VerticalMergeType.RESTART
            : docx.VerticalMergeType.CONTINUE,
        });
      }),
    });
  });

  const table = new Table({
    rows: tableRows,
    width: { type: docx.WidthType.PERCENTAGE, size: 100 },
    margins: {
      top: 120,
      left: 120,
      right: 120,
    },
    // Todo: Fix table indentation after docx.js 7 upgrade
    // Read more here: https://github.com/dolanmiu/docx/issues/984
    // indent: {
    //   size: _leftIndent
    // }
  });

  return table;
};

// Source: https://stackoverflow.com/a/14731922
const calculateAspectRatioFit = (
  srcWidth: number,
  srcHeight: number,
  maxWidth: number,
  maxHeight: number,
) => {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  return { width: srcWidth * ratio, height: srcHeight * ratio };
};

export const getImage = async (imageUrl: string): Promise<ImageRun | null> => {
  const referencePhoto = await getRemoteImage(imageUrl);
  if (referencePhoto) {
    const { width, height } = calculateAspectRatioFit(
      referencePhoto.width,
      referencePhoto.height,
      MAX_IMAGE_WIDTH,
      MAX_IMAGE_HEIGHT,
    );

    return new ImageRun({
      data: referencePhoto.data,
      type: "jpg",
      transformation: { width, height },
    });
  }
  return null;
};

export const createImage = async (imageUrl: string, maxWidth: number) => {
  const referencePhoto = await getRemoteImage(imageUrl);
  if (referencePhoto) {
    return new ImageRun({
      data: referencePhoto.data,
      type: "jpg",
      transformation: {
        height: Math.min(
          referencePhoto.height,
          (maxWidth / referencePhoto.width) * referencePhoto.height,
        ),
        width: Math.min(referencePhoto.width, maxWidth),
      },
    });
  }
  return null;
};

// --
// General Helper

export const getRemoteImage = async (
  photo: string,
): Promise<null | { data: ArrayBuffer; width: number; height: number }> => {
  if (!photo) {
    return null;
  }
  const blob = await fetch(photo, { redirect: "follow" }).then((r) => r.blob());
  const img = document.createElement("img");
  img.src = URL.createObjectURL(blob);
  return new Promise((resolve, _reject) => {
    img.onload = async () => {
      resolve({
        data: await blob.arrayBuffer(),
        width: img.width,
        height: img.height,
      });
    };
    img.onerror = (ev) => {
      console.log(`Error fetching image for CV export`, photo, ev);
      resolve(null);
    };
  });
};
