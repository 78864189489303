import React, { useContext } from "react";
import AppContext from "context/app";

import { GenericField, GenericFieldProps } from "./fieldProps";
import { CustomCreatable } from "./creatable";
import { getTextFromMultiLang } from "helpers/multiLang";
import { EmployeeSkill } from "../../generated/graphql";

type SkillsType = EmployeeSkill[];

type SkillFieldProps = GenericFieldProps<SkillsType>;

export const SkillsField = ({
  value,
  onChange,
  disabled,
  language,
}: SkillFieldProps): JSX.Element => {
  const { catalogues } = useContext(AppContext);
  const m = getTextFromMultiLang(language ?? "de");

  const allSkills = catalogues?.skills?.map((skill: any) => ({
    label: m(skill?.name),
    value: `${skill.id}`,
  }));

  return (
    <div className="text-sm">
      <CustomCreatable
        disabled={disabled}
        onChange={onChange}
        options={allSkills}
        value={value}
        idValue="skillId"
      />
    </div>
  );
};

export const skillsField: GenericField<SkillsType> = {
  renderer: SkillsField,
  onBeforeSave: (value) =>
    value
      ?.map((v) => ({
        skillId: v.skillId,
        newText: v.newText,
      }))
      .filter(
        (v) => (v.skillId ? +v.skillId : 0) > 0 || (v.newText?.length ?? 0) > 0,
      ),
  equals: (a, b) => {
    return (
      (a?.map((v) => `${v.skillId}-${v.newText ?? ""}`).join(",") ?? "") ===
      (b?.map((v) => `${v.skillId}-${v.newText ?? ""}`).join(",") ?? "")
    );
  },
};
