import {
  hoaiToSia,
  HOAI_CODES,
  HOAI_PHASES,
  siaToHoai,
  SIA_CODES,
  SIA_PHASES,
} from "../../pages/project/utils";

export const groupConsecutives = (list, compareFunction) => {
  return list.reduce((acc, el, i) => {
    if (i) {
      const lastGroup = acc[acc.length - 1];
      if (compareFunction(lastGroup[lastGroup.length - 1], el)) {
        lastGroup.push(el);
        return acc;
      } else {
        return acc.concat([[el]]);
      }
    } else {
      return acc.concat([[el]]);
    }
  }, [] as any);
};

export const shortenSiaPhases = (siaPhases: string[]) => {
  siaPhases = [...siaPhases].sort((a, b) =>
    SIA_CODES[a] < SIA_CODES[b] ? -1 : 1,
  );
  const isConsecutivePhase = (prevElem, currentElem) => {
    return SIA_PHASES.indexOf(prevElem) + 1 === SIA_PHASES.indexOf(currentElem);
  };
  const convertToRangeString = (list) => {
    if (list.length > 1) {
      return `${SIA_CODES[list[0]]}–${SIA_CODES[list[list.length - 1]]}`;
    } else {
      return `${SIA_CODES[list[0]]}`;
    }
  };

  return groupConsecutives(siaPhases ?? [], isConsecutivePhase)
    .map((g: any) => convertToRangeString(g))
    ?.join(", ");
};

export const shortenHOAIPhases = (hoaiPhases: string[]) => {
  hoaiPhases = [...hoaiPhases]
    .filter(
      (p) =>
        !["hoai_phase_0_11", "hoai_phase_0_21", "hoai_phase_0_22"].includes(p),
    )
    .sort((a, b) => (a < b ? -1 : 1));
  const isConsecutivePhase = (prevElem, currentElem) => {
    return (
      HOAI_PHASES.indexOf(prevElem) + 1 === HOAI_PHASES.indexOf(currentElem)
    );
  };
  const convertToRangeString = (list) => {
    if (list.length > 1) {
      return `${HOAI_CODES[list[0]]}–${HOAI_CODES[list[list.length - 1]]}`;
    } else {
      return `${HOAI_CODES[list[0]]}`;
    }
  };

  return groupConsecutives(hoaiPhases ?? [], isConsecutivePhase)
    .map((g: any) => convertToRangeString(g))
    ?.join(", ");
};

export const shortenSiaOrHoai = (
  useSia: boolean,
  siaPhases: Array<string>,
  hoaiPhases: Array<string>,
) => {
  if (useSia) {
    return siaPhases.length > 0
      ? shortenSiaPhases(siaPhases)
      : shortenSiaPhases(hoaiToSia(hoaiPhases));
  } else {
    return hoaiPhases.length > 0
      ? shortenHOAIPhases(hoaiPhases)
      : shortenHOAIPhases(siaToHoai(siaPhases));
  }
};

export const siaOrHoai = (
  useSia: boolean,
  siaPhases: Array<string>,
  hoaiPhases: Array<string>,
) => {
  if (useSia) {
    return siaPhases.length > 0 ? siaPhases : hoaiToSia(hoaiPhases);
  } else {
    return hoaiPhases.length > 0 ? hoaiPhases : siaToHoai(siaPhases);
  }
};
