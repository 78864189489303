// Extension inspired by https://github.com/ueberdosis/tiptap/tree/main/packages/extension-image
import { Node, mergeAttributes } from "@tiptap/core";
import { generateJSON } from "@tiptap/html";
import Document from "@tiptap/extension-document";
import Text from "@tiptap/extension-text";
import Paragraph from "@tiptap/extension-paragraph";

import axios from "axios";

export interface VimeoOptions {
  HTMLAttributes: Record<string, any>;
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    video: {
      /**
       * Add a video
       */
      setVideo: (options: { src: string }) => ReturnType;
    };
  }
}

export const Vimeo = Node.create<VimeoOptions>({
  name: "vimeo",

  // https://prosemirror.net/docs/ref/#model.NodeSpec
  group: "block",
  inline: false,
  atom: true,
  draggable: true,

  addOptions() {
    return {
      HTMLAttributes: {
        width: "640",
        height: "360",
        frameborder: "0",
        allowfullscreen: "true",
        allow:
          "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
      },
    };
  },

  addAttributes() {
    return { src: { default: null } };
  },

  parseHTML() {
    return [{ tag: "iframe[src]" }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "iframe",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
    ];
  },

  addCommands() {
    return {
      setVideo:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },
    };
  },
});

export const getVimeoEmbedUrl = async (vimeoUrl: string): Promise<string> => {
  const encodedVimeoUrl = encodeURIComponent(vimeoUrl);
  // https://developer.vimeo.com/api/oembed/videos
  return axios
    .get(`https://vimeo.com/api/oembed.json?url=${encodedVimeoUrl}`)
    .then((result) => {
      if (!(result.status == 200 || result.status == 304)) {
        return Promise.reject(new Error("Vimeo error"));
      }
      const html = result?.data?.html;
      // parse the iframe html to extract the embed url
      const out = generateJSON(html, [Document, Text, Paragraph, Vimeo]);
      const src = out?.content?.[0]?.attrs?.src;
      return src;
    });
};
