import React, { FC } from "react";
import { useMultiLang } from "helpers/multiLang";
import { CvEntry } from "generated/graphql";
import { useTranslation } from "react-i18next";

export const CvItemView: FC<{ item: CvEntry }> = ({ item }) => {
  const m = useMultiLang();
  const { t } = useTranslation("employee");

  const duration = item.to
    ? `${item?.from ? item.from.slice(0, 4) : ""}–${item.to.slice(0, 4)}`
    : `${t("cvItem.since")} ${item?.from ? item.from.slice(0, 4) : ""}`;

  return (
    <div className="flex flex-col w-full pt-2 mb-6 md:flex-row ">
      <div className="flex-none w-full mb-2 text-sm md:w-32 md:font-medium">
        {duration}
      </div>
      <div className="flex-1 leading-6 whitespace-pre-wrap md:pb-2">
        <div className="mb-2 text-sm font-medium">{m(item?.institution)}</div>
        <div className="text-light">{m(item?.description)}</div>
      </div>
    </div>
  );
};
