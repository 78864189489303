export const findAndReturnReferenceTitle = (
  reference: any,
  t: any,
  m: any,
): string | null | undefined => {
  const getFirstNonEmpty = (options: Array<string | undefined | null>) => {
    return options.find(
      (option) => option !== "" && option !== null && option !== undefined,
    );
  };
  const isPrivateReference = !reference.projectId;

  const title = isPrivateReference
    ? getFirstNonEmpty([
        m(reference?.title),
        m(reference?.privateReferenceProjectName),
        t("searchHighlights:privateReference"),
      ])
    : getFirstNonEmpty([
        m(reference?.title),
        m(reference?.parentReference?.title),
        m(reference?.parentReference?.parentReference?.title),
        t("project:noTitle"),
      ]);

  return title;
};
