import React from "react";
import { User } from "./components/userProvider";

type AuthContextType = {
  user: User;
  token: string;
  logOut: () => void;
};

const initialValue: AuthContextType = {
  user: {
    username: "",
    isContentAdmin: false,
    isWebsiteEditor: false,
  },
  token: "",
  logOut: () => null,
};

const AuthContext = React.createContext(initialValue);

export default AuthContext;
