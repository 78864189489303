import React from "react";

import { GenericFieldProps } from "./fieldProps";
import { numberField, NumberField } from "./numberField";
import Select from "react-select";
import { customStyles } from "components/form/multiSelectField";
import { CURRENCIES } from "pages/project/utils";

export type InvestmentFieldProps = GenericFieldProps<{
  investment?: number | string;
  investmentCurrency?: string;
}> &
  InvestmentFields;

type CurrencyType = {
  label: string;
  value: string;
};

type InvestmentFields = {
  investmentField: "privateReferenceProjectInvestment" | "investment";
  currencyField:
    | "privateReferenceProjectInvestmentCurrency"
    | "investmentCurrency";
};

export const InvestmentField = ({
  value,
  language,
  onChange,
  disabled,
  investmentField,
  currencyField,
}: InvestmentFieldProps) => {
  // This converts currencies-array of strings to array of objects. This is required for the select-field
  const currencyOptions: CurrencyType[] = CURRENCIES.map((currency) => ({
    label: currency,
    value: currency,
  }));

  const currentCurrency = {
    label: value?.[currencyField] ?? "CHF",
    value: value?.[currencyField] ?? "CHF",
  };

  return (
    <div className="flex space-x-2">
      <NumberField
        value={value?.[investmentField]}
        onChange={(v) => {
          onChange({ ...value, [investmentField]: v });
        }}
        language={language}
        disabled={disabled}
      />
      <div className="text-sm w-60">
        <Select
          styles={customStyles}
          options={currencyOptions}
          value={currentCurrency}
          isDisabled={disabled}
          onChange={(v) => {
            onChange({ ...value, [currencyField]: v?.value });
          }}
          isMulti={false}
        />
      </div>
    </div>
  );
};

export const investmentField = (isPrivateReference = false) => {
  const fields = isPrivateReference
    ? {
        investmentField: "privateReferenceProjectInvestment",
        currencyField: "privateReferenceProjectInvestmentCurrency",
      }
    : {
        investmentField: "investment",
        currencyField: "investmentCurrency",
      };
  const { investmentField, currencyField } = fields;
  return {
    renderer: (props) => <InvestmentField {...props} {...fields} />,
    onBeforeSave: (value) => {
      return {
        [investmentField]: numberField?.onBeforeSave?.(
          value?.[investmentField],
        ),
        [currencyField]: value?.[currencyField],
      };
    },
    equals: (a, b) => {
      return (
        a?.[investmentField] === b?.[investmentField] &&
        a?.[currencyField] === b?.[currencyField]
      );
    },
  };
};
