import React from "react";
import { useTranslation } from "react-i18next";
import { stages, getReleaseStage } from "helpers/getReleaseStage";

// @ts-ignore
import tbfLogo from "assets/images/tbf-logo.svg";

// MAIN COMPONENT

type Props = {
  loginState: string;
};

const LoginForm = ({ loginState }: Props): JSX.Element => {
  const { t } = useTranslation("login");

  // See https://learn.microsoft.com/en-us/graph/auth-v2-user
  const mainUrl =
    getReleaseStage() === stages.production_swarm
      ? "https://tbf.digital"
      : getReleaseStage() === stages.staging_swarm
        ? "https://stage.tbf.digital"
        : // dev
          "http://localhost:4000";

  const redirectUri = encodeURIComponent(`${mainUrl}/auth/callback`);

  return (
    <div className="flex mx-auto max-w-screen-tbf">
      <div className="mx-auto">
        <div className="flex flex-col items-center justify-center w-56 gap-y-5">
          <div className="py-10">
            <img src={tbfLogo} width="180" alt="TBF-Logo" />
          </div>
          <div className="w-56 p-5 mb-5 border rounded-md">
            {t("notice")}{" "}
            <a href="mailto:it_support@tbf.ch">it_support@tbf.ch</a>
          </div>
          <a
            href={`https://login.microsoftonline.com/a9da9e3c-a0c6-4f03-bb67-6af6ad7abac9/oauth2/v2.0/authorize?client_id=ec19cbf8-ae5d-4230-aa97-b5e4068c795a&response_type=code&redirect_uri=${redirectUri}&response_mode=query&scope=offline_access%20user.read`}
            className="w-full text-center block px-4 py-1  text-white bg-red-500 border border-red-500 rounded-md cursor-pointer hover:bg-gray-400 hover:border-gray-400"
          >
            {t("MSLogin")}
          </a>
          {
            // Visualize errors:
            ["LoginError", "MSError", "ExpiredError", "ServerError"].includes(
              loginState,
            ) ? (
              <div
                data-cy="error"
                className="w-56 p-2 mb-5 bg-red-200 border border-red-500 rounded-md"
              >
                {t(loginState as any)}
              </div>
            ) : null
          }
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
