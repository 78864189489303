import gql from "graphql-tag";

import { multiLangString } from "./common";

export const newReferenceShortFragment = gql`
  fragment NewReference_shortFields on NewReference {
    id
    employeeId
    projectId
    parentReferenceId
    from
    to
    roles {
      id
      referenceRoleId
      newText
      referenceRole {
        id
        name ${multiLangString}
      }
    }
    siaPhases
    hoaiPhases
    frPhases
    itPhases
    primaryPhase
    services
    pictureCredit
    picture {
      id
      url(width: 400, height: 107)
      urlWord: url(width: 1200, height: 321)
      urlFull: url(width: 0)
      focusX
      focusY
    }
    applicationAreas
    title ${multiLangString}
    description ${multiLangString}
    facts {
      id
      value ${multiLangString}
      newText
      keyId
      key {
        id
        name ${multiLangString}
      }
    }
    internalNote
    privateReferenceProjectClientName
    privateReferenceProjectName ${multiLangString}
    privateReferenceProjectDescription ${multiLangString}
    privateReferenceProjectInvestment
    privateReferenceProjectInvestmentCurrency
    privateReferenceProjectFrom
    privateReferenceProjectTo
    privateReferenceProjectSiaPhases
    privateReferenceProjectHoaiPhases
    privateReferenceProjectFrPhases
    privateReferenceProjectItPhases
    privateReferenceProjectPrimaryPhase
    privateReferenceProjectApplicationAreas
    privateReferenceProjectServices
    privateReferenceProjectCountry
    privateReferenceProjectFacts {
      id
      value ${multiLangString}
      newText
      keyId
      key {
        id
        name ${multiLangString}
      }
    }
  }
`;

export const newReferenceFragment = gql`
  ${newReferenceShortFragment}
  fragment NewReference_fields on NewReference {
    ...NewReference_shortFields
    employee {
      id
      fullName
    }
    modifiedBy { __typename }
    approvedAt
    deletedAt
    insertedAt
    connectedFields {
      field,
      isConnected
    }
    project {
      id
      abacusProjectId
      name ${multiLangString}
      client {
        id
        name
      }
      clientHidden
      investment
      investmentCurrency
      isUnlisted
      parentProjects { 
        id
        name ${multiLangString}
      }
    }
    childReferences {
      id
      title ${multiLangString}
      projectId
      employee {
        id
        fullName
      }
    }
    isReadyForReview
  }
`;

export const parentReferenceFragment = gql`
  fragment ParentReference_fields on NewReference {
    id
    applicationAreas
    siaPhases
    hoaiPhases
    frPhases
    itPhases
    primaryPhase
    services
    from
    to
    picture {
      id
      url
    }
    parentReference {
      id
      applicationAreas
      siaPhases
      hoaiPhases
      frPhases
      itPhases
      primaryPhase
      services
      from
      to
      title ${multiLangString}
      picture {
        id
        url
      }
    }
    title ${multiLangString}
  }
`;

export const projectReferenceFragment = gql`
  fragment ProjectReference_fields on NewReference {
    id
    title ${multiLangString}
    description ${multiLangString}
    projectId
  }
`;

export const employeeReferenceFragment = gql`
  fragment EmployeeReference_fields on NewReference {
    id
    title ${multiLangString}
    description ${multiLangString}
    project {
      id
      name ${multiLangString}
    }
  }
`;

export const EDIT_REFERENCE_QUERY = gql`
  ${newReferenceFragment}

  query Reference($id: ID!) {
    reference(id: $id) {
      ...NewReference_fields
      approvedEntityId
      project {
        id
        name ${multiLangString}
      }
      employee {
        id
        firstName
        lastName
      }
    }
  }
`;

export const CREATE_REFERENCE_MUTATION = gql`
  ${newReferenceFragment}

  mutation CreateNewReferenceMutation($fields: InputNewReference!) {
    createNewReference(fields: $fields) {
      ...NewReference_fields
    }
  }
`;

export const EDIT_REFERENCE_MUTATION = gql`
  ${newReferenceFragment}

  mutation EditNewReferenceMutation($id: ID!, $fields: InputNewReference!) {
    editNewReference(id: $id, fields: $fields) {
      ...NewReference_fields
      draft {
        ...NewReference_fields
      }
    }
  }
`;

export const EDIT_AND_APPROVE_REFERENCE_MUTATION = gql`
  ${newReferenceFragment}

  mutation EditAndApproveNewReferenceMutation(
    $id: ID!
    $fields: InputNewReference!
  ) {
    editAndApproveNewReference(id: $id, fields: $fields) {
      ...NewReference_fields
    }
  }
`;

export const DELETE_REFERENCE_MUTATION = gql`
  ${newReferenceFragment}

  mutation DeleteNewReferenceMutation($id: ID!) {
    deleteNewReference(id: $id) {
      ...NewReference_fields
      draft {
        ...NewReference_fields
      }
    }
  }
`;

export const ONEPAGER_STARRED_REFERENCE_QUERY = gql`
  query StarredReference {
    me {
      id
      starredReferences {
        id
        referenceId
      }
    }
  }
`;

export const ADD_STARRED_REFERENCE_MUTATION = gql`
  ${newReferenceFragment}

  mutation AddStarredNewReferenceMutation($id: ID!) {
    addStarredReference(id: $id) {
      id
      reference {
        ...NewReference_fields
        isStarredForCurrentUser
      }
    }
  }
`;

export const REMOVE_STARRED_REFERENCE_MUTATION = gql`
  ${newReferenceFragment}

  mutation RemoveStarredNewReferenceMutation($id: ID!) {
    removeStarredReference(id: $id) {
      id
      reference {
        ...NewReference_fields
        isStarredForCurrentUser
      }
    }
  }
`;

export const ALL_PROJECT_REFERENCE_QUERY = gql`
  query allProjectNewReferenceQuery {
    allProjectReferences {
      id
      title ${multiLangString}
      projectId
      project {
        id
        abacusProjectId
        name ${multiLangString}
      }
    }
  }
`;

export const ONEPAGER_REFERENCE_QUERY = gql`
  ${newReferenceShortFragment}

  query Reference($id: ID!) {
    reference(id: $id) {
      ...NewReference_shortFields
      childReferences {
        id
        employeeId
        employee {
          id
          fullName
          roles {
            id
            role {
              id
              name ${multiLangString}
            }
          }
        }
      }
    }
  }
`;
