import gql from "graphql-tag";
import { multiLangString } from "./common";

export const teamMemberFragment = gql`
  fragment teamMember_fields on ProjectTeamMember {
    id
    employeeId
    employee {
      id
      firstName
      lastName
      fullName
      activeDirectoryId
      isFormerEmployee
      profilePicture(size: 96)
    }
    projectId
    project {
      name ${multiLangString}
    }
    hours
    role
    connectedFields {
      field
      isConnected
    }
  }
`;

export const aggregatedTeamMemberFragment = gql`
  fragment aggregatedTeamMemberFragment on AggregatedProjectTeamMember {
    id
    employeeId
  }
`;

export const teamMemberDraftFragment = gql`
  ${teamMemberFragment}

  fragment teamMemberDraft_fields on ProjectTeamMember {
    approvedBy {
      __typename
    }
    approvedAt
    deletedAt
    draft {
      ...teamMember_fields
      modifiedBy {
        __typename
      }
      approvedAt
      deletedAt
    }
  }
`;

export const EDIT_TEAM_MEMBER_MUTATION = gql`
  ${teamMemberFragment}
  ${teamMemberDraftFragment}

  mutation EditProjectTeamMemberMutation(
    $id: ID!
    $fields: InputProjectTeamMember!
  ) {
    editProjectTeamMember(id: $id, fields: $fields) {
      ...teamMember_fields
      ...teamMemberDraft_fields
    }
  }
`;
