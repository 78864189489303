import React, { useEffect, useState } from "react";
import { Node, mergeAttributes } from "@tiptap/core";
import { ReactNodeViewRenderer, NodeViewWrapper } from "@tiptap/react";
import { FILE_ATTACHMENT_QUERY } from "queries/fileAttachment";
import { useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useMultiLang } from "helpers/multiLang";
import { FileAttachment } from "generated/graphql";
import { DropdownButton } from "components/dropdownButton";
import { Popup } from "components/popup";
import { FaFileDownload, FaSpinner } from "react-icons/fa";
import { EditAttachmentModal } from "components/editVersioned/editAttachment";

export const WikiAttachment = Node.create({
  name: "wikiAttachment",

  // https://prosemirror.net/docs/ref/#model.NodeSpec
  group: "inline",
  inline: true,
  selectable: false,
  atom: true,

  addAttributes() {
    return {
      attachmentId: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "wiki-attachment",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["wiki-attachment", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(TiptapWrapper);
  },

  addCommands(): any {
    return {
      insertWikiAttachment:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },
    };
  },
});

const TiptapWrapper = (props) => {
  const attachment = props.node.attrs.attachmentId
    ? { id: props.node.attrs.attachmentId }
    : undefined;
  const setAttachment = ({ id }) => {
    props.updateAttributes({
      attachmentId: id,
    });
  };

  return (
    <NodeViewWrapper className="inline-block mx-1">
      <SingleFileUploadComponent
        attachment={attachment}
        setAttachment={setAttachment}
        readOnly={!props.editor.isEditable}
      />
    </NodeViewWrapper>
  );
};

type WikiAttachmentAttributes = {
  id: string;
};

const SingleFileUploadComponent = ({
  attachment,
  setAttachment,
  readOnly,
}: {
  attachment?: WikiAttachmentAttributes;
  setAttachment: (attachment: WikiAttachmentAttributes) => void;
  readOnly: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [getAttachment, { data, loading }] = useLazyQuery(
    FILE_ATTACHMENT_QUERY,
  );
  const { t } = useTranslation(["wiki", "mainLanguages"]);
  const m = useMultiLang();
  useEffect(() => {
    if (attachment?.id) {
      getAttachment({ variables: { id: attachment.id } });
    }
  }, [attachment?.id]);

  const file = data?.fileAttachment as Partial<FileAttachment> | undefined;

  const files = [
    { file: file?.fileDe, lang: `${t("mainLanguages:de")}` },
    { file: file?.fileFr, lang: `${t("mainLanguages:fr")}` },
    { file: file?.fileIt, lang: `${t("mainLanguages:it")}` },
    { file: file?.fileEn, lang: `${t("mainLanguages:en")}` },
  ].filter(({ file }) => file);

  const download = (language) => {
    const fileToDownload = language
      ? files.filter(({ lang }) => lang == language)[0]?.file
      : files[0]?.file;
    if (fileToDownload?.url) {
      downloadUrl(fileToDownload.url);
    }
  };

  const dropdownOptions = files.map(({ lang }) => ({
    key: lang,
    value: lang,
    text: lang,
    onClick() {
      download(lang);
    },
  }));

  return (
    <>
      <DropdownButton
        disabled={loading}
        onClick={() => {
          if (readOnly) {
            download(null);
          } else {
            setIsOpen(true);
          }
        }}
        options={dropdownOptions}
        isEditing={!readOnly}
      >
        {readOnly ? (
          <Popup content={files[0]?.file?.filename}>
            <div className="flex items-center">
              {loading ? (
                <FaSpinner className="animate-spin" />
              ) : (
                <FaFileDownload />
              )}
              <span className="ml-2">
                {m(file?.name, files?.[0]?.file?.filename ?? "")}
              </span>
            </div>
          </Popup>
        ) : (
          <div className="flex items-center ">
            {file?.name || files[0]?.file?.filename
              ? t("wiki:attachments.edit", {
                  name: m(file?.name)
                    ? m(file?.name)
                    : files[0]?.file?.filename,
                })
              : t("wiki:attachments.upload")}
          </div>
        )}
      </DropdownButton>
      <EditAttachmentModal
        id={file ? file.id : "new"}
        open={isOpen}
        close={() => setIsOpen(false)}
        newEntity={file ? undefined : {}}
        onSave={(id) => {
          if (id) {
            setAttachment({ id });
          }
        }}
      />
    </>
  );
};

// https://stackoverflow.com/a/9834261
const downloadUrl = (url: string) => {
  const a = document.createElement("a");
  a.href = url;
  a.target = "_blank";

  document.body.appendChild(a);
  a.click();

  // Delay revoking the object, this prevents some crazy edge conditions on some browsers
  setTimeout(() => {
    document.body.removeChild(a);
  }, 0);
};
