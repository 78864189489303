import { FormDefinition } from "components/form";
import { JokerBlock } from "generated/graphql";
import { editJockerBlockModal } from "pages/onepager/editJokerBlock";
import { extractMulti } from "helpers/ui/multiLang";
import { ReducedEditor } from "pages/onepager/helpers/reducedEditor";
import { textField } from "components/form/textField";
import { previewText } from "../helpers/previewText";
import {
  pictureFieldWithCaption,
  PictureFieldWithCaption,
} from "components/form/blockPictureFieldWithCaption";
import { linksField } from "components/form/linksField";

const elementsDefinition = {
  richtext: {
    renderer: ReducedEditor,
    multiLang: true,
    getData: (data) => data?.data,
    setData: (_data, value) => ({ data: value }),
    onBeforeElementSave: (data) => ({ data: extractMulti(data?.data) }),
  },
  picture: {
    renderer: PictureFieldWithCaption,
    getData: (data) => data,
    setData: (_data, value) => value,
    onBeforeElementSave: (data) =>
      pictureFieldWithCaption?.onBeforeSave?.(data) ?? {},
  },
};

const formDefinition: FormDefinition<JokerBlock> = {
  title: () => "",
  titleString: () => "",
  previewText: (block) => {
    return previewText(block.navigationTitle?.de ?? "");
  },
  fields: {
    navigationTitle: {
      name: "onepager.joker.navigationTitle",
      component: textField,
      multiLang: true,
      required: true,
    },
    elements: {
      component: {
        onBeforeSave: (elements) => {
          return (elements ?? []).map((element) => {
            const { type, __typename, id, ...rest } = element;
            const onBeforeElementSave =
              elementsDefinition[type]?.onBeforeElementSave ?? ((a) => a);

            return { type, [type]: onBeforeElementSave(rest) };
          });
        },
      },
    },
    layout: {
      component: {
        onBeforeSave: (layout) => {
          return (layout ?? []).map((l) => {
            const { __typename, ...rest } = l;
            return rest;
          });
        },
      },
    },
    externalLinks: {
      name: "onepager.introBlock.externalLinks",
      component: linksField,
    },
  } as any,
  link: () => "",
};

export const editJoker = {
  formDefinition,
  modal: editJockerBlockModal(elementsDefinition),
};
