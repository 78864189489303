import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

import { MdChecklist } from "react-icons/md";

import { INDEX_FILE_MUTATION } from "queries/indexFile";

import Layout from "layout/layout";
import { IconButton } from "components/button";
import { FilePathInput } from "pages/fileShare";

const IndexFileForm = ({
  onSubmit,
  docPath,
  setDocPath,
  error,
  setError,
}: any) => {
  const { t } = useTranslation(["fileIndexer", "fileShare"]);

  return (
    <form onSubmit={onSubmit}>
      <FilePathInput path={docPath} setPath={setDocPath} setError={setError} />

      {error ? (
        <div className="px-5 py-3 mt-4 bg-red-200 border border-red-300 rounded bg-opacity-5">
          <h2 className="text-xl font-medium">{t("fileShare:errorHeader")}</h2>
          <p>{t("fileShare:error")}</p>
        </div>
      ) : null}

      <div className="flex flex-row items-center justify-end">
        <IconButton Icon={MdChecklist} submit className="my-4">
          {t("fileIndexer:index")}
        </IconButton>
      </div>
    </form>
  );
};

export const ManualFileIndexer = (): JSX.Element => {
  const { t } = useTranslation("fileIndexer");

  const [error, setError] = useState<any>(null);
  const [docPath, setDocPath] = useState("");

  const [indexFile] = useMutation(INDEX_FILE_MUTATION);

  const onSubmit = (event: any) => {
    event.preventDefault();

    indexFile({
      variables: { path: docPath },
    })
      .then(() => {
        setError(null);
        toast.success(t("success"));
      })
      .catch(() => {
        setError("err");
      });
  };

  return (
    <>
      <Layout title={t("title")} noPadding />

      <div className="w-full mb-8 border border-b-gray-300" />
      <div>
        <div className="px-3 mx-auto text-gray-800 max-w-screen-tbf">
          <IndexFileForm
            onSubmit={onSubmit}
            docPath={docPath}
            setDocPath={setDocPath}
            error={error}
            setError={setError}
          />
        </div>
      </div>
    </>
  );
};
