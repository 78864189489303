import React from "react";
import { GenericFieldProps } from "./fieldProps";
import {
  RENAME_SLUG_FOR_WIKI_PAGE_WITH_SUB_PAGES_MUTATION,
  WIKI_SUB_PAGE_RECURSIVE_QUERY,
} from "queries/wiki";

import { useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useMultiLang } from "helpers/multiLang";
import { IconButton } from "components/button";
import { WikiPage } from "generated/graphql";
import { IoMdClose } from "react-icons/io";
import { Modal } from "components/modal";
import { useState } from "react";
import { Link, useLocation } from "wouter";
import { MdEdit } from "react-icons/md";
import { TextField } from "./textField";
import { slugify } from "./slugField";

const RenameSlugField = ({
  entity,
  closeModal,
}: GenericFieldProps<void, WikiPage>) => {
  const { t } = useTranslation("wiki");
  const [_location, navigate] = useLocation();
  const [renameSlug] = useMutation(
    RENAME_SLUG_FOR_WIKI_PAGE_WITH_SUB_PAGES_MUTATION,
  );

  const [renameSlugOpen, setRenameSlugOpen] = useState(false);

  return (
    <>
      <div className="flex flex-row items-end">
        <span className="flex-1">{`/wiki/${
          entity?.slugs?.join("/") ?? ""
        }`}</span>
        <IconButton
          type="soft-warning"
          Icon={MdEdit}
          onClick={() => {
            setRenameSlugOpen(true);
          }}
        >
          {t("performRename")}
        </IconButton>
      </div>
      <RenameSlugModal
        isOpen={renameSlugOpen}
        setIsOpen={setRenameSlugOpen}
        entity={entity}
        performRename={(newSlug) => {
          renameSlug({ variables: { id: entity?.id, newSlug } }).then((res) => {
            const slugs =
              res.data?.renameSlugForWikiPageWithSubPages?.slugs ?? [];
            navigate(`/wiki/${slugs.join("/")}`);
            closeModal?.();
          });
        }}
      />
    </>
  );
};

const RenameSlugModal = ({ isOpen, setIsOpen, entity, performRename }) => {
  const { t } = useTranslation(["wiki", "common"]);
  const currentSlug = entity?.slugs?.[entity?.slugs?.length - 1];
  const [newSlug, setNewSlug] = useState(currentSlug);
  const { data } = useQuery(WIKI_SUB_PAGE_RECURSIVE_QUERY, {
    variables: { id: entity.id },
  });
  return (
    <Modal
      size="small"
      title={
        <div className="px-6 py-8 text-2xl font-medium text-red-500">
          {t("wiki:confirmRename")}
        </div>
      }
      open={isOpen}
      close={() => setIsOpen(false)}
      actions={
        <div className="flex flex-row space-x-2">
          <IconButton
            type="secondary"
            Icon={IoMdClose}
            onClick={() => setIsOpen(false)}
          >
            {t("common:cancel")}
          </IconButton>
          <IconButton
            type="soft-warning"
            Icon={MdEdit}
            onClick={() => {
              performRename(newSlug);
              setIsOpen(false);
            }}
          >
            {t("wiki:performRename")}
          </IconButton>
        </div>
      }
    >
      <div className="flex flex-row items-center px-6 my-8 space-x-1">
        {entity.slugs ? (
          <span>
            /wiki/{entity.slugs.slice(0, entity.slugs.length - 1).join("/")}/
          </span>
        ) : null}
        <TextField
          value={newSlug}
          onChange={(v) => {
            setNewSlug(slugify(v));
          }}
        />
      </div>
      <div className="px-6 my-8">{t("wiki:confirmRenameText")}</div>
      {data ? <ListPages page={data.wikiPage} newSlug={newSlug} /> : null}
    </Modal>
  );
};

const ListPages = ({
  page,
  newSlug,
}: {
  page: Partial<WikiPage>;
  newSlug: string;
}) => {
  const m = useMultiLang();
  const slugIndex = (page.slugs ?? []).length - 1;
  return (
    <div className="px-6 mb-8 space-y-2">
      {[page, ...(page.subPagesRecursive ?? [])].map((p) => {
        const slugs = p.slugs ?? [];
        const link = `/wiki/${slugs.join("/")}`;
        const newSlugs = [
          ...slugs.slice(0, slugIndex),
          newSlug,
          ...slugs.slice(slugIndex + 1),
        ];
        const newLink = `/wiki/${newSlugs.join("/")}`;
        return (
          <div key={p.id} className="m-2">
            <div className="font-semibold">{m(p.title)}</div>
            <Link href={link}>{link}</Link>
            <span>
              {" -> "}
              {newLink}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export const renameSlugField = {
  renderer: RenameSlugField,
};
