import React, { useEffect, useRef } from "react";
import { FocusedImage } from "image-focus/dist/index";

const FocusedImageComponent = ({ imageSrc, x, y }) => {
  const imageRef = useRef(null);

  useEffect(() => {
    if (imageRef.current !== null) {
      new FocusedImage(imageRef.current, { focus: { x, y } });
    }
  }, [x, y]);

  return (
    <img
      className="max-w-none"
      style={{ transition: "top 0.25s ease-in-out, left 0.25s ease-in-out" }}
      alt="Focused"
      ref={imageRef}
      src={imageSrc}
    />
  );
};

export default FocusedImageComponent;
