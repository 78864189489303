import { FormDefinition, virtualField } from "components/form";
import { OutroBlock } from "generated/graphql";
import { markDownField } from "components/form/markDownField";
import { editBlockModal } from "../editBlock";
import { linksField } from "components/form/linksField";
import { textField } from "components/form/textField";
import { previewText } from "../helpers/previewText";

import { ONEPAGER_EMPLOYEE_QUERY } from "queries/newEmployee";
import { employeesField } from "components/form/employeesField";

const fetchEmployeesDetail = async (apolloClient, employeeId) => {
  const employee = await apolloClient.query({
    query: ONEPAGER_EMPLOYEE_QUERY,
    variables: {
      id: employeeId,
    },
    fetchPolicy: "network-only",
  });
  return employee?.data?.employee;
};

const formDefinition: FormDefinition<OutroBlock> = {
  title: () => "",
  previewText: (block) => {
    return previewText(block.navigationTitle?.de ?? "");
  },
  titleString: () => "",
  fields: {
    navigationTitle: {
      name: "onepager.outroBlock.navigationTitle",
      component: textField,
      multiLang: true,
      required: true,
    },
    trustedAdvisorId: {
      name: "onepager.outroBlock.trustedAdvisor",
      component: employeesField,
    },
    message: {
      name: "onepager.outroBlock.message",
      component: markDownField,
      props: {
        minimal: true,
      },
      multiLang: true,
    },
    externalLinks: {
      name: "onepager.outroBlock.externalLinks",
      component: linksField,
      hidableFromExport: true,
    },
    linkedFields: virtualField,
    exportedFields: virtualField,
  },
  dataDependencies: [
    {
      fetchFunction: fetchEmployeesDetail,
      key: "employeeId",
    },
  ],
  link: () => "",
};

export const editOutro = {
  formDefinition,
  modal: editBlockModal(formDefinition),
};
