import React, { useState } from "react";
import { useLocation, Link } from "wouter";

import useOnlineStatus from "helpers/useOnlineStatus";

import { FiSearch } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { useTitle } from "helpers/useTitle";

// TODO: As a way to trigger search, we use the navigation feature.
// This could be optimised now because we use this component only in the search page now.

export const SearchBox = (): JSX.Element => {
  const isOnline = useOnlineStatus();
  const [_location, navigate] = useLocation();
  const params = new URLSearchParams(location.search);
  const [searchText, setSearchText] = useState(params.get("q") || "");

  useTitle(`${searchText.length >= 1 ? searchText + " - " : ""} TBF TeamHub`);

  const searchOpsIcons = searchText ? (
    <div>
      <IoClose
        onClick={() => {
          setSearchText("");
        }}
        className="cursor-pointer hover:text-gray-500"
      />
    </div>
  ) : (
    <Link href="/results">
      <FiSearch className="text-gray-900 cursor-pointer hover:text-blue-500" />
    </Link>
  );

  return (
    <div className="w-full rounded border border-gray-300 shadow-sm">
      <div className="flex flex-row justify-between items-center px-3 py-1">
        <input
          type="text"
          autoFocus={true}
          placeholder={"Search..."}
          className="w-5/6 h-7 border-none outline-none"
          onChange={(e) => {
            setSearchText(e.currentTarget.value);
            navigate(`/results?q=${e.target.value}`);
          }}
          value={searchText}
          disabled={!isOnline}
        />
        <div className="flex flex-row gap-x-2">{searchOpsIcons}</div>
      </div>
    </div>
  );
};
