import React, { useEffect, useState } from "react";

import { GenericFieldProps } from "./fieldProps";
import { YearField } from "./yearField";
import { useTranslation } from "react-i18next";

export type FromToFieldProps = GenericFieldProps<{
  from?: string;
  privateReferenceProjectFrom?: string;
  to?: string;
  privateReferenceProjectTo?: string;
}> &
  FromToFields;

type FromToFields = {
  fromField: "from" | "privateReferenceProjectFrom";
  toField: "to" | "privateReferenceProjectTo";
};

export const FromToField = ({
  value,
  fromField,
  toField,
  language,
  onChange,
  disabled,
}: FromToFieldProps) => {
  const { t } = useTranslation("common");
  const [invertedDateWarning, setInvertedDateWarning] = useState(false);
  const startDate = parseInt(value.from);
  const endDate = parseInt(value.to);
  useEffect(() => {
    if (startDate > endDate) {
      setInvertedDateWarning(true);
    } else {
      setInvertedDateWarning(false);
    }
  }, [startDate, endDate]);
  return (
    <div className="flex flex-col">
      <div className="flex space-x-2">
        <YearField
          value={value?.[fromField] ?? null}
          placeholder={t("from")}
          onChange={(v) => {
            onChange({ ...value, [fromField]: v ?? undefined });
          }}
          language={language}
          disabled={disabled}
        />
        <YearField
          value={value?.[toField] ?? null}
          placeholder={t("to")}
          onChange={(v) => {
            onChange({ ...value, [toField]: v ?? undefined });
          }}
          language={language}
          disabled={disabled}
        />
      </div>
      {invertedDateWarning && (
        <div className="text-xs text-red-500 whitespace-pre-line pt-2">
          {t("invertedDateWarning")}
        </div>
      )}
    </div>
  );
};

export const fromToField = (isPrivateReference = false) => {
  const fields = isPrivateReference
    ? {
        fromField: "privateReferenceProjectFrom",
        toField: "privateReferenceProjectTo",
      }
    : { fromField: "from", toField: "to" };
  const { fromField, toField } = fields;
  return {
    renderer: (props) => <FromToField {...props} {...fields} />,
    onBeforeSave: (value) => {
      return {
        [fromField]: value?.[fromField] ?? null,
        [toField]: value?.[toField] ?? null,
      };
    },
    equals: (a, b) => {
      return a?.[fromField] === b?.[fromField] && a?.[toField] === b?.[toField];
    },
  };
};
