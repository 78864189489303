import React, { useState } from "react";
import { Link } from "wouter";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

import { FaSpinner, FaCopy, FaExternalLinkAlt } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { ImCopy } from "react-icons/im";
import { copyToClipboard } from "helpers/clipboard";

import { Onepager, PublicationStatus, Employee } from "generated/graphql";
import { IconButton } from "components/button";
import { frontendUrl } from "../publications";

const Section = ({ title, content }) => {
  return (
    <div className="px-3 py-4 border-b border-gray-300 sm:pl-4 sm:pr-0">
      <h2 className="pb-4 text-sm font-medium text-gray-400 uppercase">
        {title}
      </h2>
      {content}
    </div>
  );
};

type EditingSectionProps = {
  onepager: Partial<Onepager>;
  setShowEditAttributeModal: (flag: boolean) => void;
};

const EditingSection = ({
  onepager,
  setShowEditAttributeModal,
}: EditingSectionProps): JSX.Element => {
  const { t } = useTranslation(["onepager", "common"]);

  const currentPublication = onepager?.publication;

  return (
    <>
      <Section
        title={t("common:edit")}
        content={
          <IconButton
            Icon={FiSettings}
            onClick={() => setShowEditAttributeModal(true)}
          >
            {t("onepager:settings")}
          </IconButton>
        }
      />

      {currentPublication?.status !== "PUBLISHED" ? (
        <Section
          title={"Aktueller Entwurf"}
          content={
            <IconButton
              Icon={FaExternalLinkAlt}
              onClick={() => {
                const previewId = onepager.id;
                const url = `/onepagers/preview/${previewId}`;
                window.open(url, "_blank")?.focus();
              }}
            >
              {t("common:preview")}
            </IconButton>
          }
        />
      ) : null}
    </>
  );
};

type PublishingSectionProps = {
  onepager: Partial<Onepager>;
  saving: boolean;
  setSaving: (newValue: boolean) => void;
  publish: (id: string) => Promise<Record<string, any>>;
  setShowPublications: (flag: boolean) => void;
};

const PublishingSection = ({
  onepager,
  saving,
  setSaving,
  publish,
  setShowPublications,
}: PublishingSectionProps): JSX.Element => {
  const { t } = useTranslation("onepager");

  const currentPublication = onepager?.publication;
  const previousPublication = onepager?.previousPublication;

  const modifiedById = (onepager.modifiedBy as Employee)?.activeDirectoryId;
  const modifiedByName = (onepager.modifiedBy as Employee)?.fullName;

  const formatDate = (date) => {
    return dayjs(`${date}Z`).format("DD.MM.YYYY — H:mm");
  };

  return (
    <>
      <Section
        title={t("publishVersion")}
        content={
          currentPublication?.status === "PUBLISHED" ? (
            <IconButton Icon={FaExternalLinkAlt} disabled={true}>
              {t("publish")}
            </IconButton>
          ) : (
            <>
              <IconButton
                Icon={
                  saving
                    ? () => <FaSpinner className="animate-spin" />
                    : FaExternalLinkAlt
                }
                disabled={saving}
                onClick={() => {
                  setSaving(true);
                  publish(onepager.id!)
                    .finally(() => {
                      setSaving(false);
                    })
                    .catch((_e) => {
                      toast.error(t("errorPublishing"));
                    });
                }}
              >
                {t("publish")}
              </IconButton>

              <div className="pt-4 text-sm text-gray-600">
                <span className="font-medium">{t("note")}</span>
                {saving
                  ? t("slowPublishingText")
                  : (currentPublication?.status as PublicationStatus) !==
                        "PUBLISHED" &&
                      (previousPublication?.status as PublicationStatus) !==
                        "PUBLISHED"
                    ? t("noVersionPublished")
                    : previousPublication?.status === "PUBLISHED"
                      ? t("oldVersionPublished")
                      : ""}
              </div>
            </>
          )
        }
      />
      {currentPublication?.status === "PUBLISHED" ? (
        <Section
          title={t("copyLiveLink")}
          content={
            <>
              <div className="pb-4 text-sm text-gray-600">
                {t("currentVersionModifiedBy")}
                <span className="cursor-pointer font-medium">
                  <Link href={`/user/${modifiedById}`}>{modifiedByName}</Link>
                </span>
                {t("lastModifiedAt", {
                  time: formatDate(
                    onepager.publication?.updatedAt ?? onepager.updatedAt,
                  ),
                })}
              </div>
              <IconButton
                Icon={FaCopy}
                onClick={() => {
                  const url = frontendUrl({ token: currentPublication?.token });
                  copyToClipboard(url);
                  toast.success(t("publication.linkCopied"));
                }}
              >
                {t("copyLiveLink")}
              </IconButton>
            </>
          }
        />
      ) : null}
      <Section
        title={t("versionHistory")}
        content={
          <>
            <IconButton Icon={ImCopy} onClick={() => setShowPublications(true)}>
              {t("versions")}
            </IconButton>
            <div className="pt-4 text-sm text-gray-600">
              <div className="font-medium">
                {t("numVersions", { num: onepager?.numSnapshots ?? 0 })}
              </div>
              <div>
                {t("lastModifiedBy")}

                <span className="font-medium">
                  <Link href={`/user/${modifiedById}`}>{modifiedByName}</Link>
                </span>

                {t("lastModifiedAt", { time: formatDate(onepager.updatedAt) })}
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

type SidebarProps = {
  onepager: Partial<Onepager>;
  publish: (id: string) => Promise<Record<string, any>>;
  setShowEditAttributeModal: (flag: boolean) => void;
  setShowPublications: (flag: boolean) => void;
};

export const Sidebar = ({
  onepager,
  publish,
  setShowEditAttributeModal,
  setShowPublications,
}: SidebarProps): JSX.Element => {
  const [saving, setSaving] = useState(false);

  return (
    <div
      className="flex flex-col justify-between sm:border-l sm:w-1/4 sm:h-full sm:sticky sm:top-44"
      style={{ height: "calc(100vh - 12rem)" }}
    >
      <div>
        {/* Editing section */}
        <EditingSection
          onepager={onepager}
          setShowEditAttributeModal={setShowEditAttributeModal}
        />
      </div>
      <div>
        {/* Publishing section */}
        <PublishingSection
          onepager={onepager}
          saving={saving}
          setSaving={setSaving}
          publish={publish}
          setShowPublications={setShowPublications}
        />
      </div>
    </div>
  );
};
