import React, { useEffect } from "react";
import { Router, Route, Redirect, Switch } from "wouter";
import { useTranslation } from "react-i18next";

import Layout from "layout/layout";
import { ResultsPage } from "./search";
import NewEmployee from "./newEmployee";
import NewProject from "./newProject";
import NewClient from "./newClient";
import FileShare from "./fileShare/index";
import ManageDataShell from "./manageData";
import { Wiki } from "./wiki";
import { Onepagers } from "./onepager";
import { OnepagerDetail } from "./onepager/detail";
import { OnepagerPreview } from "./onepager/preview";
import { ManualFileIndexer } from "./manualFileIndexer";
import { stages } from "helpers/getReleaseStage";
import { getReleaseStage } from "helpers/getReleaseStage";

// HELPER COMPONENTS

const PageNotFound = () => {
  const { t } = useTranslation();
  return <Layout title={t("pageNotFound")} />;
};

// MAIN COMPONENT

const EndpointRoutes = () => {
  return (
    <Router>
      <Switch>
        <Route path="/">
          {getReleaseStage() == stages.other ? (
            <Redirect to="/results" />
          ) : (
            <RedirectExternal url="https://next.tbf.digital" />
          )}
        </Route>
        <Route path="/posts">
          <RedirectExternal url="https://next.tbf.digital/posts" />
        </Route>
        <Route path="/results">
          <ResultsPage />
        </Route>
        <Route path={"/posts/:id"}>
          {(params) => (
            <RedirectExternal
              url={`https://next.tbf.digital/posts/${params.id}`}
            />
          )}
        </Route>
        <Route path="/fileshare">
          <FileShare />
        </Route>
        <Route path="/reference-data">
          <Redirect to="/manage-data/reference-data" />
        </Route>
        <Route path="/new/manage-data/:tab?">
          {(params) => <Redirect to={`/manage-data/${params.tab ?? ""}`} />}
        </Route>
        <Route path="/drafts">
          <Redirect to="/manage-data/drafts" />
        </Route>
        <Route path="/drafts/:language/:filter?">
          <Redirect to="/manage-data/drafts" />
        </Route>
        <Route path="/manage-data/:tab?">
          {(params) => <ManageDataShell tab={params.tab} />}
        </Route>
        <Route path="/admin/file-indexer">
          <ManualFileIndexer />
        </Route>
        {/* --- */}

        <Route path={"/employees/byEmployeeNumber/:employeeNumber/:tab?"}>
          {(params) => (
            <Redirect
              to={`/employee/byEmployeeNumber/${params.employeeNumber}`}
            />
          )}
        </Route>
        <Route path={"/employees/:abacusId/:tab?"}>
          {(params) => (
            <Redirect to={`/employee/byAbacusId/${params.abacusId}`} />
          )}
        </Route>
        <Route path={"/new/employees/:id/:tab?"}>
          {(params) => (
            <Redirect to={`/employee/${params.id}/${params.tab ?? ""}`} />
          )}
        </Route>
        <Route path={`/employee/byAbacusId/:abacusId/:tab?`}>
          {(params) => (
            <NewEmployee abacusId={params.abacusId} tab={params.tab} />
          )}
        </Route>
        <Route path={"/employee/byEmployeeNumber/:employeeNumber/:tab?"}>
          {(params) => (
            <NewEmployee
              employeeNumber={params.employeeNumber}
              tab={params.tab}
            />
          )}
        </Route>
        <Route path={"/employee/:id/:tab?"}>
          {(params) => <NewEmployee id={params.id} tab={params.tab} />}
        </Route>

        {/* - */}

        <Route path={"/users/:username/:tab?"}>
          {(params) => <Redirect to={`/user/${params.username}`} />}
        </Route>
        <Route path={"/new/users/:activeDirectoryId/:tab?"}>
          {(params) => (
            <Redirect
              to={`/user/${params.activeDirectoryId}/${params.tab ?? ""}`}
            />
          )}
        </Route>
        <Route path={"/user/:activeDirectoryId/:tab?"}>
          {(params) => (
            <NewEmployee
              activeDirectoryId={params.activeDirectoryId}
              tab={params.tab}
            />
          )}
        </Route>

        {/* ---- */}

        <Route path={"/clients/:abacusId/:tab?"}>
          {(params) => (
            <Redirect to={`/client/byAbacusId/${params.abacusId}`} />
          )}
        </Route>
        <Route path={"/suppliers/:abacusId/:tab?"}>
          {(params) => (
            <Redirect to={`/client/byAbacusId/${params.abacusId}`} />
          )}
        </Route>
        <Route path={"/contacts/:abacusId/:tab?"}>
          {(params) => (
            <Redirect to={`/client/byAbacusId/${params.abacusId}`} />
          )}
        </Route>
        <Route path={"/new/clients/:id/:tab?"}>
          {(params) => <Redirect to={`/client/${params.id}/${params.tab}`} />}
        </Route>
        <Route path={"/client/byAbacusId/:abacusId/:tab?"}>
          {(params) => (
            <NewClient abacusId={params.abacusId} tab={params.tab} />
          )}
        </Route>
        <Route path={"/client/:id/:tab?"}>
          {(params) => <NewClient id={params.id} tab={params.tab} />}
        </Route>

        {/* --- */}

        <Route path={"/projects/:accountingId/:tab?"}>
          {(params) => (
            <Redirect to={`/project/byAccountingId/${params.accountingId}`} />
          )}
        </Route>
        <Route path={"/archived-projects/:id/:tab?"}>
          {(params) => (
            <Redirect to={`/project/byArchivedProjectId/${params.id}`} />
          )}
        </Route>
        <Route path={"/new/projects/:id/:tab?"}>
          {(params) => <Redirect to={`/project/${params.id}/${params.tab}`} />}
        </Route>
        <Route path={"/project/byAccountingId/:accountingId/:tab?"}>
          {(params) => (
            <NewProject accountingId={params.accountingId} tab={params.tab} />
          )}
        </Route>
        <Route path={"/project/byArchivedProjectId/:archivedProjectId/:tab?"}>
          {(params) => (
            <NewProject
              archivedProjectId={params.archivedProjectId}
              tab={params.tab}
            />
          )}
        </Route>

        <Route path={"/project/:id/:tab?"}>
          {(params) => <NewProject id={params.id} tab={params.tab} />}
        </Route>

        {/* --- */}
        <Route path="/wiki/">
          <Wiki slugsPath={"/"} />
        </Route>

        <Route path={"/wiki/byId/:id"}>
          {(params) => <Wiki id={params.id} />}
        </Route>

        <Route path="/wiki/*">
          {(params) => <Wiki slugsPath={params["*"]} />}
        </Route>

        {/* --- Acquisition tool --- */}

        <Route path="/onepagers/edit/:id">
          {(params) => <OnepagerDetail id={params.id} />}
        </Route>
        <Route path="/onepagers/preview/:id">
          {(params) => <OnepagerPreview id={params.id} />}
        </Route>
        <Route path="/onepagers/:tab?">
          {(params) => <Onepagers tab={params.tab} />}
        </Route>

        {/* Feature toggles: */}
        <Route path="/activate-feature/:featureId">
          {(params) => (
            <ActivateFeature activate featureId={params.featureId} />
          )}
        </Route>
        <Route path="/deactivate-features">
          <ActivateFeature />
        </Route>
        <Route path="/set-language/:lang">
          {(params) => <SetLanguage language={params.lang} />}
        </Route>
        {/* --- */}
        <Route path="/:rest*">
          <PageNotFound />
        </Route>
      </Switch>
    </Router>
  );
};

const ActivateFeature = ({ activate = false, featureId = "" }) => {
  useEffect(() => {
    console.log((activate ? "" : "de") + "activate feature flag", featureId);
    window.sessionStorage.setItem("features", activate ? featureId : "");
    window.history.back();
  }, []);
  return null;
};

const SetLanguage = ({ language }) => {
  const { i18n } = useTranslation();
  useEffect(() => {
    console.log("set language:", language);
    sessionStorage.setItem("language", language);
    i18n.changeLanguage(language);
    window.history.back();
  }, []);
  return null;
};

const RedirectExternal = ({ url }) => {
  useEffect(() => {
    window.location.href = url;
  }, []);
  return null;
};

export default EndpointRoutes;
