import React from "react";

import { FormDefinition } from "components/form";
import {
  EDIT_REFERENCE_MUTATION,
  EDIT_REFERENCE_QUERY,
  CREATE_REFERENCE_MUTATION,
  DELETE_REFERENCE_MUTATION,
} from "queries/references";
import {
  EditModal,
  EditVersionedProps,
} from "components/editVersioned/genericForm";
import { Modal } from "components/modal";
import { IconButton } from "components/button";
import { IoMdClose } from "react-icons/io";
import { FaCheck } from "react-icons/fa";

import { NewReference } from "generated/graphql";
import {
  SELECTABLE_APPLICATION_AREAS,
  COUNTRIES,
  SELECTABLE_SERVICES,
} from "pages/project/utils";

import { textField } from "components/form/textField";
import { markDownField } from "components/form/markDownField";
import { parentReferenceField } from "components/form/parentReference";
import { pictureField } from "components/form/pictureField";
import { fromToField } from "components/form/fromToField";
import { factsField } from "components/form/factsField";
import { referenceRolesField } from "components/form/referenceRolesField";
import { siaPhasesField } from "components/form/siaPhasesField";
import { multiSelectField } from "components/form/multiSelectField";
import { singleSelectField } from "components/form/singleSelectField";
import { useMultiLang } from "helpers/multiLang";
import { getFirstNonEmpty } from "helpers/languages";

import {
  APPROVE_REFERENCE_DRAFT_MUTATION,
  REJECT_REFERENCE_DRAFT_MUTATION,
} from "queries/newDraft";
import { Link } from "wouter";
import { formatProjectId } from "pages/newProject/header";
import { investmentField } from "components/form/investmentField";
import { useTranslation } from "react-i18next";

// MAIN COMPONENT

const countryOptions = COUNTRIES.map((code) => ({
  value: code,
  labelKey: `abacusProjects:${code}`,
}));

export const isCompanyReference = (reference: NewReference): boolean =>
  !!reference.projectId && !reference.employeeId;
const isPrivateReference = (reference: NewReference): boolean =>
  !reference.projectId;
const isEmployeeReference = (reference: NewReference): boolean =>
  !!reference.projectId && !!reference.employeeId;

const referenceTitle = (entity, t, m) =>
  m(
    entity?.title,
    entity?.employee && entity?.project
      ? t("reference:employeeProjectReferenceTitle", {
          employeeName: `${entity?.employee?.fullName}`,
          projectName: `${formatProjectId(entity?.project?.abacusProjectId)}`,
        })
      : entity?.employee
        ? t("reference:employeePrivateReferenceTitle", {
            employeeName: `${entity?.employee?.fullName}`,
          })
        : entity?.project
          ? t("reference:projectReferenceTitle", {
              projectName: m(entity?.project?.name),
            })
          : !entity?.projectId && entity?.employeeId
            ? t("reference:employeePrivateReferenceTitle", {
                employeeName: `${entity?.employee?.fullName}`,
              })
            : entity?.id
              ? `${t("search:reference")} (${entity.id})`
              : t("common:newReference"),
  );

export const referenceFormDefinition: FormDefinition<
  NewReference & {
    privateReferenceSeparator: void;
  }
> = {
  title: ({ entity, t }) => {
    const m = useMultiLang();
    return <>{referenceTitle(entity, t, m)}</>;
  },
  titleString: referenceTitle,
  link: (entity) => {
    if (!entity) {
      return "";
    }
    if (entity.projectId && !entity.employeeId) {
      return `/project/${entity.projectId}/references/#${entity.id}`;
    } else if (entity.projectId && entity.employeeId) {
      return `/project/${entity.projectId}/team/#${entity.id}`;
    } else {
      return `/project/${entity.employeeId}/references/#${entity.id}`;
    }
  },
  fields: {
    employeeId: {
      name: "reference.employeeId",
      component: {
        renderer: ({ value, entity }) => (
          <Link href={`/employee/${value}`}>
            {entity?.employee?.fullName ?? `(${value})`}
          </Link>
        ),
      },
      onlyVisibleToAdmins: true,
    },
    projectId: {
      name: "reference.projectId",
      component: {
        renderer: ({ value, entity }) => {
          const m = useMultiLang();
          return (
            <Link href={`/project/${value}`}>
              {entity?.project?.abacusProjectId
                ? `${formatProjectId(entity.project.abacusProjectId)} - `
                : null}
              {m(entity?.project?.name, `(${value})`)}
            </Link>
          );
        },
      },
      onlyVisibleToAdmins: true,
    },
    privateReferenceProjectName: {
      name: "reference.privateReferenceProjectName",
      multiLang: true,
      component: textField,
      displayIf: isPrivateReference,
    },
    privateReferenceProjectClientName: {
      name: "reference.privateReferenceProjectClientName",
      component: textField,
      displayIf: isPrivateReference,
    },
    privateReferenceProjectCountry: {
      name: "reference.privateReferenceProjectCountry",
      component: singleSelectField,
      props: { options: countryOptions },
      displayIf: isPrivateReference,
    },
    privateReferenceProjectInvestment: {
      name: "reference.privateReferenceProjectInvestment",
      component: investmentField(true),
      controlledFields: [
        "privateReferenceProjectInvestment",
        "privateReferenceProjectInvestmentCurrency",
      ],
      displayIf: isPrivateReference,
    },
    privateReferenceProjectFrom: {
      name: "reference.privateReferenceProjectFromTo",
      component: fromToField(true),
      controlledFields: [
        "privateReferenceProjectFrom",
        "privateReferenceProjectTo",
      ],
      displayIf: isPrivateReference,
    },
    privateReferenceProjectSiaPhases: {
      name: "reference.privateReferenceProjectSiaPhases",
      component: siaPhasesField(true),
      controlledFields: [
        "privateReferenceProjectSiaPhases",
        "privateReferenceProjectHoaiPhases",
        "privateReferenceProjectFrPhases",
        "privateReferenceProjectItPhases",
        "privateReferenceProjectPrimaryPhase",
      ],
      displayIf: isPrivateReference,
    },
    privateReferenceProjectApplicationAreas: {
      name: "reference.privateReferenceProjectApplicationAreas",
      component: multiSelectField,
      props: { options: SELECTABLE_APPLICATION_AREAS },
      displayIf: isPrivateReference,
    },
    privateReferenceProjectServices: {
      name: "reference.privateReferenceProjectServices",
      component: multiSelectField,
      props: { options: SELECTABLE_SERVICES },
      displayIf: isPrivateReference,
    },
    privateReferenceProjectDescription: {
      name: "reference.privateReferenceProjectDescription",
      component: markDownField,
      multiLang: true,
      displayIf: isPrivateReference,
      props: {
        placeholderFn: (v) => getFirstNonEmpty(v),
      },
    },
    privateReferenceProjectFacts: {
      name: "reference.privateReferenceProjectFacts",
      component: factsField,
      displayIf: isPrivateReference,
    },
    privateReferenceSeparator: {
      name: "reference.privateReferenceSeparator",
      component: {
        renderer: () => {
          const { t } = useTranslation("form");
          return (
            <div>
              <h3 className="text-xl font-medium text-red-500">
                {t("fieldName.reference.privateReferenceSeparator")}
              </h3>
            </div>
          );
        },
      },
      displayIf: isPrivateReference,
      skipFieldOnSave: true,
      useFullWidth: true,
    },
    title: {
      name: "reference.title",
      descriptionKey: () => "reference.title_company",
      displayIf: (r) => isCompanyReference(r),
      component: textField,
      multiLang: true,
      props: {
        placeholderFn: (v) => getFirstNonEmpty(v),
      },
    },
    description: {
      name: "reference.description",
      descriptionKey: (r) =>
        isCompanyReference(r)
          ? "reference.description_company"
          : "reference.description_employee",
      component: markDownField,
      multiLang: true,
      props: {
        placeholderFn: (v) => getFirstNonEmpty(v),
      },
    },
    parentReferenceId: {
      name: "reference.parentReferenceId",
      component: parentReferenceField,
      displayIf: (r) => !isPrivateReference(r),
      descriptionKey: (r) =>
        isCompanyReference(r)
          ? "reference.parentReferenceId_company"
          : "reference.parentReferenceId_employee",
    },
    picture: {
      name: "reference.picture",
      component: pictureField,
      displayIf: (r) => isCompanyReference(r),
    },
    pictureCredit: {
      name: "reference.pictureCredit",
      component: textField,
      props: {
        placeholderKey: "form:fieldName.reference.pictureCreditPlaceholder",
      },
      displayIf: (r) => isCompanyReference(r),
    },

    from: {
      name: "reference.fromTo",
      component: fromToField(),
      controlledFields: ["from", "to"],
      descriptionKey: (r) =>
        isCompanyReference(r)
          ? "reference.fromTo_company"
          : "reference.fromTo_employee",
    },
    facts: {
      name: "reference.facts",
      descriptionKey: (r) =>
        isEmployeeReference(r) ? "reference.facts_employee" : "reference.facts",
      component: factsField,
      displayIf: (r) => !isPrivateReference(r),
    },
    roles: {
      name: "reference.roles",
      component: referenceRolesField,
      displayIf: (r) => !isCompanyReference(r),
      multiLangIcon: "admin",
    },
    siaPhases: {
      name: "reference.siaPhases",
      component: siaPhasesField(),
      controlledFields: [
        "siaPhases",
        "hoaiPhases",
        "frPhases",
        "itPhases",
        "primaryPhase",
      ],

      descriptionKey: (r) => {
        if (isPrivateReference(r)) {
          return "reference.siaPhases_private";
        } else if (isEmployeeReference(r)) {
          return "reference.siaPhases_employee";
        } else {
          return "reference.siaPhases";
        }
      },
    },
    applicationAreas: {
      name: "reference.applicationAreas",
      component: multiSelectField,
      props: { options: SELECTABLE_APPLICATION_AREAS },
      descriptionKey: (r) => {
        if (isPrivateReference(r)) {
          return "reference.applicationAreas_private";
        } else if (isEmployeeReference(r)) {
          return "reference.applicationAreas_employee";
        } else {
          return "reference.applicationAreas";
        }
      },
    },
    services: {
      name: "reference.services",
      component: multiSelectField,
      props: { options: SELECTABLE_SERVICES },
      descriptionKey: (r) => {
        if (isPrivateReference(r)) {
          return "reference.services_private";
        } else if (isEmployeeReference(r)) {
          return "reference.services_employee";
        } else {
          return "reference.services";
        }
      },
    },
    internalNote: {
      name: "reference.internalNote",
      component: markDownField,
      props: {
        maxCharCount: 250,
      },
      navTab: "internalComments",
      descriptionKey: () => "reference.internalNote",
    },
  },
};

const versionedAttributes = (
  id,
  refetchQueries,
): EditVersionedProps<NewReference> => ({
  id,
  editMutation: EDIT_REFERENCE_MUTATION,
  deleteMutation: DELETE_REFERENCE_MUTATION,
  approveDraftMutation: APPROVE_REFERENCE_DRAFT_MUTATION,
  rejectDraftMutation: REJECT_REFERENCE_DRAFT_MUTATION,
  query: EDIT_REFERENCE_QUERY,
  queryDataAccessor: "reference",
  editDataAccessor: "editNewReference",
  formDefinition: referenceFormDefinition,
  refetchQueries,
  createDataAccessor: "createNewReference",
  createMutation: CREATE_REFERENCE_MUTATION,
});

export const EditReferenceModal = ({
  open,
  id,
  close,
  newEntity,
}: {
  open: boolean;
  id?: string;
  close: () => void;
  newEntity?: Partial<NewReference>;
}): JSX.Element => {
  return (
    <EditModal
      open={open}
      editVersioned={versionedAttributes(id ?? "new", [
        "GetReference",
        "GetNewProject",
        "GetEmployee",
      ])}
      newEntity={newEntity}
      close={close}
    />
  );
};

export const EditWarningModal = ({
  open,
  close,
  accept,
}: {
  open: boolean;
  close: () => void;
  accept: () => void;
}): JSX.Element => {
  const { t } = useTranslation(["common", "project"]);

  return (
    <Modal
      size="small"
      title={
        <div className="px-6 py-8 text-2xl font-medium text-red-500">
          {t("common:warning")}
        </div>
      }
      open={open}
      close={close}
      actions={
        <div className="flex flex-row space-x-2">
          <IconButton type="secondary" Icon={IoMdClose} onClick={close}>
            {t("common:cancel")}
          </IconButton>
          <IconButton Icon={FaCheck} type={"warning"} onClick={accept}>
            {t("common:confirm")}
          </IconButton>
        </div>
      }
    >
      <div className="px-4">
        <div className="px-6 my-8">{t("project:notPartOfTheTeam")}</div>
      </div>
    </Modal>
  );
};
