import React from "react";
import { useQuery } from "@apollo/client";
import { Link } from "wouter";
import { GenericFieldProps } from "./fieldProps";
import Select from "react-select";
import { HiLink } from "react-icons/hi";
import { customStyles, i18nOptions } from "./multiSelectField";

import { PROJECT_REFERENCES_QUERY } from "queries/newProject";
import { NewReference } from "generated/graphql";
import { useMultiLang } from "helpers/multiLang";
import { useTranslation } from "react-i18next";

export type ParentReferenceFieldProps = GenericFieldProps<string, NewReference>;

const ParentReferenceField = ({
  value,
  onChange,
  disabled = false,
  entity,
}: ParentReferenceFieldProps) => {
  const m = useMultiLang();
  const { t } = useTranslation("reference");

  const { data } = useQuery(PROJECT_REFERENCES_QUERY, {
    variables: {
      id: entity?.projectId,
    },
    fetchPolicy: "no-cache",
    skip: !entity?.projectId,
  });

  if (!entity?.projectId) {
    // On a private reference, the logic below makes no sense...
    // TODO: what to do instead? Maybe we need a way to hide the field completely?
    return <Select styles={customStyles} options={[]} isDisabled={true} />;
  }

  const references: Array<Partial<NewReference>> | undefined =
    data?.newProject?.projectReferences;

  const filteredReferences = references?.filter(
    (r) => r.id !== (entity.approvedEntityId || entity.id),
  );

  const optionMap = (p: Partial<NewReference>) => ({
    value: p.id!,
    label: m(p.title) || m(p.description).split("\n")[0] || p.id,
  });

  const selectedOption = filteredReferences?.find((c) => c.id === value);

  return (
    <div className="flex flex-col space-y-3">
      <div className="flex flex-row items-center text-sm">
        <Select
          styles={customStyles}
          className="flex-1 mr-4"
          options={filteredReferences?.map(optionMap)}
          isClearable={true}
          isDisabled={disabled}
          value={selectedOption ? optionMap(selectedOption!) : null}
          {...i18nOptions(t)}
          onChange={
            onChange &&
            ((newValue: any) => {
              onChange(newValue?.value ?? null);
            })
          }
        />
        {selectedOption ? (
          <a
            className="w-4"
            href={`/project/${entity?.projectId}/references#${selectedOption.id}`}
            target="_blank"
            rel="noreferrer"
          >
            <HiLink />
          </a>
        ) : (
          <div className="w-4" />
        )}
      </div>
      <div className="overflow-y-auto max-h-36">
        {(entity.childReferences ?? []).length > 0 ? (
          <h5 className="pt-4 pb-2 text-lg">{t("childReferences")}</h5>
        ) : null}
        {entity.childReferences?.map((ref) => {
          return (
            <div key={ref.id} className="flex flex-row items-center">
              <div className="m-4">
                <Link
                  href={
                    ref.employee
                      ? `/employee/${ref.employee.id}/references#${ref.id}`
                      : `/project/${ref.projectId}/references#${ref.id}`
                  }
                >
                  <HiLink />
                </Link>
              </div>
              <div className="flex flex-col">
                <div className="leading-tight">
                  {m(ref.title, ref.employee?.fullName ?? "")}
                </div>
                <div className="text-sm leading-none text-gray-350">
                  {ref.employee
                    ? t("privateReferenceChild")
                    : t("companyReferenceChild")}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const parentReferenceField = {
  renderer: ParentReferenceField,
};
