const mostRecentYear = (from?: number, to?: number) => {
  const currentYear = new Date().getFullYear();
  const toYear = to ? to : currentYear + 1;
  const fromYear = from ? from : 0;
  return Math.max(fromYear, toYear) || 0;
};

export const sortItemsByYear = (items: any[]): any[] =>
  items.sort((a, b) => {
    const aFrom: number = +a?.from?.slice(0, 4);
    const aTo: number = +a?.to?.slice(0, 4);
    const bFrom: number = +b?.from?.slice(0, 4);
    const bTo: number = +b?.to?.slice(0, 4);

    const aSortDate = mostRecentYear(aFrom, aTo);
    const bSortDate = mostRecentYear(bFrom, bTo);

    if (aSortDate > bSortDate) return -1;
    if (aSortDate == bSortDate && (a.from ?? 0) > (b.from ?? 0)) return -1;
    else {
      return 1;
    }
  });

export const sortItemsByInheritedYear = (items: any[]): any[] =>
  items.sort((a, b) => {
    const aFrom: number = +a?.inheritedFrom?.slice(0, 4);
    const aTo: number = +a?.inheritedTo?.slice(0, 4);
    const bFrom: number = +b?.inheritedTo?.slice(0, 4);
    const bTo: number = +b?.inheritedTo?.slice(0, 4);

    const aSortDate = mostRecentYear(aFrom, aTo);
    const bSortDate = mostRecentYear(bFrom, bTo);

    if (aSortDate > bSortDate) return -1;
    if (
      aSortDate == bSortDate &&
      (a.inheritedFrom ?? 0) > (b.inheritedFrom ?? 0)
    ) {
      return -1;
    } else {
      return 1;
    }
  });
