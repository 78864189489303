import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Block } from "generated/graphql";
import { IconButton, SmallIconButton } from "components/button";

import { ImMoveUp, ImMoveDown } from "react-icons/im";
import { FaTrashAlt, FaEdit } from "react-icons/fa";

import { editIntro } from "./intro";
import { editNetwork } from "./network";
import { editJoker } from "./joker";
import { editReference } from "./reference";
import { editOutro } from "./outro";
import { editAboutTbf } from "./aboutTbf";
import { editIntermission } from "./intermission";

// @ts-ignore
import aboutTbfPreview from "assets/images/onepager/about-preview.png";
// @ts-ignore
import contentPreview from "assets/images/onepager/content-preview.png";
// @ts-ignore
import intermissionPreview from "assets/images/onepager/intermission-preview.png";
// @ts-ignore
import introPreview from "assets/images/onepager/intro-preview.png";
// @ts-ignore
import networkPreview from "assets/images/onepager/network-preview.png";
// @ts-ignore
import outroPreview from "assets/images/onepager/outro-preview.png";
// @ts-ignore
import referencePreview from "assets/images/onepager/reference-preview.png";
import { Modal } from "components/modal";
import { IoMdClose } from "react-icons/io";
import { MdArchive } from "react-icons/md";

export const Components = {
  intro: editIntro,
  network: editNetwork,
  joker: editJoker,
  reference: editReference,
  outro: editOutro,
  about_tbf: editAboutTbf,
  intermission: editIntermission,
};

export const BlockComponent = ({
  block,
  index,
  supportedLanguage,
  language,
  updateBlock,
  deleteBlock,
  moveUpBlock,
  moveDownBlock,
}: {
  block: Block;
  index: number;
  supportedLanguage: string;
  language: string;
  updateBlock: (newBlock: Block) => Promise<any>;
  deleteBlock: (id: string) => void;
  moveUpBlock: (index: number) => void;
  moveDownBlock: (index: number) => void;
}): JSX.Element => {
  const { t } = useTranslation(["common", "onepager"]);
  const [showEdit, setShowEdit] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const editBlock = block.type
    ? Components[block.type as keyof typeof Components]
    : null;

  const hoverEffect =
    "rounded-md shadow-xl hover:shadow-gray-500/50 hover:cursor-pointer group transition overflow-hidden";

  const previewImg = (type: string): string => {
    switch (type) {
      case "intro":
        return introPreview;
      case "network":
        return networkPreview;
      case "joker":
        return contentPreview;
      case "reference":
        return referencePreview;
      case "about_tbf":
        return aboutTbfPreview;
      case "intermission":
        return intermissionPreview;
      case "outro":
        return outroPreview;
      default:
        return "";
    }
  };

  return (
    <div className="flex flex-row gap-x-2 md:relative">
      <div className="flex flex-col gap-y-2 md:absolute md:top-0 md:-left-12">
        <SmallIconButton
          Icon={ImMoveUp}
          onClick={() => {
            moveUpBlock(index);
          }}
        />
        <SmallIconButton
          Icon={ImMoveDown}
          onClick={() => {
            moveDownBlock(index);
          }}
        />
      </div>
      <div
        className={hoverEffect}
        onClick={() => {
          setShowEdit(true);
        }}
      >
        <div className="p-4 bg-white rounded-t-md">
          <div className="w-1/2">
            <h2 className="font-medium text-red-500 transition text-md group-hover:text-red-700">
              {editBlock?.formDefinition?.previewText?.(block as any) ?? ""}
            </h2>
          </div>
          <div className="flex items-center justify-between">
            <h2 className="text-sm font-normal text-gray-400 transition group-hover:text-gray-500 overflow">
              {t(`onepager:block.${block.type}` as any)}
            </h2>
          </div>
        </div>

        <img
          className="w-full duration-300 ease-in-out rounded-b-md group-hover:scale-105"
          alt="block image"
          height="224"
          src={previewImg(block.type ?? "")}
        />

        <div className="flex items-center justify-between">
          {/* Trick to recreate the modal component so we are sure the modal displays the updated info:
           * Even if the modal have the information if it is closed or not, use `showEdit`here to force the modal to re-render
           * For example, on cancel, refresh the modal to display the original data
           */}
          {showEdit && editBlock ? (
            <editBlock.modal
              block={block as any}
              showEdit={showEdit}
              supportedLanguage={supportedLanguage}
              language={language}
              onClose={() => {
                setShowEdit(false);
              }}
              onSave={(newBlock) => {
                return updateBlock(newBlock).then(() => {
                  setShowEdit(false);
                });
              }}
            />
          ) : null}
        </div>
      </div>
      <div className="flex flex-col gap-y-2 md:absolute md:top-0 md:-right-12">
        <SmallIconButton
          type={"warning"}
          Icon={FaTrashAlt}
          onClick={() => {
            setShowDeleteConfirmation(true);
          }}
        />
        <Modal
          size="small"
          title={
            <div className="px-6 py-8 text-2xl font-medium text-red-500">
              {t("onepager:deleteConfirmationTitle")}
            </div>
          }
          open={showDeleteConfirmation}
          close={() => setShowDeleteConfirmation(false)}
          actions={
            <div className="flex flex-row space-x-2">
              <IconButton
                type="secondary"
                Icon={IoMdClose}
                onClick={() => setShowDeleteConfirmation(false)}
              >
                {t("common:cancel")}
              </IconButton>
              <IconButton
                type="soft-warning"
                Icon={MdArchive}
                onClick={() => {
                  deleteBlock(block.id);
                  setShowDeleteConfirmation(false);
                }}
              >
                {t("common:delete")}
              </IconButton>
            </div>
          }
        >
          <div className="px-6 my-8">
            {t("onepager:deleteConfirmationBody")}
          </div>
        </Modal>
        <SmallIconButton
          type="normal"
          Icon={FaEdit}
          onClick={() => {
            setShowEdit(true);
          }}
        />
      </div>
    </div>
  );
};
