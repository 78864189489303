import { gql } from "@apollo/client";

// FRAGMENTS

export const multiLangString = "{ id de en fr it }";

// QUERIES

export const CATALOGS_QUERY = gql`
  query GetCatalogs {
    professionalAssociations {
      id
      name ${multiLangString}
    }
    diplomas {
      id
      name ${multiLangString}
    }
    competences {
      id
      name ${multiLangString}
    }
    roles {
      id
      name ${multiLangString}
    }
    referenceRoles {
      id
      name ${multiLangString}
    }
    skills {
      id
      name ${multiLangString}
    }
    languages {
      id
      name ${multiLangString}
    }
    factKeys {
      id
      name ${multiLangString}
    }
    wikiTags {
      id
      name ${multiLangString}
    }
  }
`;
