import React from "react";
import { GenericFieldProps } from "./fieldProps";

export type HiddenFieldProps = GenericFieldProps<string>;

export const HiddenField = ({ value }: HiddenFieldProps) => {
  return <input type="hidden" value={value}></input>;
};

export const hiddenField = {
  renderer: HiddenField,
};
