import classNames from "classnames";
import React from "react";

export const TwoColumnLayout = ({ leftContent, rightContent }) => {
  return (
    <div
      className="flex flex-col bg-white md:flex-row md:-ml-128 md:pl-128"
      style={{ minHeight: "calc(100vh - 257px)" }}
    >
      <div className="relative w-full pt-8 z-5 md:w-1/3 md:pr-8">
        {leftContent}
      </div>
      <div className="w-full h-full pt-8 bg-white md:w-2/3 md:pl-8 shadow-divider">
        {rightContent}
      </div>
    </div>
  );
};

export const FullHeightTwoColumnLayout = ({ leftContent, rightContent }) => {
  return (
    <div className="flex flex-col h-full bg-white md:flex-row md:-ml-128 md:pl-128">
      <div className="relative w-full z-5 md:w-1/3">{leftContent}</div>
      <div className="w-full bg-white md:w-2/3 shadow-divider">
        {rightContent}
      </div>
    </div>
  );
};

export const NarrowFullHeightTwoColumnLayout = ({
  leftContent,
  rightContent,
}) => {
  return (
    <div className="flex flex-col h-full bg-white md:flex-row md:-ml-128 md:pl-128">
      <div className="relative w-full z-5 md:w-1/4">{leftContent}</div>
      <div className="w-full bg-white md:w-3/4 shadow-divider">
        {rightContent}
      </div>
    </div>
  );
};

export const NarrowTwoColumnLayout = ({
  leftContent,
  rightContent,
  leftClassName = "pt-8 md:pr-8",
  rightClassName = "pt-8 md:pl-12",
}) => {
  return (
    <div
      className="flex flex-col bg-white md:flex-row md:-ml-128 md:pl-128"
      style={{ minHeight: "calc(100vh - 257px)" }}
    >
      <div
        className={classNames("relative w-full z-4 md:w-1/4", leftClassName)}
      >
        {leftContent}
      </div>
      <div
        className={classNames(
          "w-full bg-white md:w-3/4 shadow-divider",
          rightClassName,
        )}
      >
        {rightContent}
      </div>
    </div>
  );
};

export default TwoColumnLayout;
