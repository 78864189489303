import { FormDefinition } from "components/form";
import { IntroBlock } from "generated/graphql";
import { markDownField } from "components/form/markDownField";
import { editBlockModal } from "../editBlock";
import { textField } from "components/form/textField";
import { previewText } from "../helpers/previewText";
import { blockPictureField } from "components/form/blockPictureField";
import { linksField } from "components/form/linksField";
import { boolField } from "components/form/boolField";

const formDefinition: FormDefinition<IntroBlock> = {
  title: () => "",
  titleString: () => "",
  previewText: (block) => {
    return previewText(block.navigationTitle?.de ?? "");
  },
  fields: {
    navigationTitle: {
      name: "onepager.introBlock.navigationTitle",
      component: textField,
      multiLang: true,
      required: true,
    },
    welcome: {
      name: "onepager.introBlock.welcome",
      component: textField,
      multiLang: true,
      props: {
        lines: 5,
      },
    },
    intro: {
      name: "onepager.introBlock.intro",
      component: markDownField,
      multiLang: true,
      props: {
        minimal: true,
      },
    },
    logo: {
      name: "onepager.introBlock.logo",
      component: blockPictureField,
      props: { hideFocusPoint: true },
    },
    hideTbfLogo: {
      name: "onepager.introBlock.hideTbfLogo",
      component: boolField,
    },
    externalLinks: {
      name: "onepager.introBlock.externalLinks",
      component: linksField,
    },
  },
  link: () => "",
};

export const editIntro = {
  formDefinition,
  modal: editBlockModal(formDefinition),
};
