import { useQuery } from "@apollo/client";
import React from "react";

import AppContext from "../app";
import { CATALOGS_QUERY } from "queries/common";

// MAIN COMPONENT

const AppProvider = ({ children }: any) => {
  const catalogues = useQuery(CATALOGS_QUERY);

  return (
    <AppContext.Provider
      value={{
        catalogues: catalogues.data || {},
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
