import React from "react";

import UserProvider from "./components/userProvider";
import AppProvider from "./components/appProvider";

// MAIN COMPONENT

const Provider = ({ LoginForm, children }: any) => {
  return (
    <UserProvider LoginForm={LoginForm}>
      <AppProvider>{children}</AppProvider>
    </UserProvider>
  );
};

export default Provider;
