import React from "react";
import { GenericFieldProps } from "./fieldProps";
import {
  DELETE_WIKI_PAGE_WITH_SUB_PAGES_MUTATION,
  EDIT_WIKI_SUB_PAGES_ORDER_MUTATION,
  WIKI_SUB_PAGE_RECURSIVE_QUERY,
} from "queries/wiki";

import { ApolloClient, useLazyQuery, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useMultiLang } from "helpers/multiLang";
import { IconButton, SmallIconButton } from "components/button";
import { FaClipboard, FaPlus } from "react-icons/fa";
import { WikiPage } from "generated/graphql";
import { IoMdClose } from "react-icons/io";
import { IoTrash } from "react-icons/io5";
import { ImMoveUp, ImMoveDown } from "react-icons/im";
import { Modal } from "components/modal";
import { useState } from "react";
import { EditWikiPageModal } from "components/editVersioned/editWikiPage";
import { useEffect } from "react";
import { Link } from "wouter";
import { copyToClipboard } from "helpers/clipboard";
import toast from "react-hot-toast";
import "./styles.scss";

const WikiSubPagesField = ({
  value,
  onChange,
  entity,
}: GenericFieldProps<Array<any>, WikiPage>) => {
  const { t } = useTranslation(["wiki", "common"]);
  const m = useMultiLang();
  const [deletePageAndSubPages] = useMutation(
    DELETE_WIKI_PAGE_WITH_SUB_PAGES_MUTATION,
  );

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState<string | null>(null);

  const [createNewOpen, setCreateNewOpen] = useState(false);
  const parentPageSlugs = entity?.slugs ?? [];
  const hasParent = entity.parentPage?.slugs?.length > 0;
  const parentUrl = hasParent
    ? `/wiki/${entity.parentPage.slugs.join("/")}`
    : "";
  const formatCurrentPageLink = hasParent
    ? "mt-4 ml-4 sub-tree pl-4 "
    : "mt-4 pl-2 tree";
  const formatSubPageLink = hasParent ? "ml-4 mr-auto" : "ml-2 mr-auto";
  return (
    <>
      <ul>
        <li className={hasParent ? "tree ml-2 relative" : ""}>
          {hasParent ? (
            <Link href={parentUrl} className="ml-2">
              {m(entity.parentPage.title)}
            </Link>
          ) : null}
          <ul>
            {hasParent && (
              <span className="absolute top-10 left-2 text-gray-400 text-5xl">
                •
              </span>
            )}
            <li className={formatCurrentPageLink}>
              {m(entity.title)}
              <ul>
                {value?.map((subPage, index) => {
                  const url = `/wiki/${(subPage.slugs ?? []).join("/")}`;
                  return (
                    <li
                      key={subPage.id}
                      className="flex items-center my-4 ml-2 pl-4 space-x-2 sub-tree relative justify-between"
                    >
                      <span className="absolute left-0 text-gray-400 text-5xl">
                        •
                      </span>
                      <Link href={url} className={formatSubPageLink}>
                        {m(subPage.title)}
                      </Link>
                      <div className=" space-x-2">
                        <SmallIconButton
                          type="normal"
                          Icon={FaClipboard}
                          popupContent={t("wiki:copyToClipboard")}
                          onClick={() => {
                            copyToClipboard(`${window.location.origin}${url}`);
                            toast.success(t("wiki:copySuccess"));
                          }}
                        />
                        <SmallIconButton
                          type="normal"
                          disabled={index === 0}
                          Icon={ImMoveUp}
                          popupContent={t("wiki:moveUp")}
                          onClick={() => {
                            const newCurrentSubPages = [
                              ...value.slice(0, index - 1),
                              value[index],
                              value[index - 1],
                              ...value.slice(index + 1, value.length),
                            ];
                            onChange(newCurrentSubPages);
                          }}
                        />
                        <SmallIconButton
                          type="normal"
                          disabled={index === value.length - 1}
                          Icon={ImMoveDown}
                          popupContent={t("wiki:moveDown")}
                          onClick={() => {
                            const newCurrentSubPages = [
                              ...value.slice(0, Math.max(index - 1, 0)),
                              value[index + 1],
                              value[index],
                              ...value.slice(index + 2, value.length),
                            ];
                            onChange(newCurrentSubPages);
                          }}
                        />
                        <SmallIconButton
                          type="warning"
                          Icon={IoTrash}
                          onClick={() => {
                            setIdToDelete(subPage.id);
                            setConfirmDeleteOpen(true);
                          }}
                        />
                      </div>
                    </li>
                  );
                })}
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <div className="mt-2 ml-4">
        <IconButton
          type="normal"
          Icon={FaPlus}
          onClick={() => {
            setCreateNewOpen(true);
          }}
        >
          {t("common:create")}
        </IconButton>
      </div>
      <ConfirmDelete
        isOpen={confirmDeleteOpen}
        setIsOpen={setConfirmDeleteOpen}
        idToDelete={idToDelete}
        onDelete={() => {
          deletePageAndSubPages({
            variables: { id: idToDelete },
            update(cache) {
              // remove from our state
              const newCurrentSubPages = value.filter(
                (p) => p.id !== idToDelete,
              );
              onChange(newCurrentSubPages);

              // remove from apollo cache
              const id = cache.identify({
                __typename: "WikiPage",
                id: idToDelete,
              });
              cache.evict({ id });
              cache.gc();
            },
          });
        }}
      />
      <EditWikiPageModal
        id="new"
        open={createNewOpen}
        close={() => setCreateNewOpen(false)}
        newEntity={{
          slugs: [...parentPageSlugs, ""],
          parentPageId: entity?.id,
        }}
      />
    </>
  );
};

const ConfirmDelete = ({ isOpen, setIsOpen, onDelete, idToDelete }) => {
  const { t } = useTranslation(["wiki", "common"]);
  const [getSubPages, { data }] = useLazyQuery(WIKI_SUB_PAGE_RECURSIVE_QUERY);
  useEffect(() => {
    if (idToDelete) {
      getSubPages({ variables: { id: idToDelete } });
    }
  }, [idToDelete]);
  return (
    <Modal
      size="small"
      title={
        <div className="px-6 py-8 text-2xl font-medium text-red-500">
          {t("wiki:confirmDelete")}
        </div>
      }
      open={isOpen}
      close={() => setIsOpen(false)}
      actions={
        <div className="flex flex-row space-x-2">
          <IconButton
            type="secondary"
            Icon={IoMdClose}
            onClick={() => setIsOpen(false)}
          >
            {t("common:cancel")}
          </IconButton>
          <IconButton
            type="warning"
            Icon={IoTrash}
            onClick={() => {
              onDelete();
              setIsOpen(false);
            }}
          >
            {t("common:delete")}
          </IconButton>
        </div>
      }
    >
      <div className="px-6 my-8">{t("wiki:confirmDeleteText")}</div>
      {data ? <ListPages page={data.wikiPage} /> : null}
    </Modal>
  );
};

const ListPages = ({ page }: { page: Partial<WikiPage> }) => {
  const m = useMultiLang();
  return (
    <div className="px-6 mb-8 space-y-2">
      {[page, ...(page.subPagesRecursive ?? [])].map((p) => {
        const link = `/wiki/${(p.slugs ?? []).join("/")}`;
        return (
          <div key={p.id} className="m-2">
            <div className="font-semibold">{m(p.title)}</div>
            <Link href={link}>{link}</Link>
          </div>
        );
      })}
    </div>
  );
};

export const editSubPagesOrder = (
  apolloClient: ApolloClient<any>,
  entity: Partial<WikiPage>,
  value,
) => {
  return apolloClient.mutate({
    mutation: EDIT_WIKI_SUB_PAGES_ORDER_MUTATION,
    variables: {
      topLevelId: entity?.id,
      newPageOrder: value?.map((p) => p.id),
    },
  });
};

export const wikiSubPagesField = {
  renderer: WikiSubPagesField,
};
