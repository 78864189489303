export const stages = {
  staging_swarm: "staging_swarm",
  production_swarm: "production_swarm",
  other: "other",
};

export const getReleaseStage = (): string => {
  switch (location.hostname) {
    case "stage.tbf.digital":
      return stages.staging_swarm;
    case "tbf.digital":
      return stages.production_swarm;
    default:
      return stages.other;
  }
};
