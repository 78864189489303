import React from "react";
import { CvItemView } from "./cvItem";
import { CvEntry } from "generated/graphql";

const CV: React.FC<{ cvItems: CvEntry[] }> = ({ cvItems }) => {
  return (
    <div className="mt-2">
      {cvItems?.map((cvItem) => <CvItemView key={cvItem.id} item={cvItem} />)}
    </div>
  );
};

export default CV;
