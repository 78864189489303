import React from "react";
import classnames from "classnames";

// TYPE

type IconBadgeProps = {
  content?: string;
  Icon?;
  badge?: string;
  className?: string;
  color?: string;
  iconClassName?: string;
};

// MAIN COMPONENT

const IconBadge: React.FC<IconBadgeProps> = ({
  Icon,
  className,
  badge,
  color,
  iconClassName,
}) => {
  return (
    <div
      className={classnames(
        "h-16 w-16 rounded-full flex items-center justify-center position relative",
        className,
      )}
      style={color ? { backgroundColor: color } : undefined}
    >
      {Icon ? (
        <Icon className={classnames("text-3xl text-white", iconClassName)} />
      ) : null}
      {badge ? (
        <div className="h-7 w-7 position absolute -bottom-1 -right-1 border border-white rounded-full bg-blue-500 justify-center items-center">
          <div className="text-white text-center mt-0.5">{badge}</div>
        </div>
      ) : null}
    </div>
  );
};

export default IconBadge;
