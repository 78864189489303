import { gql } from "@apollo/client";

import { multiLangString } from "./common";
import {
  newReferenceFragment,
  parentReferenceFragment,
  projectReferenceFragment,
} from "./references";
import { aggregatedTeamMemberFragment } from "./teamMembers";

// fragments

export const projectKeyPersonFragment = gql`
  fragment Projects_key_person_fields on Employee {
    id
    firstName
    lastName
    activeDirectoryId
    profilePicture(size: 96)
  }
`;

export const projectFragment = gql`
  fragment ArchiveItem_Fields on NewArchiveItem {
    id
    archiveNumber
    location
    room
    shelf
    description
    internalNumber
    dateCreated
    keywords
  }

  fragment Project_fields on NewProject {
    id
    abacusProjectId
    archivedProjectId
    name ${multiLangString}
    description ${multiLangString}
    status
    enrichedFrom
    relatedProjectIds
    clientId
    client {
      id
      name
    }
    invoicingResponsible {
      id
      activeDirectoryId
      isFormerEmployee
    }
    invoicingResponsibleId
    adminProjectManagerId
    adminDeputyProjectManagerId
    portfolioManagerId
    isUnlisted
    isInternalProject
    clientHidden
    departmentId
    siaPhases
    hoaiPhases
    frPhases
    itPhases
    primaryPhase
    applicationAreas
    services
    investment
    investmentCurrency
    country
    projectFolderLink
    facts {
      id
      value ${multiLangString}
      newText
      keyId
      key {
        id
        name ${multiLangString}
      }
    }
    archiveItems {
      ...ArchiveItem_Fields
    }
    isReadyForReview
  }
`;

export const projectListFields = gql`
  fragment ProjectList_fields on NewProject {
    id
    abacusProjectId
    teamSize
    projectMainReferencesCount
    from
    to
    name ${multiLangString}
    siaPhases
    hoaiPhases
    frPhases
    itPhases
    primaryPhase
    client {
      id
      name
    }
    isUnlisted
    parentProjects {
      id
      abacusProjectId
      teamSize
      projectMainReferencesCount
      from
      to
      name ${multiLangString}
      siaPhases
      hoaiPhases
      frPhases
      itPhases
      primaryPhase
      client {
        id
        name
      }
    }
  }
`;

const projectRelationsFragment = gql`
  ${parentReferenceFragment}
  ${projectListFields}
  ${projectKeyPersonFragment}
  ${aggregatedTeamMemberFragment}

  fragment ProjectRelations_fields on NewProject {
    adminProjectManager {
      ...Projects_key_person_fields
    }
    adminDeputyProjectManager {
      ...Projects_key_person_fields
    }
    portfolioManager {
      ...Projects_key_person_fields
    }
    aggregatedTeam(includeUnapproved: true) {
      ...aggregatedTeamMemberFragment
      references(includeUnapproved: true) {
        ...NewReference_fields
        parentReference {
          ...ParentReference_fields
        }
        draft {
          ...NewReference_fields
          parentReference {
            ...ParentReference_fields
          }
        }
      }
      employee {
        id
        firstName
        lastName
        fullName
        activeDirectoryId
        isFormerEmployee
        profilePicture(size: 96)
      }
    }
    projectReferences(includeUnapproved: true) {
      ...NewReference_fields
      parentReference {
        ...ParentReference_fields
      }
      isStarredForCurrentUser
      draft {
        ...NewReference_fields
        parentReference {
          ...ParentReference_fields
        }
      }
    }
    relatedProjects {
      ...ProjectList_fields
    }
  }
`;

// project

export const NEW_PROJECT_QUERY = gql`
  ${projectFragment}
  ${projectRelationsFragment}
  ${newReferenceFragment}

  query GetNewProject($id: ID, $accountingId: ID, $archivedProjectId: ID) {
    me {
      id
    }
    newProject(id: $id, accountingId: $accountingId, archivedProjectId: $archivedProjectId) {
      ...Project_fields
      deletedAt
      ...ProjectRelations_fields
      parentProjects {
        id
        name ${multiLangString}
      }
      approvedBy {
        __typename
      }
      approvedEntityId
      connectedFields {
        field
        isConnected
      }
      draft {
        ...Project_fields
        ...ProjectRelations_fields
        modifiedBy {
          __typename
        }
        deletedAt
        connectedFields {
          field
          isConnected
        }
      }
    }
  }
`;

export const EDIT_PROJECT_QUERY = gql`
  ${projectFragment}
  ${projectReferenceFragment}

  query GetNewProject($id: ID!) {
    newProject(id: $id) {
      ...Project_fields
      abacusProject {
        name
        status
        clientId
        projectManagerId
        deputyProjectManagerId
        invoicingResponsibleId
        portfolioManagerId
        parentProjectId
      }
      projectReferences {
        ...ProjectReference_fields
      }
      approvedBy {
        __typename
      }
      connectedFields {
        field
        isConnected
        previewValue
      }
      draft {
        ...Project_fields
        projectReferences {
          ...ProjectReference_fields
        }
        modifiedBy {
          __typename
        }
        connectedFields {
          field
          isConnected
          previewValue
        }
        deletedAt
      }
    }
  }
`;

export const ALL_PROJECTS_QUERY = gql`
  query AllNewProjects {
    newProjects(includeUnlisted: true) {
      id
      name ${multiLangString}
      abacusProjectId
    }
  }
`;

export const ALL_PROJECTS_SEARCH_QUERY = gql`
  query AllNewProjects {
    newProjects {
      id
      name ${multiLangString}
      abacusProjectId
    }
  }
`;

export const PROJECT_REFERENCES_QUERY = gql`
  ${projectReferenceFragment}

  query GetNewProject($id: ID!) {
    newProject(id: $id) {
      id
      projectReferences(includeUnapproved: false) {
        ...ProjectReference_fields
      }
    }
  }
`;

export const CREATE_NEW_PROJECT_MUTATION = gql`
  ${projectFragment}

  mutation CreateProjectMutation($fields: InputNewProject!) {
    createNewProject(fields: $fields) {
      ...Project_fields
      draft {
        ...Project_fields
      }
    }
  }
`;

export const DELETE_NEW_PROJECT_MUTATION = gql`
  ${projectFragment}

  mutation DeleteNewProjectMutation($id: ID!) {
    deleteNewProject(id: $id) {
      ...Project_fields
      draft {
        ...Project_fields
        deletedAt
      }
    }
  }
`;

export const EDIT_NEW_PROJECT_MUTATION = gql`
  ${projectFragment}

  mutation EditProjectMutation($id: ID!, $fields: InputNewProject!) {
    editNewProject(id: $id, fields: $fields) {
      ...Project_fields
      approvedBy {
        __typename
      }
      draft {
        ...Project_fields
        modifiedBy {
          __typename
        }
      }
    }
  }
`;

export const RESTORE_PROJECT_MUTATION = gql`
  ${projectFragment}

  mutation RestoreProjectMutation($id: ID!) {
    restoreDeletedNewProject(id: $id) {
      ...Project_fields
    }
  }
`;

export const ALL_CLIENTS_QUERY = gql`
  query allClientsQuery {
    clients {
      id
      name
    }
  }
`;
