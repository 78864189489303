import React from "react";

import { FormDefinition } from "components/form";
import {
  EDIT_EMPLOYEE_MUTATION,
  EDIT_EMPLOYEE_QUERY,
} from "queries/newEmployee";
import {
  EditModal,
  EditForm,
  ISpecializedEditFormProps,
} from "components/editVersioned/genericForm";
import { Employee } from "generated/graphql";

import {
  SELECTABLE_SERVICES,
  SELECTABLE_APPLICATION_AREAS,
} from "pages/project/utils";
import { getFirstNonEmpty } from "helpers/languages";

import { textField } from "components/form/textField";
import { languagesField } from "components/form/LanguagesField";
import { diplomasField } from "components/form/DiplomasField";
import { rolesField } from "components/form/rolesField";
import { skillsField } from "components/form/skillsField";
import { competencesField } from "components/form/competencesField";
import { cvField } from "components/form/CvField";
import { yearField } from "components/form/yearField";

import { markDownField } from "components/form/markDownField";
import { referencesField } from "components/form/referencesField";
import { multiSelectField } from "components/form/multiSelectField";
import {
  APPROVE_EMPLOYEE_DRAFT_MUTATION,
  REJECT_EMPLOYEE_DRAFT_MUTATION,
} from "queries/newDraft";
import { additionalField } from "components/form/additionalField";

// MAIN COMPONENT

export const employeeFormDefinition: FormDefinition<Employee> = {
  title: ({ entity, t }) => (
    <>
      {t("employee:editTitle", {
        firstName: entity?.firstName ?? "",
        lastName: entity?.lastName ?? "",
      })}
    </>
  ),
  titleString: (entity) => `${entity?.firstName} ${entity?.lastName}`,
  link: (entity) => `/employee/${entity.id}`,
  fields: {
    aboutMe: {
      name: "employee.aboutMe",
      component: markDownField,
      multiLang: true,
      usedIn: "form:fieldUsage:employee:aboutMe",
      props: {
        maxWordCount: 150,
        placeholderFn: (v) => getFirstNonEmpty(v),
      },
    },
    birthday: {
      name: "employee.birthday",
      component: yearField,
      multiLang: false,
    },
    services: {
      name: "employee.services",
      component: multiSelectField,
      props: { options: SELECTABLE_SERVICES },
      multiLangIcon: "admin",
    },
    domains: {
      name: "employee.domains",
      component: multiSelectField,
      props: { options: SELECTABLE_APPLICATION_AREAS },
      multiLangIcon: "admin",
    },
    competences: {
      name: "employee.competences",
      component: competencesField,
      multiLangIcon: "admin",
    },
    languages: {
      name: "employee.languages",
      component: languagesField,
      multiLangIcon: "admin",
    },
    diplomas: {
      name: "employee.diplomas",
      component: diplomasField,
      multiLangIcon: "admin",
    },
    trainings: {
      name: "employee.trainings",
      component: markDownField,
      multiLang: true,
      props: {
        placeholderFn: (v) => getFirstNonEmpty(v),
      },
    },
    skills: {
      name: "employee.skills",
      component: skillsField,
      multiLangIcon: "admin",
    },
    roles: {
      name: "employee.roles",
      component: rolesField,
      multiLangIcon: "admin",
    },
    cv: {
      name: "employee.cv",
      component: cvField,
      multiLangIcon: "custom",
    },
    linkedin: {
      name: "employee.linkedin",
      component: textField,
      multiLang: false,
    },
    references: {
      name: "employee.references",
      component: referencesField,
      onlyVisibleToAdmins: true,
    },
    additionalField: {
      name: "employee.additionalField",
      component: additionalField,
    },
  },
};

export const EditEmployeeModal = ({ open, id, close }) => {
  const editVersioned = {
    id,
    editMutation: EDIT_EMPLOYEE_MUTATION,
    approveDraftMutation: APPROVE_EMPLOYEE_DRAFT_MUTATION,
    rejectDraftMutation: REJECT_EMPLOYEE_DRAFT_MUTATION,
    query: EDIT_EMPLOYEE_QUERY,
    queryDataAccessor: "employee",
    editDataAccessor: "editEmployee",
    formDefinition: employeeFormDefinition,
    refetchQueries: ["GetEmployee"],
  };

  return <EditModal open={open} editVersioned={editVersioned} close={close} />;
};

export const EditEmployeeForm = React.memo(
  ({ id, onSaved, isOnAdminPage }: ISpecializedEditFormProps) => {
    const editVersioned = {
      id,
      editMutation: EDIT_EMPLOYEE_MUTATION,
      approveDraftMutation: APPROVE_EMPLOYEE_DRAFT_MUTATION,
      rejectDraftMutation: REJECT_EMPLOYEE_DRAFT_MUTATION,
      query: EDIT_EMPLOYEE_QUERY,
      queryDataAccessor: "employee",
      editDataAccessor: "editEmployee",
      formDefinition: employeeFormDefinition,
      refetchQueries: [],
    };

    return (
      <EditForm
        onSaved={onSaved}
        editVersioned={editVersioned}
        isOnAdminPage={isOnAdminPage}
      />
    );
  },
);
