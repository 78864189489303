import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useTitle } from "helpers/useTitle";
import Layout from "layout/layout";
import { TabBar, TabContent, Pane, useTab } from "components/tab";
import { TabButton } from "components/button";
import { EditClientModal } from "components/editVersioned/editClient";
import { ClientProjects } from "./projects";
import { ClientHeader } from "./header";
import { Client } from "generated/graphql";
import { DraftBanner, DraftProcessBanner } from "components/banner";
import { EDIT_CLIENT_MUTATION } from "queries/newClient";

const Client = ({
  id,
  loading,
  client,
  tab,
}: {
  id: string;
  loading: boolean;
  client: Partial<Client>;
  tab?: string;
}): JSX.Element => {
  const { t } = useTranslation(["client", "project"]);
  const displayedClient = client?.draft ? client.draft : client;
  const [showEditClient, setShowEditClient] = useState(false);
  useTitle(`${client?.name ?? ""} - TBF TeamHub`);

  const panes = (
    displayedClient
      ? [
          {
            name: t("client:projects"),
            key: "projects",
            Content: () => (
              <ClientProjects
                projects={
                  displayedClient.projects?.filter((p) => p !== null) || []
                }
                client={client}
              />
            ),
          },
        ].filter((pane) => pane !== null)
      : []
  ) as Pane[];

  const tabProps = { panes, path: `/client/${id}`, currentTab: tab };
  useTab(tabProps);

  if (loading && !client) {
    return <Layout loading={loading}></Layout>;
  } else if (!client) {
    return <Layout>{`client ${id} not found`}</Layout>;
  }

  return (
    <>
      <Layout noPadding loading={loading} />
      <DraftBanner url="/client" entity={client} />
      {client?.draft ? (
        <DraftProcessBanner
          draft={client.draft}
          editMutation={EDIT_CLIENT_MUTATION}
        />
      ) : null}
      <>
        <div className="sticky top-12 bg-white z-20 shadow">
          <div className="px-3 mx-auto max-w-screen-tbf">
            <ClientHeader client={displayedClient} />

            <div className="flex items-center justify-between py-3 mx-auto mt-2 max-w-screen-tbf">
              <div>
                <TabBar {...tabProps} />
              </div>
              <div className="hidden md:block">
                {client?.draft?.isReadyForReview ? null : (
                  <TabButton
                    onClick={() => {
                      setShowEditClient(true);
                    }}
                  >
                    {t("client:editClient")}
                  </TabButton>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="relative px-3 mx-auto max-w-screen-tbf">
          <TabContent {...tabProps} />
        </div>

        {showEditClient ? (
          <EditClientModal
            id={displayedClient.id}
            open={setShowEditClient}
            close={() => {
              setShowEditClient(false);
            }}
          />
        ) : null}
      </>
    </>
  );
};

export default Client;
