import React, { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaSave, FaSpinner } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { MdLinkOff, MdLink } from "react-icons/md";
import { ApolloClient, useApolloClient } from "@apollo/client";

import { Modal } from "components/modal";
import { IconButton, SingleIconButton } from "components/button";
import { Popup } from "components/popup";

import { FieldDefinition, FormDefinition } from "components/form";
import { blockPictureField } from "components/form/blockPictureField";
import { BoolField } from "components/form/boolField";
import { toMultiLangField } from "components/editVersioned/genericForm";
import { pick, uniq } from "ramda";
import { getFirstInvalidField } from "helpers/validateBlockFields";
import { useConfirmation } from "helpers/useConfirmation";
import { LoadingSpinner } from "components/loadingSpinner";

export const editBlockModal =
  <T,>(formDefinition: FormDefinition<T>) =>
  ({
    block,
    showEdit,
    supportedLanguage,
    language,
    onClose,
    onSave,
  }: {
    block: Partial<T>;
    showEdit: boolean;
    supportedLanguage: string;
    language: string;
    onClose: () => void;
    onSave: (values: Partial<T>) => Promise<any>;
  }): JSX.Element => {
    const { t } = useTranslation(["common", "error", "onepager"]);

    const originalValues = block ?? {};
    const [values, setValues] = useState(originalValues);
    const [invalidField, setInvalidField] = useState<string | null>(null);
    const [imageError, setImageError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const ValidationWarning = (): JSX.Element => {
      return (
        <div className="block mt-1 mr-4 font-medium text-red-500">
          {t("common:isRequired", {
            field: t(`${invalidField}` as any),
          })}
        </div>
      );
    };

    const ImageErrorWarning = (): JSX.Element => {
      return (
        <div className="block mt-1 mr-4 font-medium text-red-500">
          {t("error:imageError")}
        </div>
      );
    };

    useConfirmation(showEdit);

    const title = formDefinition.title({ entity: block, t });

    return (
      <Modal
        title={
          <div className="flex flex-row p-8 text-2xl font-medium">
            <div className="mr-3 text-gray-400">{t("onepager:edit")}:</div>
            <div className="text-red-500">
              {title
                ? title
                : t(`onepager:editBlockTitles.${(block as any)?.type}` as any)}
            </div>
          </div>
        }
        open={showEdit}
        close={onClose}
        closeOnClick={false}
        actions={
          <div className="flex flex-row space-x-2">
            {isLoading ? (
              <div className="max-h-8">
                <LoadingSpinner />
              </div>
            ) : (
              <>
                {invalidField ? <ValidationWarning /> : null}
                {imageError ? <ImageErrorWarning /> : null}
                <IconButton
                  type="secondary"
                  Icon={IoMdClose}
                  onClick={() => {
                    setValues({ ...originalValues });
                    onClose();
                  }}
                >
                  {t("common:cancel")}
                </IconButton>
                <IconButton
                  Icon={FaSave}
                  onClick={() => {
                    setIsLoading(true);

                    const firstInvalidField = getFirstInvalidField(
                      formDefinition.fields,
                      values,
                    );
                    if (firstInvalidField) {
                      setInvalidField(firstInvalidField);
                      setIsLoading(false);
                    } else {
                      setInvalidField(null);
                      onSave(values)
                        .catch(() => {
                          setImageError(true);
                        })
                        .finally(() => setIsLoading(false));
                    }
                  }}
                >
                  {t("common:save")}
                </IconButton>
              </>
            )}
          </div>
        }
      >
        <div className="px-8 m-4">
          <Form
            values={values}
            setValues={setValues}
            formDefinition={formDefinition}
            currentLanguage={language}
            supportedLanguage={supportedLanguage}
          />
        </div>
      </Modal>
    );
  };

const getAllExportableFieldKeys = (
  formDefinition: FormDefinition<any>,
): string[] => {
  const formKeys = Object.keys(formDefinition.fields);
  const controlledFieldKeys = formKeys.reduce((acc, key) => {
    const field = formDefinition.fields[key];
    return field?.controlledFields ? [...acc, ...field.controlledFields] : acc;
  }, [] as string[]);

  return uniq([...formKeys, ...controlledFieldKeys]);
};

type FormProps<T> = {
  formDefinition: FormDefinition<T>;
  values: Partial<T> & { extraSource?: any };
  setValues: (values: Partial<T>) => void;
  currentLanguage: string;
  supportedLanguage: string;
  newEntity?: boolean;
};

const Form = <T,>({
  formDefinition,
  currentLanguage,
  supportedLanguage,
  values,
  setValues,
}: FormProps<T>) => {
  const { t } = useTranslation("onepager");
  const apolloClient: ApolloClient<any> = useApolloClient();
  const [isLoading, setIsLoading] = useState(false);

  // This function is triggered when some external source associated to the form data is fetched
  const updateLinkedValues = (dataDependency, dependentFields) => {
    const key = dataDependency.key;
    const value = values?.[key];
    setIsLoading(true);

    if (value) {
      const allExportableFieldKeys = getAllExportableFieldKeys(formDefinition);

      dataDependency?.fetchFunction(apolloClient, value).then((result) => {
        // Extra source has been fetched. If this is the same as the one already stored in values, do nothing
        if (
          !values?.extraSource ||
          (values?.extraSource?.[key]?.id &&
            `${value}` == `${values?.extraSource?.[key]?.id}`)
        ) {
          setValues({
            ...values,
            extraSource: { ...values?.extraSource, [key]: result },
          });
          setIsLoading(false);
        } else {
          // Else reset values of the form
          const newLinkedFields = values?.extraSource?.[key]
            ? dependentFields
            : (values?.["linkedFields"] ?? dependentFields);

          const newExportedFields = values?.extraSource?.[key]
            ? allExportableFieldKeys
            : (values?.["exportedFields"] ?? allExportableFieldKeys);

          let resettedFields =
            dataDependency?.resetFieldKeysOnFetch.reduce(
              (curr, fieldKey) => ({ ...curr, [fieldKey]: undefined }),
              {},
            ) ?? {};

          resettedFields = values?.extraSource?.[key] ? resettedFields : {};

          setValues({
            ...values,
            ...resettedFields,
            linkedFields: newLinkedFields,
            exportedFields: newExportedFields,
            extraSource: { ...values?.extraSource, [key]: result },
          });
          setIsLoading(false);
        }
      });
    } else {
      // No extra source has been selected
      setValues({
        linkedFields: [],
        exportedFields: [],
        ...values,
        extraSource: { ...values?.extraSource, [key]: undefined },
      });
      setIsLoading(false);
    }
  };

  formDefinition.dataDependencies?.forEach((dataDependency) => {
    const dependentFields = Object.entries<any>(formDefinition.fields)
      .filter(([_key, value]) => value?.dependentData == dataDependency.key)
      .map(([key, _value]) => key);

    useEffect(() => {
      updateLinkedValues(dataDependency, dependentFields);
    }, [values?.[dataDependency.key]]);
  });

  return (
    <div className="mt-4">
      {isLoading ? (
        <div className="absolute top-0 left-0 z-30 w-full h-full bg-white bg-opacity-50">
          <div className="flex items-center justify-center h-full">
            <div className="p-3 text-xl">
              <FaSpinner className="animate-spin" />
            </div>
          </div>
        </div>
      ) : null}
      {(
        Object.entries(formDefinition.fields) as Array<
          [string, FieldDefinition<any>]
        >
      ).map(([key, field]) => {
        if (!field) {
          return null;
        }

        const isMultiLangField = !!field.multiLang;
        const Field = isMultiLangField
          ? useCallback(toMultiLangField(field.component.renderer), [field])
          : field.component.renderer;

        const updateValue = (v: any) => {
          if (field.controlledFields) {
            setValues({
              ...values,
              ...v,
            });
          } else {
            setValues({
              ...values,
              [key]: v,
            });
          }
        };

        let value = field.controlledFields
          ? pick<any, any>(field.controlledFields, values)
          : values[key];

        const dependencyValue =
          field.dependentData && values?.extraSource?.[field.dependentData];

        const shouldDisplay =
          field.displayIf?.(values) ??
          (!field.dependentData || !!dependencyValue) ??
          true;

        const isLinked = values?.["linkedFields"]?.includes(key);
        if ((isLinked || field.readOnly) && field.dependentData) {
          value =
            field.controlledFields && dependencyValue
              ? pick<any, any>(field.controlledFields, dependencyValue)
              : dependencyValue?.[key];

          // Initialize the de field (currentLanguage) with the value of the supportedLanguage
          // See comment in detail/index.tsx file regarding multilang onepagers
          if (value && field.multiLang) {
            value = {
              ...value,
              [currentLanguage]: value?.[supportedLanguage],
            };
          }
        }

        const handleLinkUnlink = () => {
          if (!isLinked) {
            setValues({
              ...values,
              linkedFields: [...(values?.["linkedFields"] ?? []), key],
            });
          } else {
            // Blocks can contains also pictures.
            // When unlinking a picture it is best to remove the picture
            // from the values otherwise the picture would need to be copied
            // as a new block picture -> This need to be an explicit action
            const linkedValues =
              field.component == blockPictureField
                ? {}
                : field.controlledFields && dependencyValue
                  ? pick<any, any>(field.controlledFields, dependencyValue)
                  : { [key]: dependencyValue?.[key] };

            setValues({
              ...values,
              ...linkedValues,
              linkedFields: (values?.["linkedFields"] ?? [])?.filter(
                (k) => k != key,
              ),
            });
          }
        };

        const exportableField =
          field.hidableFromExport && values?.["exportedFields"]?.includes(key);

        const handleShowHide = () => {
          if (exportableField) {
            setValues({
              ...values,
              exportedFields: (values?.["exportedFields"] ?? [])?.filter(
                field.controlledFields
                  ? (k) => !field.controlledFields?.includes(k)
                  : (k) => k != key,
              ),
            });
          } else {
            setValues({
              ...values,
              exportedFields: [
                ...(values?.["exportedFields"] ?? []),
                ...(field.controlledFields ? field.controlledFields : [key]),
              ],
            });
          }
        };

        const renderedField = (
          <Field
            key={key}
            value={value}
            disabled={field.props?.disabled || isLinked || field.readOnly}
            onChange={updateValue}
            language={currentLanguage}
            supportedLanguage={supportedLanguage}
            entity={values}
            readOnly={field.readOnly}
            {...field.props}
          />
        );

        return shouldDisplay ? (
          field.useFullWidth ? (
            renderedField
          ) : (
            <div
              className="grid grid-cols-1 gap-4 my-8 sm:grid-cols-3"
              key={key}
            >
              <div className="col-span-1">
                <div className="flex justify-between break-word">
                  {field.hidableFromExport ? (
                    <div className="w-6 mt-1 mr-4">
                      <Popup
                        content={t("exportField")}
                        disabled={field.readOnly ?? false}
                      >
                        <BoolField
                          value={exportableField}
                          disabled={field.readOnly ?? false}
                          onChange={handleShowHide}
                        />
                      </Popup>
                    </div>
                  ) : null}
                  <FieldName field={field} values={values} />
                </div>
              </div>
              <div className="col-span-2">
                <div className="flex flex-row items-start content-start">
                  <div className="flex-grow">{renderedField}</div>
                  {field.dependentData && !field.useFullWidth ? (
                    <div className="mt-1 ml-4">
                      <Popup
                        content={t("linkField")}
                        disabled={field.readOnly ?? false}
                      >
                        <SingleIconButton
                          disabled={field.readOnly ?? false}
                          onClick={handleLinkUnlink}
                        >
                          {isLinked || field.readOnly ? (
                            <MdLink
                              size="1.5em"
                              className={
                                (field.readOnly ?? false)
                                  ? "text-gray-200"
                                  : "text-blue-500"
                              }
                            />
                          ) : (
                            <MdLinkOff
                              size="1.5em"
                              className={
                                (field.readOnly ?? false)
                                  ? "text-gray-200"
                                  : "text-blue-500"
                              }
                            />
                          )}
                        </SingleIconButton>
                      </Popup>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )
        ) : null;
      })}
    </div>
  );
};

const FieldName = ({
  field,
  values,
}: {
  field: FieldDefinition<any>;
  values: any;
}) => {
  const { t } = useTranslation(["form"]);

  const descriptionKey = field.descriptionKey
    ? field.descriptionKey(values)
    : field.name;

  return (
    <div className="flex-1">
      <div className="font-medium whitespace-pre-line">
        {t(`form:fieldName:${field.name}` as any) + (field.required ? "*" : "")}
      </div>
      <div className="text-xs text-gray-500 whitespace-pre-line">
        {t(`form:fieldDescription:${descriptionKey}` as any)}
      </div>
    </div>
  );
};
