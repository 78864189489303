import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { GenericFieldProps } from "./fieldProps";
import Select from "react-select";
import { customStyles, i18nOptions } from "./multiSelectField";

import { ALL_CLIENTS_QUERY } from "queries/newProject";

export type ClientsFieldProps = GenericFieldProps<string>;

type OptionType = {
  id: string;
  name: string;
};

const ClientsField = ({
  value,
  onChange,
  disabled = false,
}: ClientsFieldProps): JSX.Element => {
  const { t } = useTranslation();
  const { data } = useQuery(ALL_CLIENTS_QUERY);
  const clients = data && data.clients;

  const optionMap = (c: OptionType) => ({
    value: c.id,
    label: c.name,
  });

  const selectedOption = clients?.find((c) => c.id === value);

  return (
    <div>
      <Select
        styles={customStyles}
        options={clients?.map(optionMap)}
        isDisabled={disabled}
        {...i18nOptions(t)}
        value={{
          value: selectedOption?.value,
          label: selectedOption?.name ?? "",
        }}
        onChange={
          onChange &&
          ((newValue: any) => {
            onChange(newValue?.value);
          })
        }
      />
    </div>
  );
};

export const clientsField = {
  renderer: ClientsField,
};
