import { gql } from "@apollo/client";

import { multiLangString } from "./common";
import { employeeFragment } from "./newEmployee";
import { newReferenceFragment } from "./references";

// QUERIES

export const EMPLOYEE_WORD_QUERY = gql`
  ${employeeFragment}
  ${newReferenceFragment}
  query getEmployeeCv($id: ID!) {
    employee(id: $id) {
      ...Employee_fields
      profilePicture(size: 504)
      colorProfilePicture(size: 504)
      references {
        ...NewReference_fields
        project {
          id
          name ${multiLangString}
          client {
            id
            name
            country
          }
          country
          clientHidden
        }
        parentReference {
          ...NewReference_fields
          parentReference {
            ...NewReference_fields
          }
        }
      }
    }
  }
`;

export const SINGLE_REFERENCE_WORD_QUERY = gql`
  ${newReferenceFragment}
  query getSingleReference($id: ID!) {
    reference(id: $id) {
      ...NewReference_fields
      project {
        id
        name ${multiLangString}
        client {
          id
          name
          country
        }
        clientHidden
        country
      }
      parentReference {
        ...NewReference_fields
        parentReference {
          ...NewReference_fields
        }
      }
    }
  }
`;
