import React from "react";
import { groupBy } from "ramda";
import { VersionedEntity } from "components/editVersioned/genericForm";
import {
  NewReference,
  NewProject,
  Client,
  Employee,
  ProjectTeamMember,
} from "generated/graphql";
import { projectFormDefinition } from "components/editVersioned/editProject";
import { employeeFormDefinition } from "components/editVersioned/editEmployee";
import { formatProjectId } from "pages/newProject/header";

export const draftGroups = {
  project: {
    getGroupEntity: (draft: NewReference | NewProject): Partial<NewProject> => {
      let project;
      switch (draft.__typename) {
        case "NewProject":
          project = draft;
          break;
        case "NewReference":
          project = draft.project;
          break;
      }
      return project;
    },
    title: (t, m, entity: NewReference | NewProject) => {
      const project = draftGroups.project.getGroupEntity(entity);
      const projectName = m(project.name);
      const projectID = formatProjectId(
        project.abacusProjectId
          ? project.abacusProjectId
          : `9${project.archivedProjectId}000`,
      );
      return `${t("drafts:groupTitle.project", {
        id: projectID,
        name: projectName,
      })}`;
    },
    link: (_t, entity: NewReference | NewProject) => {
      const project = draftGroups.project.getGroupEntity(entity);
      return projectFormDefinition.link(project);
    },
  },
  employee: {
    getGroupEntity: (
      draft: Employee | NewReference | ProjectTeamMember,
    ): Partial<Employee> => {
      let employee;
      switch (draft.__typename) {
        case "Employee":
          employee = draft;
          break;
        case "NewReference":
        case "ProjectTeamMember":
          employee = draft.employee;
          break;
      }
      return employee;
    },
    title: (t, _m, entity: Employee | NewReference | ProjectTeamMember) => {
      const employee = draftGroups.employee.getGroupEntity(entity);
      return t("drafts:groupTitle.employee", { name: employee?.fullName });
    },
    link: (_t, entity: Employee | NewReference | ProjectTeamMember) => {
      const employee = draftGroups.employee.getGroupEntity(entity);
      return employeeFormDefinition.link(employee);
    },
  },
  single: {
    title: (t, _m, entity, formDefinition) => {
      const Title = formDefinition.title;
      return <Title entity={entity} t={t} />;
    },
    link: (_t, entity, formDefintion) => {
      return formDefintion.link(entity);
    },
  },
};

type GroupType = keyof typeof draftGroups;

export type GroupableEntity = {
  data: VersionedEntity;
  groupId: string;
  groupType: GroupType;
};

export type DraftGroup = {
  drafts: GroupableEntity[];
  approveActionMapping: any;
  rejectActionMapping: any;
  resetActionMapping: any;
};

export const byGroupId = groupBy((item: GroupableEntity) => item.groupId);

const notAutomaticChanger = (d: VersionedEntity) =>
  d?.modifiedBy?.__typename !== "AutomatedChanger";
const automaticChanger = (d: VersionedEntity) =>
  d?.modifiedBy?.__typename === "AutomatedChanger";

export const newestFirst = (a: GroupableEntity, b: GroupableEntity) =>
  (a?.data?.insertedAt ?? 0) > (b?.data?.insertedAt ?? 0) ? -1 : 1;

const setType =
  (groupType: GroupType, groupId = "id") =>
  (d: VersionedEntity): GroupableEntity => ({
    data: d,
    groupId: d?.approved?.[groupId] ?? d?.[groupId] ?? "0",
    groupType,
  });

const filterProjects = ({
  projectDrafts,
  referenceDrafts,
}: {
  projectDrafts: NewProject[];
  referenceDrafts: NewReference[];
}) => {
  return [
    ...projectDrafts.filter(notAutomaticChanger).map(setType("project")),
    ...referenceDrafts
      .filter(notAutomaticChanger)
      .filter((d) => !!d.projectId)
      .map(setType("project", "projectId")),
  ];
};

const filterUserProjects = (
  {
    projectDrafts,
    referenceDrafts,
  }: {
    projectDrafts: NewProject[];
    referenceDrafts: NewReference[];
  },
  currentUser,
) => {
  return [
    ...projectDrafts
      .filter(notAutomaticChanger)
      .filter(
        (projectDraft) =>
          projectDraft.invoicingResponsible?.activeDirectoryId === currentUser,
      )
      .map(setType("project")),
    ...referenceDrafts
      .filter(notAutomaticChanger)
      .filter((d) => !!d.projectId)
      .filter(
        (referenceDraft) =>
          referenceDraft?.project?.invoicingResponsible?.activeDirectoryId ===
          currentUser,
      )
      .map(setType("project", "projectId")),
  ];
};

const filterUnassignedProjects = ({
  projectDrafts,
  referenceDrafts,
}: {
  projectDrafts: NewProject[];
  referenceDrafts: NewReference[];
}) => {
  return [
    ...projectDrafts
      .filter(notAutomaticChanger)
      .filter(
        (projectDraft) =>
          projectDraft?.invoicingResponsible === null ||
          projectDraft?.invoicingResponsible?.isFormerEmployee,
      )
      .map(setType("project")),
    ...referenceDrafts
      .filter(notAutomaticChanger)
      .filter((d) => !!d.projectId)
      .filter(
        (referenceDraft) =>
          referenceDraft?.project?.invoicingResponsible === null ||
          referenceDraft?.project?.invoicingResponsible?.isFormerEmployee,
      )
      .map(setType("project", "projectId")),
  ];
};

const filterClients = ({ clientDrafts }: { clientDrafts: Client[] }) => {
  return clientDrafts.filter(notAutomaticChanger).map(setType("single"));
};

const filterEmployees = ({
  employeeDrafts,
  referenceDrafts,
  projectTeamMemberDrafts,
}: {
  employeeDrafts: Employee[];
  referenceDrafts: NewReference[];
  projectTeamMemberDrafts: ProjectTeamMember[];
}) => {
  return [
    ...employeeDrafts.filter(notAutomaticChanger).map(setType("employee")),
    ...referenceDrafts
      .filter(notAutomaticChanger)
      .filter((d) => !d.projectId)
      .map(setType("employee", "employeeId")),
    ...projectTeamMemberDrafts
      .filter(notAutomaticChanger)
      .map(setType("employee", "employeeId")),
  ];
};

const filterAutomaticChanges = ({
  projectDrafts,
  clientDrafts,
  employeeDrafts,
  referenceDrafts,
  projectTeamMemberDrafts,
}: {
  projectDrafts: NewProject[];
  clientDrafts: Client[];
  employeeDrafts: Employee[];
  referenceDrafts: NewReference[];
  projectTeamMemberDrafts: ProjectTeamMember[];
}) => {
  return [
    ...projectDrafts.filter(automaticChanger).map(setType("single")),
    ...clientDrafts.filter(automaticChanger).map(setType("single")),
    ...employeeDrafts.filter(automaticChanger).map(setType("single")),
    ...referenceDrafts.filter(automaticChanger).map(setType("single")),
    ...projectTeamMemberDrafts.filter(automaticChanger).map(setType("single")),
  ];
};

const filterStillEditing = ({
  projectDrafts,
  clientDrafts,
  employeeDrafts,
  referenceDrafts,
  projectTeamMemberDrafts,
}: {
  projectDrafts: NewProject[];
  clientDrafts: Client[];
  employeeDrafts: Employee[];
  referenceDrafts: NewReference[];
  projectTeamMemberDrafts: ProjectTeamMember[];
}) => {
  return [
    ...projectDrafts.map(setType("single")),
    ...clientDrafts.map(setType("single")),
    ...employeeDrafts.map(setType("single")),
    ...referenceDrafts.map(setType("single")),
    ...projectTeamMemberDrafts.map(setType("single")),
  ];
};

export const filterDrafts = (
  drafts,
  category: string,
  currentUserActiveDirectoryId?: string,
) => {
  switch (category) {
    case "allProjects":
      return filterProjects(drafts);
    case "myProjects":
      return filterUserProjects(drafts, currentUserActiveDirectoryId);
    case "unassignedProjects":
      return filterUnassignedProjects(drafts);
    case "employees":
      return filterEmployees(drafts);
    case "clients":
      return filterClients(drafts);
    case "automaticChanges":
      return filterAutomaticChanges(drafts);
    case "stillEditing":
      return filterStillEditing(drafts);
    default:
      throw new Error("No category supplied for filterDrafts");
  }
};
