import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { FaRedo } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";

import { useMultiLang } from "helpers/multiLang";
import { Modal } from "components/modal";
import { IconButton } from "components/button";
import { customStyles, i18nOptions } from "components/form/multiSelectField";

import { Table, Item } from "./table";

const ConfirmModal = ({
  showConfirmReplace,
  setShowConfirmReplace,
  item,
  itemToMergeInto,
  actions,
  close,
}) => {
  const { t } = useTranslation(["reference-data", "common"]);

  return (
    <Modal
      size="small"
      title={
        <div className="px-6 py-8 text-2xl font-medium text-red-500">
          {t("reference-data:replace")}
        </div>
      }
      open={showConfirmReplace}
      close={() => setShowConfirmReplace(false)}
      actions={
        <div className="flex flex-row space-x-2">
          <IconButton
            type="secondary"
            Icon={IoMdClose}
            onClick={() => setShowConfirmReplace(false)}
          >
            {t("common:cancel")}
          </IconButton>
          <IconButton
            type="soft-warning"
            Icon={FaRedo}
            onClick={() => {
              if (itemToMergeInto) {
                actions.merge(itemToMergeInto.id, item.id);
                setShowConfirmReplace(false);
                close();
              }
            }}
          >
            {t("reference-data:replace")}
          </IconButton>
        </div>
      }
    >
      <div className="px-6 my-8">{t("reference-data:confirmReplace")}</div>
    </Modal>
  );
};

export const MergeItemModal: React.FC<{
  allItems: Item[];
  item: Item;
  close: () => void;
  actions: any;
}> = ({ close, item, actions, allItems }) => {
  const { t } = useTranslation(["reference-data", "common"]);
  const m = useMultiLang();
  const [showConfirmReplace, setShowConfirmReplace] = useState(false);
  const [itemToMergeInto, setItemToMergeInto] = useState<Item | null>(null);

  return (
    <Modal
      actions={
        <div className="flex justify-between w-full">
          <div className="flex items-center px-6 space-x-2">
            <IconButton type="secondary" Icon={IoMdClose} onClick={close}>
              {t("common:close")}
            </IconButton>
            <IconButton
              type="soft-warning"
              Icon={FaRedo}
              onClick={() => {
                setShowConfirmReplace(true);
              }}
            >
              {t("reference-data:replace")}
            </IconButton>
          </div>
          <ConfirmModal
            showConfirmReplace={showConfirmReplace}
            setShowConfirmReplace={setShowConfirmReplace}
            item={item}
            itemToMergeInto={itemToMergeInto}
            actions={actions}
            close={close}
          />
        </div>
      }
      open={true}
      close={close}
      title={
        <div className="px-6 py-8 text-2xl font-medium text-red-500">
          {`${t("reference-data:replace")} ${m(item.name)}`}
        </div>
      }
    >
      <>
        <div className="p-4">
          <p className="mt-4 mb-8">
            {t("reference-data:replaceWithInfo", { itemName: m(item.name) })}
          </p>
          <Table items={[item]} />
        </div>
        <div
          className="grid gap-4 px-6 my-8"
          style={{ gridTemplateColumns: "1fr 3fr" }}
        >
          <div>{t("reference-data:replaceWith")}</div>
          <div className="text-sm">
            <Select
              styles={customStyles}
              options={allItems
                .filter((otherItem) => otherItem.id !== item.id)
                .map((item) => ({
                  value: item,
                  label: item["employeesCount"]
                    ? `${m(item.name)} (${item?.["employeesCount"]})`
                    : m(item.name),
                }))
                .sort((a, b) => (a.label < b.label ? -1 : 1))}
              {...i18nOptions(t)}
              isClearable
              value={
                itemToMergeInto
                  ? {
                      value: itemToMergeInto,
                      label: m(itemToMergeInto.name),
                    }
                  : null
              }
              onChange={(newValue: any) => {
                setItemToMergeInto(newValue?.value ?? null);
              }}
            />
          </div>
        </div>
        {itemToMergeInto ? (
          <div className="p-4">
            <Table items={[itemToMergeInto]} />
          </div>
        ) : null}
      </>
    </Modal>
  );
};
