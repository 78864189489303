import { useQuery } from "@apollo/client";
import React, { useEffect } from "react";

import { CLIENT_QUERY } from "queries/newClient";

import Client from "./client";
import { useLocation } from "wouter";

const NewClientShell = ({
  id,
  abacusId,
  tab,
}: {
  id?: string;
  abacusId?: string;
  tab?: string;
}): JSX.Element => {
  const { data, loading } = useQuery(CLIENT_QUERY, {
    variables: { id, abacusId },
  });
  const [_location, navigate] = useLocation();
  const params: { tab?: string } = { tab };
  const loadedId = id ?? data?.client?.id;

  useEffect(() => {
    if (!id && abacusId && loadedId) {
      const tab = params.tab ? `/${params.tab}` : "";
      navigate(`/client/${loadedId}${tab}`, { replace: true });
    }
  }, [loadedId]);

  return (
    <Client
      id={loadedId}
      client={data && data.client}
      loading={loading}
      tab={tab}
    />
  );
};

export default NewClientShell;
