import React, { useState } from "react";
import { usePopper } from "react-popper";
import { VirtualElement } from "@popperjs/core";
import "./styles.scss";
import { Link } from "wouter";
import { GrMail } from "react-icons/gr";
import { FaPhone } from "react-icons/fa";
import { IconType } from "react-icons";
import classNames from "classnames";
import { Avatar } from "pages/newEmployee/avatar";

export type PopupProps = {
  children: React.ReactNode;
  image?: any;
  phone?: string;
  email?: string;
  profileLink: string;
  name: string;
};

export const ContactPopup: React.FC<PopupProps> = ({
  children,
  image,
  phone,
  email,
  profileLink,
  name,
}) => {
  //this positioning states are coming from popper.js https://popper.js.org/docs/v2/tutorial/
  const [referenceElement, setReferenceElement] = useState<
    Element | VirtualElement | null | undefined
  >(null);
  const [popperElement, setPopperElement] = useState<
    HTMLElement | null | undefined
  >(null);
  const [arrowElement, setArrowElement] = useState<
    string | HTMLElement | null | undefined
  >(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: "arrow", options: { element: arrowElement } },
      { name: "offset", options: { offset: [3, 3] } },
      {
        name: "flip",
        options: {
          padding: 10,
          flipVariations: true,
          fallbackPlacements: ["right", "left", "top", "bottom"],
        },
      },
    ],
  });

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      onMouseEnter={() => {
        setIsOpen(true);
      }}
      onMouseLeave={() => setIsOpen(false)}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div ref={setReferenceElement}>{children}</div>

      {isOpen && (
        <div
          id={"tooltip"}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <div className="hidden sm:flex flex-row px-6 py-4 gap-x-6 items-center ">
            <Link href={profileLink}>
              <Avatar url={image} hoverStyle size={"medium"} />
            </Link>
            <div className="flex flex-col gap-y-0.5 items-start">
              <Link
                className="text-gray-900 text-base font-semibold hover:text-gray-500"
                href={profileLink}
              >
                {name}
              </Link>
              <ContactLink
                Icon={GrMail}
                label={email}
                link={`mailto:${email}`}
                newTab
              />
              <ContactLink
                Icon={FaPhone}
                label={phone}
                link={`tel:${phone}`}
                newTab
              />
            </div>
          </div>
          {/*mobile version: all items in a column */}
          <div className="flex flex-col px-6 py-4 gap-y-1 items-center sm:hidden">
            <Link
              className="group flex flex-col gap-y-4 items-center hover:cursor-pointer"
              href={profileLink}
            >
              <Avatar url={image} hoverStyle />
              <span className="text-base font-semibold leading-4 text-gray-900 group-hover:text-gray-500 group-hover:cursor-pointer transition-transform ease-in-out">
                {name}
              </span>
            </Link>

            <div className="flex flex-col items-center">
              <ContactLink
                Icon={GrMail}
                label={email}
                link={`mailto:${email}`}
                newTab
              />
              <ContactLink
                Icon={FaPhone}
                label={phone}
                link={`tel:${phone}`}
                newTab
              />
            </div>
          </div>
          <div id={"arrow"} ref={setArrowElement} style={styles.arrow} />
        </div>
      )}
    </div>
  );
};

export type ContactLinkProps = {
  link: string;
  label?: string | undefined;
  Icon: IconType;
  newTab: boolean;
};

export const ContactLink = ({
  link,
  label,
  Icon,
  newTab = false,
}: ContactLinkProps): JSX.Element => {
  return (
    <>
      {label ? (
        <a
          target={newTab ? "_blank" : undefined}
          className={classNames(
            "flex items-center font-normal text-base group text-blue-500",
          )}
          href={link}
          rel="noreferrer"
        >
          <div className="text-blue-500 group-hover:text-gray-900">
            <Icon />
          </div>
          <div
            className={classNames("ml-2 group-hover:text-gray-900 transition")}
          >
            {label}
          </div>
        </a>
      ) : null}
    </>
  );
};
