import { DocumentNode, useQuery } from "@apollo/client";
import { useState } from "react";
import { useInfiniteScrollAlternative } from "./useInfiniteScrollAlternative";

export const useInfiniteScrollWithGraphql = ({
  pageSize = 10,
  gqlQuery,
  queryDataAccessor,
  extraVariables,
}: {
  pageSize: number;
  gqlQuery: DocumentNode;
  queryDataAccessor: string;
  extraVariables: any;
}): { isLoading: boolean; data: any; refetchAll: () => void } => {
  const { data, fetchMore, refetch } = useQuery(gqlQuery, {
    variables: { ...extraVariables, limit: pageSize, offset: 0 },
    onCompleted: () => setIsLoading(false),
  });

  const [hasMoreData, setHasMoreData] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const refetchAll = () => {
    setIsLoading(true);
    unlockInfiniteScroll();
    setHasMoreData(true);
    refetch().finally(() => {
      setIsLoading(false);
    });
  };

  const fetchData = (offset: number) => {
    setIsLoading(true);

    lockInfiniteScroll();
    fetchMore({
      variables: { ...extraVariables, limit: pageSize, offset },
    })
      .then((results: any) => {
        if (results?.data?.[queryDataAccessor]?.length === 0) {
          setHasMoreData(false);
        }
      })
      .finally(() => {
        setIsLoading(false);

        if (hasMoreData) {
          unlockInfiniteScroll();
        }
      });
  };

  const [lockInfiniteScroll, unlockInfiniteScroll] =
    useInfiniteScrollAlternative(() => {
      const offset = data?.[queryDataAccessor]?.length ?? 0;

      if (hasMoreData && !isLoading) {
        fetchData(offset);
      }
    });

  return { isLoading, data, refetchAll };
};
