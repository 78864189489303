type IsFeatureEnabled = (name: string, url?: string) => boolean;

const isFeatureEnabled: IsFeatureEnabled = (
  name,
  url = window.location.href,
) => {
  try {
    const featuresString =
      getQueryStringValue(parseUrl(url).query, "features") ||
      sessionStorage.getItem("features") ||
      localStorage.getItem("features") ||
      "";
    if (featuresString === "all") {
      return true;
    }
    const features = featuresString.split(",");
    return contains(features, name);
  } catch (_err) {
    return false;
  }
};

const getQueryStringValue = (query: string, key: string) => {
  const res = decodeURIComponent(
    query.replace(
      new RegExp(
        "^(?:.*[&\\?]" +
          encodeURIComponent(key).replace(/[.+*]/g, "\\$&") +
          "(?:\\=([^&]*))?)?.*$",
        "i",
      ),
      "$1",
    ),
  );
  if (res === "") {
    return null;
  }
  return res;
};

const parseUrl = (url: string) => {
  const parser = document.createElement("a");
  parser.href = url;

  return {
    hash: parser.hash, // => "#hash"
    host: parser.host, // => "example.com:3000"
    hostname: parser.hostname, // => "example.com"
    pathname: parser.pathname, // => "/pathname/"
    port: parser.port, // => "3000"
    protocol: parser.protocol, // => "http:"
    query: parser.search, // => "?foo=bar"
  };
};

// Replacement for Array.prototype.includes to avoid changing Typescript settings or adding polyfills
const contains = (array: string[], value: string): boolean => {
  return array.indexOf(value) !== -1;
};

export default isFeatureEnabled;
