import React from "react";
import { GenericFieldProps } from "./fieldProps";

export type YearFieldProps = GenericFieldProps<string | null>;

export const YearField = ({
  value,
  onChange,
  disabled = false,
  placeholder,
}: YearFieldProps) => {
  const year = value ? parseInt(value, 10) : "";

  return (
    <input
      type="number"
      disabled={disabled}
      placeholder={placeholder ?? ""}
      className="block w-full p-2 border rounded-md shadow-sm outline-none border-grey-300 focus:ring-grey-900 focus:ring-2 focus:border-grey-900 sm:text-sm"
      min={1930}
      max={3000}
      value={year}
      onChange={(e) => {
        if (e.currentTarget.value !== "") {
          onChange(`${e.currentTarget.value}-01-01`);
        } else {
          onChange(null);
        }
      }}
    />
  );
};

export const yearField = {
  renderer: YearField,
};
