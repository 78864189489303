import React from "react";
import { useQuery } from "@apollo/client";
import { Link } from "wouter";

import { PROFILE_QUERY } from "queries/notification";
import { Employee } from "generated/graphql";
import { MainMenu } from "./components/mainMenu";
import { UserMenu } from "./components/userMenu";
import { DesktopNav, MobileNav } from "./components/navigation";
import { LanguageSwitcher } from "./components/languageSwitcher";
// @ts-ignore
import tbfLogo from "assets/images/tbf.digital-logo.svg";

const SearchIcon = () => {
  return (
    <svg
      focusable="false"
      preserveAspectRatio="xMidYMid meet"
      fill="currentColor"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      role="img"
      className="h-7 w-7 md:h-5 md:w-5"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15,14.3L10.7,10c1.9-2.3,1.6-5.8-0.7-7.7S4.2,0.7,2.3,3S0.7,8.8,3,10.7c2,1.7,5,1.7,7,0l4.3,4.3L15,14.3z M2,6.5 C2,4,4,2,6.5,2S11,4,11,6.5S9,11,6.5,11S2,9,2,6.5z"></path>
    </svg>
  );
};

const navigations = [
  {
    label: "people",
    href: "https://next.tbf.digital/people/employees",
  },
  {
    label: "contributions",
    href: "https://next.tbf.digital/stories",
  },
  {
    label: "offers",
    href: "https://next.tbf.digital/offers",
  },
];

export const TopBar: React.FC = () => {
  const currentPerson = useQuery(PROFILE_QUERY);

  const me: Employee = currentPerson.data?.me;
  const isContentAdmin = !!me?.isContentAdmin;
  const isWebsiteEditor = !!me?.isWebsiteEditor;
  const activeDirectoryId = me?.activeDirectoryId ?? "";

  return (
    <div className="sticky z-40 h-12 top-0 border-gray-10 fluid-container flex w-full shrink-0 items-center justify-between overflow-hidden border-b bg-white">
      <div className="flex items-center">
        <MobileNav navigations={navigations} />
        <a
          className="!px-0 text-secondary hover:bg-gray-10 flex items-center bg-white !px-4 !py-[0.9375rem] focus-visible:ring-inset button focus bg-white hover:bg-gray-10"
          target="_self"
          href="https://next.tbf.digital"
        >
          <img src={tbfLogo} className="h-3 md:h-4" alt="TBF + Partner AG" />
        </a>
        <div className="hidden px-4 py-3 md:block">
          <div className="h-full w-px flex-shrink-0 bg-gray-100"></div>
        </div>
        <DesktopNav navigations={navigations} />
      </div>
      <div className="flex gap-1">
        <Link
          className="focus-visible:ring-inset button focus bg-white hover:bg-gray-10"
          href="/results"
        >
          <SearchIcon />
        </Link>
        <LanguageSwitcher />
        <UserMenu
          isContentAdmin={isContentAdmin}
          activeDirectoryId={activeDirectoryId}
        />
        <MainMenu
          isContentAdmin={isContentAdmin}
          isWebsiteEditor={isWebsiteEditor}
        />
      </div>
    </div>
  );
};
