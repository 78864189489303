import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { GenericField, GenericFieldProps } from "./fieldProps";
import { SimpleMDEWordCount } from "helpers/ui/markdownEditor";
import { MultiLang } from "helpers/ui/multiLang";

export type MarkDownFieldProps = GenericFieldProps<string> & {
  maxWordCount?: number;
  maxCharCount?: number;
  initialText?: string;
  options?: Record<string, unknown>;
  onInput?: () => void;
  minimal?: boolean;
  placeholder?: string;
  placeholderKey?: string;
  placeholderFn?: (value: MultiLang) => string;
};

export const MarkDownField: React.FC<MarkDownFieldProps> = ({
  value,
  multiLangValue,
  onChange,
  onInput,
  options = {},
  disabled = false,
  maxWordCount = 0,
  maxCharCount = 0,
  readOnly = false,
  initialText,
  minimal = false,
  placeholder,
  placeholderKey,
  placeholderFn,
}) => {
  const { t } = useTranslation();

  const computedInitialText = initialText ?? "";
  if (readOnly) {
    return (
      <div className="px-10 pb-4 text-sm text-gray-500">
        <ReactMarkdown>{value}</ReactMarkdown>
      </div>
    );
  }

  if (disabled) {
    return (
      <div className="p-4 text-sm border rounded b-grey-300">
        <ReactMarkdown>{value}</ReactMarkdown>
      </div>
    );
  }

  const displayedPlaceholder = useMemo(
    () =>
      placeholderKey
        ? t(placeholderKey)
        : placeholderFn
          ? placeholderFn(multiLangValue)
          : (placeholder ?? ""),
    [],
  );

  return (
    <div className="relative text-sm">
      <SimpleMDEWordCount
        value={value || computedInitialText}
        onChange={onChange}
        maxWordCount={maxWordCount}
        maxCharCount={maxCharCount}
        options={{
          ...options,
          placeholder: displayedPlaceholder,
          hideIcons: ["fullscreen", "image", "side-by-side"],
          spellChecker: false,
          status: false,
          toolbar: minimal
            ? ["bold", "italic"]
            : ["bold", "italic", "unordered-list"],
        }}
        events={{
          keypress() {
            onInput?.();
          },
          paste() {
            onInput?.();
          },
        }}
        className="postbox--body-input"
      />
    </div>
  );
};

export const markDownField: GenericField<string> = {
  renderer: MarkDownField,
  equals: (a, b) => {
    return (a ?? "") === (b ?? "");
  },
};
