import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import { GenericField, GenericFieldProps } from "./fieldProps";

type AdditionalFields = {
  options: string[];
  translationKey?: string;
};

export type MultiSelectFieldProps = GenericFieldProps<string[]> &
  AdditionalFields;

export const customStyles = {
  clearIndicator: (provided, _state) => {
    return { ...provided, cursor: "pointer", ":hover": { color: "#266782" } };
  },
  input: (provided, _state) => {
    return { ...provided, ":focus": { outline: "none" } };
  },
  dropdownIndicator: (provided, _state) => {
    return {
      ...provided,
      cursor: "pointer",
      ":hover": { color: "#266782" },
    };
  },
  multiValue: (provided, _state) => {
    return { ...provided, backgroundColor: "#f3f6f8", borderRadius: "0.25rem" };
  },
  multiValueLabel: (provided, _state) => {
    return {
      ...provided,
      borderColor: "#f3f6f8",
      backgroundColor: "#f3f6f8",
      borderTopLeftRadius: "0.25rem",
      borderBottomLeftRadius: "0.25rem",
      color: "#266782",
    };
  },
  multiValueRemove: (provided, _state) => {
    return {
      ...provided,
      color: "#266782",
      cursor: "pointer",
      backgroundColor: "#f3f6f8",
      borderTopRightRadius: "0.25rem",
      borderBottomRightRadius: "0.25rem",
      ":hover": { backgroundColor: "#e8eff2", color: "#266782" },
    };
  },
};

export const i18nOptions = (t: any) => ({
  placeholder: t("form:selectPlaceholder"),
  noOptionsMessage: () => t("form:noOptions"),
});

const MultiSelectField = ({
  value,
  onChange,
  disabled,
  options,
  language,
  translationKey = "abacusProjects",
}: MultiSelectFieldProps) => {
  const { t } = useTranslation();

  const optionMap = (v: string) => ({
    value: v,
    label: t(`${translationKey}:${v}` as any, { lng: language ?? "de" }),
  });

  return (
    <div className="text-sm">
      <Select
        isMulti
        styles={customStyles}
        options={options.map(optionMap)}
        isDisabled={disabled}
        value={value?.map(optionMap) as any}
        {...i18nOptions(t)}
        onChange={
          onChange
            ? (newValues) => {
                onChange((newValues as any).map((v) => v.value));
              }
            : undefined
        }
      />
    </div>
  );
};

export const multiSelectField: GenericField<string[]> = {
  renderer: MultiSelectField,
  equals: (a, b) => {
    return (a?.join(",") ?? "") === (b?.join(",") ?? "");
  },
};
