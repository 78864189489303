import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "context/app";
import Creatable from "react-select/creatable";
import { customStyles, i18nOptions } from "./multiSelectField";

import { GenericField, GenericFieldProps } from "./fieldProps";
import { ListField } from "./ListField";
import { TextField } from "./textField";
import { MultiLang } from "helpers/ui/multiLang";
import { extractMulti, stringifyMulti } from "helpers/ui/multiLang";
import { ActionMeta } from "react-select";

type FactType = {
  id?: string;
  value: MultiLang;
  keyId?: number;
  newText?: string;
};
type FactsType = FactType[];

export type FactsFieldProps = GenericFieldProps<FactsType>;

// HELPER COMPONENT

const Fact = ({ value, disabled, onChange, language, catalogue }) => {
  const { t } = useTranslation();

  const optionToValue = (opt) => {
    if (opt.__isNew__) {
      return {
        ...value,
        keyId: null,
        newText: opt.value,
      };
    } else {
      return {
        ...value,
        keyId: opt.value,
        newText: undefined,
      };
    }
  };

  const valueToOption = (v: any) =>
    v.newText
      ? { label: v.newText, value: v.newText }
      : catalogue?.find((r) => r.value === v.keyId);

  const displayedValue = valueToOption(value);

  const handleValueChange = (newValue) => {
    const newFact = {
      ...value,
      value: { ...value.value, [language]: newValue },
    };
    onChange(newFact);
  };

  const handleFactKeyChange = (newValue: any, { action }: ActionMeta<any>) => {
    if (
      action === "create-option" ||
      action === "select-option" ||
      action === "remove-value"
    ) {
      onChange(optionToValue(newValue));
    } else {
      // On clearing
      if (onChange) {
        onChange({ ...value, keyId: null, newText: undefined });
      }
    }
  };

  return (
    <div className="flex items-center space-x-2">
      <div className="flex-1 text-sm">
        <Creatable
          styles={customStyles}
          isDisabled={disabled}
          {...i18nOptions(t)}
          isClearable
          onChange={handleFactKeyChange}
          options={catalogue}
          value={displayedValue}
        />
      </div>
      <div className="flex-1 text-sm">
        <TextField
          disabled={disabled}
          onChange={handleValueChange}
          value={value.value?.[language] ?? ""}
          language={language}
        />
      </div>
    </div>
  );
};

const FactsField = ({
  value,
  onChange,
  language,
  disabled,
}: FactsFieldProps) => {
  const { catalogues } = useContext(AppContext);

  const allFactsKey = catalogues?.factKeys?.map((factKey: any) => ({
    id: factKey.id,
    key: factKey.id,
    label: factKey.name[language ?? "de"],
    value: factKey.id,
  }));

  const newFact = { value: { de: "", fr: "", it: "", en: "" }, key: "new" };

  return (
    <ListField
      value={(value ?? []).map((item) => ({
        ...item,
        key: `${item.id}-${item.keyId}`,
      }))}
      language={language}
      onChange={onChange}
      disabled={disabled}
      render={Fact}
      newItem={newFact}
      catalogue={allFactsKey}
    />
  );
};

export const factsField: GenericField<FactsFieldProps> = {
  renderer: FactsField,
  onBeforeSave: (value) =>
    value
      ?.map((v) => ({
        value: extractMulti(v.value),
        keyId: v.keyId,
        newText: v.newText,
      }))
      .filter((v) => v.keyId > 0 || v.newText?.length > 0),
  equals: (a, b) => {
    return (
      (a
        ?.map((v) => `${stringifyMulti(v.value)}-${v.keyId}-${v.newText ?? ""}`)
        .join(",") ?? "") ===
      (b
        ?.map((v) => `${stringifyMulti(v.value)}-${v.keyId}-${v.newText ?? ""}`)
        .join(",") ?? "")
    );
  },
};
