import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { NarrowTwoColumnLayout } from "layout/twoColumnLayout";
import {
  SimpleProjectsList,
  mergeIntoMainProjects,
} from "pages/newEmployee/projectList";
import { NewProject, Client } from "generated/graphql";
import { useFuse } from "helpers/useFuse";

// MAIN COMPONENT

export const ClientProjects: React.FC<{
  projects: NewProject[];
  client: Partial<Client>;
}> = ({ projects, client }) => {
  const { t } = useTranslation(["client", "common"]);

  const [filter, setFilter] = useState("");

  const mainProjects = mergeIntoMainProjects(projects ?? []);

  const filteredProjects = useFuse(mainProjects, filter, {
    keys: ["name.de", "name.en", "name.fr", "name.it", "abacusProjectId"],
  });

  const contacts = [client, ...(client?.childClients ?? [])];

  return (
    <NarrowTwoColumnLayout
      leftContent={
        <div>
          <div className="mb-3 font-medium text-blue-500 md:block">
            {contacts.length === 1 ? t(`client:contact`) : t(`client:contacts`)}
          </div>
          {contacts.map((c) => {
            return (
              <div key={c.id} className="mb-3">
                <div>
                  {c.title} {c.name}
                </div>
                <div>{c.street}</div>
                <div>
                  {c.postalCode} {c.town}
                </div>
                <div>
                  {c.country} {c.city}
                </div>
                <div>{c.email}</div>
                <div>{c.phone}</div>
              </div>
            );
          })}
        </div>
      }
      rightContent={
        <div>
          <div className="flex justify-between w-full pt-4 mb-8">
            <div className="text-xl font-medium text-red-500">
              {t(`client:projects`)}
            </div>
            <input
              type="text"
              className="block px-2 text-blue-500 placeholder-blue-500 border border-gray-300 rounded shadow-inner outline-none md:mt-0 w-36"
              placeholder={t(`common:search`) + "..."}
              value={filter}
              onChange={(e) => setFilter(e.currentTarget.value)}
            />
          </div>

          {filteredProjects.length ? (
            <SimpleProjectsList projects={filteredProjects} />
          ) : null}
        </div>
      }
    ></NarrowTwoColumnLayout>
  );
};
