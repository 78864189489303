import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

export const Search = ({ text, setText, className = "", width = "w-36" }) => {
  const { t } = useTranslation("common");

  return (
    <input
      type="text"
      className={classNames(
        "block px-2 py-1 text-sm text-blue-500 placeholder-blue-500 border border-gray-300 rounded shadow-inner outline-none md:mt-0",
        width,
        className,
      )}
      placeholder={t(`search`) + "..."}
      value={text}
      onChange={(e) => setText(e.currentTarget.value)}
    />
  );
};
