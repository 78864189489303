import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMultiLang } from "helpers/multiLang";
import { EditReferenceModal } from "components/editVersioned/editReference";
import { IconButton } from "components/button";
import { FaEdit } from "react-icons/fa";
import { IoMdClose, IoMdSwap } from "react-icons/io";
import { Popup } from "components/popup";
import { Modal } from "components/modal";
import { NewReference } from "generated/graphql";
import classNames from "classnames";
import { useMutation } from "@apollo/client";
import { EDIT_AND_APPROVE_REFERENCE_MUTATION } from "queries/references";

const RefText = ({ reference }: { reference: Partial<NewReference> }) => {
  const m = useMultiLang();
  const text =
    m(reference.title) ||
    m(reference.project?.name) ||
    m(reference.description).split("\n")[0] ||
    reference.id;
  return <>{text}</>;
};

const ConfirmMigrationModal = ({ isOpen, setIsOpen, onConfirm }) => {
  const { t } = useTranslation(["common", "project"]);

  return (
    <Modal
      size="small"
      title={
        <div className="px-6 py-8 text-2xl font-medium text-red-500">
          {t("project:migrateSubProjectReference")}
        </div>
      }
      open={isOpen}
      close={() => setIsOpen(false)}
      actions={
        <div className="flex flex-row space-x-2">
          <IconButton
            type="secondary"
            Icon={IoMdClose}
            onClick={() => setIsOpen(false)}
          >
            {t("common:cancel")}
          </IconButton>
          <IconButton
            type="soft-warning"
            Icon={() => <IoMdSwap size="1.25em" />}
            onClick={() => {
              onConfirm();
              setIsOpen(false);
            }}
          >
            {t("project:migrateSubProjectReference")}
          </IconButton>
        </div>
      }
    >
      <div className="px-8 my-8">
        {t("project:migrateSubProjectReferenceConfirmation")}
      </div>
    </Modal>
  );
};

export const ReferencesField = ({ entity, disabled }): JSX.Element => {
  const { t } = useTranslation(["common", "project", "tendering"]);
  const [selectedId, setSelectedId] = useState<string>();
  const references = entity.projectReferences ?? entity.references ?? [];
  const [editMutation] = useMutation(EDIT_AND_APPROVE_REFERENCE_MUTATION);
  const migrateReference = (referenceId, projectId) => {
    editMutation({
      variables: {
        id: referenceId,
        fields: { projectId: projectId },
      },
      refetchQueries: ["GetNewProject"],
    });
  };

  // Entity must be a project for this to run
  const migrateAllReferences = () =>
    references.forEach((r) => {
      if (isNonMigratedReference(r)) {
        migrateReference(r.id, entity.id);
      }
    });

  // Entity must be a project for this to be true
  const unmergedReferencesExist = () =>
    references.some(
      (r) => entity.__typename == "NewProject" && r.projectId !== entity.id,
    );

  const isNonMigratedReference = (ref) =>
    entity.__typename == "NewProject" && entity.id !== ref.projectId;

  const [confirmMigrate, setConfirmMigrate] = useState(false);

  return (
    <div className="space-y-2">
      {unmergedReferencesExist() ? (
        <>
          <div className="flex justify-center mb-4">
            <IconButton
              disabled={disabled}
              className="mr-2"
              Icon={() => <IoMdSwap size="1.25em" />}
              onClick={() => {
                setConfirmMigrate(true);
              }}
            >
              {t("project:migrateSubProjectReference")}
            </IconButton>
          </div>
          <ConfirmMigrationModal
            isOpen={confirmMigrate}
            setIsOpen={setConfirmMigrate}
            onConfirm={migrateAllReferences}
          />
        </>
      ) : null}
      {references.length > 0
        ? references.map((ref) => {
            return (
              <div key={ref.id} className="flex items-center">
                <Popup content={t("project:editReference")} disabled={disabled}>
                  <IconButton
                    disabled={disabled}
                    className="mr-2"
                    Icon={() => <FaEdit size="1.25em" />}
                    onClick={() => {
                      setSelectedId(ref.id);
                    }}
                  />
                </Popup>
                <div
                  className={classNames(
                    "overflow-hidden w-48 whitespace-nowrap overflow-ellipsis",
                    { "text-gray-500": disabled },
                  )}
                >
                  <RefText reference={ref} />
                </div>
                {isNonMigratedReference(ref) ? (
                  <div className="flex text-sm text-gray-400">
                    {t("project:nonMigratedReference")}
                  </div>
                ) : null}
              </div>
            );
          })
        : t("tendering:noReferences")}
      <EditReferenceModal
        id={selectedId}
        close={() => setSelectedId(undefined)}
        open={!!selectedId}
      />
    </div>
  );
};

export const referencesField = {
  renderer: ReferencesField,
  // Make sure we don't see this on the drafts page
  equals: () => true,
  // This is a read-only field, so we strip out anything before saving
  onBeforeSave: () => undefined,
};
