export const getErrorMessage = (message: string, t: any): string => {
  if (message == "Error413") {
    return t("error:imageErrorDescription");
  } else if (message.startsWith("error:")) {
    // Attempt to use frontend translation
    return t(message);
  } else {
    return message;
  }
};
