import React from "react";
import { Link } from "wouter";
import { useTranslation } from "react-i18next";
import { NewProject } from "generated/graphql";
import { useMultiLang } from "helpers/multiLang";
import { Popup } from "components/popup";
import {
  FaHashtag,
  FaLock,
  FaCalculator,
  FaInfo,
  FaGlobe,
  FaBuilding,
  FaFolder,
  FaArchive,
} from "react-icons/fa";
import { AiFillEyeInvisible } from "react-icons/ai";

export const formatProjectId = (abacusId: string): string => {
  if (!abacusId) return "";
  if (abacusId.length <= 4) {
    // internal project
    return abacusId;
  } else {
    const start = abacusId.slice(1, 6);
    const end = abacusId.slice(6);
    return `${start}.${end}`;
  }
};

export const getShortProjectId = (abacusId?: string): string => {
  if (!abacusId) return "";
  if (abacusId.length <= 4) {
    // internal project
    return abacusId;
  } else {
    return abacusId.slice(1, 6);
  }
};

const TopInfo = ({ project }: { project: Partial<NewProject> }) => {
  const { t, i18n } = useTranslation(["project", "abacusProjects", "common"]);

  const formatInvestment = (
    language: string,
    investment: number,
    investmentCurrency?: string | null,
  ) => {
    try {
      const lang = language === "de" ? "de-CH" : language;
      const formatter = new Intl.NumberFormat(lang ?? "de-CH", {
        minimumFractionDigits: Number.isInteger(investment) ? 0 : 1,
        style: "currency",
        currency: investmentCurrency ? investmentCurrency : "CHF",
        notation: "compact",
        compactDisplay: "long",
      } as any);

      return formatter.format(investment);
    } catch (_e) {
      return `${investment} ${investmentCurrency}`;
    }
  };

  return (
    <ul className="flex flex-row flex-wrap text-sm text-gray-350">
      {(project.abacusProjectId ?? project.archivedProjectId) ? (
        <ProjectHeaderInfoItem
          icon={<FaHashtag />}
          popupContent={t("project:projectNumber")}
        >
          {formatProjectId(
            project.abacusProjectId
              ? project.abacusProjectId
              : `9${project.archivedProjectId}000`,
          )}
        </ProjectHeaderInfoItem>
      ) : null}
      <ProjectHeaderInfoItem
        icon={<FaLock />}
        popupContent={t("project:internalExternal")}
      >
        {project.isInternalProject ? "Intern" : "Extern"}
      </ProjectHeaderInfoItem>
      {/* Only show physical archived state if not already showing the archived state from abacus */}
      {project.archivedProjectId && project.status !== "archived" ? (
        <ProjectHeaderInfoItem
          icon={<FaArchive />}
          popupContent={t("common:archived_project")}
        >
          {t("common:archived_project")}
        </ProjectHeaderInfoItem>
      ) : null}
      {project.archivedProjectId && !project.abacusProjectId ? (
        <ProjectHeaderInfoItem
          icon={<FaInfo />}
          popupContent={t("project:notInAbacusExplained")}
        >
          {t("project:notInAbacus")}
        </ProjectHeaderInfoItem>
      ) : null}
      {project.investment && (
        <ProjectHeaderInfoItem
          icon={<FaCalculator />}
          popupContent={t("project:investment")}
        >
          {formatInvestment(
            i18n.language,
            project.investment,
            project?.investmentCurrency,
          )}
        </ProjectHeaderInfoItem>
      )}
      {project.status && (
        <ProjectHeaderInfoItem
          icon={<FaInfo />}
          popupContent={t("project:projectStatus")}
        >
          {t(`project:status.${project.status}` as any)}
        </ProjectHeaderInfoItem>
      )}
      {project.country && (
        <ProjectHeaderInfoItem
          icon={<FaGlobe />}
          popupContent={t("project:country")}
        >
          {t(`abacusProjects:${project.country}` as any)}
        </ProjectHeaderInfoItem>
      )}
    </ul>
  );
};

const ProjectTitle = ({ project }) => {
  const m = useMultiLang();

  return (
    <div className="mt-4 text-3xl font-medium text-red-500" data-cy="name">
      {m(project.name)}
    </div>
  );
};

const ProjectClient = ({ project }) => {
  const { t } = useTranslation(["project", "common", "form"]);

  return (
    <h3 className="mt-2">
      <Popup content={t("common:client")}>
        {project.client ? (
          <Link
            className="flex items-center mr-3 text-gray-350"
            href={`/client/${project.client?.id}`}
          >
            <div className="mr-1">
              <FaBuilding />
            </div>
            <div className="mt-1">{project.client?.name ?? ""}</div>
          </Link>
        ) : (
          <div className="flex items-center mr-3 text-gray-350">
            <div className="mr-1">
              <FaBuilding />
            </div>
            <div className="mt-1">{t("common:noClient")}</div>
          </div>
        )}
      </Popup>
      {project.clientHidden ? (
        <Popup content={t("form:fieldDescription.project.clientHidden")}>
          <div className="flex items-center mr-3 text-gray-350">
            <div className="mr-1">
              <AiFillEyeInvisible />
            </div>
            <div className="mt-1">
              {t("form:fieldName.project.clientHidden")}
            </div>
          </div>
        </Popup>
      ) : null}
    </h3>
  );
};

const ProjectHeaderInfoItem = ({ icon, children, popupContent }) => {
  return (
    <li>
      <Popup content={popupContent}>
        <div className="flex mr-3">
          <div className="mr-1 text-gray-350 mt-0.5">{icon}</div> {children}
        </div>
      </Popup>
    </li>
  );
};
const ProjectHeader: React.FC<{
  project: Partial<NewProject>;
}> = ({ project }) => {
  const { t } = useTranslation("project");

  return (
    <>
      <div className="flex-wrap items-center justify-between pt-5 md:flex md:flex-row">
        <TopInfo project={project} />
        <div>
          {project.projectFolderLink ? (
            <a
              className="flex items-center p-1 mt-2 text-xs border rounded-sm sm:mt-none border-grey-300 hover:bg-blue-500 hover:bg-opacity-5 hover:border-blue-500 hover:border-opacity-40"
              href={project.projectFolderLink}
            >
              <FaFolder />
              <div className="ml-2">{t("openProject")}</div>
            </a>
          ) : null}
        </div>
      </div>
      <ProjectTitle project={project} />
      <ProjectClient project={project} />
    </>
  );
};

export default ProjectHeader;
