import React from "react";

export const LargeSelectableButton = ({
  isSelected,
  onClick,
  Icon,
  title,
  subtitle,
}: {
  isSelected: boolean;
  onClick: () => void;
  Icon: any;
  title: string;
  subtitle: string;
}): JSX.Element => {
  return (
    <>
      <div
        className={`flex flex-row mb-4 mr-4 border rounded-md shadow-md h-20 w-28 border-gray-300 items-center transition group hover:border-blue-500 hover:border-opacity-40 hover:bg-blue-500 hover:bg-opacity-10 grow space-x-2 ${
          isSelected
            ? "bg-blue-500 bg-opacity-10 border-blue-500 border-opacity-40"
            : ""
        }`}
        onClick={onClick}
      >
        <Icon
          className={`w-10 h-10 ml-2 group-hover:text-blue-500 transition ${
            isSelected ? "text-blue-500" : "text-gray-400"
          }`}
        />
        <div className="flex-1">
          <div className="font-medium whitespace-pre-line">{title}</div>
          <div
            className={`text-xs group-hover:text-blue-500 transition whitespace-pre-line mr-4 ${
              isSelected ? "text-blue-500" : "text-gray-500"
            }`}
          >
            {subtitle}
          </div>
        </div>
      </div>
    </>
  );
};
