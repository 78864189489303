import { useEffect } from "react";

type useConfirmationProps = (
  trueCondition: boolean,
  callback?: () => any,
) => void;

// Display confirmation question when editing a block and trying to close tab
// Taken from: https://stackoverflow.com/questions/10311341/confirmation-before-closing-of-tab-browser
const confirmationOnLeave = (e) => {
  e = e || window.event;
  // For IE and Firefox prior to version 4
  if (e) {
    e.returnValue = "Sure?";
  }
  // For Safari
  return "Sure?";
};

export const useConfirmation: useConfirmationProps = (
  trueCondition,
  callback,
) => {
  useEffect(() => {
    if (trueCondition) {
      window.onbeforeunload = (e) => {
        if (callback) {
          callback();
        }
        confirmationOnLeave(e);
      };
      return () => {
        window.onbeforeunload = () => {};
      };
    } else {
      window.onbeforeunload = () => {};
    }
  }, [trueCondition]);
};
