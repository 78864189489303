import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "wouter";
import { Popup } from "components/popup";
import classnames from "classnames";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import AuthContext from "context/user";
import IconBadge from "helpers/ui/iconBadge";
import { SearchAvatar } from "pages/newEmployee/avatar";
import { getShortProjectId } from "pages/newProject/header";
import { useMultiLang } from "helpers/multiLang";
import {
  FaAddressBook,
  FaBook,
  FaNewspaper,
  FaRegFileArchive,
  FaRegFilePowerpoint,
  FaHandshake,
  FaBuilding,
  FaUserAlt,
  FaGlobe,
  FaCalendarAlt,
  FaFolderOpen,
  FaEdit,
  FaEye,
  FaEvernote,
  FaCopy,
} from "react-icons/fa";
import { SiNotion } from "react-icons/si";
import {
  AiOutlineFile,
  AiOutlineFileExcel,
  AiOutlineFileImage,
  AiOutlineFilePdf,
  AiOutlineFileWord,
} from "react-icons/ai";
import { IconButton } from "components/button";
import { pathShortener } from "helpers/ui/pathShortener";
import { selectReferenceData } from "helpers/getReferenceData";
import { Employee, NewProject } from "generated/graphql";
import { IconType } from "react-icons";
import { ContactPopup } from "components/contactPopup/contactPopupPopper";
import { copyToClipboard } from "helpers/clipboard";
import toast from "react-hot-toast";

dayjs.extend(relativeTime);

// TYPES

type ResultItemLabelColor = "red" | "green" | "gray";

type ResultItemLabel = {
  text: string;
  alt?: string;
  color?: ResultItemLabelColor;
};

type ResultItemAction = {
  text: string;
  to?: string;
  alt?: string;
  content?: string;
  icon: IconType;
};

type ResultItemObject = {
  [prop: string]: any;
};

type ElasticResult = {
  id: string;
  type: string;
  highlights: string[];
  data: any;
};

type ResultItem = (
  id: string,
  object: ResultItemObject,
  t: any,
  token: string | undefined,
  m: (key: string) => string,
) => {
  href: string | undefined;
  icon: JSX.Element;
  title: string;
  content?: string | JSX.Element;
  labels?: ResultItemLabel[];
  actions?: ResultItemAction[];
};

type ResultConverter = {
  [key: string]: ResultItem;
};

// HELPER FUNCTIONS

const i18nifyHighlightsKey = (key: string) => {
  return key
    .replace(/\.exact$/, "")
    .replace(/\.simple$/, "")
    .replace(/\.de$/, "")
    .replace(/\.de\./, ".")
    .replace(/\.fr$/, "")
    .replace(/\.fr\./, ".")
    .replace(/\.it$/, "")
    .replace(/\.it\./, ".")
    .replace(/\.en$/, "")
    .replace(/\.en\./, ".")
    .replace(/\./g, "_");
};

const fileIconByExtension = (extension: string) => {
  switch (extension.toLowerCase()) {
    case "xls":
    case "xlsx":
      return [AiOutlineFileExcel, "excel"];
    case "doc":
    case "docx":
      return [AiOutlineFileWord, "word"];
    case "pdf":
      return [AiOutlineFilePdf, "pdf"];
    case "ppt":
    case "pptx":
      return [FaRegFilePowerpoint, "powerpoint"];
    case "bmp":
    case "png":
    case "jpg":
    case "jpeg":
    case "gif":
    case "tiff":
    case "eps":
      return [AiOutlineFileImage, "image"];
    case "zip":
    case "rar":
      return [FaRegFileArchive, "archive"];
    default:
      return [AiOutlineFile, "fileDefault"];
  }
};

// EXPORTS

/**
 * type (aka keys) are the type of the available indicecs.
 * Currently those are (copied from the server code):
 *   "new_project"
 *   "new_employee"
 *   "new_client"
 *   "new_reference"
 *   "wiki_page"
 *   "onepager"
 *   "note"
 *   "notion"
 *   "file"
 */

export const resultConverter: ResultConverter = {
  new_project: (_id, { newProject }, t) => {
    const isArchived = newProject?.projectType === "archived";
    const isPhysicallyArchived = newProject?.projectType === "paper_archived";
    const iconColor = isArchived
      ? "#9e8584"
      : isPhysicallyArchived
        ? "#B8AEAD"
        : "#e9817b";
    return {
      href: `/project/${newProject?.id}`,
      icon: <IconBadge Icon={FaBook} color={iconColor} />,
      title: newProject?.name?.de,
      labels: [
        newProject?.abacusProjectId
          ? { text: getShortProjectId(newProject.abacusProjectId) }
          : null,
        isArchived
          ? { text: t("common:archived"), color: "red" as ResultItemLabelColor }
          : null,
        isPhysicallyArchived
          ? {
              text: t("common:paper_archived"),
              color: "red" as ResultItemLabelColor,
            }
          : null,
      ].filter((it) => it) as ResultItemLabel[],
      content: <ProjectDetails projectData={newProject} />,
    };
  },

  new_employee: (_id, { newEmployee }, t) => ({
    href: `/employee/${newEmployee?.id}`,
    icon: (
      <SearchAvatar
        profileUrl={newEmployee?.profileUrl}
        isFormer={newEmployee?.isFormerEmployee}
      />
    ),
    title: newEmployee?.fullName ?? "",
    labels: [
      newEmployee?.isFormerEmployee
        ? { text: t("common:former"), color: "red" as ResultItemLabelColor }
        : null,
    ].filter((it) => it) as ResultItemLabel[],
    content: <EmployeeDetails employeeData={newEmployee} />,
  }),

  new_client: (_id, { newClient }, t) => ({
    href: `/client/${newClient?.id}`,
    icon: <IconBadge Icon={FaAddressBook} color="#7fbcbc" />,
    title: `${newClient?.name ?? ""}`,
    labels: [
      newClient?.isClient ? { text: t("common:client") } : null,
      newClient?.isSupplier ? { text: t("common:supplier") } : null,
      newClient?.isContact ? { text: t("common:contact") } : null,
    ].filter((it) => it) as ResultItemLabel[],
  }),

  new_reference: (_id, { newReference }, t, _token, m) => {
    const projectName = m(newReference.title)
      ? m(newReference.title)
      : newReference?.project?.name
        ? m(newReference.project.name)
        : newReference?.projectName;
    const employee = newReference?.employee;
    const employeeName = employee ? employee?.fullName : null;
    const title =
      projectName && employeeName
        ? `${projectName} - ${employeeName}`
        : (projectName ?? employeeName);
    const isFormerEmployee = newReference?.employee?.isFormerEmployee;

    return {
      href: employee
        ? `/employee/${newReference?.employee?.id}/references#${newReference.id}`
        : `/project/${newReference?.project?.id}/references#${newReference.id}`,
      icon: newReference?.employee ? (
        <SearchAvatar
          profileUrl={newReference?.employee?.profileUrl}
          isFormer={isFormerEmployee}
          badge="R"
        />
      ) : (
        <IconBadge Icon={FaBook} badge="R" color="#e9817b" />
      ),
      title,
      content: <ReferenceDetails newReference={newReference} />,
      labels: [
        // TODO: we are missing a label for archived projects:
        // { text: t("projectArchived") }
        !newReference?.project
          ? {
              text: t("privateReference"),
              color: "gray" as ResultItemLabelColor,
            }
          : { text: getShortProjectId(newReference?.project?.abacusProjectId) },
        isFormerEmployee
          ? { text: t("common:former"), color: "red" as ResultItemLabelColor }
          : null,
      ].filter((it) => it) as ResultItemLabel[],
    };
  },

  wiki_page: (_id, { wikiPage }, _t, _token, m) => {
    const slugs = wikiPage?.slugs?.join("/");
    const url = `/wiki/${slugs}`;
    return {
      href: url,
      icon: <IconBadge Icon={FaNewspaper} color="#abdbd6" />,
      title: m(wikiPage?.title),
      content: url,
      labels: (wikiPage?.tagNames ?? []).map((tag) => ({
        text: m(tag),
      })),
    };
  },

  onepager: (_id, { onepager }, t) => ({
    href: `/onepagers/preview/${onepager?.id}`,
    icon: <IconBadge Icon={FaHandshake} color="#7bace9" />,
    title: onepager?.title,
    content: onepager?.institution,
    actions: [
      {
        text: t("editOnepager"),
        to: `/onepagers/edit/${onepager?.id}`,
        icon: FaEdit,
      },
      {
        text: t("openPreview"),
        to: `/onepagers/preview/${onepager?.id}`,
        icon: FaEye,
      },
    ],
    labels: [{ text: t("search:onepager") }],
  }),

  note: (_id, { note }) => ({
    href: `https://www.evernote.com/shard/s547/nl/${note?.creatorId}/${note?.guid}`,
    icon: <IconBadge Icon={FaEvernote} color="#74cb87" />,
    title: note?.title,
    content: [note.notebook?.name, note.author].filter((x) => x).join(", "),
  }),

  notion: (_id, { notion }) => ({
    href: notion?.url,
    icon: <IconBadge Icon={SiNotion} color="#6b7280" />,
    title: notion?.title,
    content:
      notion?.content?.length > 250
        ? notion?.content?.substring(0, 250) + "..."
        : notion?.content,
  }),

  file: (_id, { data, openJWT, revealJWT, pathKey }, t, token) => {
    const filename = data?.path?.split("\\")?.pop()?.split("/")?.pop();

    const labels = [
      data?.bucket && data?.bucket !== "External"
        ? { text: data?.bucket }
        : null,
      data?.projectId
        ? { text: data?.projectId, alt: data?.projectName }
        : null,
    ].filter((x) => x);

    const [icon, color] = fileIconByExtension(data?.extension);

    const ownerName = data?.metadata?.owner?.replace("TBFZH\\", "") || "";
    const ownerNameFallback =
      ownerName.length > 5 ? "" : ownerName.toUpperCase();

    const employeeData = {
      ...(data?.metadata ?? {}),
      fullName: data?.metadata?.fullName ?? ownerNameFallback,
    };

    return {
      icon:
        color === "image" ? (
          <img src={`/file-thumbnail/${pathKey}?token=${token}`} />
        ) : (
          <IconBadge
            Icon={icon as any}
            color="#e4e4e4"
            iconClassName={`resultItem--icon__${color}`}
          />
        ),
      href: `tbf://${openJWT}`,
      title: filename,
      content: (
        <div>
          <div className="mb-3 text-gray-600 break-all hover:text-blue-500">
            {pathShortener(data?.path)}
          </div>
          <div className="flex items-center space-x-2">
            <div>
              {t("common:created")} {dayjs(data?.metadata?.created).fromNow()}
            </div>
            <span>-</span>
            <EmployeeLink employee={employeeData} />
          </div>
        </div>
      ),
      labels: labels as ResultItemLabel[],
      actions: [
        {
          text: t("fileShare:fileModalCopyLink"),
          content: data?.path,
          icon: FaCopy,
          alt: data?.path,
        },
        {
          text: t("openFolder"),
          to: `tbf://${revealJWT}`,
          icon: FaFolderOpen,
        },
      ],
    };
  },
};

export const Highlights = ({ highlights }: any): JSX.Element => {
  const { t } = useTranslation("searchHighlights");

  const duplicatedKeys = Object.entries(highlights || {}).map(([key]) =>
    t(i18nifyHighlightsKey(key) as any),
  );

  const eliminateDuplicates = (arr) => {
    const out: string[] = [];
    const obj = {};

    for (let i = 0; i < arr.length; i++) {
      obj[arr[i]] = 0;
    }
    for (const j in obj as any) {
      out.push(j);
    }
    return out;
  };

  const nonDuplicatedKeys = eliminateDuplicates(duplicatedKeys);

  return (
    <div>
      {nonDuplicatedKeys.length > 2 ? (
        <div> {t("matchedInMany") + " "} </div>
      ) : (
        <div>
          {t("matchesIn") + " "}
          <em className="font-medium">
            {nonDuplicatedKeys.map((key) => (
              <span
                key={key}
                style={{ display: "inline-block", marginRight: 8 }}
              >
                {t(i18nifyHighlightsKey(key) as any)}
              </span>
            ))}
          </em>
        </div>
      )}
    </div>
  );
};

const EmployeeLink = ({
  employee,
}: {
  employee: Partial<Employee>;
}): JSX.Element => {
  const fullName = employee?.fullName;
  const profileLink = `/users/${employee?.activeDirectoryId}`;
  const email = employee?.email;

  return (
    <div className="flex items-center">
      <ContactPopup
        image={employee?.profilePicture}
        email={email ?? ""}
        phone={employee?.phone ?? ""}
        profileLink={profileLink}
        name={fullName ?? ""}
      >
        <Link className="hover:text-gray-900" href={profileLink}>
          {fullName}
        </Link>
      </ContactPopup>
    </div>
  );
};

export const niceReadableNumber = (n: number): number => {
  // Taken from: https://stackoverflow.com/questions/7725278/round-to-nearest-nice-number
  let nice = Math.pow(10, Math.ceil(Math.log10(n)));

  // scale the power to a "nice enough" value
  if (n <= 30) {
    nice = n;
  } else if (n < 0.25 * nice) {
    nice = 0.25 * nice;
  } else if (n < 0.5 * nice) {
    nice = 0.5 * nice;
  }

  return Math.floor(nice);
};

const EmployeeDetails = ({ employeeData }) => {
  const m = useMultiLang();
  const { phone, email, roles } = employeeData;
  return (
    <div className="flex overflow-hidden flex-wrap items-center mr-32 break-none text-gray-350">
      {roles[0]?.role?.name ? (
        <div className="flex flex-row mr-4">
          <div className="mt-1 w-4 h-4 text-xs text-center text-white rounded bg-gray-350">
            R
          </div>
          <div className="pl-2 leading-6">{m(roles[0].role.name)}</div>
        </div>
      ) : null}
      {email ? (
        <div className="flex flex-row mr-4 leading-6">
          <FaBuilding className="mt-1" />
          <div className="pl-2 leading-6">{email}</div>
        </div>
      ) : null}
      <div className="flex flex-row">
        <FaUserAlt className="mt-1" />
        <div className="pl-2 leading-6">{phone}</div>
      </div>
    </div>
  );
};

const ProjectDetails = ({
  projectData,
}: {
  projectData: NewProject;
}): JSX.Element => {
  const { t } = useTranslation(["common", "abacusProjects", "project"]);
  const { aggregatedTeam, client, country, adminProjectManager } = projectData;

  const employees = (aggregatedTeam ?? []).map(
    (teamMember) => teamMember?.employee,
  );

  const activeTeam = employees.filter(
    (teamMember) => !teamMember?.isFormerEmployee,
  );

  const teamSize = activeTeam.length > 0 ? activeTeam.length : null;
  const countryName = t(`abacusProjects:${country}` as any);

  return (
    <div className="flex overflow-hidden flex-wrap items-center mt-1 mr-8 break-none text-gray-350">
      <div className="flex flex-row mr-4 leading-6">
        <FaBuilding className="mt-1" />
        <div className="pl-2 leading-6">{client?.name}</div>
      </div>

      {adminProjectManager ? (
        <div className="flex flex-row mr-4">
          <div className="mt-1 w-6 h-4 text-xs text-center text-white rounded bg-gray-350">
            PL
          </div>
          <div className="pl-2 leading-6">{adminProjectManager?.fullName}</div>
        </div>
      ) : null}
      {teamSize ? (
        <div className="flex flex-row mr-4">
          <FaUserAlt className="mt-1" />
          <div className="pl-2 leading-6">
            {t(`project:teamMembersCount`, {
              count: teamSize,
            })}
          </div>
        </div>
      ) : null}

      {country ? (
        <div className="flex flex-row">
          <FaGlobe className="mt-1" />
          <div className="pl-2 leading-6">{countryName}</div>
        </div>
      ) : null}
    </div>
  );
};

const ReferenceDetails = ({ newReference }): JSX.Element => {
  const { t } = useTranslation(["common", "abacusProjects"]);
  const {
    project,
    privateReferenceProjectClientName,
    privateReferenceProjectCountry,
  } = newReference;

  const from = selectReferenceData(newReference, "from");
  const to = selectReferenceData(newReference, "to");

  const duration =
    to && to.length > 0
      ? `${from?.slice(0, 4) ?? ""}–${to.slice(0, 4)}`
      : `${from?.slice(0, 4) ?? ""}`;
  const clientName = project?.client?.name
    ? project?.client?.name
    : privateReferenceProjectClientName;
  const clientCountry = project?.country
    ? t(`abacusProjects:${project.country}` as any)
    : t(`abacusProjects:${privateReferenceProjectCountry}` as any);

  return (
    <div className="flex overflow-hidden flex-wrap items-center mt-1 mr-8 break-none text-gray-350">
      {clientName ? (
        <div className="flex flex-row mr-4 leading-6">
          <FaBuilding className="mt-1" />
          <div className="pl-2 leading-6">{clientName}</div>
        </div>
      ) : null}
      {duration ? (
        <div className="flex flex-row mr-4">
          <FaCalendarAlt className="mt-1" />
          <div className="pl-2 leading-6">{duration}</div>
        </div>
      ) : null}

      {clientCountry !== "null" ? (
        <div className="flex flex-row">
          <FaGlobe className="mt-1" />
          <div className="pl-2 leading-6">{clientCountry}</div>
        </div>
      ) : null}
    </div>
  );
};

export const ResultItem = ({
  result,
  isImageFilterSelected,
}: {
  result: any;
  isImageFilterSelected: any;
}): JSX.Element => {
  const { t } = useTranslation(["searchHighlights", "fileShare"]);
  const m = useMultiLang();

  const { id, type, data, highlights } = result;
  const { token } = useContext(AuthContext);
  const resultProperties = resultConverter[type]?.(id, data, t, token, m);

  if (!resultProperties) {
    return (
      <div>
        Error: Result Item not found for {type} {id}
      </div>
    );
  }

  const { icon, href, title, content, labels, actions } = resultProperties;

  const otherResultItem = (
    <div className="grid flex-row grid-cols-12 items-center px-2 py-4 w-full h-auto text-gray-800 hover:bg-gray-100 hover:cursor-pointer">
      <div className="col-span-1 mr-3">{icon}</div>
      <div
        className={`${type == "onepager" ? "col-span-7 pr-2" : "col-span-8"}`}
      >
        <div className="flex items-center">
          <ResultItemLabels labels={labels} />
        </div>
        <h1 className="text-xl">{title}</h1>
        <div className="text-base">{content}</div>
      </div>

      <div
        className={`text-right ${
          type == "onepager" ? "col-span-4" : "col-span-3 pl-10"
        }`}
      >
        <div className="">
          <Highlights highlights={highlights} />
        </div>
        <div className="flex justify-end mr-2 space-x-2">
          {(actions ?? []).map((action) => {
            const label = (
              <div
                key={action.text}
                className="flex flex-row items-center py-1 pr-2 mt-3 space-x-1 text-sm text-blue-500 rounded border-2 hover:border-gray-400 shrink"
                onClick={(e) => {
                  e.preventDefault();
                  if (action.to) {
                    window.open(action.to, "_blank");
                  }
                  if (action.content) {
                    copyToClipboard(action.content);
                    toast.success(t("fileShare:copySuccess"));
                  }
                }}
              >
                <IconButton type="search" Icon={action.icon ?? FaFolderOpen} />
                <span className="whitespace-nowrap">{action.text}</span>
              </div>
            );
            return action.alt ? (
              <Popup key={action.text} content={action.alt}>
                {label}
              </Popup>
            ) : (
              label
            );
          })}
        </div>
      </div>
    </div>
  );

  const imageResultItem = (
    <div className="flex flex-col p-4 w-full h-auto text-gray-800 hover:bg-gray-100 hover:cursor-pointer">
      <div className="mb-3">{icon}</div>
      <div className="flex items-start">
        <div className="flex-grow mr-2 mb-1 text-xl font-medium text-blue-500 truncate">
          <h1 className="truncate">{title}</h1>
        </div>
        <div className="">
          {(labels ?? []).map(({ text, alt, color }) => {
            const label = (
              <span
                key={text}
                className={classnames(
                  "flex-1 px-2 py-1 text-sm text-gray-600 bg-gray-800 bg-opacity-10 rounded border-none sm:text-sm",
                  {
                    [`resultItem--label__${color}`]: color,
                  },
                )}
              >
                {text}
              </span>
            );
            return alt ? (
              <Popup key={text} content={alt}>
                {label}
              </Popup>
            ) : (
              label
            );
          })}
        </div>
      </div>
      <div className="mt-3 text-sm text-gray-600">{content}</div>
      <div>
        <div className="flex flex-1 mt-3 text-sm">
          <Highlights highlights={highlights} />
        </div>
        <div className="flex justify-end">
          {(actions ?? []).map((action) => {
            const label = (
              <div
                key={action.text}
                className="flex flex-row items-center py-1 pr-2 mt-3 space-x-1 text-sm text-blue-500 rounded border-2 hover:border-gray-400 shrink"
                onClick={() => {
                  window.open(action.to, "_blank");
                }}
              >
                <IconButton type="search" Icon={FaFolderOpen} />
                {action.text}
              </div>
            );
            return action.alt ? (
              <Popup key={action.text} content={action.alt}>
                {label}
              </Popup>
            ) : (
              label
            );
          })}
        </div>
      </div>
    </div>
  );

  if (href && (type === "file" || type === "note" || type === "notion")) {
    return (
      <a href={href} className="flex items-stretch">
        {isImageFilterSelected ? imageResultItem : otherResultItem}
      </a>
    );
  } else if (href) {
    return (
      <Link href={href}>
        <div className="flex items-stretch">
          {isImageFilterSelected ? imageResultItem : otherResultItem}
        </div>
      </Link>
    );
  } else {
    return (
      <div className="flex items-stretch">
        {isImageFilterSelected ? imageResultItem : otherResultItem}
      </div>
    );
  }
};

const ResultItemLabels = ({
  labels,
}: {
  labels?: ResultItemLabel[];
}): JSX.Element => {
  return (
    <>
      {(labels ?? []).map(({ text, alt, color }) => {
        const label = (
          <span
            key={text}
            className={classnames(
              "px-2 mr-3 mb-2 ml-0 text-xs text-blue-500 whitespace-nowrap bg-blue-500 bg-opacity-10 rounded border-none sm:text-sm",
              {
                [`resultItem--label__${color}`]: color,
              },
            )}
          >
            {text}
          </span>
        );
        return alt ? (
          <Popup key={text} content={alt}>
            {label}
          </Popup>
        ) : (
          label
        );
      })}
    </>
  );
};

export const WikiSearchResultItem = ({
  result,
  onClick,
}: {
  result: ElasticResult;
  onClick: (props: {
    result: ElasticResult;
    href?: string;
    id: string;
    title?: string;
  }) => void;
}): JSX.Element => {
  const { t } = useTranslation("searchHighlights");
  const m = useMultiLang();
  const { id, type, data } = result;

  const { token } = useContext(AuthContext);
  const resultProperties = resultConverter[type]?.(id, data, t, token, m);

  const { href, title, content } = resultProperties;

  return (
    <div
      className="flex flex-row items-center px-2 py-2 text-gray-800 border-t cursor-pointer hover:bg-gray-100"
      onClick={() => {
        onClick({ result, href, id, title });
      }}
    >
      <div className="flex-1">
        <h1 className="text-sm">{title}</h1>
        <div className="text-sm text-gray-600">{content}</div>
      </div>
    </div>
  );
};
