import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { InputBlock } from "generated/graphql";

import { IconButton } from "components/button";
import { Modal } from "components/modal";

// @ts-ignore
import aboutTbfPreview from "assets/images/onepager/about-add-preview.png";
// @ts-ignore
import contentPreview from "assets/images/onepager/content-add-preview.png";
// @ts-ignore
import intermissionPreview from "assets/images/onepager/intermission-add-preview.png";
// @ts-ignore
import introPreview from "assets/images/onepager/intro-add-preview.png";
// @ts-ignore
import networkPreview from "assets/images/onepager/network-add-preview.png";
// @ts-ignore
import outroPreview from "assets/images/onepager/outro-add-preview.png";
// @ts-ignore
import referencePreview from "assets/images/onepager/reference-add-preview.png";

type BlokOption = {
  key: string;
  img: string;
  onClick: () => void;
};

const AddBlockModal = ({
  isOpen,
  setIsOpen,
  addBlock,
  index,
  supportedLanguage,
}) => {
  const { t } = useTranslation(["onepager", "common"]);

  const addBlockOptions: BlokOption[] = [
    {
      key: "intro",
      img: introPreview,
      onClick: () => {
        createNewBlock("intro");
      },
    },
    {
      key: "network",
      img: networkPreview,
      onClick: () => {
        createNewBlock("network");
      },
    },
    {
      key: "joker",
      img: contentPreview,
      onClick: () => {
        createNewBlock("joker");
      },
    },
    {
      key: "reference",
      img: referencePreview,
      onClick: () => {
        createNewBlock("reference");
      },
    },
    {
      key: "about_tbf",
      img: aboutTbfPreview,
      onClick: () => {
        createNewBlock("about_tbf", {
          navigationTitle: buildMultilangText(
            "onepager:aboutTbfBlock.title",
            supportedLanguage,
          ),
          intro: buildMultilangText(
            "onepager:aboutTbfBlock.introText",
            supportedLanguage,
          ),
          facts: [
            {
              value: buildMultilangText(
                "onepager:aboutTbfBlock.placeholder.COMPETENCES",
                supportedLanguage,
              ),
              type: "COMPETENCES",
              itemKey: { de: "", en: "", fr: "", it: "" },
            },
            {
              value: buildMultilangText(
                "onepager:aboutTbfBlock.placeholder.INDEPENDENCE",
                supportedLanguage,
              ),
              type: "INDEPENDENCE",
              itemKey: { de: "", en: "", fr: "", it: "" },
            },
            {
              value: buildMultilangText(
                "onepager:aboutTbfBlock.placeholder.NUM_EMPLOYEES",
                supportedLanguage,
              ),
              type: "NUM_EMPLOYEES",
              itemKey: { de: "", en: "", fr: "", it: "" },
            },
            {
              value: buildMultilangText(
                "onepager:aboutTbfBlock.placeholder.MAP",
                supportedLanguage,
              ),
              type: "MAP",
              itemKey: { de: "", en: "", fr: "", it: "" },
            },
          ],
        });
      },
    },
    {
      key: "intermission",
      img: intermissionPreview,
      onClick: () => {
        createNewBlock("intermission");
      },
    },
    {
      key: "outro",
      img: outroPreview,
      onClick: () => {
        createNewBlock("outro", {
          navigationTitle: buildMultilangText(
            "onepager:outroBlock.initialMessageTitle",
            supportedLanguage,
          ),
          message: buildMultilangText(
            "onepager:outroBlock.initialMessageText",
            supportedLanguage,
          ),
          externalLinks: [
            {
              name: buildMultilangText(
                "onepager:outroBlock.initialExternalLinkName",
                supportedLanguage,
              ),
              url: "https://tbf.ch",
            },
          ],
        });
      },
    },
  ];

  // At first, the onepager was designed to be multilang,
  // but it turned out that it works better being in one language only.
  // Because of this, we hardcode the language to "de" and store everything there.

  const buildMultilangText = (textKey, supportedLanguage) => ({
    de: t(textKey, { lng: supportedLanguage }),
    fr: "",
    it: "",
    en: "",
  });

  const createNewBlock = (type, extraFields = {}) => {
    const newBlock = {
      type: type,
      navigationTitle: { de: "", en: "", fr: "", it: "" },
      ...extraFields,
    };
    addBlock(newBlock, index);
  };

  return (
    <Modal
      title={
        <div className="px-6 py-8 text-2xl font-medium text-red-500">
          {t("onepager:addBlock")}
        </div>
      }
      open={isOpen}
      close={() => setIsOpen(false)}
      actions={
        <div className="flex flex-row space-x-2">
          <IconButton
            type="secondary"
            Icon={IoMdClose}
            onClick={() => setIsOpen(false)}
          >
            {t("common:cancel")}
          </IconButton>
        </div>
      }
    >
      <div className="px-8 my-8">
        <div className="grid grid-cols-1 px-3 mt-4 sm:grid-cols-2 gap-x-6 gap-y-3">
          {addBlockOptions.map((blockOption: BlokOption) => {
            return (
              <div
                key={blockOption.key}
                className="flex overflow-hidden transition border rounded cursor-pointer group hover:shadow-lg"
                onClick={() => {
                  blockOption.onClick();
                  setIsOpen(false);
                }}
              >
                <div className="flex-1 w-3/5 p-4">
                  <h2 className="text-xl transition group-hover:text-blue-500">
                    {t(`onepager:editBlockTitles.${blockOption.key}` as any)}
                  </h2>
                  <div className="mt-4 text-sm">
                    {t(
                      `onepager:editBlockDescription.${blockOption.key}` as any,
                    )}
                  </div>
                </div>
                <div className="flex w-2/5">
                  <img
                    className="w-full duration-300 ease-in-out rounded-r group-hover:scale-105"
                    alt="block image"
                    height="224"
                    src={blockOption.img}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export const AddBlock = ({
  addBlock,
  index,
  supportedLanguage,
}: {
  addBlock: (newBlock: InputBlock, i: number) => void;
  supportedLanguage: string;
  index: number;
}): JSX.Element => {
  const { t } = useTranslation("onepager");
  const [showAddBlockModal, setShowAddBlockModal] = useState(false);

  return (
    <>
      <div className="flex items-center space-x-2 group">
        <div
          className="flex-1 transition border-b border-gray-200 border-dashed group-hover:border-blue-500"
          style={{ height: 1 }}
        ></div>

        <IconButton
          Icon={FaPlus}
          className="text-gray-400 border-gray-400group-hover:border-blue-500 group-hover:text-blue-500"
          onClick={() => {
            setShowAddBlockModal(true);
          }}
        >
          {t("addBlock")}
        </IconButton>
        <div
          className="flex-1 transition border-b border-gray-200 border-dashed group-hover:border-blue-500"
          style={{ height: 1 }}
        ></div>
      </div>
      <AddBlockModal
        isOpen={showAddBlockModal}
        setIsOpen={setShowAddBlockModal}
        addBlock={addBlock}
        index={index}
        supportedLanguage={supportedLanguage}
      />
    </>
  );
};
