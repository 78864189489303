import React, { MutableRefObject, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMultiLang } from "helpers/multiLang";
import { IconButton } from "components/button";

import { IoMdSettings } from "react-icons/io";
import { WikiPage } from "generated/graphql";
import { Link } from "wouter";
import { HiChevronRight } from "react-icons/hi";
import { FaLongArrowAltDown } from "react-icons/fa";
import { Transition } from "@headlessui/react";
import { useClickAway } from "@uidotdev/usehooks";

export const NavBar = ({
  page,
  parentPage,
  readOnly = false,
  isEmptyPage = false,
  onEditClick = () => {},
}: {
  page: Partial<WikiPage>;
  parentPage: Partial<WikiPage>;
  readOnly?: boolean;
  onEditClick?: () => void;
  isEmptyPage?: boolean;
}): JSX.Element => {
  const m = useMultiLang();

  const { t } = useTranslation("wiki");

  const [secondaryMenuOpen, setSecondaryMenuOpen] = useState(false);
  const ref: MutableRefObject<HTMLDivElement> = useClickAway(() => {
    setSecondaryMenuOpen(false);
  });

  const showSecondaryMenuCondition =
    (page?.subPages ?? []).length > 0 ||
    (parentPage?.subPages ?? []).length > 1;

  const siblingPages = parentPage?.subPages?.filter(
    (subpage) => subpage.id != page.id,
  );

  const displayLarge =
    (page?.subPages ?? []).length > 8 || (siblingPages ?? []).length > 8;

  const onClickTitle = () => {
    if (!secondaryMenuOpen) {
      setSecondaryMenuOpen(showSecondaryMenuCondition);
    } else {
      setSecondaryMenuOpen(false);
    }
  };

  return (
    <div
      className="sticky top-12 w-full left-0 z-20 bg-white shadow transition-all ease-in-out duration-300"
      style={{
        maxHeight: `${
          displayLarge && secondaryMenuOpen
            ? "552px"
            : secondaryMenuOpen
              ? "425px"
              : "120px"
        }`,
      }}
    >
      <div ref={ref}>
        <div className="max-w-screen-xl px-3 mx-auto py-9">
          <LinkBar
            page={page}
            isEmptyPage={isEmptyPage}
            onEditClick={onEditClick}
            readOnly={readOnly}
            showArrow={showSecondaryMenuCondition}
            onClickTitle={onClickTitle}
          />
        </div>
        <Transition
          show={secondaryMenuOpen && showSecondaryMenuCondition}
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div>
            <div className="shadow h-1 -mt-1 z-100"></div>
            <div
              className={`max-w-screen-xl mx-auto flex flex-row overflow-hidden px-3 py-4 z-100 ${
                displayLarge
                  ? "overflow-y-auto max-h-96 transition-all ease-in-out duration-300"
                  : "max-h-60"
              }`}
            >
              {page?.subPages?.length ? (
                <div className="py-4 text-xl font-medium text-gray-350">
                  {t("subPages")}
                  <ul className="grid grid-cols-2 pt-2">
                    {page?.subPages?.map((subpage) => {
                      return (
                        <li
                          key={subpage.id}
                          className="cursor-pointer py-2 mr-20 text-blue-500 text-base hover:text-gray-950 transition truncate"
                        >
                          <Link
                            href={`/wiki/${subpage.slugs.join("/").toString()}`}
                          >
                            {m(subpage.title)}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : null}
              {(parentPage?.subPages ?? []).length > 1 ? (
                <div className="py-4 text-xl font-medium text-gray-350">
                  {t("relatedPages")}
                  <ul className="grid grid-cols-2 pt-2">
                    {siblingPages?.map((subpage) => {
                      return (
                        <li
                          key={subpage.id}
                          className="cursor-pointer py-2 mr-20 text-blue-500 text-base hover:text-gray-950 transition truncate"
                        >
                          <Link
                            href={`/wiki/${subpage.slugs.join("/").toString()}`}
                          >
                            {m(subpage.title)}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
};

const LinkBar = ({
  page,
  onEditClick,
  isEmptyPage,
  readOnly,
  showArrow,
  onClickTitle,
}: {
  page?: Partial<WikiPage>;
  onEditClick: () => void;
  isEmptyPage: boolean;
  readOnly: boolean;
  showArrow: boolean;
  onClickTitle: () => void;
}): JSX.Element => {
  const { t } = useTranslation("wiki");
  const m = useMultiLang();

  return (
    <>
      <div className="flex flex-row justify-between">
        <div className="flex flex-row space-x-1 flex-wrap items-center">
          {page?.allParentPages?.length == 0 ? (
            <WikiTitle
              title={m(page.title)}
              showArrow={showArrow}
              onClick={onClickTitle}
            />
          ) : (
            <>
              {page?.allParentPages?.map((parentPage) => {
                return (
                  <React.Fragment key={parentPage?.id}>
                    <Link
                      href={`/wiki/${parentPage?.slugs?.join("/").toString()}`}
                    >
                      <div className="cursor-pointer text-2xl font-medium text-gray-350 hover:text-gray-950 transition">
                        {m(parentPage?.title)}
                      </div>
                    </Link>
                    <div className="text-2xl text-gray-350">
                      <HiChevronRight />
                    </div>
                  </React.Fragment>
                );
              })}
              <WikiTitle
                title={m(page?.title)}
                showArrow={showArrow}
                onClick={onClickTitle}
              />
            </>
          )}
        </div>
        {isEmptyPage ? null : readOnly ? null : (
          <div className="justify-between hidden md:block">
            <IconButton Icon={IoMdSettings} onClick={onEditClick}>
              {t("settings")}
            </IconButton>
          </div>
        )}
      </div>
    </>
  );
};

const WikiTitle = ({
  title,
  showArrow,
  onClick,
}: {
  title: string;
  showArrow: boolean;
  onClick: () => void;
}): JSX.Element => {
  return (
    <div
      className="text-red-500 hover:text-red-800 flex flex-row items-center gap-x-2"
      role="button"
      onClick={onClick}
    >
      <div className="text-2xl font-medium " onClick={onClick}>
        {title}
      </div>
      {showArrow ? <FaLongArrowAltDown className="text-lg" /> : null}
    </div>
  );
};
