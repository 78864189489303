import React, { useState } from "react";
import { GenericField, GenericFieldProps } from "./fieldProps";

import { FileUpload } from "generated/graphql";
import { InputFileBase64 } from "components/inputFileBase64";
import { SecondaryButton } from "components/button";
import { useTranslation } from "react-i18next";
import { LoadingSpinner } from "components/loadingSpinner";

export type FileFieldProps = GenericFieldProps<FileUpload | null>;

const FileField = ({ value, onChange, disabled = false }: FileFieldProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          {disabled ? null : (
            <div className="flex">
              <InputFileBase64
                title={t("upload")}
                filename={value?.filename ?? undefined}
                onChange={(base64Url, filename) => {
                  onChange?.({ url: base64Url, filename });
                }}
                setIsLoading={setIsLoading}
              />
              {value?.url ? (
                <>
                  <div className="flex-grow" />
                  <SecondaryButton
                    onClick={() => {
                      onChange?.(null);
                    }}
                  >
                    {t("delete")}
                  </SecondaryButton>
                </>
              ) : null}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export const fileField: GenericField<FileFieldProps> = {
  renderer: FileField,
  onBeforeSave: (file) =>
    file?.url ? { url: file.url, filename: file.filename } : null,
  equals: (a, b) => (a?.url ?? "") === (b?.url ?? ""),
};
