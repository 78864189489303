// @ts-ignore
import de from "hyphenated-de";
// @ts-ignore
import fr from "hyphenated-fr";
// @ts-ignore
import enUs from "hyphenated-en-us";
// @ts-ignore
import { hyphenated } from "hyphenated";

type HyphenLangs = { [key: string]: string };
const hyphenLangs: HyphenLangs = { de: de, fr: fr, en: enUs, "en-GB": enUs };

export const hyphenateText = (text: string, locale: string): string => {
  if (
    locale === "de" ||
    locale === "fr" ||
    locale === "en-GB" ||
    locale === "en"
  ) {
    return hyphenated(text, { language: hyphenLangs[locale] });
  } else {
    return text;
  }
};
