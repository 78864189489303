import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { GenericFieldProps } from "./fieldProps";
import Select from "react-select";
import { customStyles, i18nOptions } from "./multiSelectField";

import { ALL_EMPLOYEES_QUERY } from "queries/newEmployee";
import { useFuse } from "helpers/useFuse";
import { Employee } from "generated/graphql";

export type EmployeesFieldProps = GenericFieldProps<string> & {
  isMulti: boolean;
  allEmployees?: Partial<Employee>[];
  hideOptionIf?: (employee: Partial<Employee>) => boolean;
};

export const EmployeesField = ({
  value,
  onChange,
  disabled,
  isMulti,
  allEmployees,
  hideOptionIf,
}: EmployeesFieldProps): JSX.Element => {
  const { t } = useTranslation();
  const { data } = useQuery(ALL_EMPLOYEES_QUERY, { skip: !!allEmployees });
  const employees = allEmployees ? allEmployees : (data?.employees ?? []);

  const optionMap = (e: Partial<Employee>) => ({
    value: e.id,
    label: `${e.firstName} ${e.lastName} ${
      e?.isFormerEmployee ? `(${t("former")})` : ""
    }`,
    shouldHide: hideOptionIf ? hideOptionIf(e) : false,
  });

  const toOption = (employee) => ({
    value: employee?.id,
    label: `${employee?.firstName ?? ""} ${employee?.lastName ?? ""} ${
      employee?.isFormerEmployee ? `(${t("former")})` : ""
    }`,
  });

  const selectedOption = isMulti
    ? (employees?.filter((employee) => (value ?? []).includes(employee.id)) ??
      [])
    : employees?.find((c) => c.id === value);

  const preparedEmployees = employees?.map(optionMap);
  const [inputValue, setInputValue] = useState("");
  const filterEmployees = useFuse(preparedEmployees, inputValue, {
    keys: ["label"],
  })
    // We can't cap the options when using a filter
    ?.slice(0, hideOptionIf ? Infinity : 200);

  return (
    <div className="text-sm">
      <Select
        onInputChange={(value) => setInputValue(value)}
        styles={customStyles}
        options={filterEmployees}
        isMulti={isMulti}
        isDisabled={disabled}
        {...i18nOptions(t)}
        value={
          isMulti ? selectedOption.map(toOption) : toOption(selectedOption)
        }
        onChange={
          onChange &&
          ((newValue: any) => {
            onChange(isMulti ? newValue?.map((v) => v.value) : newValue?.value);
          })
        }
        filterOption={(option) => {
          return !option.data.shouldHide;
        }}
      />
    </div>
  );
};

export const employeesField = {
  renderer: EmployeesField,
};
