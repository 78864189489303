import React, { useRef, useState } from "react";
import { IconButton } from "components/button";
import { useEffect } from "react";
import { FaImage } from "react-icons/fa";

type InputFileProps = {
  title: string;
  filename?: string;
  accept?: string;
  showFilename?: boolean;
  iconComponent?: React.ComponentType<any>;
  disabled?: boolean;
  onChange: (file: File) => void;
};

export const InputFile: React.FC<InputFileProps> = ({
  title,
  onChange,
  filename,
  accept,
  showFilename = true,
  disabled = false,
  iconComponent = FaImage,
}) => {
  const inputRef: any = useRef(null);
  const [newFilename, setNewFilename] = useState(filename ?? null);
  useEffect(() => {
    setNewFilename(filename ?? null);
  }, [filename]);

  return (
    <>
      <IconButton
        disabled={disabled}
        Icon={iconComponent}
        onClick={(e) => {
          inputRef!.current.click();
          e.preventDefault();
        }}
      >
        {title || "Upload"}
      </IconButton>

      <input
        type="file"
        accept={accept}
        hidden
        ref={inputRef}
        onChange={(e) => {
          const file = (e?.target as HTMLInputElement)?.files?.[0];

          if (file) {
            setNewFilename(file.name);
            onChange(file);
          }
        }}
      />
      {showFilename && newFilename ? (
        <span className="ml-4">{newFilename}</span>
      ) : null}
    </>
  );
};
