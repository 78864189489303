import React, { useEffect, useState } from "react";
import { useLocation } from "wouter";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { FaEdit } from "react-icons/fa";

import Layout from "layout/layout";
import { IconButton } from "components/button";
import { EditWikiPageModal } from "components/editVersioned/editWikiPage";

import { WIKI_PAGE_QUERY } from "queries/wiki";

import { Content } from "./Content";
import { NavBar } from "./NavBar";
import { WikiPage } from "generated/graphql";
import { useTitle } from "helpers/useTitle";
import { useMultiLang } from "helpers/multiLang";
import { NarrowTwoColumnLayout } from "layout/twoColumnLayout";
import { BsTextLeft } from "react-icons/bs";

const NotFound = ({
  slugs,
  parentPage,
}: {
  slugs?: string[];
  parentPage?: Partial<WikiPage>;
}) => {
  const { t } = useTranslation("wiki");
  const [isOpen, setIsOpen] = useState(false);

  if (!slugs) {
    return <Layout title={t("notFound")}></Layout>;
  }

  const parentPageSlugs = parentPage?.slugs ?? [];

  return (
    <>
      {parentPage ? (
        <>
          <Layout noPadding></Layout>
          <div className="bg-white">
            <NavBar page={parentPage} parentPage={parentPage} isEmptyPage />
            <div className="relative max-w-screen-xl px-3 mx-auto pt-32">
              <NarrowTwoColumnLayout
                leftContent={
                  <div
                    className="h-full sticky top-24.5 overflow-y-auto"
                    style={{ maxHeight: "calc(100vh - 218px)" }}
                  >
                    <div className="mb-4">
                      <h5 className="hidden mb-2 text-xl font-medium text-blue-500 md:flex">
                        {t("whoToAsk")}
                      </h5>
                    </div>
                  </div>
                }
                leftClassName="pt-8"
                rightClassName=""
                rightContent={
                  <div className="flex justify-center px-3 py-12 mx-auto max-w-screen-tbf text-wrap">
                    <div className="flex flex-col items-center max-w-sm py-6 space-y-6 border-2 border-gray-300 border-dashed rounded">
                      <BsTextLeft className="text-4xl text-blue-500" />
                      <div className="text-2xl text-blue-500 text-center max-w-[55%]">
                        {t("emptyPage")}
                      </div>
                      <IconButton onClick={() => setIsOpen(true)} Icon={FaEdit}>
                        {t("addKnowledge")}
                      </IconButton>
                      <EditWikiPageModal
                        id="new"
                        open={isOpen}
                        close={() => setIsOpen(false)}
                        newEntity={
                          parentPageSlugs.length > 0
                            ? {
                                slugs: slugs,
                                parentPageId: parentPage?.id,
                              }
                            : { slugs: slugs }
                        }
                      />
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <Layout noPadding title={t("notFound")}></Layout>
          <div className="flex justify-center px-3 py-12 mx-auto max-w-screen-tbf text-wrap">
            <div className="flex flex-col items-center max-w-sm py-6 space-y-6 border-2 border-gray-300 border-dashed rounded">
              <BsTextLeft className="text-4xl text-blue-500" />
              <div className="text-2xl text-blue-500 text-center max-w-[55%]">
                {t("emptyPage")}
              </div>
              <IconButton onClick={() => setIsOpen(true)} Icon={FaEdit}>
                {t("addKnowledge")}
              </IconButton>
              <EditWikiPageModal
                id="new"
                open={isOpen}
                close={() => setIsOpen(false)}
                newEntity={
                  parentPageSlugs.length > 0
                    ? {
                        slugs: slugs,
                        parentPageId: undefined,
                      }
                    : { slugs: slugs }
                }
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export const MainVersion = ({
  slugs,
  id,
}: {
  slugs?: string[];
  id?: string;
}): JSX.Element => {
  const m = useMultiLang();

  const { data, loading } = useQuery(WIKI_PAGE_QUERY, {
    variables: { id, slugs },
    // Return data even if some fields failed to resolve
    errorPolicy: "all",
  });

  const page = data?.["wikiPage"];
  const parentPage = data?.["parentWikiPage"];

  useTitle(page?.title ? `TBF Wiki - ${m(page.title)}` : "TBF Wiki");

  const [isEditOpen, setIsEditOpen] = useState(false);

  const [_location, navigate] = useLocation();

  const loadedSlugs = slugs ?? data?.wikiPage?.slugs;

  useEffect(() => {
    if (!slugs && id && loadedSlugs) {
      const path = loadedSlugs.join("/");
      navigate(`/wiki/${path}`, { replace: true });
    }
  }, [loadedSlugs]);

  if (loading && !page) {
    return <Layout loading={loading} />;
  } else if (!page && slugs) {
    return <NotFound slugs={slugs} parentPage={parentPage} />;
  } else if (!page) {
    return <NotFound parentPage={parentPage} />;
  } else {
    return (
      <>
        <Layout loading={loading} noPadding />
        <div className="bg-white">
          <NavBar
            page={page}
            parentPage={parentPage}
            onEditClick={() => {
              setIsEditOpen(true);
            }}
          />

          <div className="relative max-w-screen-xl px-3 mx-auto fixed">
            <Content page={page} parentPage={parentPage} slugs={slugs ?? []} />
          </div>
          <EditWikiPageModal
            id={page.id}
            open={isEditOpen}
            close={() => setIsEditOpen(false)}
          />
        </div>
      </>
    );
  }
};
