import React from "react";
import { MdClose, MdAdd } from "react-icons/md";
import { SingleIconButton } from "components/button";
import { GenericFieldProps } from "./fieldProps";
import { Popup } from "components/popup";
import { ImMoveUp, ImMoveDown } from "react-icons/im";

type WithKey = { key: string };

type List<T> = T[];
export type ListType<T> = GenericFieldProps<List<T>> & {
  render: (args: any) => any;
  newItem: T;
  catalogue?: any[];
  addText?: string;
  vertical?: boolean;
  componentOptions?: any;
};

const AddButton = ({ addText, handleAddNew }) => {
  return (
    <div className="flex flex-col items-end">
      {addText ? (
        <Popup content={addText}>
          <SingleIconButton onClick={handleAddNew}>
            <MdAdd size="1.5em" className="text-blue-500" />
          </SingleIconButton>
        </Popup>
      ) : (
        <SingleIconButton onClick={handleAddNew}>
          <MdAdd size="1.5em" className="text-blue-500" />
        </SingleIconButton>
      )}
    </div>
  );
};

export const ListField: React.FC<any> = <T extends WithKey>({
  value,
  onChange,
  language,
  supportedLanguage,
  disabled = false,
  render,
  newItem,
  catalogue,
  addText,
  vertical = false,
  AddComponent = null,
  componentOptions,
}: ListType<T>) => {
  const handleAddNew = () => {
    const newList = [...value, newItem];
    if (onChange) {
      onChange(newList);
    }
  };

  const handleRemove = (index) => {
    const newList = [...value.slice(0, index), ...value.slice(index + 1)];
    if (onChange) {
      onChange(newList);
    }
  };

  const handleChange = (index: number, changedItem: T) => {
    const newList = value.map((item, i) => (i !== index ? item : changedItem));
    if (onChange) {
      onChange(newList);
    }
  };

  const moveUpItem = (i) => {
    const newList = [
      ...value.slice(0, i - 1),
      value[i],
      value[i - 1],
      ...value.slice(i + 1, value.length),
    ];
    if (onChange) {
      onChange(newList);
    }
  };

  const moveDownItem = (i) => {
    const newList = [
      ...value.slice(0, i),
      value[i + 1],
      value[i],
      ...value.slice(i + 2, value.length),
    ];
    if (onChange) {
      onChange(newList);
    }
  };

  const RenderComponent = render;
  const lastIndex = value.length - 1;

  return (
    <>
      {value?.map((item: T, i: number) => (
        <div
          className={`flex ${vertical ? "" : "items-center"} mb-4`}
          key={item.key}
        >
          <div className="flex-1">
            <RenderComponent
              value={value[i]}
              onChange={(newValue: T) => {
                handleChange(i, newValue);
              }}
              item={item}
              language={language}
              supportedLanguage={supportedLanguage}
              catalogue={catalogue}
              disabled={disabled}
              {...componentOptions}
            />
          </div>
          {!disabled && vertical ? (
            <div className="flex flex-col ml-2 space-y-2">
              <SingleIconButton
                onClick={() => {
                  handleRemove(i);
                }}
              >
                <MdClose size="1.5em" className="text-blue-500" />
              </SingleIconButton>
              <SingleIconButton
                disabled={i === 0}
                onClick={() => {
                  moveUpItem(i);
                }}
              >
                <ImMoveUp
                  size="1.5em"
                  className={i === 0 ? "text-gray-200" : "text-blue-500"}
                />
              </SingleIconButton>
              <SingleIconButton
                disabled={i === lastIndex}
                onClick={() => {
                  moveDownItem(i);
                }}
              >
                <ImMoveDown
                  size="1.5em"
                  className={
                    i === lastIndex ? "text-gray-200" : "text-blue-500"
                  }
                />
              </SingleIconButton>
            </div>
          ) : null}
          {!disabled && !vertical ? (
            <div className="flex items-center justify-between ml-2 space-x-2">
              <SingleIconButton
                disabled={i === 0}
                onClick={() => {
                  moveUpItem(i);
                }}
              >
                <ImMoveUp
                  size="1.5em"
                  className={i === 0 ? "text-gray-200" : "text-blue-500"}
                />
              </SingleIconButton>
              <SingleIconButton
                disabled={i === lastIndex}
                onClick={() => {
                  moveDownItem(i);
                }}
              >
                <ImMoveDown
                  size="1.5em"
                  className={
                    i === lastIndex ? "text-gray-200" : "text-blue-500"
                  }
                />
              </SingleIconButton>
              <SingleIconButton
                onClick={() => {
                  handleRemove(i);
                }}
              >
                <MdClose size="1.5em" className="text-blue-500" />
              </SingleIconButton>
            </div>
          ) : null}
        </div>
      ))}

      {!disabled ? (
        AddComponent ? (
          <AddComponent />
        ) : (
          <AddButton addText={addText} handleAddNew={handleAddNew} />
        )
      ) : null}
    </>
  );
};
