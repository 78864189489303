import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { customStyles, i18nOptions } from "./multiSelectField";

import { GenericFieldProps } from "./fieldProps";
import {
  SIA_PHASES,
  HOAI_CODES,
  FR_PHASES,
  IT_PHASES,
} from "pages/project/utils";
import { NewProject } from "generated/graphql";

type SiaFields = Pick<
  NewProject,
  "siaPhases" | "hoaiPhases" | "frPhases" | "itPhases" | "primaryPhase"
>;
export type SiaPhasesFieldProps = GenericFieldProps<SiaFields> & PhasesFields;

type PhaseType = {
  label: string;
  value: string;
};

type PhasesFields = {
  siaField: "siaPhases" | "privateReferenceProjectSiaPhases";
  hoaiField: "hoaiPhases" | "privateReferenceProjectHoaiPhases";
  frPhasesField: "frPhases" | "privateReferenceProjectFrPhases";
  itPhasesField: "itPhases" | "privateReferenceProjectItPhases";
  phaseField: "primaryPhase" | "privateReferenceProjectPrimaryPhase";
};

export const SiaPhasesField = ({
  value,
  onChange,
  disabled = false,
  siaField,
  hoaiField,
  frPhasesField,
  itPhasesField,
  phaseField,
}: SiaPhasesFieldProps) => {
  const { t } = useTranslation(["project", "abacusProjects", "common"]);

  const phaseTypes: PhaseType[] = [
    {
      label: "SIA",
      value: "sia",
    },
    {
      label: "HOAI",
      value: "hoai",
    },
    {
      label: t("project:frenchPhases"),
      value: "fr_phases",
    },
    {
      label: t("project:italianPhases"),
      value: "it_phases",
    },
  ];

  const currentPhaseType = {
    label: value?.[phaseField]?.toUpperCase() ?? "SIA",
    value: value?.[phaseField] ?? "sia",
  };

  const allHoaiPhases = Object.keys(HOAI_CODES).map((c) => ({
    label: t(`abacusProjects:${c}` as any),
    value: c,
  }));

  const allSiaPhases = SIA_PHASES.map((c) => ({
    label: t(`abacusProjects:${c}` as any),
    value: c,
  }));

  const allFrenchPhases = FR_PHASES.map((c) => ({
    label: t(`abacusProjects:${c}` as any),
    value: c,
  }));

  const allItalianPhases = IT_PHASES.map((c) => ({
    label: t(`abacusProjects:${c}` as any),
    value: c,
  }));

  return (
    <div className="flex space-x-2">
      <div className="w-24 text-sm">
        <Select
          styles={customStyles}
          options={phaseTypes}
          value={currentPhaseType}
          isDisabled={disabled}
          {...i18nOptions(t)}
          onChange={
            onChange
              ? (newValue) =>
                  onChange({ ...value, [phaseField]: newValue?.value ?? "sia" })
              : undefined
          }
        />
      </div>
      <div className="flex-1 text-sm">
        {value?.[phaseField] == "hoai" ? (
          <Select
            styles={customStyles}
            placeholder={t("common:addEntry", {
              title: t("project:hoaiPhases"),
            })}
            isMulti
            options={allHoaiPhases}
            isDisabled={disabled}
            value={allHoaiPhases.filter((d) =>
              value?.[hoaiField]?.includes(d.value),
            )}
            onChange={
              onChange
                ? (newValues) => {
                    onChange({
                      ...value,
                      [hoaiField]: (newValues as typeof allHoaiPhases).map(
                        (phase) => phase.value,
                      ),
                    });
                  }
                : undefined
            }
          />
        ) : value?.[phaseField] == "fr_phases" ? (
          <Select
            styles={customStyles}
            placeholder={t("common:addEntry", {
              title: t("project:frenchPhases"),
            })}
            isMulti
            options={allFrenchPhases}
            isDisabled={disabled}
            value={allFrenchPhases.filter((d) =>
              value?.[frPhasesField]?.includes(d.value),
            )}
            onChange={
              onChange
                ? (newValues) => {
                    onChange({
                      ...value,
                      [frPhasesField]: (
                        newValues as typeof allFrenchPhases
                      ).map((phase) => phase.value),
                    });
                  }
                : undefined
            }
          />
        ) : value?.[phaseField] == "it_phases" ? (
          <Select
            styles={customStyles}
            placeholder={t("common:addEntry", {
              title: t("project:italianPhases"),
            })}
            isMulti
            options={allItalianPhases}
            isDisabled={disabled}
            value={allItalianPhases.filter((d) =>
              value?.[itPhasesField]?.includes(d.value),
            )}
            onChange={
              onChange
                ? (newValues) => {
                    onChange({
                      ...value,
                      [itPhasesField]: (
                        newValues as typeof allItalianPhases
                      ).map((phase) => phase.value),
                    });
                  }
                : undefined
            }
          />
        ) : (
          <Select
            styles={customStyles}
            placeholder={t("common:addEntry", {
              title: t("project:siaPhases"),
            })}
            isMulti
            options={allSiaPhases}
            isDisabled={disabled}
            value={allSiaPhases.filter((d) =>
              value?.[siaField]?.includes(d.value),
            )}
            onChange={
              onChange
                ? (newValues) => {
                    onChange({
                      ...value,
                      [siaField]: (newValues as typeof allSiaPhases).map(
                        (phase) => phase.value,
                      ),
                    });
                  }
                : undefined
            }
          />
        )}
      </div>
    </div>
  );
};

export const siaPhasesField = (isPrivateReference = false) => {
  const fields = isPrivateReference
    ? {
        siaField: "privateReferenceProjectSiaPhases",
        hoaiField: "privateReferenceProjectHoaiPhases",
        frPhasesField: "privateReferenceProjectFrPhases",
        itPhasesField: "privateReferenceProjectItPhases",
        phaseField: "privateReferenceProjectPrimaryPhase",
      }
    : {
        siaField: "siaPhases",
        hoaiField: "hoaiPhases",
        frPhasesField: "frPhases",
        itPhasesField: "itPhases",
        phaseField: "primaryPhase",
      };
  const { siaField, hoaiField, frPhasesField, itPhasesField, phaseField } =
    fields;
  return {
    renderer: (props) => <SiaPhasesField {...props} {...fields} />,
    onBeforeSave: (value: SiaFields) => {
      return {
        [siaField]: value[siaField],
        [hoaiField]: value[hoaiField],
        [frPhasesField]: value[frPhasesField],
        [itPhasesField]: value[itPhasesField],
        [phaseField]: value[phaseField],
      };
    },
    equals: (a: SiaFields, b: SiaFields) => {
      return (
        (a?.[siaField]?.join(",") ?? "") +
          (a?.[hoaiField]?.join(",") ?? "") +
          (a?.[frPhasesField]?.join(",") ?? "") +
          (a?.[itPhasesField]?.join(",") ?? "") +
          (a?.[phaseField] ?? "") ==
        (b?.[siaField]?.join(",") ?? "") +
          (b?.[hoaiField]?.join(",") ?? "") +
          (b?.[frPhasesField]?.join(",") ?? "") +
          (b?.[itPhasesField]?.join(",") ?? "") +
          (b?.[phaseField] ?? "")
      );
    },
  };
};
