import Appsignal from "@appsignal/javascript";
import * as AppsignalPathPlugin from "@appsignal/plugin-path-decorator";
import * as AppsignalWindowEventsPlugin from "@appsignal/plugin-window-events";

import { stages, getReleaseStage } from "helpers/getReleaseStage";

export const appsignal = new Appsignal({
  key:
    getReleaseStage() === stages.production_swarm
      ? "838f6a72-d00e-4545-8ba8-710841a0dc3a"
      : getReleaseStage() === stages.staging_swarm
        ? "288b10e2-0682-4e7d-b6f7-8b90df6740ec"
        : // dev
          "ba893198-58a9-403c-b4c4-bd02c7cc2f8c",
  // @ts-ignore
  revision: import.meta.env.VITE_APP_REVISION,
  // We can safely ignore ResizeObserver loop error
  // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
  ignoreErrors: [/ResizeObserver loop limit exceeded/],
});
appsignal.use(AppsignalPathPlugin.plugin());
appsignal.use(AppsignalWindowEventsPlugin.plugin());
