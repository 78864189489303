import { useMemo } from "react";
import Fuse, { IFuseOptions } from "fuse.js";

// MAIN EXPORT

export const useFuse = <T,>(
  items: T[],
  filterText: string,
  config: IFuseOptions<T>,
): T[] => {
  const fuseConfig = { isCaseSensitive: false, ...config };

  const fuse = useMemo(() => new Fuse(items, fuseConfig), [items]);

  return filterText !== ""
    ? fuse.search(filterText).map(({ item }) => item)
    : items;
};
