import { gql, DocumentNode } from "@apollo/client";

import { multiLangString } from "./common";

// QUERIES

export const CATEGORIES_QUERY = gql`
  query Categories {
    skills {
      id
      name ${multiLangString}
      employeesCount
    }
    competences {
      id
      name ${multiLangString}
      employeesCount
    }
    diplomas {
      id
      name ${multiLangString}
      employeesCount
    }
    roles {
      id
      name ${multiLangString}
      employeesCount
    }
    referenceRoles {
      id
      name ${multiLangString}
      referencesCount
    }
    languages {
      id
      name ${multiLangString}
      employeesCount
    }
    factKeys {
      id
      name ${multiLangString}
    }
  }
`;

export const category_query = (category: string): DocumentNode => {
  if (category == "referenceRole") {
    return gql`
      query GetReferenceRoleDetail($id: ID!) {
        referenceRole(id: $id) {
          id
          name ${multiLangString}
          referencesCount
          references {
            id
            employee {
              id
              firstName
              lastName
            }
          }
        }
      }
    `;
  }
  return gql`
    query GetCategoryDetail($id: ID!) {
      ${category}(id: $id) {
        id
        name ${multiLangString}
        employeesCount
        employees {
          id
          firstName
          lastName
        }
      }
    }
  `;
};

// MUATIONS

export const CREATE_SKILL_MUTATION = gql`
  mutation CreateSkill($multiLang: InputMultilangText!) {
    createSkill(multiLang: $multiLang) {
      id
      name ${multiLangString}
      employeesCount
    }
  }
`;

export const UPDATE_SKILL_MUTATION = gql`
mutation UpdateSkill($id: ID!, $multiLang: InputMultilangText!) {
  updateSkill(id: $id, multiLang: $multiLang) {
    id
    name ${multiLangString}
    employeesCount
  }
}
`;

export const DELETE_SKILL_MUTATION = gql`
  mutation DeleteSkill($id: ID!) {
    deleteSkill(id: $id) {
      id
    }
  }
`;

export const MERGE_SKILLS_MUTATION = gql`
  mutation MergeSkill($leftId: ID!, $rightId: ID!) {
    mergeSkill(leftId: $leftId, rightId: $rightId)
  }
`;

export const CREATE_COMPETENCE_MUTATION = gql`
  mutation CreateCompetence($multiLang: InputMultilangText!) {
    createCompetence(multiLang: $multiLang) {
      id
      name ${multiLangString}
      employeesCount
    }
  }
`;

export const UPDATE_COMPETENCE_MUTATION = gql`
  mutation UpdateCompetence($id: ID!, $multiLang: InputMultilangText!) {
    updateCompetence(id: $id, multiLang: $multiLang) {
      id
      name ${multiLangString}
      employeesCount
    }
  }
`;

export const DELETE_COMPETENCE_MUTATION = gql`
  mutation DeleteCompetence($id: ID!) {
    deleteCompetence(id: $id) {
      id
    }
  }
`;

export const MERGE_COMPETENCES_MUTATION = gql`
  mutation MergeCompetence($leftId: ID!, $rightId: ID!) {
    mergeCompetence(leftId: $leftId, rightId: $rightId)
  }
`;

export const CREATE_DIPLOMA_MUTATION = gql`
  mutation CreateDiploma($multiLang: InputMultilangText!) {
    createDiploma(multiLang: $multiLang) {
      id
      name ${multiLangString}
      employeesCount
    }
  }
`;

export const UPDATE_DIPLOMA_MUTATION = gql`
  mutation UpdateDiploma($id: ID!, $multiLang: InputMultilangText!) {
    updateDiploma(id: $id, multiLang: $multiLang) {
      id
      name ${multiLangString}
      employeesCount
    }
  }
`;

export const DELETE_DIPLOMA_MUTATION = gql`
  mutation DeleteDiploma($id: ID!) {
    deleteDiploma(id: $id) {
      id
    }
  }
`;

export const MERGE_DIPLOMAS_MUTATION = gql`
  mutation MergeDiploma($leftId: ID!, $rightId: ID!) {
    mergeDiploma(leftId: $leftId, rightId: $rightId)
  }
`;

export const CREATE_ROLE_MUTATION = gql`
  mutation CreateRole($multiLang: InputMultilangText!) {
    createRole(multiLang: $multiLang) {
      id
      name ${multiLangString}
      employeesCount
    }
  }
`;

export const UPDATE_ROLE_MUTATION = gql`
  mutation UpdateRole($id: ID!, $multiLang: InputMultilangText!) {
    updateRole(id: $id, multiLang: $multiLang) {
      id
      name ${multiLangString}
      employeesCount
    }
  }
`;

export const DELETE_ROLE_MUTATION = gql`
  mutation DeleteRole($id: ID!) {
    deleteRole(id: $id) {
      id
    }
  }
`;

export const MERGE_ROLES_MUTATION = gql`
  mutation MergeRole($leftId: ID!, $rightId: ID!) {
    mergeRole(leftId: $leftId, rightId: $rightId)
  }
`;

export const CREATE_REFERENCE_ROLE_MUTATION = gql`
  mutation CreateReferenceRole($multiLang: InputMultilangText!) {
    createReferenceRole(multiLang: $multiLang) {
      id
      name ${multiLangString}
    }
  }
`;

export const UPDATE_REFERENCE_ROLE_MUTATION = gql`
  mutation UpdateReferenceRole($id: ID!, $multiLang: InputMultilangText!) {
    updateReferenceRole(id: $id, multiLang: $multiLang) {
      id
      name ${multiLangString}
    }
  }
`;

export const DELETE_REFERENCE_ROLE_MUTATION = gql`
  mutation DeleteReferenceRole($id: ID!) {
    deleteReferenceRole(id: $id) {
      id
    }
  }
`;

export const MERGE_REFERENCE_ROLES_MUTATION = gql`
  mutation MergeReferenceRole($leftId: ID!, $rightId: ID!) {
    mergeReferenceRole(leftId: $leftId, rightId: $rightId)
  }
`;

export const CREATE_LANGUAGE_MUTATION = gql`
  mutation CreateLanguage($multiLang: InputMultilangText!) {
    createLanguage(multiLang: $multiLang) {
      id
      name ${multiLangString}
      employeesCount
    }
  }
`;

export const UPDATE_LANGUAGE_MUTATION = gql`
  mutation UpdateLanguage($id: ID!, $multiLang: InputMultilangText!) {
    updateLanguage(id: $id, multiLang: $multiLang) {
      id
      name ${multiLangString}
      employeesCount
    }
  }
`;

export const DELETE_LANGUAGE_MUTATION = gql`
  mutation DeleteLanguage($id: ID!) {
    deleteLanguage(id: $id) {
      id
    }
  }
`;

export const MERGE_LANGUAGES_MUTATION = gql`
  mutation MergeLanguage($leftId: ID!, $rightId: ID!) {
    mergeLanguage(leftId: $leftId, rightId: $rightId)
  }
`;

export const CREATE_FACT_KEY_MUTATION = gql`
  mutation CreateFactKey($multiLang: InputMultilangText!) {
    createFactKey(multiLang: $multiLang) {
      id
      name ${multiLangString}
    }
  }
`;

export const UPDATE_FACT_KEY_MUTATION = gql`
  mutation UpdateFactKey($id: ID!, $multiLang: InputMultilangText!) {
    updateFactKey(id: $id, multiLang: $multiLang) {
      id
      name ${multiLangString}
    }
  }
`;

export const DELETE_FACT_KEY_MUTATION = gql`
  mutation DeleteFactKey($id: ID!) {
    deleteFactKey(id: $id) {
      id
      name ${multiLangString}
    }
  }
`;

export const MERGE_FACT_KEY_MUTATION = gql`
  mutation MergeFactKey($leftId: ID!, $rightId: ID!) {
    mergeFactKey(leftId: $leftId, rightId: $rightId)
  }
`;
