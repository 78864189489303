import { FormDefinition, virtualField } from "components/form";
import { AboutTbfBlock } from "generated/graphql";
import { markDownField } from "components/form/markDownField";
import { editBlockModal } from "../editBlock";
import { boolField } from "components/form/boolField";
import { keyValueField } from "components/form/keyValueField";
import { linksField } from "components/form/linksField";
import { textField } from "components/form/textField";
import { previewText } from "../helpers/previewText";

const formDefinition: FormDefinition<AboutTbfBlock> = {
  title: () => "",
  previewText: (block) => {
    return previewText(block.navigationTitle?.de ?? "");
  },
  titleString: () => "",
  fields: {
    navigationTitle: {
      name: "onepager.aboutTbfBlock.navigationTitle",
      component: textField,
      multiLang: true,
      required: true,
    },
    intro: {
      name: "onepager.aboutTbfBlock.intro",
      component: markDownField,
      multiLang: true,
    },
    showRosette: {
      name: "onepager.aboutTbfBlock.showMap",
      component: boolField,
    },
    facts: {
      name: "onepager.aboutTbfBlock.facts",
      component: keyValueField,
      hidableFromExport: true,
    },
    externalLinks: {
      name: "onepager.aboutTbfBlock.externalLinks",
      component: linksField,
      hidableFromExport: true,
    },
    linkedFields: virtualField,
    exportedFields: virtualField,
  },
  link: () => "",
};

export const editAboutTbf = {
  formDefinition,
  modal: editBlockModal(formDefinition),
};
