import React from "react";
import classnames from "classnames";

type AvatarProps = {
  url: string | null;
  size?: string;
  hoverStyle?: boolean;
};

export const Avatar: React.FC<AvatarProps> = ({
  url,
  size = "large",
  hoverStyle = false,
}) => {
  return (
    <div
      className={classnames(
        `bg-gray-400 bg-top bg-cover rounded-full ${
          hoverStyle
            ? "hover:drop-shadow-lg group-hover:drop-shadow-lg transition-transform ease-in-out"
            : ""
        }`,
        size === "xs"
          ? "w-10 h-10"
          : size === "small"
            ? "w-12 h-12"
            : size === "medium"
              ? "w-20 h-20"
              : "w-24 h-24 md:w-36 md:h-36",
      )}
      style={url ? { backgroundImage: `url(${url})` } : undefined}
    />
  );
};

type SearchAvatarProps = {
  profileUrl?: string;
  badge?: string;
  isFormer?: boolean;
};

export const SearchAvatar: React.FC<SearchAvatarProps> = ({
  profileUrl,
  badge,
  isFormer = false,
}) => {
  return (
    <div
      className={classnames(
        "position relative h-16 w-16 rounded-full flex items-center justify-center bg-cover bg-gray-200",
        {
          "opacity-60": isFormer,
        },
      )}
      style={profileUrl ? { backgroundImage: `url(${profileUrl})` } : undefined}
    >
      {badge ? (
        <div className="absolute items-center justify-center bg-blue-500 border border-white rounded-full h-7 w-7 position -bottom-1 -right-1">
          <div className="text-white text-center mt-0.5">{badge}</div>
        </div>
      ) : null}
    </div>
  );
};
