import { useEffect, useState } from "react";

type sideEffect = () => void;

export default (onOnline?: sideEffect, onOffline?: sideEffect) => {
  const [online, set] = useState(navigator.onLine);

  const handleOnline = () => {
    if (!online) {
      set(true);
      if (onOnline) {
        onOnline();
      }
    }
  };

  const handleOffline = () => {
    if (online) {
      set(false);
      if (onOffline) {
        onOffline();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  });

  return online;
};
