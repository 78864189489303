import { gql } from "@apollo/client";
import { multiLangString } from "./common";

const wikiPageFragment = gql`
  fragment wikiPageFragment on WikiPage {
    id
    slugs
    tags {
      id
      tagId
      newText
      tag {
        id
        name ${multiLangString}
      }
    }
    subPagePosition
    employeeIdsToAsk
    employeesToAsk {
      id
      firstName
      lastName
      profilePicture(size: 240)
      colorProfilePicture(size: 504)
      roles {
        id
        role {
          id
          name ${multiLangString}
        }
      }
    }
    title ${multiLangString}
    contentHtml ${multiLangString}
  }
`;

const wikiPageTitleFragment = gql`
  fragment wikiPageTitleFragment on WikiPage {
    id
    slugs
    title ${multiLangString}
  }
`;

const wikiChangerFragment = gql`
  fragment wikiPageChangerFragment on WikiPage {
    modifiedBy {
      __typename
      ... on AutomatedChanger {
        id
      }
      ... on Employee {
        id
        fullName
      }
    }
    version
    updatedAt
  }
`;

export const allParentPages = `allParentPages {
  id
  title ${multiLangString}   
  slugs   
}`;

// QUERIES
export const EDIT_WIKI_PAGE_QUERY = gql`
  ${wikiPageFragment}
  ${wikiPageTitleFragment}

  query GetWikiPage($id: ID!) {
    wikiPage(id: $id) {
      ...wikiPageFragment
      subPages {
        ...wikiPageTitleFragment
      }
      parentPage {
        ...wikiPageTitleFragment
      }
      ${allParentPages}
    }
  }
`;

export const MINIMAL_WIKI_PAGE_QUERY = gql`
  query GetWikiPage($id: ID!) {
    wikiPage(id: $id) {
      id
      title ${multiLangString}
    }
  }
`;

export const LINK_TRANSFORMER_QUERY = gql`
  query GetTransformedLink($currentLinkUrl: String!) {
    linkTransformer(url: $currentLinkUrl)
  }
`;

export const WIKI_PAGE_QUERY = gql`
  ${wikiPageFragment}
  ${wikiPageTitleFragment}
  ${wikiChangerFragment}
  
  query GetWikiPage($id: ID, $slugs: [String!]) {
    wikiPage(id: $id, slugs: $slugs) {
      ...wikiPageFragment
      ...wikiPageChangerFragment
      subPages {
        ...wikiPageTitleFragment
      }
      ${allParentPages}
       }
    parentWikiPage(id: $id, slugs: $slugs) {
      ...wikiPageTitleFragment
      subPages {
        ...wikiPageTitleFragment
      }
      employeesToAsk {
        id
        firstName
        lastName
        profilePicture(size: 240)
        colorProfilePicture(size: 504)
        roles {
          id
          role {
            id
            name ${multiLangString}
          }
        }
      }
    }
  }
`;

export const WIKI_PAGE_SNAPSHOTS_QUERY = gql`
  ${wikiChangerFragment}

  query GetSnapshotsWikiPage($slugs: [String!]) {
    wikiPage(slugs: $slugs) {
      title ${multiLangString}
      id
      version
      ...wikiPageChangerFragment
      snapshots {
        id
        version
        ...wikiPageChangerFragment
      }
    }
  }
`;

export const WIKI_SUB_PAGE_RECURSIVE_QUERY = gql`
  ${wikiPageTitleFragment}

  query GetWikiSubPagesRecursive($id: ID!) {
    wikiPage(id: $id) {
      ...wikiPageTitleFragment
      subPagesRecursive {
        ...wikiPageTitleFragment
      }
    }
  }
`;

export const WIKI_VERSION_PAGE_QUERY = gql`
  ${wikiPageFragment}
  ${wikiPageTitleFragment}

  query GetVersionWikiPage(
    $slugs: [String!]
    $parentSlugs: [String!]
    $version: Int!
  ) {
    wikiPage(slugs: $slugs, version: $version) {
      ...wikiPageFragment
      subPages {
        ...wikiPageTitleFragment
      }
      approvedEntityId
    }
    parentPage: wikiPage(slugs: $parentSlugs) {
      ...wikiPageTitleFragment
      subPages {
        ...wikiPageTitleFragment
      }
    }
  }
`;

// MUTATIONS

export const EDIT_WIKI_PAGE_MUTATION = gql`
  ${wikiPageFragment}

  mutation EditWikiPageMutation($id: ID!, $fields: InputWikiPage!) {
    editAndApproveWikiPage(id: $id, fields: $fields) {
      ...wikiPageFragment
    }
  }
`;

export const EDIT_WIKI_SUB_PAGES_ORDER_MUTATION = gql`
  ${wikiPageFragment}
  ${wikiPageTitleFragment}

  mutation EditWikiSubPagesOrderMutation(
    $topLevelId: ID!
    $newPageOrder: [ID!]!
  ) {
    wikiSetSubPagesOrder(topLevelId: $topLevelId, newPageOrder: $newPageOrder) {
      ...wikiPageFragment
      subPages {
        ...wikiPageTitleFragment
      }
    }
  }
`;

export const CREATE_WIKI_PAGE_MUTATION = gql`
  ${wikiPageFragment}

  mutation CreateWikiPageMutation($fields: InputWikiPage!) {
    createApprovedWikiPage(fields: $fields) {
      ...wikiPageFragment
    }
  }
`;

export const RESTORE_WIKI_PAGE_MUTATION = gql`
  ${wikiPageFragment}

  mutation RestoreWikiPageMutation($id: ID!, $version: Int!) {
    restoreWikiPage(id: $id, version: $version) {
      ...wikiPageFragment
    }
  }
`;

export const DELETE_WIKI_PAGE_WITH_SUB_PAGES_MUTATION = gql`
  ${wikiPageFragment}

  mutation DeleteWikiPageMutation($id: ID!) {
    deleteWikiPageWithSubPages(id: $id) {
      ...wikiPageFragment
    }
  }
`;

export const RENAME_SLUG_FOR_WIKI_PAGE_WITH_SUB_PAGES_MUTATION = gql`
  mutation RenameSlugForWikiPageWithSubPages($id: ID!, $newSlug: String!) {
    renameSlugForWikiPageWithSubPages(id: $id, newSlug: $newSlug) {
      id
      slugs
    }
  }
`;
