import { useEffect } from "react";

export const usePreventBodyScroll = ({ active }) => {
  useEffect(() => {
    document.body.style.overflow = active ? "hidden" : "";
    return () => {
      document.body.style.overflow = "";
    };
  }, [active]);

  return null;
};
