import React from "react";
import { useTranslation } from "react-i18next";
import { ProjectsField } from "./projectsField";
import { IconButton } from "components/button";
import { uniq, concat } from "ramda";
import { FaCopy } from "react-icons/fa";

import { GenericField, GenericFieldProps } from "./fieldProps";

export type EnrichedFromFieldProps = GenericFieldProps<string[]>;

export const EnrichedFromField: React.FC<EnrichedFromFieldProps> = (props) => {
  const { value, onChange, disabled, entity } = props;
  const { t } = useTranslation("form");

  const addStuff = () => {
    const relatedIds = entity?.relatedProjectIds ?? [];
    const mergedIds = uniq(concat((value ?? []) as string[], relatedIds));
    onChange(mergedIds);
  };

  return (
    <div className="flex flex-col space-y-2">
      {disabled ? null : (
        <div>
          <IconButton onClick={addStuff} Icon={() => <FaCopy />}>
            {t("project.copyFromRelated")}
          </IconButton>
        </div>
      )}
      <div className="flex-grow">
        <ProjectsField {...props} />{" "}
      </div>
    </div>
  );
};

export const enrichedFromField: GenericField<EnrichedFromFieldProps> = {
  renderer: EnrichedFromField,
  equals: (a, b) => {
    return (a?.join(",") ?? "") === (b?.join(",") ?? "");
  },
};
