import React, { useContext } from "react";
import AppContext from "context/app";

import { CustomCreatable } from "./creatable";
import { GenericField, GenericFieldProps } from "./fieldProps";
import { getTextFromMultiLang } from "helpers/multiLang";
import { OnepagerTag, WikiTag } from "../../generated/graphql";

type TagsType = (WikiTag | OnepagerTag)[];

type TagFieldProps = GenericFieldProps<TagsType>;

const TagsFields = ({ value, onChange, disabled, language }: TagFieldProps) => {
  const m = getTextFromMultiLang(language ?? "de");
  const { catalogues } = useContext(AppContext);
  const allTags = catalogues?.wikiTags?.map((tag: any) => ({
    label: m(tag?.name),
    value: `${tag.id}`,
  }));

  return (
    <div className="text-sm">
      <CustomCreatable
        disabled={disabled}
        onChange={onChange}
        options={allTags}
        value={value}
        idValue="tagId"
      />
    </div>
  );
};

export const tagsField: GenericField<TagsType> = {
  renderer: TagsFields,
  onBeforeSave: (value) =>
    value
      ?.map((v) => ({
        tagId: v.tagId,
        newText: v.newText,
      }))
      .filter(
        (v) => (v.tagId ? +v.tagId : 0) > 0 || (v.newText?.length ?? 0) > 0,
      ),
  equals: (a, b) => {
    return (
      (a?.map((v) => `${v.tagId}-${v.newText ?? ""}`).join(",") ?? "") ===
      (b?.map((v) => `${v.tagId}-${v.newText ?? ""}`).join(",") ?? "")
    );
  },
};
