import { useQuery, useMutation } from "@apollo/client";
import React from "react";

import Categories from "./categories";
import {
  CATEGORIES_QUERY,
  CREATE_SKILL_MUTATION,
  UPDATE_SKILL_MUTATION,
  DELETE_SKILL_MUTATION,
  MERGE_SKILLS_MUTATION,
  CREATE_COMPETENCE_MUTATION,
  UPDATE_COMPETENCE_MUTATION,
  DELETE_COMPETENCE_MUTATION,
  MERGE_COMPETENCES_MUTATION,
  CREATE_DIPLOMA_MUTATION,
  UPDATE_DIPLOMA_MUTATION,
  DELETE_DIPLOMA_MUTATION,
  MERGE_DIPLOMAS_MUTATION,
  CREATE_ROLE_MUTATION,
  UPDATE_ROLE_MUTATION,
  DELETE_ROLE_MUTATION,
  MERGE_ROLES_MUTATION,
  CREATE_REFERENCE_ROLE_MUTATION,
  UPDATE_REFERENCE_ROLE_MUTATION,
  DELETE_REFERENCE_ROLE_MUTATION,
  MERGE_REFERENCE_ROLES_MUTATION,
  CREATE_LANGUAGE_MUTATION,
  UPDATE_LANGUAGE_MUTATION,
  DELETE_LANGUAGE_MUTATION,
  MERGE_LANGUAGES_MUTATION,
  CREATE_FACT_KEY_MUTATION,
  UPDATE_FACT_KEY_MUTATION,
  DELETE_FACT_KEY_MUTATION,
  MERGE_FACT_KEY_MUTATION,
} from "queries/referenceData";
import { DocumentNode } from "graphql";
import { extractMulti } from "helpers/ui/multiLang";

const useReferenceDataActions = (
  createMutation: DocumentNode,
  updateMutation: DocumentNode,
  deleteMutation: DocumentNode,
  mergeMutation?: DocumentNode,
) => {
  const [create] = useMutation(createMutation);
  const [update] = useMutation(updateMutation);
  const [doDelete] = useMutation(deleteMutation);
  const [merge] = mergeMutation ? useMutation(mergeMutation) : [undefined];

  const createAction = (multiLang: any) => {
    return create({
      variables: { multiLang: extractMulti(multiLang) },
      refetchQueries: [{ query: CATEGORIES_QUERY }],
    });
  };

  const updateAction = (id: string, multiLang: any) => {
    return update({ variables: { id, multiLang: extractMulti(multiLang) } });
  };

  const deleteAction = (id: string) => {
    return doDelete({
      variables: { id },
      refetchQueries: [{ query: CATEGORIES_QUERY }],
    });
  };

  const mergeAction = merge
    ? (leftId: string, rightId: string) => {
        return merge({
          variables: { leftId, rightId },
          refetchQueries: [{ query: CATEGORIES_QUERY }],
        });
      }
    : undefined;

  return {
    create: createAction,
    update: updateAction,
    delete: deleteAction,
    merge: mergeAction,
  };
};

// MAIN COMPONENT

const ReferenceData = () => {
  const { loading, data } = useQuery(CATEGORIES_QUERY);

  const skillsActions = useReferenceDataActions(
    CREATE_SKILL_MUTATION,
    UPDATE_SKILL_MUTATION,
    DELETE_SKILL_MUTATION,
    MERGE_SKILLS_MUTATION,
  );
  const competencesActions = useReferenceDataActions(
    CREATE_COMPETENCE_MUTATION,
    UPDATE_COMPETENCE_MUTATION,
    DELETE_COMPETENCE_MUTATION,
    MERGE_COMPETENCES_MUTATION,
  );

  const diplomasActions = useReferenceDataActions(
    CREATE_DIPLOMA_MUTATION,
    UPDATE_DIPLOMA_MUTATION,
    DELETE_DIPLOMA_MUTATION,
    MERGE_DIPLOMAS_MUTATION,
  );
  const rolesActions = useReferenceDataActions(
    CREATE_ROLE_MUTATION,
    UPDATE_ROLE_MUTATION,
    DELETE_ROLE_MUTATION,
    MERGE_ROLES_MUTATION,
  );
  const referenceRolesActions = useReferenceDataActions(
    CREATE_REFERENCE_ROLE_MUTATION,
    UPDATE_REFERENCE_ROLE_MUTATION,
    DELETE_REFERENCE_ROLE_MUTATION,
    MERGE_REFERENCE_ROLES_MUTATION,
  );
  const languagesActions = useReferenceDataActions(
    CREATE_LANGUAGE_MUTATION,
    UPDATE_LANGUAGE_MUTATION,
    DELETE_LANGUAGE_MUTATION,
    MERGE_LANGUAGES_MUTATION,
  );
  const factKeysActions = useReferenceDataActions(
    CREATE_FACT_KEY_MUTATION,
    UPDATE_FACT_KEY_MUTATION,
    DELETE_FACT_KEY_MUTATION,
    MERGE_FACT_KEY_MUTATION,
  );

  const actions = {
    skills: skillsActions,
    competences: competencesActions,
    diplomas: diplomasActions,
    roles: rolesActions,
    referenceRoles: referenceRolesActions,
    languages: languagesActions,
    factKeys: factKeysActions,
  };

  return <Categories loading={loading} data={data} actions={actions} />;
};

export default ReferenceData;
