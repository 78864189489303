import React, { useState } from "react";
import dayjs from "dayjs";
import { useMutation, useQuery } from "@apollo/client";

import {
  ONEPAGER_SNAPSHOTS_QUERY,
  PUBLISH_ONEPAGER_MUTATION,
  UN_PUBLISH_ONEPAGER_MUTATION,
} from "queries/onepager";
import { useTranslation } from "react-i18next";
import { Modal } from "components/modal";
import { IconButton } from "components/button";
import { IoClose } from "react-icons/io5";
import { FaExternalLinkAlt, FaSpinner } from "react-icons/fa";
import { getReleaseStage, stages } from "helpers/getReleaseStage";
import { Link } from "wouter";
import { Onepager, Employee } from "generated/graphql";
import { LoadingSpinner } from "components/loadingSpinner";

const Title = ({ text }) => {
  return <h2 className="my-4 text-xl text-red-500">{text}</h2>;
};

const Header = () => {
  const { t } = useTranslation("onepager");

  return (
    <div className="flex flex-row">
      <div className="w-1/12 p-3 text-gray-500 text-md">
        {t("publication.version")}
      </div>
      <div className="w-5/12 p-3 text-gray-500 text-md">
        {t("publication.modified")}
      </div>
      <div className="w-2/12 p-3 text-gray-500 text-md">
        {t("publication.status")}
      </div>
      <div className="w-2/12 p-3 text-gray-500 text-md">
        {t("publication.link")}
      </div>
      <div className="w-2/12 p-3 text-gray-500 text-md">
        {t("publication.action")}
      </div>
    </div>
  );
};

const formatDate = (date) => {
  return dayjs(`${date}Z`).format("DD.MM.YYYY — H:mm");
};

const Item = ({
  page,
  publish,
  unPublish,
  isPublishing,
  isAnyPublishing,
  isSnapshot,
}: {
  page: Partial<Onepager>;
  publish: (id: string) => void;
  unPublish: (id: string) => void;
  isPublishing: boolean;
  isAnyPublishing: boolean;
  isSnapshot: boolean;
}) => {
  const { t } = useTranslation(["onepager", "common"]);
  const modifiedById = (page.modifiedBy as Employee)?.activeDirectoryId;
  const modifiedByName = (page.modifiedBy as Employee)?.fullName;

  return (
    <div className="flex flex-row border-t border-gray-300">
      <div className="w-1/12 p-3 text-md">{page.version}</div>
      <div className="w-5/12 p-3 text-md">
        {`${formatDate(page.updatedAt)}, von `}
        {<Link href={`/user/${modifiedById}`}>{modifiedByName}</Link>}
      </div>
      <div className="w-2/12 p-3 text-md">
        {page.publication?.status === "PUBLISHED" ? (
          <span className="text-black">{t("onepager:published")}</span>
        ) : !isSnapshot ? (
          <span className="text-yellow-600">{t("onepager:draft")}</span>
        ) : null}
      </div>
      {page.publication?.status === "PUBLISHED" ? (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={frontendUrl({ token: page.publication.token })}
          className="w-2/12 p-3 text-md"
        >
          {t("onepager:openLink")}
        </a>
      ) : (
        <Link
          target="_blank"
          href={`/onepagers/preview/${page.id}`}
          className="w-2/12 p-3 text-md"
        >
          {t("common:preview")}
        </Link>
      )}
      <div className="w-2/12 p-3 text-md">
        {page.publication?.status === "PUBLISHED" ? (
          <IconButton
            disabled={isAnyPublishing}
            type="warning"
            Icon={
              isPublishing
                ? () => <FaSpinner className="animate-spin" />
                : IoClose
            }
            onClick={() => unPublish(page.id!)}
          >
            {t("onepager:unpublish")}
          </IconButton>
        ) : (
          <IconButton
            disabled={isAnyPublishing}
            Icon={
              isPublishing
                ? () => <FaSpinner className="animate-spin" />
                : FaExternalLinkAlt
            }
            onClick={() => publish(page.id!)}
          >
            {t("onepager:publish")}
          </IconButton>
        )}
      </div>
    </div>
  );
};

export const frontendUrl = ({
  previewId,
  token,
  authToken,
}: {
  previewId?: string;
  token?: string;
  authToken?: string;
}) => {
  const env =
    stages.production_swarm === getReleaseStage()
      ? null
      : stages.staging_swarm === getReleaseStage()
        ? "stage"
        : "dev";
  const url = "https://www.tbf.page" + (token ? `/${token}` : "/preview");
  // Useful if debugging onepager the frontend
  // const url = "http://localhost:3001" + (token ? `/${token}` : "/preview");
  const params = {
    ...(previewId ? { previewId } : {}),
    ...(env ? { env } : {}),
    ...(authToken ? { authToken } : {}),
  };
  const query = new URLSearchParams(params).toString();
  return query === "" ? url : `${url}?${query}`;
};

export const Publications = ({ id, isOpen, setIsOpen }) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [isPublishingId, setIsPublishingId] = useState(null);
  const { data, refetch } = useQuery(ONEPAGER_SNAPSHOTS_QUERY, {
    variables: { id },
    onCompleted: () => setIsLoading(false),
  });
  const [publishOnepager] = useMutation(PUBLISH_ONEPAGER_MUTATION);
  const [unPublishOnepager] = useMutation(UN_PUBLISH_ONEPAGER_MUTATION);

  const page = data?.onepager;
  const snapshots = page?.snapshots;

  const publish = (pageId) => {
    setIsPublishingId(pageId);
    publishOnepager({ variables: { id: pageId } })
      .then((_) => {
        refetch({ id });
      })
      .finally(() => {
        setIsPublishingId(null);
      });
  };

  const unPublish = (pageId) => {
    setIsPublishingId(pageId);
    unPublishOnepager({ variables: { id: pageId } })
      .then((_) => {
        refetch({ id });
      })
      .finally(() => {
        setIsPublishingId(null);
      });
  };

  return (
    <>
      <Modal
        open={isOpen}
        close={() => setIsOpen(false)}
        title={
          <div className="flex items-start justify-between px-6 py-8 space-x-4">
            <h2 className="text-2xl font-medium text-red-500 ">
              {page?.title}
            </h2>
          </div>
        }
        actions={
          <div className="flex flex-row-reverse justify-between w-full">
            <div className="flex items-center px-6 space-x-2">
              <IconButton
                type="secondary"
                Icon={IoClose}
                onClick={() => setIsOpen(false)}
              >
                {t("close")}
              </IconButton>
            </div>
          </div>
        }
      >
        <div className="px-6">
          <div>
            <div className="relative px-3 mx-auto mt-4 max-w-screen-tbf">
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <Title text={"Aktuelle Version"} />
                  <Header />
                  {page ? (
                    <Item
                      page={page}
                      publish={publish}
                      unPublish={unPublish}
                      isPublishing={page.id === isPublishingId}
                      isAnyPublishing={isPublishingId !== null}
                      isSnapshot={false}
                    />
                  ) : null}

                  <Title text={"Vorherige Versionen"} />
                  <Header />
                  {snapshots?.map((p) => (
                    <Item
                      key={p.id}
                      isSnapshot={true}
                      page={p}
                      publish={publish}
                      unPublish={unPublish}
                      isPublishing={p.id === isPublishingId}
                      isAnyPublishing={isPublishingId !== null}
                    />
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
