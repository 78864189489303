import React from "react";
import { FaSpinner } from "react-icons/fa";

export const LoadingSpinner = (): JSX.Element => {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="p-3 text-xl">
        <FaSpinner className="animate-spin" />
      </div>
    </div>
  );
};
