import React, { useState } from "react";
import { IconButton } from "components/button";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa";
import { useFuse } from "helpers/useFuse";
import Masonry from "react-masonry-css";

import {
  NewReference,
  Employee,
  AggregatedProjectTeamMember,
} from "generated/graphql";

import { EditReferenceModal } from "components/editVersioned/editReference";
import { ReferenceItemView } from "components/newReferences";

import { Search } from "components/search";
import { getSortedProjectReferences } from "helpers/getFirstProjectReference";

export const ProjectReferences = ({
  references,
  team,
  projectId,
  currentEmployee,
}: {
  projectId: string;
  team: Array<Partial<AggregatedProjectTeamMember>>;
  references: Array<Partial<NewReference>>;
  currentEmployee: Partial<Employee>;
}) => {
  const { t } = useTranslation(["project"]);

  const employeesIds = team.map((teamMember) => teamMember.employeeId);
  const isEmployeePartOfTeam = employeesIds.includes(currentEmployee?.id);

  const [showEdit, setShowEdit] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const filteredReferences = useFuse(references || [], searchTerm, {
    keys: [
      "project.name.de",
      "project.name.en",
      "project.name.fr",
      "project.name.it",
      "project.abacusProjectId",
      "title.de",
      "title.en",
      "title.fr",
      "title.it",
    ],
  });

  const allSortedReferences = getSortedProjectReferences(filteredReferences);

  const breakpointColumns = {
    default: 3,
    1024: 2,
    767: 1,
  };

  return (
    <>
      <div className="pb-4 mt-6">
        <div className="flex flex-col sm:flex-row justify-between mb-6 sm:mb-10">
          <div className="pb-1 sm:pb-none text-xl font-medium text-red-500">
            {t(`project:references`)}
          </div>
          <div className="flex space-x-2">
            <IconButton
              Icon={FaPlus}
              onClick={() => {
                setShowEdit(true);
              }}
            >
              {t("project:addReference")}
            </IconButton>
            <Search text={searchTerm} setText={setSearchTerm} />
          </div>
        </div>
        {allSortedReferences?.length > 0 ? (
          <Masonry
            breakpointCols={breakpointColumns}
            className="masonry-grid"
            columnClassName="masonry-grid_column"
          >
            {allSortedReferences.map((reference) => {
              return (
                <ReferenceItemView
                  key={reference.id}
                  reference={reference}
                  currentEmployee={currentEmployee}
                  isEmployeePartOfTeam={isEmployeePartOfTeam}
                />
              );
            })}
          </Masonry>
        ) : (
          <div className="text-gray-500">
            {t(`project:referencesAppearHere`)}
          </div>
        )}
      </div>
      <EditReferenceModal
        newEntity={{ projectId }}
        close={() => {
          setShowEdit(false);
        }}
        open={showEdit}
      />
    </>
  );
};
