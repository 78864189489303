import React from "react";
import { useMultiLang } from "helpers/multiLang";
import { EmployeeDiploma } from "generated/graphql";

export const DiplomaItem = ({
  diploma,
}: {
  diploma: Partial<EmployeeDiploma>;
}) => {
  const m = useMultiLang();

  return (
    <div className="flex flex-row w-full pt-2">
      {diploma.year ? (
        <div className="flex-none mr-2 text-sm leading-6 md:font-medium">
          {diploma.year}
        </div>
      ) : (
        <div className="flex-none mr-8 text-sm md:font-medium">
          <p>-</p>
        </div>
      )}
      <div className="flex-1 pr-3 leading-6 whitespace-pre-wrap ">
        <div className="text-light">
          {m(diploma?.diploma?.name) || diploma?.newText}
        </div>
      </div>
    </div>
  );
};
