import React, { useContext, useRef } from "react";

import { BubbleMenu, Editor } from "@tiptap/react";
import classNames from "classnames";
import { Popup } from "components/popup";
import AuthContext from "context/user";
import { getApiUrl } from "helpers/apiUrl";
import { useTranslation } from "react-i18next";
import {
  AiOutlineBold,
  AiOutlineItalic,
  AiOutlineLink,
  AiOutlineOrderedList,
  AiOutlineStrikethrough,
  AiOutlineUnorderedList,
} from "react-icons/ai";
import { FaFile, FaHighlighter, FaVideo } from "react-icons/fa";
import { ImFilePicture, ImQuotesLeft, ImTable2 } from "react-icons/im";
import { RiH1, RiH2, RiH3, RiSuperscript } from "react-icons/ri";
import { GoFileSymlinkFile } from "react-icons/go";

export const FullMenuBar = ({
  editor,
  showAdditionalInput,
  onClickWikiLink,
  onClickLink,
  onClickVimeoLink,
  currentAdditionalInput,
}: {
  editor: Editor;
  showAdditionalInput: boolean;
  onClickWikiLink: () => void;
  onClickLink: () => void;
  onClickVimeoLink: () => void;
  currentAdditionalInput?: string | null;
}): JSX.Element => {
  const { t } = useTranslation("tiptapMenubar");

  return (
    <div>
      <div
        className={classNames("tiptap-editor rounded border", {
          "rounded-b-none": showAdditionalInput,
        })}
      >
        <div className="relative tiptap-editor-header">
          <Popup content={<p className="py-1">{t("bold")} </p>}>
            <BoldButton editor={editor} />
          </Popup>
          <Popup content={<p className="py-1">{t("italic")} </p>}>
            <ItalicButton editor={editor} />
          </Popup>
          <Popup content={<p className="py-1">{t("strikethrough")} </p>}>
            <StrikethroughButton editor={editor} />
          </Popup>
          <Popup content={<p className="py-1">{t("highlighter")} </p>}>
            <HighlighterButton editor={editor} />
          </Popup>
          <Popup content={<p className="py-1">{t("unorderedList")} </p>}>
            <UnorderedListButton editor={editor} />
          </Popup>
          <Popup content={<p className="py-1">{t("orderedList")} </p>}>
            <OrderedListButton editor={editor} />
          </Popup>

          <div className="divider"></div>
          <Popup content={<p className="py-1">{t("heading1")} </p>}>
            <HeadingButton level={1} editor={editor} />
          </Popup>
          <Popup content={<p className="py-1">{t("heading2")} </p>}>
            <HeadingButton level={2} editor={editor} />
          </Popup>
          <Popup content={<p className="py-1">{t("heading3")} </p>}>
            <HeadingButton level={3} editor={editor} />
          </Popup>
          <Popup content={<p className="py-1">{t("superscript")} </p>}>
            <SuperscriptButton editor={editor} />
          </Popup>
          <div className="divider"></div>
          <Popup content={<p className="py-1">{t("link")} </p>}>
            <LinkButton
              onClick={onClickLink}
              currentAdditionalInput={currentAdditionalInput}
            />
          </Popup>
          <Popup content={<p className="py-1">{t("wikiLink")} </p>}>
            <WikiLinkButton
              onClick={onClickWikiLink}
              currentAdditionalInput={currentAdditionalInput}
            />
          </Popup>
          <Popup content={<p className="py-1">{t("table")} </p>}>
            <TableButton editor={editor} />
          </Popup>
          <Popup content={<p className="py-1">{t("uploadPicture")} </p>}>
            <ImageUploadButton editor={editor} />
          </Popup>
          <Popup content={<p className="py-1">{t("uploadAttachment")} </p>}>
            <WikiAttachmentButton editor={editor} />
          </Popup>
          <Popup content={<p className="py-1">{t("insertVideo")} </p>}>
            <VimeoButton
              onClick={onClickVimeoLink}
              currentAdditionalInput={currentAdditionalInput}
            />
          </Popup>

          <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
            <div className="border rounded tiptap-editor">
              <div className="tiptap-editor-header">
                <Popup content={<p className="py-1">{t("bold")} </p>}>
                  <BoldButton editor={editor} />
                </Popup>
                <Popup content={<p className="py-1">{t("italic")} </p>}>
                  <ItalicButton editor={editor} />
                </Popup>
                <Popup content={<p className="py-1">{t("strikethrough")} </p>}>
                  <StrikethroughButton editor={editor} />
                </Popup>
                <Popup content={<p className="py-1">{t("highlighter")} </p>}>
                  <HighlighterButton editor={editor} />
                </Popup>
                <Popup content={<p className="py-1">{t("link")} </p>}>
                  <LinkButton
                    onClick={onClickLink}
                    currentAdditionalInput={currentAdditionalInput}
                  />
                </Popup>
                <Popup content={<p className="py-1">{t("wikiLink")} </p>}>
                  <WikiLinkButton
                    onClick={onClickWikiLink}
                    currentAdditionalInput={currentAdditionalInput}
                  />
                </Popup>
                <Popup content={<p className="py-1">{t("insertVideo")} </p>}>
                  <VimeoButton
                    onClick={onClickVimeoLink}
                    currentAdditionalInput={currentAdditionalInput}
                  />
                </Popup>
              </div>
            </div>
          </BubbleMenu>
        </div>
      </div>
    </div>
  );
};

export const ReducedMenuBar = ({
  editor,
  onClickLink,
  currentAdditionalInput,
}: {
  editor: Editor;
  onClickLink: () => void;
  currentAdditionalInput?: string | null;
}): JSX.Element => {
  const { t } = useTranslation("tiptapMenubar");

  return (
    <div className="rounded tiptap-editor">
      <div className="tiptap-editor-header">
        <Popup content={<p className="py-1">{t("bold")}</p>}>
          <BoldButton editor={editor} />
        </Popup>
        <Popup content={<p className="py-1">{t("italic")}</p>}>
          <ItalicButton editor={editor} />
        </Popup>
        <Popup content={<p className="py-1">{t("link")} </p>}>
          <LinkButton
            onClick={onClickLink}
            currentAdditionalInput={currentAdditionalInput}
          />
        </Popup>
        <Popup content={<p className="py-1">{t("heading1")}</p>}>
          <HeadingButton level={1} editor={editor} />
        </Popup>
        <Popup content={<p className="py-1">{t("heading2")}</p>}>
          <HeadingButton level={2} editor={editor} />
        </Popup>
        <Popup content={<p className="py-1">{t("heading3")}</p>}>
          <HeadingButton level={3} editor={editor} />
        </Popup>
        <Popup content={<p className="py-1">{t("unorderedList")}</p>}>
          <UnorderedListButton editor={editor} />
        </Popup>
        <Popup content={<p className="py-1">{t("orderedList")}</p>}>
          <OrderedListButton editor={editor} />
        </Popup>
        <Popup content={<p className="py-1">{t("quotes")}</p>}>
          <QuotesButton editor={editor} />
        </Popup>
      </div>
    </div>
  );
};

// Buttons

const WikiAttachmentButton = ({ editor }) => {
  return (
    <button
      onClick={() =>
        editor
          .chain()
          .focus()
          .insertWikiAttachment()
          // Workaround for getting the curser to behave a bit better with inline elements
          // Without this, the cursor jumps to weird places.
          // However, with this workaround, the user needs to press the arrow key twice to navigate the cursor.
          .insertContent("\u200B")
          .run()
      }
      className="menu-item"
    >
      <FaFile />
    </button>
  );
};

const ItalicButton = ({ editor }) => {
  return (
    <button
      onClick={() => editor.chain().focus().toggleItalic().run()}
      className={classNames("menu-item", {
        "menu-item-active": editor.isActive("italic"),
      })}
    >
      <AiOutlineItalic />
    </button>
  );
};

const StrikethroughButton = ({ editor }) => {
  return (
    <button
      onClick={() => editor.chain().focus().toggleStrike().run()}
      className={classNames("menu-item", {
        "menu-item-active": editor.isActive("strike"),
      })}
    >
      <AiOutlineStrikethrough />
    </button>
  );
};

const HighlighterButton = ({ editor }) => {
  return (
    <button
      onClick={() => editor.chain().focus().toggleHighlight().run()}
      className={classNames("menu-item", {
        "menu-item-active": editor.isActive("highlight"),
      })}
    >
      <FaHighlighter />
    </button>
  );
};

const UnorderedListButton = ({ editor }) => {
  return (
    <button
      onClick={() => editor.chain().focus().toggleBulletList().run()}
      className={classNames("menu-item", {
        "menu-item-active": editor.isActive("bulletList"),
      })}
    >
      <AiOutlineUnorderedList />
    </button>
  );
};

const OrderedListButton = ({ editor }) => {
  return (
    <button
      onClick={() => editor.chain().focus().toggleOrderedList().run()}
      className={classNames("menu-item", {
        "menu-item-active": editor.isActive("orderedList"),
      })}
    >
      <AiOutlineOrderedList />
    </button>
  );
};

const HeadingButton = ({ level, editor }) => {
  return (
    <button
      onClick={() => editor.chain().focus().toggleHeading({ level }).run()}
      className={classNames("menu-item", {
        "menu-item-active": editor.isActive("heading", { level }),
      })}
    >
      {level === 1 ? (
        <RiH1 />
      ) : level === 2 ? (
        <RiH2 />
      ) : level === 3 ? (
        <RiH3 />
      ) : null}
    </button>
  );
};

const SuperscriptButton = ({ editor }) => {
  return (
    <button
      onClick={() => editor.chain().focus().toggleSuperscript().run()}
      className={classNames("menu-item", {
        "menu-item-active": editor.isActive("superscript"),
      })}
    >
      <RiSuperscript />
    </button>
  );
};

const LinkButton = ({ onClick, currentAdditionalInput }) => {
  return (
    <button
      onClick={onClick}
      className={classNames("menu-item", {
        "menu-item-active": currentAdditionalInput == "link",
      })}
    >
      <AiOutlineLink />
    </button>
  );
};

const WikiLinkButton = ({ onClick, currentAdditionalInput }) => {
  return (
    <button
      onClick={onClick}
      className={classNames("menu-item", {
        "menu-item-active": currentAdditionalInput == "wikiLink",
      })}
    >
      <GoFileSymlinkFile />
    </button>
  );
};

const TableButton = ({ editor }) => {
  return (
    <button
      onClick={() => {
        editor
          .chain()
          .focus()
          .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
          .run();
      }}
      className={classNames("menu-item", {
        "menu-item-active": editor.isActive("table"),
      })}
    >
      <ImTable2 />
    </button>
  );
};

const QuotesButton = ({ editor }) => {
  return (
    <button
      onClick={() => editor.chain().focus().toggleBlockquote().run()}
      className={classNames("menu-item", {
        "menu-item-active": editor.isActive("blockquote"),
      })}
    >
      <ImQuotesLeft />
    </button>
  );
};

const BoldButton = ({ editor }) => {
  return (
    <button
      onClick={() => editor.chain().focus().toggleBold().run()}
      className={classNames("menu-item", {
        "menu-item-active": editor.isActive("bold"),
      })}
    >
      <AiOutlineBold />
    </button>
  );
};

const ImageUploadButton = ({ editor }) => {
  const inputRef: any = useRef(null);

  const { token } = useContext(AuthContext);

  const addImage = (src) => {
    if (src) {
      editor.chain().focus().setImage({ src: src }).run();
    }
  };

  return (
    <>
      <button
        onClick={(e) => {
          inputRef!.current.click();
          e.preventDefault();
        }}
        className={classNames("menu-item", {})}
      >
        <ImFilePicture />
      </button>
      <input
        type="file"
        accept="image/jpeg, image/png, image/gif, image/png"
        hidden
        ref={inputRef}
        onChange={(e) => {
          const file = (e as any).target.files[0];

          const formData = new FormData();
          formData.append("image", file);

          fetch(`${getApiUrl()}/wiki-image/uploadFile`, {
            method: "post",
            body: formData,
            headers: {
              authorization: token ? `Bearer ${token}` : "",
            },
          })
            .then((res) => res.json())
            .then((data) => {
              const imageUrl = data?.file?.url;
              if (imageUrl) {
                addImage(imageUrl);
              }
            });
        }}
      />
    </>
  );
};

const VimeoButton = ({ onClick, currentAdditionalInput }) => {
  return (
    <button
      onClick={onClick}
      className={classNames("menu-item", {
        "menu-item-active": currentAdditionalInput == "vimeo",
      })}
    >
      <FaVideo />
    </button>
  );
};
