import { NewReference } from "generated/graphql";

export const getReferenceDuration = (to: any, from: any): string => {
  return to && to.length > 0
    ? `${from?.slice(0, 4) ?? ""}–${to.slice(0, 4)}`
    : `${from?.slice(0, 4) ?? ""}`;
};

export const isNotNilOrEmpty = (field: any): boolean =>
  Array.isArray(field)
    ? field?.length > 0
    : field !== undefined && field !== null;

export const selectReferenceData = (
  reference: Partial<NewReference>,
  data: string,
): any => {
  const privateRefFieldName = `privateReferenceProject${data.charAt(0).toUpperCase() + data.slice(1)}`;
  const dataSources: any = [
    reference?.[data],
    reference?.parentReference?.[data],
    reference?.parentReference?.parentReference?.[data],
    !reference.projectId ? reference?.[privateRefFieldName] : undefined,
  ];

  const referenceData = dataSources.find((source) => isNotNilOrEmpty(source));
  if (referenceData === undefined) {
    return [];
  } else {
    return referenceData;
  }
};

export const selectTeamMemberReferenceData = (
  reference: Partial<NewReference>,
  data: string,
): any => {
  const dataSources: any = [
    reference?.parentReference?.parentReference?.[data],
    reference?.parentReference?.[data],
  ].filter((i) => !!i);

  return dataSources.find((source) => {
    return source !== undefined && source !== null;
  });
};
