import React, { useState } from "react";
import { useLocation } from "wouter";
import { VersionedEntity } from "./editVersioned/genericForm";
import { ActionLink, IconButton } from "./button";
import { useTranslation } from "react-i18next";
import { FaExchangeAlt, FaLongArrowAltRight, FaRedo } from "react-icons/fa";
import { DocumentNode, useMutation } from "@apollo/client";
import { Modal } from "components/modal";
import { IoMdClose } from "react-icons/io";
import { RestoreModal } from "pages/wiki/RestoreModal";
import { Popup } from "components/popup";
import { Employee, NewReference } from "generated/graphql";

export const Banner: React.FC<{
  title: string;
  text?: string;
  secondText?: string;
  thirdText?: string;
  children?: React.ReactNode;
}> = ({ title, text, secondText, thirdText, children }) => {
  return (
    <div className="relative border-t border-b border-gray-100 shadow-top-bottom z-1 bg-gray-50">
      <div className="flex flex-col px-2 py-6 mx-auto flex-start max-w-screen-tbf">
        <div className="pl-2 text-lg font-medium text-red-500">{title}</div>
        <div className="relative flex flex-row items-baseline justify-between w-full px-2 mx-auto">
          <div className="text-sm font-medium text-gray-600">{text}</div>
          <div>{children}</div>
        </div>
        {((text && secondText) || (text && secondText && thirdText)) && (
          <hr className="my-2" />
        )}
        <div className="w-10/12 pl-2 text-sm font-medium text-gray-600">
          {secondText}
        </div>
        {((text && thirdText) ||
          (text && secondText && thirdText) ||
          (secondText && thirdText)) && <hr className="my-2" />}
        <div className="w-10/12 pl-2 text-sm font-medium text-gray-600">
          {thirdText}
        </div>
      </div>
    </div>
  );
};

export const DraftProcessBanner: React.FC<{
  draft: Partial<VersionedEntity> | null;
  editMutation: DocumentNode;
  refDrafts?: Array<Partial<NewReference>>;
  teamRefDrafts?: Array<Partial<NewReference>>;
}> = ({ draft, editMutation, refDrafts, teamRefDrafts }) => {
  const { t } = useTranslation(["project", "common"]);
  const [edit] = useMutation(editMutation);
  const setReadyForReview = (isReadyForReview) => {
    edit({ variables: { id: draft?.id, fields: { isReadyForReview } } });
  };
  const [showConfirmation, setShowConfirmation] = useState(false);

  const getBannerText = (drafts, firstText, secondText) => {
    return (drafts ?? []).length > 0
      ? drafts?.every((d) => d.isReadyForReview)
        ? firstText
        : secondText
      : "";
  };

  return (
    <>
      <Banner
        title={t("project:pendingChanges")}
        text={
          !draft
            ? ""
            : draft.isReadyForReview
              ? t("project:changeWaitingForApprovalGeneric")
              : t("project:changeWaitingForReadyForReview")
        }
        secondText={getBannerText(
          refDrafts,
          t("project:refChangeWaitingForApprovalGeneric"),
          t("project:referencesDraftsArePresent"),
        )}
        thirdText={getBannerText(
          teamRefDrafts,
          t("project:teamRefChangeWaitingForApprovalGeneric"),
          t("project:teamReferencesDraftsArePresent"),
        )}
      >
        {draft && !draft.isReadyForReview ? (
          <Popup content={t("project:setReadyForReview")}>
            <IconButton
              Icon={FaExchangeAlt}
              onClick={() => setShowConfirmation(true)}
            >
              {t("project:release")}
            </IconButton>
          </Popup>
        ) : null}
      </Banner>
      <Modal
        size="small"
        title={
          <div className="px-6 py-8 text-2xl font-medium text-red-500">
            {t("project:setReadyForReview")}
          </div>
        }
        open={showConfirmation}
        close={() => setShowConfirmation(false)}
        actions={
          <div className="flex flex-row space-x-2">
            <IconButton
              type="secondary"
              Icon={IoMdClose}
              onClick={() => setShowConfirmation(false)}
            >
              {t("common:cancel")}
            </IconButton>
            <IconButton
              type="soft-warning"
              Icon={FaExchangeAlt}
              onClick={() => {
                setReadyForReview(true);
                setShowConfirmation(false);
              }}
            >
              {t("common:confirm")}
            </IconButton>
          </div>
        }
      >
        <div className="px-6 my-8">
          {t("common:confirmationSetReadyForReview")}
        </div>
      </Modal>
    </>
  );
};

export const DraftBanner: React.FC<{
  entity: Partial<VersionedEntity>;
  url: string;
}> = ({ entity, url }) => {
  const { t } = useTranslation("common");
  return entity.approvedEntityId ? (
    <Banner
      title={t("warning")}
      text={t("notApprovedEntity")}
      secondText={""}
      thirdText={""}
    >
      <ActionLink
        link={`${url}/${entity.approvedEntityId}`}
        Icon={FaLongArrowAltRight}
        label={t("navigateToCurrentVersion")}
        iconRight
      />
    </Banner>
  ) : null;
};

export const FormerEmployeeBanner: React.FC<{
  employee: Partial<Employee>;
}> = ({ employee }) => {
  const { t } = useTranslation("common");
  return employee?.isFormerEmployee ? <Banner title={t("former")} /> : null;
};

export const PreviousVersionBanner: React.FC<{
  entity: Partial<VersionedEntity>;
  url: string;
  restore: (id: string, version: number) => Promise<any>;
  version: number;
}> = ({ entity, url, restore, version }) => {
  const [showsRecoverModal, setShowRecoverModal] = useState(false);
  const { t } = useTranslation("common");
  const [_location, navigate] = useLocation();
  return entity.approvedEntityId ? (
    <div className="relative border-t border-b border-gray-100 shadow-top-bottom z-1 bg-gray-50">
      <div className="flex flex-row w-full px-2 py-6 mx-auto max-w-screen-xl">
        <div className="pl-2">
          <div className="text-lg font-medium text-red-500 ">
            {t("warning")}
          </div>
          <div className="text-sm font-medium text-gray-600">
            {t("olderEntity")}
          </div>
        </div>
        <div className="flex-1" />
        <div>
          <ActionLink
            link={url}
            Icon={FaLongArrowAltRight}
            label={t("navigateToCurrentVersion")}
            iconRight
          />
          <IconButton
            className="mt-1"
            type="soft-warning"
            Icon={FaRedo}
            onClick={() => setShowRecoverModal(true)}
          >
            {t("restore")}
          </IconButton>
          <RestoreModal
            showRecoverModal={showsRecoverModal}
            setShowRecoverModal={setShowRecoverModal}
            restore={() => {
              restore(entity.approvedEntityId ?? "", version).then(() => {
                navigate(url);
              });
            }}
          />
        </div>
      </div>
    </div>
  ) : null;
};
