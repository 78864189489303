import React, { ReactNode, useState, MutableRefObject } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import { Popup } from "components/popup";
import classNames from "classnames";
import { useClickAway } from "@uidotdev/usehooks";

// HELPER COMPONENTS

const ActionButton = ({ disabled, onClick, children, className = "" }) => {
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={onClick}
      className={`flex items-center px-2 py-1 text-sm  border-grey-300 ${className}`}
    >
      {children}
    </button>
  );
};

type DropDownOption = {
  text: ReactNode;
  value?: string;
  key: string;
  onClick?: (value?: string) => any;
  icon?: ReactNode;
};

type DropDownSeparator = {
  title: string;
};

export const DropDown = ({
  open,
  options,
  disabled = false,
  arrowIcon = true,
}: {
  open: boolean;
  disabled: boolean;
  options: Array<DropDownOption | DropDownSeparator>;
  arrowIcon?: boolean;
}) => {
  return (
    <div className="relative px-2 py-1 cursor-pointer">
      {arrowIcon ? <IoMdArrowDropdown /> : null}

      <div
        className={`${
          open ? "block" : "hidden"
        } absolute right-0 z-10 min-w-24 max-w-sm mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
      >
        <div className="py-1" role="none">
          {options.map((o) => {
            const isSeparator = !!(o as DropDownSeparator).title;
            const option = o as DropDownOption;
            const group = o as DropDownSeparator;
            return isSeparator ? (
              <div
                key={group.title}
                className={classNames({
                  "block px-4 pt-3 pb-1 text-xs  border-t border-gray-200 cursor-default":
                    true,
                  "text-gray-700": !disabled,
                  "text-gray-300": disabled,
                })}
              >
                {group.title}
              </div>
            ) : (
              <div
                key={option.key || option.value}
                className={classNames({
                  "flex items-center justify-between px-4 py-2 space-x-3 text-sm  whitespace-nowrap ":
                    true,
                  "text-gray-700 hover:bg-gray-100 hover:text-gray-900":
                    !disabled,
                  "text-gray-300": disabled,
                })}
                role="menuitem"
                onClick={() => {
                  if (!disabled && option.onClick) {
                    option.onClick(option.value);
                  }
                }}
              >
                <div>{option.text}</div>
                {option.icon && (
                  <div className={disabled ? "" : "text-blue-500"}>
                    {option.icon}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

// MAIN COMPONENT

type DropdownButtonProps = {
  disabled?: boolean;
  onClick?: () => any;
  children?: ReactNode;
  optionsPopupContent?: ReactNode;
  options: Array<DropDownOption | DropDownSeparator>;
  arrowIcon?: boolean;
  isEditing?: boolean;
  straightLeftBorder?: boolean;
  straightRightBorder?: boolean;
};

export const DropdownButton = ({
  disabled = false,
  onClick = () => {},
  children,
  optionsPopupContent,
  options,
  arrowIcon,
  isEditing = false,
  straightLeftBorder = false,
  straightRightBorder = false,
}: DropdownButtonProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const ref: MutableRefObject<HTMLDivElement> = useClickAway(() => {
    setOpen(false);
  });

  const showDropdown = options.length > 1 && !isEditing;

  const optionsUI = showDropdown && (
    <div
      ref={ref}
      onClick={() => {
        setOpen(!open);
      }}
    >
      <DropDown
        open={open}
        options={options}
        disabled={disabled}
        arrowIcon={arrowIcon}
      />
    </div>
  );

  return (
    <div
      className={classNames(
        "flex items-center text-blue-500 border border-grey-300 hover:bg-blue-500 hover:bg-opacity-5 hover:border-blue-500 hover:border-opacity-40",
        { "rounded-l": !straightLeftBorder },
        { "rounded-r": !straightRightBorder },
      )}
    >
      {children && (
        <ActionButton
          onClick={onClick}
          disabled={disabled}
          className={showDropdown ? "border-r" : ""}
        >
          {children}
        </ActionButton>
      )}
      {optionsPopupContent ? (
        <Popup content={optionsPopupContent}>{optionsUI}</Popup>
      ) : (
        optionsUI
      )}
    </div>
  );
};

export const FullyClickableDropdownButton = ({
  disabled = false,
  onClick = () => {},
  children,
  optionsPopupContent,
  options,
}: {
  disabled?: boolean;
  onClick?: () => any;
  children?: ReactNode;
  optionsPopupContent?: ReactNode;
  options?: Array<DropDownOption | DropDownSeparator>;
}): JSX.Element => {
  const [open, setOpen] = useState(false);
  const ref: MutableRefObject<HTMLDivElement> = useClickAway(() => {
    setOpen(false);
  });

  const optionsUI = options ? (
    <div
      ref={ref}
      className="flex"
      onClick={() => {
        setOpen(!open);
      }}
    >
      {children && (
        <ActionButton onClick={onClick} disabled={disabled}>
          {children}
        </ActionButton>
      )}
      <div className="mt-1">
        <DropDown open={open} options={options} disabled={disabled} />
      </div>
    </div>
  ) : null;

  return (
    <div className="flex items-center text-blue-500 border rounded border-grey-300 hover:bg-blue-500 hover:bg-opacity-5 hover:border-blue-500 hover:border-opacity-40">
      {optionsPopupContent ? (
        <Popup content={optionsPopupContent}>{optionsUI}</Popup>
      ) : (
        optionsUI
      )}
    </div>
  );
};
