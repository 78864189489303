import { useEffect, useRef } from "react";

const useInfiniteScroll = (
  callback: any,
  element: any = window,
  fixedOffset = 100,
  deps?: any[],
) => {
  const active = useRef(true);

  const handleScroll = () => {
    const root = document.getElementById("root");
    if (
      root &&
      active.current &&
      element.innerHeight + window.scrollY + fixedOffset >= root.offsetHeight
    ) {
      active.current = false;
      callback();
    }
  };

  useEffect(() => {
    element.addEventListener("scroll", handleScroll);
    return () => element.removeEventListener("scroll", handleScroll);
  }, deps);

  const resetInfiniteScroll = () => {
    active.current = true;
    // We call handle scroll here again, because the page might not be scrollable yet,
    // e.g. there aren't enough items yet to even have a scroll bar.
    // By calling it again here we expect the caller to fetch more items such that eventually,
    // scrollbars will be shown and the callback is only triggered when the user actually scrolls to the bottom.
    handleScroll();
  };

  return resetInfiniteScroll;
};

export default useInfiniteScroll;
