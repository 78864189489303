import React from "react";
import { useQuery } from "@apollo/client";

import { useMultiLang } from "helpers/multiLang";
import { WIKI_PAGE_SNAPSHOTS_QUERY } from "queries/wiki";
import { useTranslation } from "react-i18next";
import { Modal } from "components/modal";
import { IconButton } from "components/button";
import { IoClose } from "react-icons/io5";
import { Link } from "wouter";

export const VersionList = ({
  slugs,
  showsVersionList,
  setShowsVersionList,
}) => {
  const m = useMultiLang();
  const { t } = useTranslation(["wiki", "common"]);

  const { data } = useQuery(WIKI_PAGE_SNAPSHOTS_QUERY, {
    variables: { slugs: slugs },
  });

  const page = data?.["wikiPage"];

  return (
    <>
      <Modal
        open={showsVersionList}
        close={() => setShowsVersionList(false)}
        title={
          <div className="flex items-start justify-between px-6 py-8 space-x-4">
            <h2 className="text-2xl font-medium text-red-500 ">
              {m(page?.title)}
            </h2>
          </div>
        }
        actions={
          <div className="flex flex-row-reverse justify-between w-full">
            <div className="flex items-center px-6 space-x-2">
              <IconButton
                type="secondary"
                Icon={IoClose}
                onClick={() => setShowsVersionList(false)}
              >
                {t("common:close")}
              </IconButton>
            </div>
          </div>
        }
      >
        <div className="px-6">
          <div className="relative px-3 mx-auto mt-4 max-w-screen-tbf">
            <div className="flex flex-row">
              <div className="w-2/12 p-3 text-md">{t("wiki:version")}</div>
              <div className="w-3/12 p-3 text-md">{t("wiki:date")}</div>
              <div className="w-3/12 p-3 text-md">{t("wiki:author")}</div>
            </div>
            <Link
              href={`/wiki/${slugs.join("/")}`}
              onClick={() => setShowsVersionList(false)}
            >
              <VersionItemView wikiPage={page} latestVersion={true} />
            </Link>
            <VersionHistory wikiPage={page} />
          </div>
        </div>
      </Modal>
    </>
  );
};

const VersionHistory = ({ wikiPage }) =>
  wikiPage.snapshots.map((snapshot) => {
    return (
      <Link href={`?version=${snapshot.version}`} key={snapshot.version}>
        <VersionItemView
          wikiPage={snapshot}
          key={snapshot.version}
          latestVersion={false}
        />
      </Link>
    );
  });

const VersionItemView = (props) => {
  const { wikiPage, latestVersion } = props;
  const { t } = useTranslation("wiki");
  return (
    <a href={props.href} onClick={props.onClick}>
      <div className="flex flex-row border-t border-gray-300 hover:text-blue-500 cursor-pointer">
        <div className="p-3 w-2/12 text-md">{wikiPage.version}</div>
        <div className="p-3 w-3/12 text-md">{wikiPage.updatedAt}</div>
        <div className="p-3 w-3/12 text-md">{wikiPage.modifiedBy.fullName}</div>
        {latestVersion && (
          <div
            className="p-3 w-auto text-sm w-2/12 text-gray-350"
            key="latestVersion"
          >
            {t("newestVersion")}
          </div>
        )}
      </div>
    </a>
  );
};
