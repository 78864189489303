import React from "react";
import { Popup } from "components/popup";
import { useTranslation } from "react-i18next";
import { useMultiLang } from "helpers/multiLang";
import classnames from "classnames";
import { levelCodes, levelOptions } from "components/form/LanguagesField";

const isAtLeast = (referenceLevel, currentLevel) => {
  const indexReferenceLevel = levelCodes.indexOf(referenceLevel);
  const indexCurrentLevel = levelCodes.indexOf(currentLevel);
  return currentLevel && indexCurrentLevel >= indexReferenceLevel;
};

const LanguageLevel = ({ level }) => {
  const { t } = useTranslation("employee");

  return (
    <div className="flex space-x-2">
      {levelOptions.map((l) => (
        <Popup key={l.value} content={t(l.labelKey as any)}>
          <div
            className={classnames("w-4 h-2 bg-blue-500", {
              "bg-opacity-25": !isAtLeast(l.value, level),
            })}
          ></div>
        </Popup>
      ))}
    </div>
  );
};

const LanguageItem = ({ language }) => {
  const m = useMultiLang();

  return (
    <div className="flex items-center w-full">
      <div className="flex-1 my-1 text-sm text-blue-500">
        {m(language?.language?.name)}
      </div>
      <div>
        <LanguageLevel level={language?.level} />
      </div>
    </div>
  );
};

export const LanguagesWithLevel = ({ languages }) => {
  return (
    <div>
      {languages.map((language) => {
        return <LanguageItem key={language.id} language={language} />;
      })}
    </div>
  );
};
