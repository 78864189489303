import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";

import { MultiLang, stringifyMulti } from "helpers/ui/multiLang";
import { ListField } from "./ListField";
import { TextField } from "./textField";
import { extractMulti } from "helpers/ui/multiLang";
import { EmployeesField } from "components/form/employeesField";
import { GenericField, GenericFieldProps } from "./fieldProps";
import { EmployeeRoleNetwork } from "generated/graphql";

import { ALL_EMPLOYEES_WITH_ROLE_QUERY } from "queries/newEmployee";
import { IconButton, SecondaryButton } from "components/button";
import { MdAdd } from "react-icons/md";

type TrustedAdvisorType = {
  id?: string;
  employeeId?: string;
  role?: MultiLang;
};
type TeamType = TrustedAdvisorType[];

export type TeamProps = GenericFieldProps<TeamType>;

const TrustedAdvisor = ({
  value,
  disabled,
  onChange,
  language,
  supportedLanguage,
  employeesWithRole,
  hideNonWebsitePeople = false,
}) => {
  const { t } = useTranslation(["common", "onepager"]);

  const handleRoleChange = (newValue) => {
    const newAdvisorItem = {
      ...value,
      role: { ...value.role, [language]: newValue },
    };
    onChange(newAdvisorItem);
  };

  const handleEmployeeChange = (newValue) => {
    const newAdvisorItem = { ...value, employeeId: newValue };
    onChange(newAdvisorItem);
  };

  const defaultRole = employeesWithRole
    ?.map((e) => ({ id: e.id, role: e.roles?.[0]?.role?.name }))
    ?.find((e) => e.id === value.employeeId);

  return (
    <div className="flex items-center w-full space-x-2">
      <div className="flex-1">
        <EmployeesField
          value={value.employeeId}
          onChange={handleEmployeeChange}
          disabled={disabled}
          allEmployees={employeesWithRole}
          hideOptionIf={
            hideNonWebsitePeople
              ? (e) => e.isFormerEmployee || !e?.colorProfilePicture
              : undefined
          }
          isMulti={false}
        />
      </div>
      <div className="flex-1">
        <TextField
          disabled={disabled}
          onChange={handleRoleChange}
          placeholder={`${
            defaultRole?.role?.[supportedLanguage] ??
            t("onepager:block.noRoleDefined")
          }`}
          value={value.role?.[language] ?? ""}
          language={language}
        />
      </div>
    </div>
  );
};

const ExternalPerson = ({ value, disabled, onChange, language }) => {
  const { t } = useTranslation(["common", "onepager"]);

  const handleNameChange = (newValue) => {
    onChange({ ...value, externalName: newValue });
  };

  const handleRoleChange = (newValue) => {
    onChange({
      ...value,
      role: { ...value.role, [language]: newValue },
    });
  };

  return (
    <div className="flex items-center w-full space-x-2">
      <div className="flex-1">
        <TextField
          disabled={disabled}
          onChange={handleNameChange}
          placeholder={t("name")}
          value={value.externalName}
        />
      </div>
      <div className="flex-1">
        <TextField
          disabled={disabled}
          onChange={handleRoleChange}
          placeholder={t("onepager:block.noRoleDefined")}
          value={value.role?.[language] ?? ""}
          language={language}
        />
      </div>
    </div>
  );
};

const TeamMemberField = (props) => {
  if (props.value.externalName || props.value.externalName === "") {
    return <ExternalPerson {...props} />;
  } else {
    return <TrustedAdvisor {...props} />;
  }
};

const TeamField = ({
  value,
  onChange,
  language,
  supportedLanguage,
  disabled,
  hideNonWebsitePeople = false,
  allowExternalPeople = false,
}: TeamProps): JSX.Element => {
  const { t } = useTranslation(["common", "onepager"]);
  const { data } = useQuery(ALL_EMPLOYEES_WITH_ROLE_QUERY);
  const employeesWithRole = data && data.employees;

  const addNewItem = (personType) => {
    onChange([
      ...value,
      {
        id: `new-${Math.random()}`,
        employeeId: undefined,
        role: { de: "", fr: "", it: "", en: "" },
        externalName: personType === "external" ? "" : null,
        key: "new",
      },
    ]);
  };

  const AddComponent = () => {
    return (
      <div className="flex flex-row justify-end space-x-4">
        {allowExternalPeople ? (
          <SecondaryButton onClick={() => addNewItem("external")}>
            <MdAdd />
            <span className="ml-2">
              {t("onepager:block.addExternalPerson")}
            </span>
          </SecondaryButton>
        ) : null}
        <IconButton onClick={() => addNewItem("internal")} Icon={MdAdd}>
          {t("onepager:block.addTrustedAdvisor")}
        </IconButton>
      </div>
    );
  };

  return (
    <ListField
      value={(value ?? []).map((item) => ({
        ...item,
        key: `${item.id}`,
      }))}
      language={language}
      supportedLanguage={supportedLanguage}
      onChange={onChange}
      disabled={disabled}
      render={TeamMemberField}
      AddComponent={AddComponent}
      componentOptions={{
        employeesWithRole,
        hideNonWebsitePeople,
      }}
    />
  );
};

export const teamField: GenericField<TeamProps> = {
  renderer: TeamField,
  onBeforeSave: (value) =>
    value?.map((v) => ({
      role: extractMulti(v.role),
      employeeId: v.employeeId,
      externalName: v.externalName,
    })),
  equals: (
    a: Partial<EmployeeRoleNetwork>[],
    b: Partial<EmployeeRoleNetwork>[],
  ) => {
    return (
      (a
        ?.map(
          (v) =>
            `${v?.employeeId}-${stringifyMulti(v?.role)}-${v?.externalName}`,
        )
        .join(",") ?? "") ===
      (b
        ?.map(
          (v) =>
            `${v?.employeeId}-${stringifyMulti(v?.role)}-${v?.externalName}`,
        )
        .join(",") ?? "")
    );
  },
};
