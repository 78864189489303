import React from "react";
import classnames from "classnames";

import useScrollToHash from "helpers/useScrollToHash";
import { TopBar } from "./topbar";
import { FaSpinner } from "react-icons/fa";

// TYPE

type LayoutProps = {
  title?: string;
  loading?: boolean;
  noPadding?: boolean;
  children?: React.ReactNode;
};

// MAIN COMPONENT

const Layout: React.FC<LayoutProps> = ({
  title,
  loading,
  noPadding = false,
  children,
}) => {
  useScrollToHash();

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center w-screen h-screen">
          <div className="p-3 text-xl">
            <FaSpinner className="animate-spin" />
          </div>
        </div>
      ) : null}
      <TopBar />
      <div>
        {title && (
          <div className="sticky top-12 z-40">
            <div className="px-3 mx-auto max-w-screen-tbf">
              <div className="bg-white shadow-gray-500">
                <div className="py-10 text-base text-gray-600 leading-5 font-light">
                  <h2 className="text-4xl font-normal text-red-500">{title}</h2>
                </div>
              </div>
            </div>
            <div className="w-full mb-8 border border-b-gray-300" />
          </div>
        )}
        <div className={classnames({ "py-4 xl:py-8": !noPadding })}>
          <div>{children}</div>
        </div>
      </div>
    </>
  );
};

export default Layout;
