import React from "react";
import { useTranslation } from "react-i18next";
import { GenericFieldProps } from "./fieldProps";
import { MultiLang } from "helpers/ui/multiLang";
import { TextField } from "./textField";
import { extractMulti, stringifyMulti } from "helpers/ui/multiLang";
import { AdditionalField } from "generated/graphql";
import { MarkDownField } from "./markDownField";

type AdditionalFieldType = {
  id?: string;
  title?: MultiLang;
  content?: MultiLang;
};
type FieldType = AdditionalFieldType[];

export type AdditionalFieldProps = GenericFieldProps<FieldType>;

const AdditionalField = ({
  value,
  onChange,
  language,
}: AdditionalFieldProps): JSX.Element => {
  const { t } = useTranslation(["common", "form"]);

  const handleTitleChange = (newValue) => {
    const newField = {
      ...value,
      title: { ...value?.title, [language]: newValue },
    };
    onChange(newField);
  };

  const handleContentChange = (newValue) => {
    const newField = {
      ...value,
      content: { ...value?.content, [language]: newValue },
    };
    onChange(newField);
  };
  return (
    <div className="space-y-2">
      <TextField
        value={value?.title?.[language] ?? ""}
        placeholder={t("form:fieldName.employee.additionalField")}
        onChange={handleTitleChange}
        language={language}
      />
      <MarkDownField
        value={value?.content?.[language] ?? ""}
        onChange={handleContentChange}
        language={language}
      />
    </div>
  );
};

export const additionalField: GenericFieldProps<AdditionalFieldType> = {
  renderer: AdditionalField,
  onBeforeSave: (value) => {
    return {
      title: extractMulti(value?.title),
      content: extractMulti(value?.content),
    };
  },
  equals: (a: Partial<AdditionalField>, b: Partial<AdditionalField>) => {
    return (
      `${stringifyMulti(a?.title ?? {})}-${stringifyMulti(
        a?.content ?? {},
      )}` ===
      `${stringifyMulti(b?.title ?? {})}-${stringifyMulti(b?.content ?? {})}`
    );
  },
};
