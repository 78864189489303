export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: string; output: string; }
  DateTime: { input: string; output: string; }
  NaiveDateTime: { input: string; output: string; }
};

export type AbacusProject = {
  __typename?: 'AbacusProject';
  accountingId: Scalars['String']['output'];
  clientId: Maybe<Scalars['ID']['output']>;
  deputyProjectManagerId: Maybe<Scalars['ID']['output']>;
  invoicingResponsibleId: Maybe<Scalars['ID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  parentProjectId: Maybe<Scalars['ID']['output']>;
  portfolioManagerId: Maybe<Scalars['ID']['output']>;
  projectManagerId: Maybe<Scalars['ID']['output']>;
  status: Maybe<Scalars['String']['output']>;
};

export type AboutTbfBlock = {
  __typename?: 'AboutTbfBlock';
  exportedFields: Maybe<Array<Scalars['String']['output']>>;
  externalLinks: Maybe<Array<Link>>;
  facts: Maybe<Array<KeyValue>>;
  id: Scalars['ID']['output'];
  intro: Maybe<MultilangText>;
  linkedFields: Maybe<Array<Scalars['String']['output']>>;
  navigationTitle: MultilangText;
  showRosette: Maybe<Scalars['Boolean']['output']>;
  type: Maybe<Scalars['String']['output']>;
};

export type AdditionalField = {
  __typename?: 'AdditionalField';
  content: Maybe<MultilangText>;
  id: Scalars['ID']['output'];
  title: Maybe<MultilangText>;
};

export type AggregatedProjectTeamMember = {
  __typename?: 'AggregatedProjectTeamMember';
  employee: Maybe<Employee>;
  employeeId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  projectIds: Array<Scalars['ID']['output']>;
  references: Array<NewReference>;
};


export type AggregatedProjectTeamMemberReferencesArgs = {
  includeUnapproved: InputMaybe<Scalars['Boolean']['input']>;
};

export type AutomatedChanger = {
  __typename?: 'AutomatedChanger';
  id: Maybe<Scalars['String']['output']>;
  type: Maybe<Scalars['String']['output']>;
};

export type Block = AboutTbfBlock | IntermissionBlock | IntroBlock | JokerBlock | NetworkBlock | OutroBlock | ReferenceBlock;

export type BlockPicture = {
  __typename?: 'BlockPicture';
  contentType: Maybe<Scalars['String']['output']>;
  filename: Maybe<Scalars['String']['output']>;
  focusX: Maybe<Scalars['Float']['output']>;
  focusY: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  url: Maybe<Scalars['String']['output']>;
};


export type BlockPictureUrlArgs = {
  height: InputMaybe<Scalars['Int']['input']>;
  width: InputMaybe<Scalars['Int']['input']>;
};

export type BlockPictureWithCaption = {
  __typename?: 'BlockPictureWithCaption';
  caption: Maybe<MultilangText>;
  captionPosition: Maybe<CaptionPositions>;
  id: Scalars['ID']['output'];
  orientation: Maybe<Orientations>;
  picture: Maybe<BlockPicture>;
  pictureId: Maybe<Scalars['String']['output']>;
};

export enum CaptionPositions {
  After = 'AFTER',
  Before = 'BEFORE'
}

export type Client = VersionedEntity & {
  __typename?: 'Client';
  approved: Maybe<Client>;
  approvedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  approvedBy: Maybe<EntityChanger>;
  approvedEntityId: Maybe<Scalars['ID']['output']>;
  childClients: Array<Client>;
  city: Maybe<Scalars['String']['output']>;
  connectedFields: Array<ExternalConnection>;
  country: Maybe<Scalars['String']['output']>;
  createdBy: Maybe<EntityChanger>;
  deletedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  draft: Maybe<Client>;
  email: Maybe<Scalars['String']['output']>;
  employees: Array<Employee>;
  id: Scalars['ID']['output'];
  insertedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  isClient: Maybe<Scalars['Boolean']['output']>;
  isContact: Maybe<Scalars['Boolean']['output']>;
  isReadyForReview: Scalars['Boolean']['output'];
  isSupplier: Maybe<Scalars['Boolean']['output']>;
  isUnlisted: Maybe<Scalars['Boolean']['output']>;
  language: Maybe<Scalars['String']['output']>;
  modifiedBy: Maybe<EntityChanger>;
  name: Maybe<Scalars['String']['output']>;
  numSnapshots: Scalars['Int']['output'];
  phone: Maybe<Scalars['String']['output']>;
  postalCode: Maybe<Scalars['String']['output']>;
  projects: Array<NewProject>;
  snapshots: Array<Client>;
  street: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  town: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  version: Maybe<Scalars['Int']['output']>;
};


export type ClientChildClientsArgs = {
  includeUnapproved: InputMaybe<Scalars['Boolean']['input']>;
};


export type ClientEmployeesArgs = {
  sortBy: InputMaybe<SortBy>;
};


export type ClientProjectsArgs = {
  includeUnapproved: InputMaybe<Scalars['Boolean']['input']>;
};

export type CompetenceAssoc = {
  __typename?: 'CompetenceAssoc';
  employees: Array<Employee>;
  employeesCount: Scalars['Int']['output'];
  id: Maybe<Scalars['ID']['output']>;
  name: Maybe<MultilangText>;
};

export type CvEntry = {
  __typename?: 'CvEntry';
  description: Maybe<MultilangText>;
  from: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  institution: Maybe<MultilangText>;
  to: Maybe<Scalars['Date']['output']>;
};

export type DiplomaAssoc = {
  __typename?: 'DiplomaAssoc';
  employees: Array<Employee>;
  employeesCount: Scalars['Int']['output'];
  id: Maybe<Scalars['ID']['output']>;
  name: Maybe<MultilangText>;
};

export type Element = EmptyElement | PictureElement | RichtextElement;

export type Employee = VersionedEntity & {
  __typename?: 'Employee';
  aboutMe: Maybe<MultilangText>;
  activeDirectoryGuid: Maybe<Scalars['String']['output']>;
  activeDirectoryId: Maybe<Scalars['String']['output']>;
  additionalField: Maybe<AdditionalField>;
  aggregatedTags: Array<NoIdMultilangText>;
  approved: Maybe<Employee>;
  approvedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  approvedBy: Maybe<EntityChanger>;
  approvedEntityId: Maybe<Scalars['ID']['output']>;
  birthday: Maybe<Scalars['Date']['output']>;
  colorProfilePicture: Maybe<Scalars['String']['output']>;
  competences: Maybe<Array<EmployeeCompetence>>;
  connectedFields: Array<ExternalConnection>;
  countOpenDraftsCreatedByMe: Scalars['Int']['output'];
  createdBy: Maybe<EntityChanger>;
  cv: Maybe<Array<CvEntry>>;
  deletedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  diplomas: Maybe<Array<EmployeeDiploma>>;
  domains: Maybe<Array<Scalars['String']['output']>>;
  draft: Maybe<Employee>;
  email: Maybe<Scalars['String']['output']>;
  employeeNumber: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  formerEmployeeReverseRank: Maybe<Scalars['Int']['output']>;
  fullName: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  insertedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  isContentAdmin: Maybe<Scalars['Boolean']['output']>;
  isFormerEmployee: Maybe<Scalars['Boolean']['output']>;
  isReadyForReview: Scalars['Boolean']['output'];
  isTrustedAdvisor: Maybe<Scalars['Boolean']['output']>;
  isWebsiteEditor: Maybe<Scalars['Boolean']['output']>;
  isWikiEditor: Maybe<Scalars['Boolean']['output']>;
  languages: Maybe<Array<EmployeeLanguage>>;
  lastName: Maybe<Scalars['String']['output']>;
  linkedin: Maybe<Scalars['String']['output']>;
  modifiedBy: Maybe<EntityChanger>;
  numSnapshots: Scalars['Int']['output'];
  phone: Maybe<Scalars['String']['output']>;
  profilePicture: Maybe<Scalars['String']['output']>;
  projects: Array<NewProject>;
  projectsRank: Maybe<Scalars['Int']['output']>;
  publicProfileUrl: Maybe<Scalars['String']['output']>;
  references: Array<NewReference>;
  referencesRank: Maybe<Scalars['Int']['output']>;
  roles: Maybe<Array<EmployeeRole>>;
  services: Maybe<Array<Scalars['String']['output']>>;
  skills: Maybe<Array<EmployeeSkill>>;
  snapshots: Array<Employee>;
  starredReferences: Array<StarredReference>;
  trainings: Maybe<MultilangText>;
  translatedDomains: Maybe<Array<NoIdMultilangText>>;
  translatedServices: Maybe<Array<NoIdMultilangText>>;
  updatedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  updatedMultilangFields: Maybe<Array<UpdatedMultilangField>>;
  version: Maybe<Scalars['Int']['output']>;
};


export type EmployeeColorProfilePictureArgs = {
  size: Scalars['Int']['input'];
};


export type EmployeeCountOpenDraftsCreatedByMeArgs = {
  status?: InputMaybe<Scalars['String']['input']>;
};


export type EmployeeProfilePictureArgs = {
  public: InputMaybe<Scalars['Boolean']['input']>;
  size: Scalars['Int']['input'];
};


export type EmployeeReferencesArgs = {
  includeUnapproved: InputMaybe<Scalars['Boolean']['input']>;
};

export type EmployeeCompetence = {
  __typename?: 'EmployeeCompetence';
  competence: Maybe<CompetenceAssoc>;
  competenceId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  newText: Maybe<Scalars['String']['output']>;
};

export type EmployeeDiploma = {
  __typename?: 'EmployeeDiploma';
  diploma: Maybe<DiplomaAssoc>;
  diplomaId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  newText: Maybe<Scalars['String']['output']>;
  year: Maybe<Scalars['Int']['output']>;
};

export type EmployeeLanguage = {
  __typename?: 'EmployeeLanguage';
  id: Scalars['ID']['output'];
  language: LanguageAssoc;
  languageId: Scalars['ID']['output'];
  level: Maybe<Scalars['String']['output']>;
};

export type EmployeeRole = {
  __typename?: 'EmployeeRole';
  id: Scalars['ID']['output'];
  newText: Maybe<Scalars['String']['output']>;
  role: Maybe<RoleAssoc>;
  roleId: Maybe<Scalars['ID']['output']>;
};

export type EmployeeRoleNetwork = {
  __typename?: 'EmployeeRoleNetwork';
  employee: Maybe<Employee>;
  employeeId: Maybe<Scalars['ID']['output']>;
  externalName: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  role: Maybe<MultilangText>;
};

export type EmployeeSkill = {
  __typename?: 'EmployeeSkill';
  id: Scalars['ID']['output'];
  newText: Maybe<Scalars['String']['output']>;
  skill: Maybe<SkillAssoc>;
  skillId: Maybe<Scalars['ID']['output']>;
};

export type EmptyElement = {
  __typename?: 'EmptyElement';
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
};

export type EntityChanger = AutomatedChanger | Employee;

export type Evernote = {
  __typename?: 'Evernote';
  author: Maybe<Scalars['String']['output']>;
  content: Maybe<Scalars['String']['output']>;
  creatorId: Maybe<Scalars['String']['output']>;
  guid: Scalars['ID']['output'];
  notebook: Maybe<EvernoteNotebook>;
  notebookGuid: Scalars['ID']['output'];
  title: Maybe<Scalars['String']['output']>;
};

export type EvernoteNotebook = {
  __typename?: 'EvernoteNotebook';
  businessNotebookDescription: Maybe<Scalars['String']['output']>;
  contactName: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
};

export type ExternalConnection = {
  __typename?: 'ExternalConnection';
  field: Scalars['String']['output'];
  isConnected: Scalars['Boolean']['output'];
  previewValue: Maybe<Scalars['String']['output']>;
};

export type ExternalConnectionInput = {
  field: Scalars['String']['input'];
  isConnected: Scalars['Boolean']['input'];
};

export type Fact = {
  __typename?: 'Fact';
  id: Scalars['ID']['output'];
  inheritedFromId: Maybe<Scalars['ID']['output']>;
  key: Maybe<FactKeyAssoc>;
  keyId: Maybe<Scalars['ID']['output']>;
  newText: Maybe<Scalars['String']['output']>;
  value: Maybe<MultilangText>;
};

export type FactKeyAssoc = {
  __typename?: 'FactKeyAssoc';
  id: Maybe<Scalars['ID']['output']>;
  name: Maybe<MultilangText>;
};

export type File = {
  __typename?: 'File';
  data: Maybe<FileData>;
  fileContent: Maybe<FileContent>;
  openJwt: Maybe<Scalars['String']['output']>;
  revealJwt: Maybe<Scalars['String']['output']>;
  source: Maybe<Scalars['String']['output']>;
};

export type FileAttachment = VersionedEntity & {
  __typename?: 'FileAttachment';
  approved: Maybe<FileAttachment>;
  approvedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  approvedBy: Maybe<EntityChanger>;
  approvedEntityId: Maybe<Scalars['ID']['output']>;
  connectedFields: Array<ExternalConnection>;
  createdBy: Maybe<EntityChanger>;
  deletedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  draft: Maybe<FileAttachment>;
  fileDe: Maybe<FileUpload>;
  fileEn: Maybe<FileUpload>;
  fileFr: Maybe<FileUpload>;
  fileIt: Maybe<FileUpload>;
  id: Scalars['ID']['output'];
  insertedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  isReadyForReview: Scalars['Boolean']['output'];
  modifiedBy: Maybe<EntityChanger>;
  name: Maybe<MultilangText>;
  numSnapshots: Scalars['Int']['output'];
  snapshots: Array<FileAttachment>;
  updatedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  version: Maybe<Scalars['Int']['output']>;
};

export type FileContent = {
  __typename?: 'FileContent';
  content: Maybe<Scalars['String']['output']>;
  language: Maybe<Scalars['String']['output']>;
};

export type FileData = {
  __typename?: 'FileData';
  accountingId: Maybe<Scalars['String']['output']>;
  bucket: Maybe<Scalars['String']['output']>;
  cleanPath: Maybe<Scalars['String']['output']>;
  extension: Maybe<Scalars['String']['output']>;
  fileName: Maybe<Scalars['String']['output']>;
  metadata: Maybe<FileMetadata>;
  path: Maybe<Scalars['String']['output']>;
  permissions: Maybe<Array<Maybe<FilePermission>>>;
  posixPath: Maybe<Scalars['String']['output']>;
  projectId: Maybe<Scalars['String']['output']>;
  projectName: Maybe<Scalars['String']['output']>;
};

export type FileMetadata = {
  __typename?: 'FileMetadata';
  created: Maybe<Scalars['String']['output']>;
  lastAccessed: Maybe<Scalars['String']['output']>;
  lastChanged: Maybe<Scalars['String']['output']>;
  lastModified: Maybe<Scalars['String']['output']>;
  owner: Maybe<Scalars['String']['output']>;
};

export type FilePermission = {
  __typename?: 'FilePermission';
  execute: Maybe<Scalars['Boolean']['output']>;
  isInherited: Maybe<Scalars['Boolean']['output']>;
  ldapIdentity: Maybe<Scalars['String']['output']>;
  read: Maybe<Scalars['Boolean']['output']>;
  write: Maybe<Scalars['Boolean']['output']>;
};

export type FileUpload = {
  __typename?: 'FileUpload';
  filename: Maybe<Scalars['String']['output']>;
  format: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  url: Maybe<Scalars['String']['output']>;
};

export type IndexedBlock = {
  __typename?: 'IndexedBlock';
  body: Maybe<MultilangText>;
  title: Maybe<MultilangText>;
};

export type InputAboutTbfBlock = {
  exportedFields: InputMaybe<Array<Scalars['String']['input']>>;
  externalLinks: InputMaybe<Array<InputLink>>;
  facts: InputMaybe<Array<InputKeyValue>>;
  intro: InputMaybe<InputMultilangText>;
  linkedFields: InputMaybe<Array<Scalars['String']['input']>>;
  navigationTitle: InputMultilangText;
  numberOfEmployees: InputMaybe<Scalars['Int']['input']>;
  showRosette: InputMaybe<Scalars['Boolean']['input']>;
};

export type InputBlock = {
  aboutTbf: InputMaybe<InputAboutTbfBlock>;
  intermission: InputMaybe<InputIntermissionBlock>;
  intro: InputMaybe<InputIntroBlock>;
  joker: InputMaybe<InputJokerBlock>;
  network: InputMaybe<InputNetworkBlock>;
  outro: InputMaybe<InputOutroBlock>;
  reference: InputMaybe<InputReferenceBlock>;
  type: InputMaybe<Scalars['String']['input']>;
};

export type InputBlockPicture = {
  action: InputMaybe<Scalars['String']['input']>;
  filename: InputMaybe<Scalars['String']['input']>;
  focusX: InputMaybe<Scalars['Float']['input']>;
  focusY: InputMaybe<Scalars['Float']['input']>;
  id: InputMaybe<Scalars['ID']['input']>;
  url: InputMaybe<Scalars['String']['input']>;
};

export type InputBlockPictureWithCaption = {
  caption: InputMaybe<InputMultilangText>;
  captionPosition: InputMaybe<CaptionPositions>;
  orientation: InputMaybe<Orientations>;
  picture: InputMaybe<InputBlockPicture>;
};

export type InputClient = {
  connectedFields: InputMaybe<Array<ExternalConnectionInput>>;
  isReadyForReview: InputMaybe<Scalars['Boolean']['input']>;
  isUnlisted: InputMaybe<Scalars['Boolean']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
};

export type InputElement = {
  empty: InputMaybe<InputEmptyElement>;
  picture: InputMaybe<InputPictureElement>;
  richtext: InputMaybe<InputRichtextElement>;
  type: InputMaybe<Scalars['String']['input']>;
};

export type InputEmployee = {
  aboutMe: InputMaybe<InputMultilangText>;
  additionalField: InputMaybe<InputEmployeeAdditionalEntry>;
  birthday: InputMaybe<Scalars['Date']['input']>;
  competences: InputMaybe<Array<InputEmployeeCompetence>>;
  connectedFields: InputMaybe<Array<ExternalConnectionInput>>;
  cv: InputMaybe<Array<InputEmployeeCvEntry>>;
  diplomas: InputMaybe<Array<InputEmployeeDiploma>>;
  domains: InputMaybe<Array<Scalars['String']['input']>>;
  email: InputMaybe<Scalars['String']['input']>;
  firstName: InputMaybe<Scalars['String']['input']>;
  isReadyForReview: InputMaybe<Scalars['Boolean']['input']>;
  languages: InputMaybe<Array<InputEmployeeLanguage>>;
  lastName: InputMaybe<Scalars['String']['input']>;
  linkedin: InputMaybe<Scalars['String']['input']>;
  phone: InputMaybe<Scalars['String']['input']>;
  roles: InputMaybe<Array<InputEmployeeRole>>;
  services: InputMaybe<Array<Scalars['String']['input']>>;
  skills: InputMaybe<Array<InputEmployeeSkill>>;
  trainings: InputMaybe<InputMultilangText>;
  updatedMultilangFields: InputMaybe<Array<InputUpdatedMultilangField>>;
};

export type InputEmployeeAdditionalEntry = {
  content: InputMaybe<InputMultilangText>;
  title: InputMaybe<InputMultilangText>;
};

export type InputEmployeeCompetence = {
  competenceId: InputMaybe<Scalars['ID']['input']>;
  newText: InputMaybe<Scalars['String']['input']>;
};

export type InputEmployeeCvEntry = {
  description: InputMaybe<InputMultilangText>;
  from: InputMaybe<Scalars['Date']['input']>;
  institution: InputMaybe<InputMultilangText>;
  to: InputMaybe<Scalars['Date']['input']>;
};

export type InputEmployeeDiploma = {
  diplomaId: InputMaybe<Scalars['ID']['input']>;
  newText: InputMaybe<Scalars['String']['input']>;
  year: InputMaybe<Scalars['Int']['input']>;
};

export type InputEmployeeLanguage = {
  languageId: Scalars['ID']['input'];
  level: InputMaybe<Scalars['String']['input']>;
};

export type InputEmployeeRole = {
  newText: InputMaybe<Scalars['String']['input']>;
  roleId: InputMaybe<Scalars['ID']['input']>;
};

export type InputEmployeeRoleNetwork = {
  employeeId: InputMaybe<Scalars['ID']['input']>;
  externalName: InputMaybe<Scalars['String']['input']>;
  role: InputMaybe<InputMultilangText>;
};

export type InputEmployeeSkill = {
  newText: InputMaybe<Scalars['String']['input']>;
  skillId: InputMaybe<Scalars['ID']['input']>;
};

export type InputEmptyElement = {
  type: InputMaybe<Scalars['String']['input']>;
};

export type InputFact = {
  keyId: InputMaybe<Scalars['ID']['input']>;
  newText: InputMaybe<Scalars['String']['input']>;
  value: InputMaybe<InputMultilangText>;
};

export type InputFile = {
  filename: InputMaybe<Scalars['String']['input']>;
  url: InputMaybe<Scalars['String']['input']>;
};

export type InputFileAttachment = {
  connectedFields: InputMaybe<Array<ExternalConnectionInput>>;
  fileDe: InputMaybe<InputFile>;
  fileEn: InputMaybe<InputFile>;
  fileFr: InputMaybe<InputFile>;
  fileIt: InputMaybe<InputFile>;
  isReadyForReview: InputMaybe<Scalars['Boolean']['input']>;
  name: InputMaybe<InputMultilangText>;
};

export type InputIntermissionBlock = {
  externalLinks: InputMaybe<Array<InputLink>>;
  picture: InputMaybe<Scalars['String']['input']>;
  text: InputMaybe<InputMultilangText>;
  title: InputMaybe<InputMultilangText>;
};

export type InputIntroBlock = {
  externalLinks: InputMaybe<Array<InputLink>>;
  hideTbfLogo: InputMaybe<Scalars['Boolean']['input']>;
  intro: InputMaybe<InputMultilangText>;
  logo: InputMaybe<InputBlockPicture>;
  navigationTitle: InputMultilangText;
  welcome: InputMaybe<InputMultilangText>;
};

export type InputJokerBlock = {
  elements: InputMaybe<Array<InputElement>>;
  externalLinks: InputMaybe<Array<InputLink>>;
  layout: InputMaybe<Array<InputLayout>>;
  navigationTitle: InputMultilangText;
};

export type InputKeyValue = {
  itemKey: InputMaybe<InputMultilangText>;
  type: InputMaybe<KeyValueType>;
  value: InputMaybe<InputMultilangText>;
};

export type InputLayout = {
  id: Scalars['ID']['input'];
  index: InputMaybe<Scalars['Int']['input']>;
  indexes: InputMaybe<Array<Scalars['Int']['input']>>;
  type: Scalars['String']['input'];
};

export type InputLink = {
  name: InputMaybe<InputMultilangText>;
  url: InputMaybe<Scalars['String']['input']>;
};

export type InputMultilangText = {
  de: InputMaybe<Scalars['String']['input']>;
  en: InputMaybe<Scalars['String']['input']>;
  fr: InputMaybe<Scalars['String']['input']>;
  it: InputMaybe<Scalars['String']['input']>;
};

export type InputNetworkBlock = {
  centerLogo: InputMaybe<InputBlockPicture>;
  centerText: InputMaybe<InputMultilangText>;
  centerType: InputMaybe<Scalars['String']['input']>;
  externalLinks: InputMaybe<Array<InputLink>>;
  intro: InputMaybe<InputMultilangText>;
  navigationTitle: InputMultilangText;
  teamLevel1: InputMaybe<Array<InputEmployeeRoleNetwork>>;
  teamLevel2: InputMaybe<Array<InputEmployeeRoleNetwork>>;
  teamLevel3: InputMaybe<Array<InputEmployeeRoleNetwork>>;
  title: InputMaybe<InputMultilangText>;
};

export type InputNewProject = {
  adminDeputyProjectManagerId: InputMaybe<Scalars['String']['input']>;
  adminProjectManagerId: InputMaybe<Scalars['String']['input']>;
  applicationAreas: InputMaybe<Array<Scalars['String']['input']>>;
  clientHidden: InputMaybe<Scalars['Boolean']['input']>;
  clientId: InputMaybe<Scalars['ID']['input']>;
  connectedFields: InputMaybe<Array<ExternalConnectionInput>>;
  country: InputMaybe<Scalars['String']['input']>;
  description: InputMaybe<InputMultilangText>;
  enrichedFrom: InputMaybe<Array<Scalars['ID']['input']>>;
  facts: InputMaybe<Array<InputMaybe<InputFact>>>;
  frPhases: InputMaybe<Array<Scalars['String']['input']>>;
  hoaiPhases: InputMaybe<Array<Scalars['String']['input']>>;
  investment: InputMaybe<Scalars['Int']['input']>;
  investmentCurrency: InputMaybe<Scalars['String']['input']>;
  invoicingResponsibleId: InputMaybe<Scalars['String']['input']>;
  isReadyForReview: InputMaybe<Scalars['Boolean']['input']>;
  isUnlisted: InputMaybe<Scalars['Boolean']['input']>;
  itPhases: InputMaybe<Array<Scalars['String']['input']>>;
  name: InputMaybe<InputMultilangText>;
  portfolioManagerId: InputMaybe<Scalars['String']['input']>;
  primaryPhase: InputMaybe<Scalars['String']['input']>;
  relatedProjectIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  services: InputMaybe<Array<Scalars['String']['input']>>;
  siaPhases: InputMaybe<Array<Scalars['String']['input']>>;
  status: InputMaybe<Scalars['String']['input']>;
};

export type InputNewReference = {
  applicationAreas: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  connectedFields: InputMaybe<Array<ExternalConnectionInput>>;
  description: InputMaybe<InputMultilangText>;
  employeeId: InputMaybe<Scalars['ID']['input']>;
  facts: InputMaybe<Array<InputMaybe<InputFact>>>;
  frPhases: InputMaybe<Array<Scalars['String']['input']>>;
  from: InputMaybe<Scalars['Date']['input']>;
  hoaiPhases: InputMaybe<Array<Scalars['String']['input']>>;
  internalNote: InputMaybe<Scalars['String']['input']>;
  isReadyForReview: InputMaybe<Scalars['Boolean']['input']>;
  itPhases: InputMaybe<Array<Scalars['String']['input']>>;
  parentReferenceId: InputMaybe<Scalars['ID']['input']>;
  picture: InputMaybe<InputPicture>;
  pictureCredit: InputMaybe<Scalars['String']['input']>;
  primaryPhase: InputMaybe<Scalars['String']['input']>;
  privateReferenceProjectApplicationAreas: InputMaybe<Array<Scalars['String']['input']>>;
  privateReferenceProjectClientName: InputMaybe<Scalars['String']['input']>;
  privateReferenceProjectCountry: InputMaybe<Scalars['String']['input']>;
  privateReferenceProjectDescription: InputMaybe<InputMultilangText>;
  privateReferenceProjectFacts: InputMaybe<Array<InputMaybe<InputFact>>>;
  privateReferenceProjectFrPhases: InputMaybe<Array<Scalars['String']['input']>>;
  privateReferenceProjectFrom: InputMaybe<Scalars['Date']['input']>;
  privateReferenceProjectHoaiPhases: InputMaybe<Array<Scalars['String']['input']>>;
  privateReferenceProjectInvestment: InputMaybe<Scalars['Int']['input']>;
  privateReferenceProjectInvestmentCurrency: InputMaybe<Scalars['String']['input']>;
  privateReferenceProjectItPhases: InputMaybe<Array<Scalars['String']['input']>>;
  privateReferenceProjectName: InputMaybe<InputMultilangText>;
  privateReferenceProjectPrimaryPhase: InputMaybe<Scalars['String']['input']>;
  privateReferenceProjectServices: InputMaybe<Array<Scalars['String']['input']>>;
  privateReferenceProjectSiaPhases: InputMaybe<Array<Scalars['String']['input']>>;
  privateReferenceProjectTo: InputMaybe<Scalars['Date']['input']>;
  projectId: InputMaybe<Scalars['ID']['input']>;
  roles: InputMaybe<Array<InputReferenceRole>>;
  services: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siaPhases: InputMaybe<Array<Scalars['String']['input']>>;
  title: InputMaybe<InputMultilangText>;
  to: InputMaybe<Scalars['Date']['input']>;
};

export type InputOnepager = {
  blocks: InputMaybe<Array<InputBlock>>;
  browserTitle: InputMaybe<Scalars['String']['input']>;
  connectedFields: InputMaybe<Array<ExternalConnectionInput>>;
  institution: InputMaybe<Scalars['String']['input']>;
  isReadyForReview: InputMaybe<Scalars['Boolean']['input']>;
  person: InputMaybe<Scalars['String']['input']>;
  supportedLanguages: InputMaybe<Array<Scalars['String']['input']>>;
  tags: InputMaybe<Array<InputOnepagerTag>>;
  targetCountry: InputMaybe<Scalars['String']['input']>;
  title: InputMaybe<Scalars['String']['input']>;
};

export type InputOnepagerTag = {
  newText: InputMaybe<Scalars['String']['input']>;
  tagId: InputMaybe<Scalars['ID']['input']>;
};

export type InputOutroBlock = {
  exportedFields: InputMaybe<Array<Scalars['String']['input']>>;
  externalLinks: InputMaybe<Array<InputLink>>;
  linkedFields: InputMaybe<Array<Scalars['String']['input']>>;
  message: InputMaybe<InputMultilangText>;
  navigationTitle: InputMaybe<InputMultilangText>;
  publicProfileUrl: InputMaybe<Scalars['String']['input']>;
  trustedAdvisorId: InputMaybe<Scalars['ID']['input']>;
};

export type InputPicture = {
  action: InputMaybe<Scalars['String']['input']>;
  filename: InputMaybe<Scalars['String']['input']>;
  focusX: InputMaybe<Scalars['Float']['input']>;
  focusY: InputMaybe<Scalars['Float']['input']>;
  id: InputMaybe<Scalars['ID']['input']>;
  url: InputMaybe<Scalars['String']['input']>;
};

export type InputPictureElement = {
  caption: InputMaybe<InputMultilangText>;
  captionPosition: InputMaybe<CaptionPositions>;
  orientation: InputMaybe<Orientations>;
  picture: InputMaybe<InputBlockPicture>;
  type: InputMaybe<Scalars['String']['input']>;
};

export type InputProjectTeamMember = {
  connectedFields: InputMaybe<Array<ExternalConnectionInput>>;
  employeeId: Scalars['ID']['input'];
  hours: InputMaybe<Scalars['Float']['input']>;
  isReadyForReview: InputMaybe<Scalars['Boolean']['input']>;
  projectId: Scalars['ID']['input'];
  role: InputMaybe<Scalars['String']['input']>;
};

export type InputReferenceBlock = {
  applicationAreas: InputMaybe<Array<Scalars['String']['input']>>;
  exportedFields: InputMaybe<Array<Scalars['String']['input']>>;
  externalLinks: InputMaybe<Array<InputLink>>;
  facts: InputMaybe<Array<InputFact>>;
  frPhases: InputMaybe<Array<Scalars['String']['input']>>;
  from: InputMaybe<Scalars['Date']['input']>;
  gallery: InputMaybe<Array<InputBlockPictureWithCaption>>;
  headline: InputMaybe<InputMultilangText>;
  hoaiPhases: InputMaybe<Array<Scalars['String']['input']>>;
  itPhases: InputMaybe<Array<Scalars['String']['input']>>;
  linkedFields: InputMaybe<Array<Scalars['String']['input']>>;
  navigationTitle: InputMultilangText;
  picture: InputMaybe<InputPicture>;
  primaryPhase: InputMaybe<Scalars['String']['input']>;
  referenceId: InputMaybe<Scalars['ID']['input']>;
  services: InputMaybe<Array<Scalars['String']['input']>>;
  siaPhases: InputMaybe<Array<Scalars['String']['input']>>;
  team: InputMaybe<Array<InputEmployeeRoleNetwork>>;
  title: InputMaybe<InputMultilangText>;
  to: InputMaybe<Scalars['Date']['input']>;
};

export type InputReferenceRole = {
  newText: InputMaybe<Scalars['String']['input']>;
  referenceRoleId: InputMaybe<Scalars['ID']['input']>;
};

export type InputRichtextElement = {
  data: InputMaybe<InputMultilangText>;
  type: InputMaybe<Scalars['String']['input']>;
};

export type InputUpdatedMultilangField = {
  field: Scalars['String']['input'];
  updatedLanguages: InputMaybe<Array<Scalars['String']['input']>>;
};

export type InputWikiPage = {
  connectedFields: InputMaybe<Array<ExternalConnectionInput>>;
  contentHtml: InputMaybe<InputMultilangText>;
  employeeIdsToAsk: InputMaybe<Array<Scalars['ID']['input']>>;
  isReadyForReview: InputMaybe<Scalars['Boolean']['input']>;
  parentPageId: InputMaybe<Scalars['ID']['input']>;
  slugs: InputMaybe<Array<Scalars['String']['input']>>;
  tags: InputMaybe<Array<InputWikiTag>>;
  title: InputMaybe<InputMultilangText>;
};

export type InputWikiTag = {
  newText: InputMaybe<Scalars['String']['input']>;
  tagId: InputMaybe<Scalars['ID']['input']>;
};

export type IntermissionBlock = {
  __typename?: 'IntermissionBlock';
  externalLinks: Maybe<Array<Link>>;
  id: Scalars['ID']['output'];
  picture: Maybe<Scalars['String']['output']>;
  text: Maybe<MultilangText>;
  title: Maybe<MultilangText>;
  type: Maybe<Scalars['String']['output']>;
};

export type IntroBlock = {
  __typename?: 'IntroBlock';
  externalLinks: Maybe<Array<Link>>;
  hideTbfLogo: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  intro: Maybe<MultilangText>;
  logo: Maybe<BlockPicture>;
  logoId: Maybe<Scalars['ID']['output']>;
  navigationTitle: MultilangText;
  type: Maybe<Scalars['String']['output']>;
  welcome: Maybe<MultilangText>;
};

export type JokerBlock = {
  __typename?: 'JokerBlock';
  elements: Maybe<Array<Element>>;
  externalLinks: Maybe<Array<Link>>;
  id: Scalars['ID']['output'];
  layout: Maybe<Array<Layout>>;
  navigationTitle: MultilangText;
  type: Maybe<Scalars['String']['output']>;
};

export type KeyValue = {
  __typename?: 'KeyValue';
  id: Scalars['ID']['output'];
  itemKey: Maybe<MultilangText>;
  type: Maybe<KeyValueType>;
  value: Maybe<MultilangText>;
};

export enum KeyValueType {
  Competences = 'COMPETENCES',
  Custom = 'CUSTOM',
  Independence = 'INDEPENDENCE',
  Map = 'MAP',
  NumEmployees = 'NUM_EMPLOYEES'
}

export type LanguageAssoc = {
  __typename?: 'LanguageAssoc';
  employees: Array<Employee>;
  employeesCount: Scalars['Int']['output'];
  id: Maybe<Scalars['ID']['output']>;
  name: Maybe<MultilangText>;
};

export type Layout = {
  __typename?: 'Layout';
  id: Scalars['ID']['output'];
  index: Maybe<Scalars['Int']['output']>;
  indexes: Maybe<Array<Scalars['Int']['output']>>;
  type: Scalars['String']['output'];
};

export type Link = {
  __typename?: 'Link';
  id: Scalars['ID']['output'];
  name: Maybe<MultilangText>;
  url: Maybe<Scalars['String']['output']>;
};

export type MultilangTag = {
  __typename?: 'MultilangTag';
  id: Scalars['ID']['output'];
  name: Maybe<MultilangText>;
};

export type MultilangText = {
  __typename?: 'MultilangText';
  de: Maybe<Scalars['String']['output']>;
  en: Maybe<Scalars['String']['output']>;
  fr: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  it: Maybe<Scalars['String']['output']>;
};

export type MultilangWikiAttachments = {
  __typename?: 'MultilangWikiAttachments';
  de: Maybe<Array<WikiAttachment>>;
  en: Maybe<Array<WikiAttachment>>;
  fr: Maybe<Array<WikiAttachment>>;
  it: Maybe<Array<WikiAttachment>>;
};

export type NetworkBlock = {
  __typename?: 'NetworkBlock';
  centerLogo: Maybe<BlockPicture>;
  centerText: Maybe<MultilangText>;
  centerType: Maybe<Scalars['String']['output']>;
  externalLinks: Maybe<Array<Link>>;
  id: Scalars['ID']['output'];
  intro: Maybe<MultilangText>;
  navigationTitle: MultilangText;
  teamLevel1: Maybe<Array<EmployeeRoleNetwork>>;
  teamLevel2: Maybe<Array<EmployeeRoleNetwork>>;
  teamLevel3: Maybe<Array<EmployeeRoleNetwork>>;
  title: Maybe<MultilangText>;
  type: Maybe<Scalars['String']['output']>;
};

export type NewArchiveItem = {
  __typename?: 'NewArchiveItem';
  archiveNumber: Maybe<Scalars['String']['output']>;
  dateCreated: Maybe<Scalars['Date']['output']>;
  description: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  internalNumber: Maybe<Scalars['String']['output']>;
  keywords: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  location: Maybe<Scalars['String']['output']>;
  phase: Maybe<Scalars['String']['output']>;
  room: Maybe<Scalars['String']['output']>;
  shelf: Maybe<Scalars['String']['output']>;
  type: Maybe<Scalars['String']['output']>;
};

export type NewProject = VersionedEntity & {
  __typename?: 'NewProject';
  abacusProject: Maybe<AbacusProject>;
  abacusProjectId: Maybe<Scalars['String']['output']>;
  adminDeputyProjectManager: Maybe<Employee>;
  adminDeputyProjectManagerId: Maybe<Scalars['String']['output']>;
  adminProjectManager: Maybe<Employee>;
  adminProjectManagerId: Maybe<Scalars['String']['output']>;
  aggregatedTeam: Array<AggregatedProjectTeamMember>;
  applicationAreas: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  approved: Maybe<NewProject>;
  approvedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  approvedBy: Maybe<EntityChanger>;
  approvedEntityId: Maybe<Scalars['ID']['output']>;
  archiveItems: Maybe<Array<Maybe<NewArchiveItem>>>;
  archivedProjectId: Maybe<Scalars['ID']['output']>;
  client: Maybe<Client>;
  clientHidden: Maybe<Scalars['Boolean']['output']>;
  clientId: Maybe<Scalars['ID']['output']>;
  connectedFields: Array<ExternalConnection>;
  country: Maybe<Scalars['String']['output']>;
  createdBy: Maybe<EntityChanger>;
  deletedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  departmentId: Maybe<Scalars['Int']['output']>;
  description: Maybe<MultilangText>;
  draft: Maybe<NewProject>;
  employees: Array<Employee>;
  enrichedFrom: Maybe<Array<Scalars['ID']['output']>>;
  facts: Maybe<Array<Maybe<Fact>>>;
  frPhases: Maybe<Array<Scalars['String']['output']>>;
  from: Maybe<Scalars['Date']['output']>;
  hoaiPhases: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  insertedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  investment: Maybe<Scalars['Int']['output']>;
  investmentCurrency: Maybe<Scalars['String']['output']>;
  invoicingResponsible: Maybe<Employee>;
  invoicingResponsibleId: Maybe<Scalars['String']['output']>;
  isInternalProject: Maybe<Scalars['Boolean']['output']>;
  isReadyForReview: Scalars['Boolean']['output'];
  isUnlisted: Maybe<Scalars['Boolean']['output']>;
  itPhases: Maybe<Array<Scalars['String']['output']>>;
  modifiedBy: Maybe<EntityChanger>;
  name: Maybe<MultilangText>;
  numSnapshots: Scalars['Int']['output'];
  parentProjects: Array<NewProject>;
  portfolioManager: Maybe<Employee>;
  portfolioManagerId: Maybe<Scalars['String']['output']>;
  primaryPhase: Scalars['String']['output'];
  projectFolderLink: Maybe<Scalars['String']['output']>;
  projectId: Maybe<Scalars['String']['output']>;
  projectMainReferencesCount: Maybe<Scalars['Int']['output']>;
  projectReferences: Array<NewReference>;
  projectType: Maybe<Scalars['String']['output']>;
  references: Array<NewReference>;
  referencesRank: Maybe<Scalars['Int']['output']>;
  relatedProjectIds: Maybe<Array<Scalars['ID']['output']>>;
  relatedProjects: Array<NewProject>;
  services: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  siaPhases: Maybe<Array<Scalars['String']['output']>>;
  snapshots: Array<NewProject>;
  status: Maybe<Scalars['String']['output']>;
  teamRank: Maybe<Scalars['Int']['output']>;
  teamSize: Maybe<Scalars['Int']['output']>;
  to: Maybe<Scalars['Date']['output']>;
  updatedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  version: Maybe<Scalars['Int']['output']>;
};


export type NewProjectAggregatedTeamArgs = {
  includeUnapproved: InputMaybe<Scalars['Boolean']['input']>;
};


export type NewProjectEmployeesArgs = {
  sortBy: InputMaybe<SortBy>;
};


export type NewProjectProjectReferencesArgs = {
  includeUnapproved: InputMaybe<Scalars['Boolean']['input']>;
};


export type NewProjectReferencesArgs = {
  includeUnapproved: InputMaybe<Scalars['Boolean']['input']>;
};

export type NewReference = VersionedEntity & {
  __typename?: 'NewReference';
  applicationAreas: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  approved: Maybe<NewReference>;
  approvedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  approvedBy: Maybe<EntityChanger>;
  approvedEntityId: Maybe<Scalars['ID']['output']>;
  childReferences: Array<NewReference>;
  connectedFields: Array<ExternalConnection>;
  createdBy: Maybe<EntityChanger>;
  deletedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  description: Maybe<MultilangText>;
  draft: Maybe<NewReference>;
  employee: Maybe<Employee>;
  employeeId: Maybe<Scalars['ID']['output']>;
  facts: Maybe<Array<Maybe<Fact>>>;
  frPhases: Maybe<Array<Scalars['String']['output']>>;
  from: Maybe<Scalars['Date']['output']>;
  hoaiPhases: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  inheritedFacts: Maybe<Array<Maybe<Fact>>>;
  insertedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  internalNote: Maybe<Scalars['String']['output']>;
  isReadyForReview: Scalars['Boolean']['output'];
  isStarredForCurrentUser: Scalars['Boolean']['output'];
  itPhases: Maybe<Array<Scalars['String']['output']>>;
  modifiedBy: Maybe<EntityChanger>;
  numSnapshots: Scalars['Int']['output'];
  parentReference: Maybe<NewReference>;
  parentReferenceId: Maybe<Scalars['ID']['output']>;
  picture: Maybe<Picture>;
  pictureCredit: Maybe<Scalars['String']['output']>;
  primaryPhase: Scalars['String']['output'];
  privateReferenceProjectApplicationAreas: Maybe<Array<Scalars['String']['output']>>;
  privateReferenceProjectClientName: Maybe<Scalars['String']['output']>;
  privateReferenceProjectCountry: Maybe<Scalars['String']['output']>;
  privateReferenceProjectDescription: Maybe<MultilangText>;
  privateReferenceProjectFacts: Maybe<Array<Maybe<Fact>>>;
  privateReferenceProjectFrPhases: Maybe<Array<Scalars['String']['output']>>;
  privateReferenceProjectFrom: Maybe<Scalars['Date']['output']>;
  privateReferenceProjectHoaiPhases: Maybe<Array<Scalars['String']['output']>>;
  privateReferenceProjectInvestment: Maybe<Scalars['Int']['output']>;
  privateReferenceProjectInvestmentCurrency: Maybe<Scalars['String']['output']>;
  privateReferenceProjectItPhases: Maybe<Array<Scalars['String']['output']>>;
  privateReferenceProjectName: Maybe<MultilangText>;
  privateReferenceProjectPrimaryPhase: Scalars['String']['output'];
  privateReferenceProjectServices: Maybe<Array<Scalars['String']['output']>>;
  privateReferenceProjectSiaPhases: Maybe<Array<Scalars['String']['output']>>;
  privateReferenceProjectTo: Maybe<Scalars['Date']['output']>;
  project: Maybe<NewProject>;
  projectId: Maybe<Scalars['ID']['output']>;
  roles: Maybe<Array<ReferenceRole>>;
  services: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  siaPhases: Maybe<Array<Scalars['String']['output']>>;
  snapshots: Array<NewReference>;
  title: Maybe<MultilangText>;
  to: Maybe<Scalars['Date']['output']>;
  updatedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  version: Maybe<Scalars['Int']['output']>;
};


export type NewReferenceChildReferencesArgs = {
  includeUnapproved: InputMaybe<Scalars['Boolean']['input']>;
};

export type NoIdMultilangText = {
  __typename?: 'NoIdMultilangText';
  de: Maybe<Scalars['String']['output']>;
  en: Maybe<Scalars['String']['output']>;
  fr: Maybe<Scalars['String']['output']>;
  it: Maybe<Scalars['String']['output']>;
};

export type Notion = {
  __typename?: 'Notion';
  content: Maybe<Scalars['String']['output']>;
  employeeAccountingIds: Maybe<Array<Scalars['String']['output']>>;
  /** Returns all employees that have worked on the note. Use this only when queriyng one note since it does not use dataloader */
  employees: Maybe<Array<Employee>>;
  id: Scalars['ID']['output'];
  title: Maybe<Scalars['String']['output']>;
  url: Maybe<Scalars['String']['output']>;
};

export type Onepager = VersionedEntity & {
  __typename?: 'Onepager';
  approved: Maybe<Onepager>;
  approvedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  approvedBy: Maybe<EntityChanger>;
  approvedEntityId: Maybe<Scalars['ID']['output']>;
  blocks: Maybe<Array<Block>>;
  browserTitle: Maybe<Scalars['String']['output']>;
  connectedFields: Array<ExternalConnection>;
  createdBy: Maybe<EntityChanger>;
  deletedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  draft: Maybe<Onepager>;
  id: Scalars['ID']['output'];
  indexedBlocks: Maybe<Array<IndexedBlock>>;
  insertedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  institution: Maybe<Scalars['String']['output']>;
  isReadyForReview: Scalars['Boolean']['output'];
  modifiedBy: Maybe<EntityChanger>;
  numSnapshots: Scalars['Int']['output'];
  person: Maybe<Scalars['String']['output']>;
  previousPublication: Maybe<Publication>;
  publication: Maybe<Publication>;
  snapshots: Array<Onepager>;
  supportedLanguages: Maybe<Array<Scalars['String']['output']>>;
  tags: Maybe<Array<OnepagerTag>>;
  targetCountry: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  version: Maybe<Scalars['Int']['output']>;
};

export type OnepagerTag = {
  __typename?: 'OnepagerTag';
  id: Scalars['ID']['output'];
  newText: Maybe<Scalars['String']['output']>;
  tag: Maybe<MultilangTag>;
  tagId: Maybe<Scalars['ID']['output']>;
};

export enum Orientations {
  Landscape = 'LANDSCAPE',
  Portrait = 'PORTRAIT'
}

export type OutroBlock = {
  __typename?: 'OutroBlock';
  exportedFields: Maybe<Array<Scalars['String']['output']>>;
  externalLinks: Maybe<Array<Link>>;
  id: Scalars['ID']['output'];
  linkedFields: Maybe<Array<Scalars['String']['output']>>;
  message: Maybe<MultilangText>;
  navigationTitle: MultilangText;
  trustedAdvisor: Maybe<Employee>;
  trustedAdvisorId: Maybe<Scalars['ID']['output']>;
  type: Maybe<Scalars['String']['output']>;
};

export type Picture = {
  __typename?: 'Picture';
  filename: Maybe<Scalars['String']['output']>;
  focusX: Maybe<Scalars['Float']['output']>;
  focusY: Maybe<Scalars['Float']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  url: Maybe<Scalars['String']['output']>;
};


export type PictureUrlArgs = {
  height: InputMaybe<Scalars['Int']['input']>;
  width: InputMaybe<Scalars['Int']['input']>;
};

export type PictureElement = {
  __typename?: 'PictureElement';
  caption: Maybe<MultilangText>;
  captionPosition: Maybe<CaptionPositions>;
  id: Scalars['ID']['output'];
  orientation: Maybe<Orientations>;
  picture: Maybe<BlockPicture>;
  pictureId: Maybe<Scalars['ID']['output']>;
  type: Scalars['String']['output'];
};

export type ProfessionalAssociationAssoc = {
  __typename?: 'ProfessionalAssociationAssoc';
  id: Maybe<Scalars['ID']['output']>;
  name: Maybe<MultilangText>;
};

export type ProjectTeamMember = VersionedEntity & {
  __typename?: 'ProjectTeamMember';
  approved: Maybe<ProjectTeamMember>;
  approvedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  approvedBy: Maybe<EntityChanger>;
  approvedEntityId: Maybe<Scalars['ID']['output']>;
  connectedFields: Array<ExternalConnection>;
  createdBy: Maybe<EntityChanger>;
  deletedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  draft: Maybe<ProjectTeamMember>;
  employee: Maybe<Employee>;
  employeeId: Scalars['ID']['output'];
  hours: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  insertedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  isReadyForReview: Scalars['Boolean']['output'];
  modifiedBy: Maybe<EntityChanger>;
  numSnapshots: Scalars['Int']['output'];
  project: Maybe<NewProject>;
  projectId: Scalars['ID']['output'];
  references: Array<NewReference>;
  role: Maybe<Scalars['String']['output']>;
  snapshots: Array<ProjectTeamMember>;
  updatedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  version: Maybe<Scalars['Int']['output']>;
};


export type ProjectTeamMemberReferencesArgs = {
  includeUnapproved: InputMaybe<Scalars['Boolean']['input']>;
};

export type Publication = {
  __typename?: 'Publication';
  id: Scalars['ID']['output'];
  onepager: Maybe<Onepager>;
  onepagerId: Scalars['ID']['output'];
  status: PublicationStatus;
  token: Scalars['String']['output'];
  updatedAt: Maybe<Scalars['NaiveDateTime']['output']>;
};

export enum PublicationStatus {
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED'
}

export type ReferenceBlock = {
  __typename?: 'ReferenceBlock';
  applicationAreas: Maybe<Array<Scalars['String']['output']>>;
  exportedFields: Maybe<Array<Scalars['String']['output']>>;
  externalLinks: Maybe<Array<Link>>;
  facts: Maybe<Array<Fact>>;
  frPhases: Maybe<Array<Scalars['String']['output']>>;
  from: Maybe<Scalars['Date']['output']>;
  gallery: Maybe<Array<BlockPictureWithCaption>>;
  headline: Maybe<MultilangText>;
  hoaiPhases: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  itPhases: Maybe<Array<Scalars['String']['output']>>;
  linkedFields: Maybe<Array<Scalars['String']['output']>>;
  navigationTitle: MultilangText;
  picture: Maybe<BlockPicture>;
  primaryPhase: Maybe<Scalars['String']['output']>;
  reference: Maybe<NewReference>;
  referenceId: Maybe<Scalars['ID']['output']>;
  services: Maybe<Array<Scalars['String']['output']>>;
  siaPhases: Maybe<Array<Scalars['String']['output']>>;
  team: Maybe<Array<EmployeeRoleNetwork>>;
  title: Maybe<MultilangText>;
  to: Maybe<Scalars['Date']['output']>;
  type: Maybe<Scalars['String']['output']>;
};

export type ReferenceRole = {
  __typename?: 'ReferenceRole';
  id: Scalars['ID']['output'];
  newText: Maybe<Scalars['String']['output']>;
  referenceRole: Maybe<ReferenceRoleAssoc>;
  referenceRoleId: Maybe<Scalars['ID']['output']>;
};

export type ReferenceRoleAssoc = {
  __typename?: 'ReferenceRoleAssoc';
  id: Maybe<Scalars['ID']['output']>;
  name: Maybe<MultilangText>;
  references: Array<NewReference>;
  referencesCount: Scalars['Int']['output'];
};

export type RichtextElement = {
  __typename?: 'RichtextElement';
  data: Maybe<MultilangText>;
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
};

export type RoleAssoc = {
  __typename?: 'RoleAssoc';
  employees: Array<Employee>;
  employeesCount: Scalars['Int']['output'];
  id: Maybe<Scalars['ID']['output']>;
  name: Maybe<MultilangText>;
};

export type RootMutationType = {
  __typename?: 'RootMutationType';
  addStarredReference: Maybe<StarredReference>;
  applyClientDraft: Maybe<Client>;
  applyEmployeeDraft: Maybe<Employee>;
  applyNewProjectDraft: Maybe<NewProject>;
  applyNewReferenceDraft: Maybe<NewReference>;
  applyProjectTeamMemberDraft: Maybe<ProjectTeamMember>;
  copyOnepager: Maybe<Onepager>;
  createApprovedFileAttachment: Maybe<FileAttachment>;
  createApprovedOnepager: Maybe<Onepager>;
  createApprovedWikiPage: Maybe<WikiPage>;
  createCompetence: Maybe<CompetenceAssoc>;
  createDiploma: Maybe<DiplomaAssoc>;
  createFactKey: Maybe<FactKeyAssoc>;
  createLanguage: Maybe<LanguageAssoc>;
  createNewProject: Maybe<NewProject>;
  createNewReference: Maybe<NewReference>;
  createProjectTeamMember: Maybe<ProjectTeamMember>;
  createReferenceRole: Maybe<ReferenceRoleAssoc>;
  createRole: Maybe<RoleAssoc>;
  /** Create a shared link to a file */
  createSharedFile: Maybe<SharedFile>;
  createSkill: Maybe<SkillAssoc>;
  deleteAndApproveOnepager: Maybe<Onepager>;
  deleteAndApproveWikiPage: Maybe<WikiPage>;
  deleteCompetence: Maybe<CompetenceAssoc>;
  deleteDiploma: Maybe<DiplomaAssoc>;
  deleteFactKey: Maybe<FactKeyAssoc>;
  deleteLanguage: Maybe<LanguageAssoc>;
  deleteNewProject: Maybe<NewProject>;
  deleteNewReference: Maybe<NewReference>;
  deleteProjectTeamMember: Maybe<ProjectTeamMember>;
  deleteReferenceRole: Maybe<ReferenceRoleAssoc>;
  deleteRole: Maybe<RoleAssoc>;
  /** Delete a shared link */
  deleteSharedFile: Maybe<SharedFile>;
  deleteSkill: Maybe<SkillAssoc>;
  deleteWikiPageWithSubPages: Maybe<WikiPage>;
  editAndApproveEmployee: Maybe<Employee>;
  editAndApproveFileAttachment: Maybe<FileAttachment>;
  editAndApproveNewReference: Maybe<NewReference>;
  editAndApproveOnepager: Maybe<Onepager>;
  editAndApproveWikiPage: Maybe<WikiPage>;
  editClient: Maybe<Client>;
  editEmployee: Maybe<Employee>;
  editNewProject: Maybe<NewProject>;
  editNewReference: Maybe<NewReference>;
  editProjectTeamMember: Maybe<ProjectTeamMember>;
  /** Manually index a file */
  indexFile: Maybe<Scalars['String']['output']>;
  mergeCompetence: Maybe<Scalars['Int']['output']>;
  mergeDiploma: Maybe<Scalars['Int']['output']>;
  mergeFactKey: Maybe<Scalars['Int']['output']>;
  mergeLanguage: Maybe<Scalars['Int']['output']>;
  mergeReferenceRole: Maybe<Scalars['Int']['output']>;
  mergeRole: Maybe<Scalars['Int']['output']>;
  mergeSkill: Maybe<Scalars['Int']['output']>;
  publishOnepager: Maybe<Publication>;
  rejectClientDraft: Maybe<Client>;
  rejectEmployeeDraft: Maybe<Employee>;
  rejectNewProjectDraft: Maybe<NewProject>;
  rejectNewReferenceDraft: Maybe<NewReference>;
  rejectProjectTeamMemberDraft: Maybe<ProjectTeamMember>;
  removeStarredReference: Maybe<StarredReference>;
  renameSlugForWikiPageWithSubPages: Maybe<WikiPage>;
  restoreDeletedNewProject: Maybe<NewProject>;
  restoreDeletedOnepager: Maybe<Onepager>;
  restoreWikiPage: Maybe<WikiPage>;
  unPublishOnepager: Maybe<Publication>;
  updateCompetence: Maybe<CompetenceAssoc>;
  updateDiploma: Maybe<DiplomaAssoc>;
  updateFactKey: Maybe<FactKeyAssoc>;
  updateLanguage: Maybe<LanguageAssoc>;
  updateReferenceRole: Maybe<ReferenceRoleAssoc>;
  updateRole: Maybe<RoleAssoc>;
  updateSkill: Maybe<SkillAssoc>;
  wikiSetSubPagesOrder: Maybe<WikiPage>;
};


export type RootMutationTypeAddStarredReferenceArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeApplyClientDraftArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeApplyEmployeeDraftArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeApplyNewProjectDraftArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeApplyNewReferenceDraftArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeApplyProjectTeamMemberDraftArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeCopyOnepagerArgs = {
  fields: InputMaybe<InputOnepager>;
  id: Scalars['ID']['input'];
};


export type RootMutationTypeCreateApprovedFileAttachmentArgs = {
  fields: InputMaybe<InputFileAttachment>;
};


export type RootMutationTypeCreateApprovedOnepagerArgs = {
  fields: InputMaybe<InputOnepager>;
};


export type RootMutationTypeCreateApprovedWikiPageArgs = {
  fields: InputMaybe<InputWikiPage>;
};


export type RootMutationTypeCreateCompetenceArgs = {
  multiLang: InputMaybe<InputMultilangText>;
};


export type RootMutationTypeCreateDiplomaArgs = {
  multiLang: InputMaybe<InputMultilangText>;
};


export type RootMutationTypeCreateFactKeyArgs = {
  multiLang: InputMaybe<InputMultilangText>;
};


export type RootMutationTypeCreateLanguageArgs = {
  multiLang: InputMaybe<InputMultilangText>;
};


export type RootMutationTypeCreateNewProjectArgs = {
  fields: InputMaybe<InputNewProject>;
};


export type RootMutationTypeCreateNewReferenceArgs = {
  fields: InputMaybe<InputNewReference>;
};


export type RootMutationTypeCreateProjectTeamMemberArgs = {
  fields: InputMaybe<InputProjectTeamMember>;
};


export type RootMutationTypeCreateReferenceRoleArgs = {
  multiLang: InputMaybe<InputMultilangText>;
};


export type RootMutationTypeCreateRoleArgs = {
  multiLang: InputMaybe<InputMultilangText>;
};


export type RootMutationTypeCreateSharedFileArgs = {
  expiresAt: InputMaybe<Scalars['DateTime']['input']>;
  isOneTime: InputMaybe<Scalars['Boolean']['input']>;
  path: Scalars['String']['input'];
};


export type RootMutationTypeCreateSkillArgs = {
  multiLang: InputMaybe<InputMultilangText>;
};


export type RootMutationTypeDeleteAndApproveOnepagerArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteAndApproveWikiPageArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteCompetenceArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteDiplomaArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteFactKeyArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteLanguageArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteNewProjectArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteNewReferenceArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteProjectTeamMemberArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteReferenceRoleArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteRoleArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteSharedFileArgs = {
  token: Scalars['String']['input'];
};


export type RootMutationTypeDeleteSkillArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteWikiPageWithSubPagesArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeEditAndApproveEmployeeArgs = {
  fields: InputMaybe<InputEmployee>;
  id: Scalars['ID']['input'];
};


export type RootMutationTypeEditAndApproveFileAttachmentArgs = {
  fields: InputMaybe<InputFileAttachment>;
  id: Scalars['ID']['input'];
};


export type RootMutationTypeEditAndApproveNewReferenceArgs = {
  fields: InputMaybe<InputNewReference>;
  id: Scalars['ID']['input'];
};


export type RootMutationTypeEditAndApproveOnepagerArgs = {
  fields: InputMaybe<InputOnepager>;
  id: Scalars['ID']['input'];
};


export type RootMutationTypeEditAndApproveWikiPageArgs = {
  fields: InputMaybe<InputWikiPage>;
  id: Scalars['ID']['input'];
};


export type RootMutationTypeEditClientArgs = {
  fields: InputMaybe<InputClient>;
  id: Scalars['ID']['input'];
};


export type RootMutationTypeEditEmployeeArgs = {
  fields: InputMaybe<InputEmployee>;
  id: Scalars['ID']['input'];
};


export type RootMutationTypeEditNewProjectArgs = {
  fields: InputMaybe<InputNewProject>;
  id: Scalars['ID']['input'];
};


export type RootMutationTypeEditNewReferenceArgs = {
  fields: InputMaybe<InputNewReference>;
  id: Scalars['ID']['input'];
};


export type RootMutationTypeEditProjectTeamMemberArgs = {
  fields: InputMaybe<InputProjectTeamMember>;
  id: Scalars['ID']['input'];
};


export type RootMutationTypeIndexFileArgs = {
  path: Scalars['String']['input'];
};


export type RootMutationTypeMergeCompetenceArgs = {
  leftId: Scalars['ID']['input'];
  rightId: Scalars['ID']['input'];
};


export type RootMutationTypeMergeDiplomaArgs = {
  leftId: Scalars['ID']['input'];
  rightId: Scalars['ID']['input'];
};


export type RootMutationTypeMergeFactKeyArgs = {
  leftId: Scalars['ID']['input'];
  rightId: Scalars['ID']['input'];
};


export type RootMutationTypeMergeLanguageArgs = {
  leftId: Scalars['ID']['input'];
  rightId: Scalars['ID']['input'];
};


export type RootMutationTypeMergeReferenceRoleArgs = {
  leftId: Scalars['ID']['input'];
  rightId: Scalars['ID']['input'];
};


export type RootMutationTypeMergeRoleArgs = {
  leftId: Scalars['ID']['input'];
  rightId: Scalars['ID']['input'];
};


export type RootMutationTypeMergeSkillArgs = {
  leftId: Scalars['ID']['input'];
  rightId: Scalars['ID']['input'];
};


export type RootMutationTypePublishOnepagerArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeRejectClientDraftArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeRejectEmployeeDraftArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeRejectNewProjectDraftArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeRejectNewReferenceDraftArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeRejectProjectTeamMemberDraftArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeRemoveStarredReferenceArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeRenameSlugForWikiPageWithSubPagesArgs = {
  id: Scalars['ID']['input'];
  newSlug: Scalars['String']['input'];
};


export type RootMutationTypeRestoreDeletedNewProjectArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeRestoreDeletedOnepagerArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeRestoreWikiPageArgs = {
  id: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
};


export type RootMutationTypeUnPublishOnepagerArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeUpdateCompetenceArgs = {
  id: Scalars['ID']['input'];
  multiLang: InputMaybe<InputMultilangText>;
};


export type RootMutationTypeUpdateDiplomaArgs = {
  id: Scalars['ID']['input'];
  multiLang: InputMaybe<InputMultilangText>;
};


export type RootMutationTypeUpdateFactKeyArgs = {
  id: Scalars['ID']['input'];
  multiLang: InputMaybe<InputMultilangText>;
};


export type RootMutationTypeUpdateLanguageArgs = {
  id: Scalars['ID']['input'];
  multiLang: InputMaybe<InputMultilangText>;
};


export type RootMutationTypeUpdateReferenceRoleArgs = {
  id: Scalars['ID']['input'];
  multiLang: InputMaybe<InputMultilangText>;
};


export type RootMutationTypeUpdateRoleArgs = {
  id: Scalars['ID']['input'];
  multiLang: InputMaybe<InputMultilangText>;
};


export type RootMutationTypeUpdateSkillArgs = {
  id: Scalars['ID']['input'];
  multiLang: InputMaybe<InputMultilangText>;
};


export type RootMutationTypeWikiSetSubPagesOrderArgs = {
  newPageOrder: Array<Scalars['ID']['input']>;
  topLevelId: Scalars['ID']['input'];
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  allOnepagers: Array<Onepager>;
  allProjectReferences: Maybe<Array<Maybe<NewReference>>>;
  client: Maybe<Client>;
  clientDrafts: Maybe<Array<Maybe<Client>>>;
  clients: Maybe<Array<Maybe<Client>>>;
  competence: Maybe<CompetenceAssoc>;
  /** List of all available competences */
  competences: Maybe<Array<Maybe<CompetenceAssoc>>>;
  countDrafts: Scalars['Int']['output'];
  countOutdatedTranslations: Scalars['Int']['output'];
  diploma: Maybe<DiplomaAssoc>;
  /** List of all available diplomas */
  diplomas: Maybe<Array<Maybe<DiplomaAssoc>>>;
  employee: Maybe<Employee>;
  employeeDrafts: Maybe<Array<Maybe<Employee>>>;
  employees: Maybe<Array<Maybe<Employee>>>;
  /** return all employees that have some multilang field that has been updated but not translated yet */
  employeesWithOutdatedTranslations: Maybe<Array<Maybe<Employee>>>;
  /** List of all available factKeys */
  factKeys: Maybe<Array<Maybe<FactKeyAssoc>>>;
  file: Maybe<File>;
  fileAttachment: Maybe<FileAttachment>;
  language: Maybe<LanguageAssoc>;
  /** List of all available languages */
  languages: Maybe<Array<Maybe<LanguageAssoc>>>;
  linkTransformer: Maybe<Scalars['String']['output']>;
  /** Get current logged in employee */
  me: Maybe<Employee>;
  newProject: Maybe<NewProject>;
  newProjectDrafts: Maybe<Array<Maybe<NewProject>>>;
  newProjects: Maybe<Array<Maybe<NewProject>>>;
  note: Maybe<Evernote>;
  notion: Maybe<Notion>;
  onepager: Maybe<Onepager>;
  onlineFile: Maybe<FileContent>;
  parentWikiPage: Maybe<WikiPage>;
  /** List of all available professional associations */
  professionalAssociations: Maybe<Array<Maybe<ProfessionalAssociationAssoc>>>;
  projectTeamMember: Maybe<ProjectTeamMember>;
  projectTeamMemberDrafts: Maybe<Array<Maybe<ProjectTeamMember>>>;
  reference: Maybe<NewReference>;
  referenceDrafts: Maybe<Array<Maybe<NewReference>>>;
  referenceRole: Maybe<ReferenceRoleAssoc>;
  /** List of all available reference roles */
  referenceRoles: Maybe<Array<Maybe<ReferenceRoleAssoc>>>;
  role: Maybe<RoleAssoc>;
  /** List of all available roles */
  roles: Maybe<Array<Maybe<RoleAssoc>>>;
  sharedFile: Maybe<SharedFile>;
  sharedFiles: Maybe<Array<Maybe<SharedFile>>>;
  skill: Maybe<SkillAssoc>;
  /** List of all available skills */
  skills: Maybe<Array<Maybe<SkillAssoc>>>;
  wikiPage: Maybe<WikiPage>;
  wikiTags: Maybe<Array<MultilangTag>>;
};


export type RootQueryTypeAllOnepagersArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  onepagerType: Scalars['String']['input'];
};


export type RootQueryTypeClientArgs = {
  abacusId: InputMaybe<Scalars['ID']['input']>;
  id: InputMaybe<Scalars['ID']['input']>;
};


export type RootQueryTypeClientDraftsArgs = {
  createdBy: InputMaybe<Scalars['Int']['input']>;
  includeUnlisted: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeClientsArgs = {
  includeUnlisted: InputMaybe<Scalars['Boolean']['input']>;
};


export type RootQueryTypeCompetenceArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypeCountDraftsArgs = {
  status?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeDiplomaArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypeEmployeeArgs = {
  abacusId: InputMaybe<Scalars['ID']['input']>;
  activeDirectoryId: InputMaybe<Scalars['String']['input']>;
  employeeNumber: InputMaybe<Scalars['ID']['input']>;
  id: InputMaybe<Scalars['ID']['input']>;
};


export type RootQueryTypeEmployeeDraftsArgs = {
  createdBy: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeFileArgs = {
  path: Scalars['String']['input'];
};


export type RootQueryTypeFileAttachmentArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypeLanguageArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypeLinkTransformerArgs = {
  url: Scalars['String']['input'];
};


export type RootQueryTypeNewProjectArgs = {
  accountingId: InputMaybe<Scalars['ID']['input']>;
  archivedProjectId: InputMaybe<Scalars['ID']['input']>;
  id: InputMaybe<Scalars['ID']['input']>;
};


export type RootQueryTypeNewProjectDraftsArgs = {
  createdBy: InputMaybe<Scalars['Int']['input']>;
  includeUnlisted: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeNewProjectsArgs = {
  includeUnlisted: InputMaybe<Scalars['Boolean']['input']>;
};


export type RootQueryTypeNoteArgs = {
  guid: Scalars['ID']['input'];
};


export type RootQueryTypeNotionArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypeOnepagerArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypeOnlineFileArgs = {
  path: Scalars['String']['input'];
};


export type RootQueryTypeParentWikiPageArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
  slugs: InputMaybe<Array<Scalars['String']['input']>>;
};


export type RootQueryTypeProjectTeamMemberArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypeProjectTeamMemberDraftsArgs = {
  createdBy: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeReferenceArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypeReferenceDraftsArgs = {
  createdBy: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeReferenceRoleArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypeRoleArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypeSharedFileArgs = {
  token: Scalars['String']['input'];
};


export type RootQueryTypeSkillArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypeWikiPageArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
  slugs: InputMaybe<Array<Scalars['String']['input']>>;
  version: InputMaybe<Scalars['Int']['input']>;
};

export type SharedFile = {
  __typename?: 'SharedFile';
  downloadCount: Maybe<Scalars['Int']['output']>;
  expiresAt: Maybe<Scalars['NaiveDateTime']['output']>;
  externalLink: Maybe<Scalars['String']['output']>;
  insertedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  isOneTime: Maybe<Scalars['Boolean']['output']>;
  ownerActiveDirectoryId: Maybe<Scalars['String']['output']>;
  path: Maybe<Scalars['String']['output']>;
  tbfLink: Maybe<Scalars['String']['output']>;
  token: Maybe<Scalars['String']['output']>;
};

export type SkillAssoc = {
  __typename?: 'SkillAssoc';
  employees: Array<Employee>;
  employeesCount: Scalars['Int']['output'];
  id: Maybe<Scalars['ID']['output']>;
  name: Maybe<MultilangText>;
};

export enum SortBy {
  Relevance = 'RELEVANCE'
}

export type StarredReference = {
  __typename?: 'StarredReference';
  employee: Maybe<Employee>;
  employeeId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  reference: Maybe<NewReference>;
  referenceId: Scalars['ID']['output'];
};

export type UpdatedMultilangField = {
  __typename?: 'UpdatedMultilangField';
  field: Scalars['String']['output'];
  updatedLanguages: Maybe<Array<Scalars['String']['output']>>;
};

export type VersionedEntity = {
  approved: Maybe<VersionedEntity>;
  approvedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  approvedBy: Maybe<EntityChanger>;
  approvedEntityId: Maybe<Scalars['ID']['output']>;
  connectedFields: Array<ExternalConnection>;
  createdBy: Maybe<EntityChanger>;
  deletedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  draft: Maybe<VersionedEntity>;
  id: Scalars['ID']['output'];
  insertedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  isReadyForReview: Scalars['Boolean']['output'];
  modifiedBy: Maybe<EntityChanger>;
  updatedAt: Maybe<Scalars['NaiveDateTime']['output']>;
};

export type WikiAttachment = {
  __typename?: 'WikiAttachment';
  fileContent: Maybe<MultilangText>;
  id: Maybe<Scalars['String']['output']>;
  name: Maybe<MultilangText>;
};

export type WikiPage = VersionedEntity & {
  __typename?: 'WikiPage';
  allParentPages: Array<Maybe<WikiPage>>;
  approved: Maybe<WikiPage>;
  approvedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  approvedBy: Maybe<EntityChanger>;
  approvedEntityId: Maybe<Scalars['ID']['output']>;
  connectedFields: Array<ExternalConnection>;
  contentHtml: Maybe<MultilangText>;
  createdBy: Maybe<EntityChanger>;
  deletedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  draft: Maybe<WikiPage>;
  employeeIdsToAsk: Maybe<Array<Scalars['ID']['output']>>;
  employeesToAsk: Array<Employee>;
  extractedHtmlContent: Maybe<MultilangText>;
  htmlAttachments: Maybe<MultilangWikiAttachments>;
  id: Scalars['ID']['output'];
  insertedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  isReadyForReview: Scalars['Boolean']['output'];
  modifiedBy: Maybe<EntityChanger>;
  numSnapshots: Scalars['Int']['output'];
  parentPage: Maybe<WikiPage>;
  parentPageId: Maybe<Scalars['ID']['output']>;
  slugs: Array<Scalars['String']['output']>;
  snapshots: Array<WikiPage>;
  subPagePosition: Maybe<Scalars['Int']['output']>;
  subPages: Array<WikiPage>;
  subPagesRecursive: Array<WikiPage>;
  tagNames: Maybe<Array<MultilangText>>;
  tags: Maybe<Array<WikiTag>>;
  title: Maybe<MultilangText>;
  updatedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  version: Maybe<Scalars['Int']['output']>;
};

export type WikiTag = {
  __typename?: 'WikiTag';
  id: Scalars['ID']['output'];
  newText: Maybe<Scalars['String']['output']>;
  tag: Maybe<MultilangTag>;
  tagId: Maybe<Scalars['ID']['output']>;
};
