import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import { GenericField, GenericFieldProps } from "./fieldProps";
import { ListField } from "./ListField";
import { TextField } from "./textField";
import { MarkDownField } from "./markDownField";
import { MultiLang } from "helpers/ui/multiLang";
import { extractMulti, stringifyMulti } from "helpers/ui/multiLang";
import { KeyValueType } from "generated/graphql";
import { customStyles, i18nOptions } from "./multiSelectField";

type KeyValueFieldType = {
  id?: string;
  itemKey: MultiLang;
  type: KeyValueType;
  value: MultiLang;
};

type KeyValuesType = KeyValueFieldType[];

export type LinksFieldProps = GenericFieldProps<KeyValuesType>;

// HELPER COMPONENT

const KeyValue = ({ value, disabled, onChange, language }) => {
  const { t } = useTranslation(["common", "onepager"]);

  const handleKeyChange = (newValue) => {
    onChange({
      ...value,
      itemKey: { ...value.itemKey, [language]: newValue },
    });
  };

  const handleValueChange = (newValue) => {
    onChange({
      ...value,
      value: { ...value.value, [language]: newValue },
    });
  };

  const handleTypeChange = (newValue) => {
    // Reset all other values
    onChange({
      ...value,
      value: undefined,
      itemKey: undefined,
      type: newValue.value,
    });
  };

  const getInitialText = (type: string) => {
    if (
      ["NUM_EMPLOYEES", "MAP", "INDEPENDENCE", "COMPETENCES"].includes(type)
    ) {
      return t(`onepager:aboutTbfBlock.placeholder.${type}` as any);
    } else {
      return "";
    }
  };

  const optionMap = (o: string) => ({
    value: o,
    label: o ? t(`onepager:select.factType.${o}` as any) : "",
  });

  return (
    <>
      <div className="flex space-x-2">
        <div className="flex-1 text-sm">
          <Select
            styles={customStyles}
            options={Object.values(KeyValueType)?.map(optionMap)}
            isDisabled={disabled}
            {...i18nOptions(t)}
            value={{
              value: value.type,
              label: value.type
                ? t(`onepager:select.factType.${value.type}` as any)
                : "",
            }}
            onChange={handleTypeChange}
          />
        </div>
        {value?.type === "CUSTOM" ? (
          <div className="flex-1 text-sm">
            <TextField
              placeholder={"key"}
              disabled={disabled}
              onChange={handleKeyChange}
              value={value.itemKey?.[language] ?? ""}
              language={language}
            />
          </div>
        ) : null}
      </div>

      <div className="mt-2 text-sm">
        <MarkDownField
          initialText={getInitialText(value.type ?? "")}
          value={value.value?.[language] ?? ""}
          onChange={handleValueChange}
        />
      </div>
    </>
  );
};

const KeyValueField = ({
  value,
  onChange,
  language,
  disabled,
}: LinksFieldProps): JSX.Element => {
  const newItem = {
    value: { de: "", fr: "", it: "", en: "" },
    itemKey: { de: "", fr: "", it: "", en: "" },
    key: "new",
  };

  return (
    <ListField
      value={(value ?? []).map((item, i) => ({
        ...item,
        key: item.id || `${i}`,
      }))}
      language={language}
      onChange={onChange}
      disabled={disabled}
      render={KeyValue}
      newItem={newItem}
      catalogue={[]}
      vertical={true}
    />
  );
};

export const keyValueField: GenericField<KeyValuesType> = {
  renderer: KeyValueField,
  onBeforeSave: (value) =>
    value?.map((v) => ({
      value: extractMulti(v.value),
      type: v.type,
      itemKey: extractMulti(v.itemKey),
    })),
  equals: (a, b) => {
    return (
      (a
        ?.map(
          (v) =>
            `${stringifyMulti(v.value)}-${stringifyMulti(v.itemKey)}-${v.type}`,
        )
        .join(",") ?? "") ===
      (b
        ?.map(
          (v) =>
            `${stringifyMulti(v.value)}-${stringifyMulti(v.itemKey)}-${v.type}`,
        )
        .join(",") ?? "")
    );
  },
};
