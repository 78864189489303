import React from "react";
import { GenericFieldProps } from "./fieldProps";
import { WikiPage } from "generated/graphql";
import { IconButton } from "components/button";
import { getTextFromMultiLang } from "helpers/multiLang";
import { FaLink, FaUnlink } from "react-icons/fa";
import { useState } from "react";
import { useEffect } from "react";

export const SlugField = ({
  value,
  onChange,
  disabled = false,
  language,
  entity,
}: GenericFieldProps<string[], WikiPage>) => {
  const baseSlug = value?.slice(0, -1) ?? [];
  const slug = value?.[value?.length - 1] ?? "";

  const m = getTextFromMultiLang(language ?? "de");
  const [inferFromTitle, setInferFromTitle] = useState<boolean>(
    !(slug.length > 0),
  );
  const title = m(entity?.title);

  useEffect(() => {
    if (inferFromTitle && onChange) {
      onChange([...baseSlug, slugify(title)]);
    }
  }, [title]);

  return (
    <div className="flex flex-row space-x-2">
      <input
        type="text"
        disabled={disabled || inferFromTitle}
        className="block w-full p-2 border rounded-md shadow-sm outline-none border-grey-300 focus:ring-grey-900 focus:ring-2 focus:border-grey-900 sm:text-sm"
        value={inferFromTitle ? slugify(title) : slug}
        onChange={(e) =>
          onChange && onChange([...baseSlug, e.currentTarget.value])
        }
      />
      <IconButton
        Icon={inferFromTitle ? FaLink : FaUnlink}
        disabled={disabled}
        onClick={() => {
          setInferFromTitle(!inferFromTitle);
          if (!inferFromTitle && onChange) {
            onChange([...baseSlug, slugify(title)]);
          }
        }}
      />
    </div>
  );
};

// https://gist.github.com/mathewbyrne/1280286#gistcomment-2005392
export const slugify = (text: string) => {
  const a = "àáäâèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;";
  const b = "aaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------";
  const p = new RegExp(a.split("").join("|"), "g");
  return text
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special chars
    .replace(/[^\w-]+/g, "") // Remove all non-word chars
    .replace(/-+/g, "-"); // collapse dashes
};

export const slugField = {
  renderer: SlugField,
  equals: (a: string[], b: string[]) => {
    return (a?.join(",") ?? "") === (b?.join(",") ?? "");
  },
};
