// CONFIG

export const ROLES_KEY_ORDER = [
  "role_10",
  "role_11",
  "role_20",
  "role_21",
  "role_30",
  "role_31",
  "role_40",
  "role_41",
  "role_50",
  "role_51",
  "role_60",
  "role_61",
  "role_70",
  "role_71",
  "role_80",
  "role_81",
  "role_90",
  "role_91",
  "role_100",
  "role_101",
  "role_110",
  "role_111",
  "role_120",
  "role_121",
  "role_130",
  "role_131",
  "role_0",
];
